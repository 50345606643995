<template>
    <div class="a-table" :class="{'is-loading': isLoading}">
        <div v-if="$slots.header" class="a-table__header">
            <slot name="header" />
        </div>

        <div class="a-table__content">
            <table class="a-table__table">
                <slot name="table-header" :rows="{rows: headRows}" />
                <slot name="table-body" :rows="{rows: bodyRows}" />
            </table>

            <div v-if="isLoading" class="a-table__spinner"><icon name="Spinner" /></div>
            <div v-if="!isLoading && !bodyRows.length" class="a-table__empty">
                <slot name="empty" />
            </div>
        </div>

        <div v-if="$slots.footer" class="a-table__footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    components: {Icon},
    props: {
        isLoading: {
            type: Boolean,
            default: true
        },
        headRows: {
            type: Array,
            default: () => ([
                [
                    {
                        key: 'head-1',
                        content: 'head 1'
                    }, {
                        key: 'head-2',
                        content: 'head 2',
                        resizing: 'fill'
                    }, {
                        key: 'head-3',
                        content: 'head 3',
                        resizing: 'hug'
                    }
                ]
            ])
        },
        bodyRows: {
            type: Array,
            default: () => {
                const row = [
                    {
                        key: 'body-1',
                        content: 'body 1'
                    }, {
                        key: 'body-2',
                        content: 'body 2',
                        resizing: 'fill'
                    }, {
                        key: 'body-3',
                        content: 'body 3',
                        resizing: 'hug'
                    }
                ];

                return new Array(10).fill(row);
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.a-table {

    &.is-loading {
        opacity: .6;
    }

    @at-root {
        .a-table__content {
            position: relative;
            min-height: 152px;
            border-radius: 2px;
            width: min-content;
            min-width: 100%;
            padding: 8px 16px 24px;
            background: var.$color-utils-background;
        }

        .a-table__empty {
            display: flex;
            justify-content: center;
            padding: 24px 24px 8px 24px;
        }

        .a-table__spinner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 58px 24px 8px 24px;

            > svg {
                animation: .86s linear 0s infinite normal none running animation-loading-spinner;
            }
        }

        .a-table__table {
            table-layout: auto;
            width: 100%;
        }

        .a-table-body {
            min-height: 80px;
        }

        .a-table__footer {
            display: flex;
            align-items: center;
            padding: 24px 0;
            color: var.$color-text-disabled;
            font-size: 1rem;

            > :not(:root) {
                flex-basis: calc((1 / 3) * 100%);
                max-width: calc((1 / 3) * 100%);
            }
        }
    }
}
</style>
