<template>
    <div class="a-modal-footer"><slot /></div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss">
.a-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 8px;
    padding: 16px 24px;
}
</style>
