<template>
    <ul
        ref="list"
        class="a-menu-list"
        role="menu" tabindex="-1"
        :aria-labelledby="$attrs['aria-labelledby']"
        @keydown.up.prevent="methods.focusControl(arrowKeyControlSupport.previous)"
        @keydown.down.prevent="methods.focusControl(arrowKeyControlSupport.next)"
        @keydown.esc="$emit('close', $event)"
        @focusout="$event.currentTarget.contains($event.relatedTarget) ? undefined : $emit('close', $event)"
    >
        <template v-for="(menu, index) in menus" :key="menu">
            <li v-if="menu.isSeparator" role="separator" class="a-menu-list__item" />
            <li
                v-else
                role="presentation"
                class="a-menu-list__item"
            >
                <slot name="menu-item" :index="index" :item="{...menu, tabindex: focusIndex === index ? 0 : -1}" />
            </li>
        </template>
    </ul>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, nextTick, computed, watch
} from 'vue';

export default defineComponent({
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        },
        menus: {
            type: Array,
            default: () => ([
                {label: 'Menu 1'},
                {label: 'Menu 2', appearance: 'link', afterIcon: 'Chevron Arrow Right Small'},
                {isSeparator: true},
                {label: 'Menu 3', appearance: 'warning', beforeIcon: 'Warning'},
                {isSeparator: true},
                {label: 'Menu 4', appearance: 'danger', beforeIcon: 'Delete'}
            ])
        }
    },
    setup(props) {
        const list = ref(null);
        const focusIndex = ref(props.menus.findIndex((menu) => !menu.isSeparator && !menu.disabled) || 0);
        const arrowKeyControlSupport = computed(() => {
            const current = focusIndex.value;
            let validCurrentIndex = 0;

            const validMenuIndex = props.menus.reduce((array, menu, index) => {
                if (!menu.isSeparator && !menu.disabled) {
                    array.push(index);
                }

                if (index === current) {
                    validCurrentIndex = array.length - 1;
                }

                return array;
            }, []);

            return {
                previous: validCurrentIndex - 1 >= 0 ? validMenuIndex[validCurrentIndex - 1] : validMenuIndex.slice(-1)[0],
                next: validMenuIndex[validCurrentIndex + 1] || validMenuIndex[0]
            };
        });
        const methods = {
            focusControl(index) {
                focusIndex.value = index;

                nextTick(() => {
                    list.value.querySelector('[tabindex="0"]').focus();
                });
            }
        };

        watch(() => props.isExpanded, () => {
            focusIndex.value = props.menus.findIndex((menu) => !menu.isSeparator && !menu.disabled) || 0;
        });

        return {
            list, arrowKeyControlSupport, focusIndex, methods
        };
    }
});
</script>

<style lang="scss" scoped>
.a-menu-list {
    display: inline-flex;
    flex-direction: column;

    @at-root {
        .a-menu-list__item {

            &[role="separator"] {
                width: 100%;
                margin: 8px auto;
                border-bottom: solid 1px #F2F6F9;
            }
        }
    }
}
</style>
