import Tables from './Tables.vue';
import TableHead from './TableHead.vue';
import TableBody from './TableBody.vue';
import TableRow from './TableRow.vue';
import TableCell from './TableCell.vue';

export {
    TableHead, TableBody, TableRow, TableCell
};
export default Tables;
