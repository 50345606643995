<template>
    <div ref="root" :id="$attrs.id" class="a-menus" :class="{[`a-menus--${place}`]: place !== 'bottom-left'}">
        <slot name="menu-trigger" :trigger="{
            id: `${$attrs.id || 'menu'}-control`,
            'aria-expanded': isExpanded,
            'aria-haspopup': true,
            'aria-controls': `${$attrs.id || 'menu'}`,
            disabled: $attrs.disabled
        }">
            <button :id="`${$attrs.id || 'menu'}-control`" type="button" :aria-expanded="isExpanded" aria-haspopup="true">Menu Trigger</button>
        </slot>

        <div ref="menu" class="a-menus__body" :hidden="!isExpanded">
            <slot name="menu-list" :menus="{menus, isExpanded, id:`${$attrs.id || 'menu'}`, 'aria-labelledby': `${$attrs.id || 'menu'}-control`}" />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, nextTick, watch, onMounted
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'bottom-left',
            validator: (value) => {
                const [vertical, horizontal] = value.split('-');

                return ['top', 'bottom', 'auto'].includes(vertical) && ['left', 'right'].includes(horizontal);
            }
        },
        menus: {
            type: Array,
            default: () => ([
                {label: 'Menu 1'},
                {label: 'Menu 2', appearance: 'link', afterIcon: 'Chevron Arrow Right Small'},
                {isSeparator: true},
                {label: 'Menu 3', appearance: 'warning', beforeIcon: 'Warning'},
                {isSeparator: true},
                {label: 'Menu 4', appearance: 'danger', beforeIcon: 'Delete'}
            ])
        }
    },
    setup(props, $) {
        const root = ref(null);
        const menu = ref(null);
        const place = ref(props.placement);

        onMounted(() => {
            const [vertical, horizontal] = props.placement.split('-');

            // 上下位置の自動調整が必要ではない場合は何もしない
            if (vertical !== 'auto') {
                return;
            }

            // メニューの上下位置監視
            const observer = new window.IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const {isIntersecting} = entry;

                    place.value = isIntersecting ? `bottom-${horizontal}` : `top-${horizontal}`;
                });
            }, {rootMargin: '0px 0px -200px 0px', threshold: 0});

            observer.observe(root.value);
        });

        watch(() => props.isExpanded, (isExpanded) => {
            if (!menu.value || !root.value) {
                return;
            }

            nextTick(() => {
                if (isExpanded) {
                    menu.value.querySelector('[tabindex="0"]').focus();
                } else {
                    root.value.children[`${$.attrs.id || 'menu'}-control`].focus();
                }
            });
        });

        return {menu, root, place};
    }
});
</script>

<style lang="scss" scoped>
.a-menus {
    display: inline-flex;
    position: relative;

    &--bottom-right .a-menus__body {
        right: 0;
    }

    &--top-right .a-menus__body {
        top: -4px;
        right: 0;
        bottom: auto;
        transform: translateY(-100%);
    }

    &--top-left .a-menus__body {
        top: -4px;
        bottom: auto;
        transform: translateY(-100%);
    }

    > :not(.a-menus__body):not([aria-expanded="false"]) {
        pointer-events: none;
    }

    @at-root {
        .a-menus__body {
            position: absolute;
            bottom: -4px;
            padding: 8px 0;
            border-radius: 2px;
            border: solid 1px #F2F6F9;
            background: var.$color-utils-background;
            transform: translateY(100%);
            box-shadow: 0 2px 8px 0 rgba(#182549, .18);
            z-index: 10;

            &[hidden] {
                display: none;
            }
        }
    }
}
</style>
