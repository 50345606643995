<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <cards>
            <template v-slot:header>
                <form-header title="パスワード変更" />
            </template>

            <banner v-if="formErrors.system.value.length" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
            </banner>

            <forms @submit.prevent="$emit('update-password', $event, models.client)">
                <definition-list :items="forms.client">
                    <template v-slot:item-0="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            >
                                <template v-slot:action="fieldActionProps">
                                    <button
                                        type="button"
                                        aria-pressed="false"
                                        @click="
                                            (fieldActionProps.action.element.type = fieldActionProps.action.element.type === 'text' ? 'password' : 'text') &
                                            ($event.currentTarget.setAttribute('aria-pressed', fieldActionProps.action.element.type === 'text')) &
                                            ($refs.icon1.$.props.name = fieldActionProps.action.element.type === 'text' ? 'Visibility' : 'Visibility Off')
                                        "
                                    ><icon ref="icon1" name="Visibility Off" /></button>
                                </template>
                            </text-field>
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-1="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            >
                                <template v-slot:action="fieldActionProps">
                                    <button
                                        type="button"
                                        aria-pressed="false"
                                        @click="
                                            (fieldActionProps.action.element.type = fieldActionProps.action.element.type === 'text' ? 'password' : 'text') &
                                            ($event.currentTarget.setAttribute('aria-pressed', fieldActionProps.action.element.type === 'text')) &
                                            ($refs.icon2.$.props.name = fieldActionProps.action.element.type === 'text' ? 'Visibility' : 'Visibility Off')
                                        "
                                    ><icon ref="icon2" name="Visibility Off" /></button>
                                </template>
                            </text-field>
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-2="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            >
                                <template v-slot:action="fieldActionProps">
                                    <button
                                        type="button"
                                        aria-pressed="false"
                                        @click="
                                            (fieldActionProps.action.element.type = fieldActionProps.action.element.type === 'text' ? 'password' : 'text') &
                                            ($event.currentTarget.setAttribute('aria-pressed', fieldActionProps.action.element.type === 'text')) &
                                            ($refs.icon3.$.props.name = fieldActionProps.action.element.type === 'text' ? 'Visibility' : 'Visibility Off')
                                        "
                                    ><icon ref="icon3" name="Visibility Off" /></button>
                                </template>
                            </text-field>
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                </definition-list>

                <template v-slot:footer>
                    <form-footer>
                        <button-group layout="end">
                            <standard-button :is-loading="forms.isLoading.value" type="submit" appearance="primary">変更を保存する</standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>
        </cards>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Cards from '@/components/01_Atoms/Cards';
import Banner from '@/components/01_Atoms/Banners';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Forms, {
    FormHeader, FormFooter, FormLabel, FormMessage
} from '@/components/01_Atoms/Forms';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import {TextField} from '@/components/01_Atoms/TextFields';

export default defineComponent({
    inheritAttrs: false,
    emits: [
        'update-password',
        'update:current_password',
        'update:password',
        'update:password_confirmation'
    ],
    components: {
        Cards, Banner, ButtonGroup, StandardButton, Icon, Forms, FormHeader, FormFooter, FormLabel, FormMessage, DefinitionList, DefinitionListTerm, DefinitionListDescription, TextField
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        forms: {
            type: Object,
            default: () => ({})
        },
        formErrors: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 24px 24px 24px 277px;
    background: #F2F6F9;
    min-height: calc(100vh - 72px);
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 415px;
    }
}
</style>
