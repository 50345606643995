<template>
    <div
        class="a-button-group"
        :class="{
            [`a-button-group--${layout}`]: layout !== 'default',
            [`a-button-group--${direction}`]: direction !== 'row'
        }"
        role="group"
        :aria-label="$attrs['aria-label']"
    >
        <slot />
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        direction: {
            type: String,
            default: 'row',
            validator: (value) => ['row', 'column'].includes(value)
        },
        layout: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'center', 'end', 'between', 'around', 'last-end', 'first-start'].includes(value)
        }
    }
});
</script>

<style lang="scss">
.a-button-group {
    display: flex;
    align-items: center;
    width: 100%;

    &--column {
        width: auto;
        flex-direction: column;
        align-items: flex-end;

        > [class^="a-button"]:not(:first-child) {
            margin-left: 0;
            margin-top: 8px;
        }
    }

    &--center {
        justify-content: center;
    }

    &--end {
        justify-content: flex-end;
    }

    &--between {
        justify-content: space-between;
    }

    &--around {
        justify-content: space-around;
    }

    &--last-end {

        > [class^="a-button"]:not(:first-child):last-child {
            margin-left: auto;
        }
    }

    &--first-start {

        > [class^="a-button"]:first-child {
            margin-right: auto;
        }
    }


    > [class^="a-button"]:not(:first-child) {
        margin-left: 8px;
    }
}
</style>
