<template>
    <component
        :is="['Approve', 'Ban'][tabNavigation.currentIndex.value]"
        :is-menu-expanded="isMenuExpanded"
        :tabs="tabNavigation"
        :client-table-data="currentTableData"
        :alert-modal="[accountBanAlertModal, accountReactiveAlertModal][tabNavigation.currentIndex.value]"
        @open-modal="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @close-modal="(state, element) => (state.isExpanded.value = false) & element.focus()"
        @ban-client="methods.setBanStatus"
        @reactivate-client="methods.setApprovalStatus"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, computed, watch
} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import axios from 'axios';
import store from '@/store';
import {ClientIndexApprove as Approve, ClientIndexBan as Ban} from '@/components/04_Templates/Admin';

export default defineComponent({
    inheritAttrs: false,
    components: {Approve, Ban},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();
        const $router = useRouter();

        /**
         * alias - テンプレートコンポーネントのエイリアス名
         * @type {Array<String>}
         */
        const alias = ['Approve', 'Ban'];

        /**
         * tabNavigation - タブ表示のデータ
         * @type {Object}
         * @property {red<Number>}   currentIndex - 現在のインデックス
         * @property {Boolean}       isNavigation - ナビゲーションモードを有効にする
         * @property {Array<Object>} tabs         - タブのデータ
         */
        const tabNavigation = {
            currentIndex: ref($route.path.substring($route.path.lastIndexOf('/') + 1) === 'pending' ? 0 : 1),
            isNavigation: true,
            tabs: [
                {
                    id: 'approve',
                    label: '承認済み',
                    to: {name: 'AdminClientAccountApproved'}
                }, {
                    id: 'ban',
                    label: '停止中',
                    to: {name: 'AdminClientAccountBan'}
                }
            ]
        };

        /**
         * approvedClientTableData - クライアントアカウントのテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading - データ読み込み中ステータス
         * @property {Array<Array>} headRows  - テーブルヘッダー情報
         * @property {Array<Array>} bodyRows  - テーブルボディ情報
         */
        const approvedClientTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'contact_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'email',
                        content: 'メールアドレス',
                        isHead: true
                    }, {
                        key: 'department',
                        content: '部署名',
                        isHead: true
                    }, {
                        key: 'confirmation_sent_at',
                        content: '申請日',
                        isHead: true
                    }, {
                        key: 'confirmed_at',
                        content: '承認日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: 'クライアントアカウントが存在しません',
                repletion: 'サービス利用を承認したクライアントのアカウントがここに表示されます。'
            }
        };

        /**
         * banClientTableData - クライアントアカウントのテーブルデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading - データ読み込み中ステータス
         * @property {Array<Array>} headRows  - テーブルヘッダー情報
         * @property {Array<Array>} bodyRows  - テーブルボディ情報
         */
        const banClientTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'contact_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'email',
                        content: 'メールアドレス',
                        isHead: true
                    }, {
                        key: 'department',
                        content: '部署名',
                        isHead: true
                    }, {
                        key: 'confirmation_sent_at',
                        content: '申請日',
                        isHead: true
                    }, {
                        key: 'confirmed_at',
                        content: '承認日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: 'クライアントアカウントが存在しません',
                repletion: 'サービス利用停止中クライアントのアカウントがここに表示されます。'
            }
        };

        /**
         * accountBanAlertModal - アカウント停止アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - モーダル読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const accountBanAlertModal = {
            id: 'alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: 'アカウントを停止しますか？',
            description: '停止したアカウントはサービス利用不可になります。',
            ariaDescribedby: 'alert-modal-description'
        };

        /**
         * accountReactiveAlertModal - アカウント復帰アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - モーダル読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const accountReactiveAlertModal = {
            id: 'alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'confirm',
            closable: false,
            title: 'アカウントの停止を解除しますか？',
            description: '停止を解除すると、アカウントは再びサービス利用可能になります。',
            ariaDescribedby: 'alert-modal-description'
        };

        /**
         * methods - メソッド（処理）
         * @type {Object}
         */
        const methods = {
            /**
             * getClientAccounts - APIからクライアントアカウント一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ accounts; Array, meta: Object }>}
             */
            async getClientAccounts(params) {
                const result = {};

                await axios.get('/api/v2/admin_account/clients', {
                    headers: store.state.auth,
                    params: Object.assign(params, $route.query)
                }).then((response) => {
                    const {clients, meta} = response.data;

                    result.accounts = clients.map((account) => ([
                        {key: 'id', content: account.id, resizing: 'hug'},
                        {key: 'contact_name', content: {contact_name: account.contact_name, company: account.name}},
                        {key: 'email', content: account.client_accounts[0].email},
                        {key: 'department', content: account.department},
                        {key: 'confirmation_sent_at', content: new Date(account.client_accounts[0].confirmation_sent_at).toLocaleDateString()},
                        {key: 'confirmed_at', content: account.client_accounts[0].confirmed_at ? new Date(account.client_accounts[0].confirmed_at).toLocaleDateString() : ''},
                        {key: 'action', content: account.id, resizing: 'hug'}
                    ]));

                    result.meta = meta;
                });

                return result;
            },
            /**
             * getClientAccountTableData - クライアントアカウント一覧を取得・データ更新
             * @param {Object} targetTable - 更新するテーブルデータ
             * @param {Object} params      - 送信するパラメータ
             * @returns {Void}
             */
            getClientAccountTableData(targetTable, params) {
                // すでに読み込み中の場合は取得しない
                if (targetTable.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetTable.isLoading.value = true;

                // データ取得
                methods.getClientAccounts(params).then((response) => {
                    const {accounts, meta} = response;

                    // テーブルデータを更新
                    targetTable.bodyRows.value = accounts;

                    // ページネーションを更新
                    targetTable.pagination.currentPage.value = meta.current_page;
                    targetTable.pagination.pages.value = Array.from(Array(meta.total_pages).keys(), (index) => index + 1);

                    // 現在のページが合計ページ数を逸脱している場合
                    if (meta.current_page > meta.total_pages) {
                        $router.replace({query: {...$route.query, ...{page: meta.total_pages}}});
                    }
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetTable.isLoading.value = false;
                });
            },
            /**
             * setBanStatus - アカウント停止処理
             * @returns {Void}
             */
            setBanStatus() {
                const clientId = accountBanAlertModal.currentId.value;

                // 読み込みフラグを更新（読み込み中）
                accountBanAlertModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${clientId}/set_ban_status`,
                    {ban_status: true},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    accountBanAlertModal.isExpanded.value = false;

                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'アカウントを停止しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // データを再読み込み
                    methods.getClientAccountTableData(approvedClientTableData, {
                        approval_status: 'is_approve',
                        ban_status: false,
                        includes: ['client_accounts']
                    });
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    accountBanAlertModal.isLoading.value = false;
                });
            },
            /**
             * setApprovalStatus - アカウント再有効化処理
             * @returns {Void}
             */
            setApprovalStatus() {
                const clientId = accountReactiveAlertModal.currentId.value;

                // 読み込みフラグを更新（読み込み中）
                accountReactiveAlertModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${clientId}/set_ban_status`,
                    {ban_status: false},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    accountReactiveAlertModal.isExpanded.value = false;

                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'アカウントの停止を解除しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // データを再読み込み
                    methods.getClientAccountTableData(banClientTableData, {
                        approval_status: 'is_approve',
                        ban_status: true,
                        includes: ['client_accounts']
                    });
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    accountReactiveAlertModal.isLoading.value = false;
                });
            }
        };

        /**
         * currentTableData - ページに応じたテーブルデータの算出
         */
        const currentTableData = computed(() => [approvedClientTableData, banClientTableData][tabNavigation.currentIndex.value]);

        /**
         * URLパス変更監視処理
         * @type {Function}
         */
        watch(() => $route.path, (path) => {
            const lastSegment = path.substring(path.lastIndexOf('/') + 1);
            const index = alias.map((alia) => alia.toLowerCase()).indexOf(lastSegment);

            // /client-accounts 以外のページヘ遷移する場合は何もしない
            if (index === -1) {
                return;
            }

            tabNavigation.currentIndex.value = index;
            methods.getClientAccountTableData(currentTableData.value, {
                approval_status: 'is_approve',
                ban_status: tabNavigation.currentIndex.value === 1,
                includes: ['client_accounts']
            });
        }, {immediate: true});

        /**
         * URLクエリ変更監視処理
         * @type {Function}
         */
        watch(() => $route.query, (query) => {
            const currentQuery = tabNavigation.tabs[tabNavigation.currentIndex.value].to.query || {};

            tabNavigation.tabs[tabNavigation.currentIndex.value].to.query = query;

            // クエリに変更がない場合は読み込み処理を行わない
            if (JSON.stringify(Object.entries(currentQuery).sort()) === JSON.stringify(Object.entries(query).sort())) {
                return;
            }

            const currentTable = currentTableData.value;

            currentTable.pagination.currentPage.value = parseInt(query.page, 10);
            methods.getClientAccountTableData(currentTable, {
                approval_status: 'is_approve',
                ban_status: tabNavigation.currentIndex.value === 1,
                includes: ['client_accounts']
            });
        }, {immediate: true});

        return {
            tabNavigation, approvedClientTableData, banClientTableData, accountBanAlertModal, accountReactiveAlertModal, currentTableData, methods
        };
    }
});
</script>
