<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__content">
            <div class="t-main__utils">
                <breadcrumbs :breadcrumbs="breadcrumbs">
                    <template v-slot:item="breadcrumbsItemProps">
                        <breadcrumbs-item v-bind="breadcrumbsItemProps.breadcrumbs">
                            <template v-if="breadcrumbsItemProps.breadcrumbs.beforeIcon" v-slot:before><icon :name="breadcrumbsItemProps.breadcrumbs.beforeIcon" /></template>
                            {{breadcrumbsItemProps.breadcrumbs.label}}
                            <template v-if="breadcrumbsItemProps.breadcrumbs.afterIcon" v-slot:after><icon :name="breadcrumbsItemProps.breadcrumbs.afterIcon" /></template>
                        </breadcrumbs-item>
                    </template>
                </breadcrumbs>
            </div>

            <div class="t-main__heading">
                <h1 class="t-main__title">設問設定</h1>
                <p>設問数は50個まで、選択肢は20個まで設定できます。</p>
            </div>

            <banner v-if="formErrors.system.value.length" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
            </banner>

            <banner v-if="!surveyData.isEditable.value" appearance="information">
                <template v-slot:icon><icon name="Information" /></template>
                <p>申請中、実施中あるいは終了しているアンケートです。編集することができません。</p>
            </banner>

            <banner v-if="surveyData.isError.value" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p>アンケートのデータが存在しません。</p>
            </banner>

            <cards>
                <template v-slot:header>
                    <form-header :title="surveyData.data.bonus_name || ''" :level="2" />
                </template>

                <div class="t-markdown" v-html="surveyData.data.description"></div>

                <div class="t-date-text" v-if="surveyData.data.start_date && surveyData.data.end_date">
                    <p class="t-date-text__item"><span class="t-date-text__flag"><icon name="Calendar Available" /></span>{{surveyData.data.start_date.toLocaleDateString()}}</p>
                    <p class="t-date-text__item"><span class="t-date-text__flag"><icon name="Calendar Busy" /></span>{{surveyData.data.end_date.toLocaleDateString()}}</p>
                </div>
            </cards>

            <forms ref="form" id="question-form" @submit.prevent="$emit('updateQuestion', $event, models.question)">
                <cards v-for="(model, index) in models.question.surveys" :key="model">
                    <template v-slot:header>
                        <form-header :title="`設問${index + 1}`" :level="2" />
                    </template>

                    <field-set legend="質問内容" :level="2">
                        <definition-list :items="[forms.question[0]]">
                            <template v-slot:item-0="itemProps">
                                <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                                <definition-list-description>
                                    <text-field
                                        v-bind="itemProps.description"
                                        :id="`${itemProps.description.id}-${index}`"
                                        :name="`${itemProps.description.name}-${index}`"
                                        :is-error="formErrors.question[`${itemProps.description.name}-${index}`] !== undefined"
                                        :aria-errormessage="`${itemProps.description.id}-invalid`"
                                        :aria-required="itemProps.term.isRequire"
                                        :readonly="!surveyData.isEditable.value || surveyData.isError.value"
                                        :model-value="model[itemProps.description.name]"
                                        @update:model-value="$emit(`update:${itemProps.description.name}-${index}`, $event)"
                                    />
                                    <form-message v-if="formErrors.question[`${itemProps.description.name}-${index}`]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.question[`${itemProps.description.name}-${index}`]"><template v-slot:icon><icon name="Error" /></template></form-message>
                                </definition-list-description>
                            </template>
                        </definition-list>
                    </field-set>

                    <field-set legend="回答内容" :level="2">
                        <definition-list :items="model.survey_type === '2' ? [forms.question[1]] : [forms.question[1], forms.question[2]]">
                            <template v-slot:item-0="itemProps">
                                <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                                <definition-list-description>
                                    <selects
                                        v-bind="itemProps.description"
                                        :id="`${itemProps.description.id}-${index}`"
                                        :name="`${itemProps.description.name}-${index}`"
                                        :is-error="formErrors.question[`${itemProps.description.name}-${index}`] !== undefined"
                                        :aria-errormessage="`${itemProps.description.id}-invalid`"
                                        :aria-required="itemProps.term.isRequire"
                                        :model-value="model[itemProps.description.name]"
                                        :readonly="!surveyData.isEditable.value || surveyData.isError.value"
                                        :is-expanded="itemProps.description.isExpanded.value && itemProps.description.currentId.value === `${itemProps.description.id}-${index}`"
                                        placement="auto-left"
                                        @open="$emit('openPicker', itemProps.description, `${itemProps.description.id}-${index}`)"
                                        @close="$emit('closePicker', itemProps.description)"
                                        @update:model-value="$emit(`update:${itemProps.description.name}-${index}`, $event)"
                                    >
                                        <template v-slot:option="optionProps">
                                            <select-option v-bind="optionProps.option">
                                                <template v-slot:icon><icon :name="optionProps.option.icon" /></template>
                                            </select-option>
                                        </template>
                                    </selects>
                                    <form-message v-if="formErrors.question[`${itemProps.description.name}-${index}`]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.question[`${itemProps.description.name}-${index}`]"><template v-slot:icon><icon name="Error" /></template></form-message>
                                </definition-list-description>
                            </template>
                            <template v-slot:item-1="itemProps">
                                <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                                <definition-list-description>
                                    <choice-input-field
                                        v-bind="itemProps.description"
                                        :id="`${itemProps.description.id}-${index}`"
                                        :name="`${itemProps.description.name}-${index}`"
                                        :is-error="formErrors.question[`${itemProps.description.name}-${index}`] !== undefined"
                                        :aria-errormessage="`${itemProps.description.id}-invalid`"
                                        :aria-required="itemProps.term.isRequire"
                                        :readonly="!surveyData.isEditable.value || surveyData.isError.value"
                                        :model-value="model[itemProps.description.name]"
                                        @update:model-value="$emit(`update:${itemProps.description.name}-${index}`, $event)"
                                    />
                                    <form-message v-if="formErrors.question[`${itemProps.description.name}-${index}`]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.question[`${itemProps.description.name}-${index}`]"><template v-slot:icon><icon name="Error" /></template></form-message>
                                </definition-list-description>
                            </template>
                        </definition-list>
                    </field-set>

                    <template v-slot:footer>
                        <card-footer>
                            <button-group layout="end">
                                <icon-button title="設問を追加" :disabled="models.question.surveys.length >= 50 || (!surveyData.isEditable.value || surveyData.isError.value)" @click="$emit('addQuestion', $refs.form.$el, index)"><icon name="Plus" /></icon-button>
                                <icon-button title="1つ上に移動" :disabled="index === 0 || (!surveyData.isEditable.value || surveyData.isError.value)" @click="$emit('shiftQuestion', $refs.form.$el, index, index - 1)"><icon name="Upwards" /></icon-button>
                                <icon-button title="1つ下に移動" :disabled="index === models.question.surveys.length - 1 || (!surveyData.isEditable.value || surveyData.isError.value)" @click="$emit('shiftQuestion', $refs.form.$el, index, index + 1)"><icon name="Downwards" /></icon-button>
                                <icon-button title="設問を複製" :disabled="models.question.surveys.length >= 50 || (!surveyData.isEditable.value || surveyData.isError.value)" @click="$emit('duplicateQuestion', $refs.form.$el, index)"><icon name="Duplicate" /></icon-button>
                                <icon-button title="設問を削除" :disabled="models.question.surveys.length === 1 || (!surveyData.isEditable.value || surveyData.isError.value)" @click="$emit('deleteQuestion', index)"><icon name="Delete" /></icon-button>
                            </button-group>
                        </card-footer>
                    </template>
                </cards>

                <div class="t-footer" :class="{'is-menu-expanded': isMenuExpanded}">
                    <div class="t-footer__inner">
                        <button-group layout="end">
                            <standard-button
                                type="submit"
                                :is-loading="forms.isLoading.value && forms.isDraft.value"
                                :disabled="(forms.isLoading.value && !forms.isDraft.value) || (!surveyData.isEditable.value || surveyData.isError.value)"
                                data-draft="true"
                            >下書き保存</standard-button>
                            <standard-button
                                type="submit"
                                appearance="primary"
                                :is-loading="forms.isLoading.value && !forms.isDraft.value"
                                :disabled="(forms.isLoading.value && forms.isDraft.value) || (!surveyData.isEditable.value || surveyData.isError.value)"
                                data-draft="false
                            ">配信申請をして保存</standard-button>
                        </button-group>
                    </div>
                </div>
            </forms>

            <action-button appearance="additional" spacing="fill" :disabled="models.question.surveys.length >= 50 || (!surveyData.isEditable.value || surveyData.isError.value)" @click="$emit('addQuestion', $refs.form.$el)">
                <template v-slot:before><icon name="Add" /></template>
                設問を追加する
            </action-button>
        </div>
        <div class="t-main__previews">
            <previews>
                <template v-slot:header><preview-price :is-loading="surveyData.isLoading.value" :title="preview.price.title" :unit-price="preview.price.unitPrice.value" :calc="(unit) => ((surveyData.data.cap || 0) * preview.validQuestionLength) * unit" /></template>
                <template v-slot:body><preview-question v-html="preview.markup" /></template>
            </previews>
        </div>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import Breadcrumbs, {BreadcrumbsItem} from '@/components/01_Atoms/Breadcrumbs';
import Cards, {CardFooter} from '@/components/01_Atoms/Cards';
import Icon from '@/components/01_Atoms/Icons';
import ButtonGroup, {StandardButton, ActionButton, IconButton} from '@/components/01_Atoms/Buttons';
import Forms, {
    FieldSet, FormHeader, FormLabel, FormMessage
} from '@/components/01_Atoms/Forms';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import {TextField} from '@/components/01_Atoms/TextFields';
import Selects, {SelectOption} from '@/components/01_Atoms/Selects';
import ChoiceInputField from '@/components/01_Atoms/ChoiceInputFields';
import Previews, {PreviewQuestion, PreviewPrice} from '@/components/01_Atoms/Previews';

export default defineComponent({
    inheritAttrs: false,
    emits: [].concat(
        ['openPicker', 'closePicker', 'updateQuestion', 'addQuestion', 'shiftQuestion', 'duplicateQuestion', 'deleteQuestion'],
        [...Array(50)].map((_, index) => [`update:survey_type-${index}`, `update:question-${index}`, `update:choices-${index}`]).flat()
    ),
    components: {
        Banner,
        Breadcrumbs,
        BreadcrumbsItem,
        Cards,
        CardFooter,
        Icon,
        ButtonGroup,
        StandardButton,
        ActionButton,
        IconButton,
        Forms,
        FieldSet,
        FormHeader,
        FormLabel,
        FormMessage,
        DefinitionList,
        DefinitionListTerm,
        DefinitionListDescription,
        TextField,
        Selects,
        SelectOption,
        ChoiceInputField,
        Previews,
        PreviewQuestion,
        PreviewPrice
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        breadcrumbs: {
            type: Array,
            default: () => []
        },
        forms: {
            type: Object,
            default: () => ({})
        },
        formErrors: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        },
        surveyData: {
            type: Object,
            default: () => ({})
        },
        preview: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    padding: 0  24px 100px 98px;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__content {
            flex-basis: 100%;
            margin-right: 24px;
        }

        .t-main__previews {
            position: sticky;
            top: 0;
            flex: 0 0 378px;
            margin: -72px -24px -36px 0;
            padding-top: 72px;
            height: calc(100vh - 76px);
            overflow: auto;
            background: var.$color-gray-10;
        }

        .t-main__heading {
            padding: 24px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;

            + p {
                font-size: 1.4rem;
                color: var.$color-text-medium;
                margin-top: 16px;
            }
        }

        .t-main__utils {
            padding: 24px 0;
        }
    }
}

.t-date-text {
    display: flex;
    margin-top: 24px;

    @at-root {
        .t-date-text__item {
            display: inline-flex;
            align-items: center;
            color: var.$color-text-medium;
            font-size: 1.4rem;
            line-height: (22 / 14);

            &:not(:first-child) {
                margin-left: 24px;
            }
        }

        .t-date-text__flag {
            display: flex;
            margin-right: 8px;
        }
    }
}

.t-markdown {

    > :not(:first-child) {
        margin-top: 1.96rem;
    }

    ::v-deep(a) {
        text-decoration: underline;
    }

    ::v-deep(img) {
        max-width: 390px;
    }

    ::v-deep(ul) {
        list-style: disc;
        padding-left: 40px;

        > ::v-deep(ul) {
            list-style: circle;

            > ::v-deep(ul) {
                list-style: square;
            }
        }
    }

    ::v-deep(ol) {
        list-style: decimal;
        padding-left: 40px;
    }
}

.t-footer {
    position: fixed;
    right: 0;
    left: 73px;
    bottom: 0;
    background: var.$color-utils-background;
    transition: left .3s ease 0s;
    border-top: solid 1px #E6E7E6;
    z-index: 1;

    &.is-menu-expanded {
        left: 210px;
    }

    @at-root {
        .t-footer__inner {
            padding: 16px 24px;
        }
    }
}
</style>
