<template>
    <nav id="side-navigation-sub" class="t-navigation-local" :class="{'is-menu-expanded': isMenuExpanded}" aria-label="サブメニュー">
        <ul class="t-navigation-local__list">
            <li
                v-for="navigation in navigations"
                :key="navigation"
                class="t-navigation-local__item"
            >
                <router-link :to="navigation.to" class="t-navigation-local__link">
                    <span class="t-navigation-local__text">{{navigation.label}}</span>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        navigations: {
            type: Array,
            default: () => [
                {label: '基本情報変更', to: {name: 'ClientAccountSetting'}},
                {label: 'パスワード変更', to: {name: 'ClientChangePassword'}}
            ]
        }
    }
});
</script>

<style lang="scss" scoped>
.t-navigation-local {
    position: fixed;
    top: 72px;
    bottom: 0;
    left: 73px;
    width: fit-content;
    padding: 16px 0 16px 16px;
    background: var.$color-utils-background;
    transition: left .3s ease 0s;
    z-index: 10;

    &.is-menu-expanded {
        left: 211px;
    }

    @at-root {
        .t-navigation-local__list {
            display: flex;
            flex-direction: column;
        }

        .t-navigation-local__item {

            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        .t-navigation-local__link {
            display: inline-flex;
            align-items: center;
            color: var.$color-text-disabled;
            background: var.$color-utils-background;
            width: 100%;
            min-width: 164px;
            min-height: 40px;
            padding: 4px 16px 4px 8px;
            border-radius: 2px 0 0 2px;
            text-decoration: none;
            transition: background-color .3s ease 0s, color .3s ease 0s;

            &[aria-current] {
                background: #F9FBFC;
                color: var.$color-primary-50;
            }

            @include mixin.hover {
                background: #F9FBFC;
                color: var.$color-primary-50;
            }
        }

        .t-navigation-local__text {
            font-size: 1.6rem;
            line-height: 1;
            white-space: nowrap;
        }
    }
}
</style>
