<template>
    <div class="a-card">
        <slot name="header" />
        <div class="a-card__content"><slot /></div>
        <slot name="footer" />
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss" scoped>
.a-card {
    background: var.$color-utils-background;
    border-radius: 2px;

    & + & {
        margin-top: 24px;
    }

    @at-root {
        .a-card__content {
            padding: 24px;

            .a-form-header + & {
                padding-top: 0;
            }
        }
    }
}
</style>
