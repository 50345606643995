<template>
    <dd class="a-definition-list-description"><span class="a-definition-list-description__inner"><slot /></span></dd>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.a-definition-list-description {
    margin-left: 24px;
    flex-basis: 100%;
    min-width: 494px;

    @at-root {
        .a-definition-list-description__inner {
            width: 100%;
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            min-height: 48px;
        }
    }
}
</style>
