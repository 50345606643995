<template>
    <nav id="side-navigation" class="t-navigation" :class="{'is-expanded': isExpanded}" aria-label="メインメニュー">
        <ul class="t-navigation__list">
            <li
                v-for="navigation in navigations"
                :key="navigation"
                class="t-navigation__item"
            >
                <component
                    :is="navigation.disabled || !navigation.to ? 'a' : 'router-link'"
                    v-bind="{
                        to: navigation.disabled || !navigation.to ? undefined : navigation.to,
                        href: navigation.disabled || !navigation.to ? navigation.href || '#' : undefined
                    }"
                    class="t-navigation__link"
                    :aria-disabled="navigation.disabled ? 'true' : undefined"
                    :target="navigation.href ? '_blank' : undefined"
                    @click="(event) => navigation.href ? true : event.preventDefault()"
                >
                    <icon width="24" height="24" :name="navigation.icon" />
                    <span class="t-navigation__text">{{navigation.label}}</span>
                </component>
            </li>
        </ul>
    </nav>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    components: {Icon},
    props: {
        isExpanded: {
            type: Boolean,
            default: false
        },
        navigations: {
            type: Array,
            default: () => []
        }
    }
});
</script>

<style lang="scss" scoped>
.t-navigation {
    position: fixed;
    top: 72px;
    bottom: 0;
    left: 0;
    width: fit-content;
    width: auto;
    padding: 16px;
    background: var.$color-primary-20;
    border-right: solid 1px #E6E7E6;
    z-index: 5;

    &.is-expanded {
        .t-navigation__link {
            width: 178px;
            padding: 4px 16px 4px 8px;
        }

        .t-navigation__text {
            position: static;
            padding: 0;
            background: none;
            opacity: 1;
            pointer-events: auto;
            transform: none;
            color: inherit;
        }
    }

    @at-root {
        .t-navigation__list {
            display: flex;
            flex-direction: column;
        }

        .t-navigation__item {

            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        .t-navigation__link {
            position: relative;
            display: inline-flex;
            align-items: center;
            color: var.$color-text-disabled;
            background: rgba(#F2F6F9, .5);
            width: 40px;
            min-height: 40px;
            padding: 4px 8px;
            border-radius: 2px;
            text-decoration: none;
            transition: background-color .3s ease 0s, color .3s ease 0s, width .3s ease 0s;

            > svg {
                flex: 0 0 auto;
            }

            &[aria-current] {
                background: var.$color-utils-background;
                color: var.$color-primary-50;
            }

            &[aria-disabled="true"] {
                cursor: default;
                background: var.$color-gray-10;
                color: var.$color-text-disabled;

                @include mixin.hover {
                    background: var.$color-gray-10;
                    color: var.$color-text-disabled;

                    .t-navigation__text {
                        opacity: 1;
                    }
                }
            }

            @include mixin.hover {
                background: var.$color-utils-background;
                color: var.$color-primary-50;

                .t-navigation__text {
                    opacity: 1;
                }
            }
        }

        .t-navigation__text {
            position: absolute;
            left: 100%;
            padding: 2px 6px;
            border-radius: 2px;
            opacity: 0;
            background: var.$color-primary-50;
            transform: translateX(12px);
            pointer-events: none;
            font-size: 1.4rem;
            line-height: 1;
            white-space: nowrap;
            margin-left: 16px;
            color: var.$color-text-white;
        }
    }
}
</style>
