<template>
    <div class="a-editor">
        <editor
            ref="editor"
            v-bind="$attrs"
            class="a-editor__input"
            :class="{
                'is-error': isError
            }"
            api-key="m4o3miwy8xh1z0dm2q0a7zm30wvr1st095bxar5nzhlf4uiq"
            :init="{
                height: 180,
                min_height: 180,
                resize: true,
                placeholder: $attrs.placeholder || '',
                menubar: false,
                elementpath: false,
                branding: false,
                paste_as_text: true,
                relative_urls : false,
                language: 'ja',
                language_url : '/js/ja.js',
                content_css: [
                    'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Open+Sans:wght@400;700&display=swap',
                    '/css/editor.css'
                ],
                plugins: 'autolink lists link image',
                toolbar: 'bold link numlist bullist',
                link_target_list: false,
                anchor_bottom: false,
                anchor_top: false
            }"
            :value="modelValue"
            @keydown.delete="methods.selectionDelete"
            @update:model-value="methods.model($event)"
        />
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Editor from '@tinymce/tinymce-vue';
import {remark} from 'remark';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';

export default defineComponent({
    inheritAttrs: false,
    components: {Editor},
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        isError: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        /**
         * methods - 処理
         * @type {Object}
         */
        const methods = {
            /**
             * model - 入出力の制御
             * @param {String} value - 入力された値
             * @return {Promise}
             */
            async model(value) {
                const remarkHTML = await methods.remark(value);

                $.emit('update:model-value', remarkHTML);
            },
            /**
             * remark - 入出力の制御
             * @param {String} html - markdownに変換するHTMLテキスト
             * @return {Promise}
             */
            remark(html) {
                return new Promise((resolve) => {
                    const remarkHTML = async () => {
                        const result = await remark().use(rehypeParse).use(rehypeRemark).use(remarkStringify)
                            .process(html);

                        return result;
                    };

                    remarkHTML().then((response) => {
                        resolve(response.toString());
                    });
                });
            },
            selectionDelete() {
                methods.model(window.tinymce.activeEditor.getContent());
            }
        };

        return {methods};
    }
});
</script>

<style lang="scss" scoped>
.a-editor {
    position: relative;
    width: 100%;
    z-index: 0;

    ::v-deep(.tox-tinymce) {
        border-radius: 2px;
        border: solid 1px #B3B8B6;
    }

    ::v-deep(.tox-statusbar) {
        border-top: 0;
    }

    ::v-deep(.tox-toolbar__group) {
        padding: 0 6px;
    }

    ::v-deep(.tox:not(.tox-tinymce-inline) .tox-editor-header) {
        box-shadow: none;
        padding: 0;
        transition: none;
        border-bottom: solid 1px #B3B8B6;
    }

    ::v-deep(textarea[aria-hidden="true"]) {
        display: block !important;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        pointer-events: none;
    }

    @at-root {
        .a-editor__input {
            position: relative;
            width: 100%;

            &.is-error + ::v-deep(.tox) {
                box-shadow: 0 0 0 1px var.$color-danger-50;
            }
        }
    }
}
</style>
