<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__heading">
            <h1 class="t-main__title">クライアント申請管理</h1>
        </div>

        <div class="t-main__utils">
            <tab-list v-bind="tabs" :current-index="tabs.currentIndex.value">
                <template v-slot:tab="slotProps">
                    <tab v-bind="slotProps.item" :is-external-link="false">{{slotProps.item.label}}</tab>
                </template>
            </tab-list>
        </div>

        <tables v-bind="requestTableData" :is-loading="requestTableData.isLoading.value" :body-rows="requestTableData.bodyRows.value">
            <template v-slot:table-header="tableHeaderProps">
                <table-head v-bind="tableHeaderProps.rows">
                    <template v-slot:header-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-head>
            </template>

            <template v-slot:table-body="tableBodyProps">
                <table-body v-bind="tableBodyProps.rows">
                    <template v-slot:body-row="tableRowProps">
                        <table-row
                            v-bind="tableRowProps"
                            :clickable="true"
                            @click.stop="$router.push({name: 'AdminRequestShow', params:{requestId: tableRowProps.cells.slice(-1)[0].content}})"
                            @keydown.enter.stop="$router.push({name: 'AdminRequestShow', params:{requestId: tableRowProps.cells.slice(-1)[0].content}})"
                        >
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell" :clamp="1">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell" :clamp="1">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-body>
            </template>

            <template v-slot:empty>
                <empty-message v-bind="requestTableData.emptyMessage"/>
            </template>

            <template v-slot:footer v-if="requestTableData.bodyRows.value.length">
                <p v-if="requestTableData.pagination.pages.value.length">{{requestTableData.pagination.pages.value.slice(-1)[0]}}ページ中 {{requestTableData.pagination.currentPage.value}}ページ</p>
                <pagination :current-page="requestTableData.pagination.currentPage.value" :pages="requestTableData.pagination.pages.value">
                    <template v-slot:item="pagitaionItemProps">
                        <pagination-item
                            :is-current="pagitaionItemProps.item.isCurrent"
                            :disabled="pagitaionItemProps.item.disabled"
                            @clicked="$router.push({query: {...Object.assign({}, $route.query), ...{page: pagitaionItemProps.item.page}}})"
                        >
                            <template v-if="pagitaionItemProps.item.icon" v-slot:icon><icon :name="pagitaionItemProps.item.icon" /></template>
                            {{pagitaionItemProps.item.label}}
                        </pagination-item>
                    </template>
                </pagination>
            </template>
        </tables>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Icon from '@/components/01_Atoms/Icons';
import Tables, {
    TableHead, TableBody, TableRow, TableCell
} from '@/components/01_Atoms/Tables';
import {Tab, TabList} from '@/components/01_Atoms/Tabs';
import Pagination, {PaginationItem} from '@/components/01_Atoms/Paginations';
import {EmptyMessage} from '@/components/01_Atoms/Messages';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Tab, TabList, Icon, Tables, TableHead, TableBody, TableRow, TableCell, Pagination, PaginationItem, EmptyMessage
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        tabs: {
            type: Object,
            default: () => ({})
        },
        requestTableData: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px 0 64px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__utils {
            padding-bottom: 48px;
            margin: auto -24px;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }
    }
}
</style>
