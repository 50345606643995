<template>
    <div class="a-form-field">
        <label v-if="label !== ''" :for="id" class="a-form-field__label">{{label}}<span v-if="isRequire" class="a-form-field__badge">必須</span></label>
        <p v-if="description !== ''" :id="`${id}-description`" class="a-form-field__description">{{description}}</p>

        <div class="a-form-field__input">
            <slot name="field" :field="{
                id,
                ...$attrs,
                'aria-labelledby': label === '' ? `${id}-label` : undefined,
                'aria-describedby': description !== '' ? `${id}-description` : undefined,
                'aria-required': isRequire ? 'true' : undefined
            }" />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            required: true
        },
        isRequire: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss">
.a-form-field {
    &:not(:first-child) {
        margin-top: 16px;
    }

    @at-root {
        .a-form-field__label {
            font-size: 1.6rem;
            line-height: (22 / 16);
            color: var.$color-text-medium;
        }

        .a-form-field__description {
            font-size: 1rem;
            line-height: (18 / 10);
            color: var.$color-text-disabled;

            &:not(:first-child) {
                margin-top: 4px;
            }
        }

        .a-form-field__input {
            margin-top: 8px;
        }

        .a-form-field__badge {
            display: inline-flex;
            margin-left: 8px;
            padding: 2px 8px;
            font-size: 1.2rem;
            line-height: 1;
            color: var.$color-text-white;
            background: var.$color-danger-40;
            border-radius: 2px;
        }
    }
}
</style>
