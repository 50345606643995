<template>
    <p
        :id="$attrs.id"
        class="a-form-message"
        :class="{[`a-form-message--${appearance}`]: true}"
    >
        <span v-if="$slots.icon" class="a-form-message__icon"><slot name="icon" /></span>
        {{message}}
    </p>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        appearance: {
            type: String,
            required: true,
            varidator: (value) => ['information', 'danger', 'warning', 'success'].includes(value)
        },
        message: {
            type: String,
            required: true
        }
    }
});
</script>

<style lang="scss">
.a-form-message {
    width: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: (22 / 14);

    &:not(:first-child) {
        margin-top: 4px;
    }

    &--information {
        color: var.$color-information-50;
    }

    &--danger {
        color: var.$color-danger-50;
    }

    &--warning {
        color: var.$color-warning-50;
    }

    &--success {
        color: var.$color-success-50;
    }

    .a-checkbox-group + & {
        margin-top: 16px;
    }

    @at-root {
        .a-form-message__icon {
            display: flex;
            margin-right: 8px;
        }
    }
}
</style>
