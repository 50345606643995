<template>
    <survey-edit-question
        :is-menu-expanded="isMenuExpanded"
        :breadcrumbs="breadcrumbs"
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        :survey-data="surveyData"
        :preview="{
            price: preview.price,
            markup: previewQuestionMarkup.markup,
            validQuestionLength: previewQuestionMarkup.model.length
        }"
        v-bind="bindModelValue.attrs"
        v-on="bindModelValue.events"
        @openPicker="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @closePicker="(state) => (state.isExpanded.value = false) & (state.currentId.value = '')"
        @addQuestion="methods.addQuestion"
        @shiftQuestion="methods.shiftQuestion"
        @duplicateQuestion="methods.duplicateQuestion"
        @deleteQuestion="methods.deleteQuestion"
        @updateQuestion="methods.validateQuestion"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, computed, onMounted, nextTick
} from 'vue';
import axios from 'axios';
import store from '@/store';
import Validate from '@/validates';
import {useRoute, useRouter} from 'vue-router';
import {SurveyEditQuestion} from '@/components/04_Templates/Client';
import MarkdownIt from 'markdown-it';

export default defineComponent({
    inheritAttrs: false,
    components: {SurveyEditQuestion},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();
        const $router = useRouter();
        const {surveyId} = $route.params;
        const markdownit = MarkdownIt({
            typographer: true
        });

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: '賢い買物スキルの生活者アンケート',
                to: {name: 'ClientSurveys'},
                beforeIcon: 'Questionary'
            }, {
                label: '基本情報設定',
                to: {name: 'ClientSurveysEdit', params: {surveyId}}
            }, {
                label: '設問設定',
                to: {name: 'ClientSurveysQuestion', params: {surveyId}}
            }
        ];

        /**
         * models - フォームのモデル定義
         * @type Object
         * @property {reactive<Object>}                                                           question           - アンケート設問モデル
         * @property {Number}                                                                     question.cb_amount - キャッシュバックポイント
         * @property {Array< { survey_type: Number, question: String, choices: Array<String> } >} question.surveys   - 設問の配列
         * @property {Object}                                                                     survey             - 設問モデル
         * @property {String}                                                                     survey.survey_type - 回答タイプ
         * @property {String}                                                                     survey.question    - 設問分
         * @property {Array}                                                                      survey.choices     - 選択肢
         */
        const models = {
            question: reactive({
                cb_amount: 0,
                surveys: [{
                    survey_type: '0',
                    question: '',
                    choices: ['']
                }]
            }),
            survey: {
                survey_type: '0',
                question: '',
                choices: ['']
            }
        };

        /**
         * forms - フォームの定義
         * @type Object
         * @property {ref<Boolean>}                           isLoading - 読み込みフラグ
         * @property {ref<Boolean>}                           isDraft   - 下書き保存か否か
         * @property {Array<{ term: Any, description: Any }>} question  - 設問フォーム定義（定義リストの型で定義）
         */
        const forms = {
            isLoading: ref(false),
            isDraft: ref(false),
            question: [
                {
                    term: {
                        for: 'question',
                        text: '設問文',
                        description: '200文字以内',
                        isRequire: true,
                        maxlength: 200
                    },
                    description: {
                        id: 'question',
                        name: 'question',
                        placeholder: '入力してください'
                    }
                }, {
                    term: {
                        for: 'survey-type',
                        text: '回答タイプ',
                        isRequire: true
                    },
                    description: {
                        isExpanded: ref(false),
                        currentId: ref(''),
                        id: 'survey-type',
                        name: 'survey_type',
                        options: [
                            {label: '単一選択', value: '0', icon: 'Radio'},
                            {label: '複数選択', value: '1', icon: 'Checkbox'},
                            {label: '記述式', value: '2', icon: 'Contents'}
                        ]
                    }
                }, {
                    term: {
                        for: 'choices',
                        text: '選択肢',
                        description: '選択肢1つあたり100文字以内',
                        isRequire: true,
                        maxlength: 100,
                        maxsize: 20
                    },
                    description: {
                        id: 'choices',
                        name: 'choices',
                        placeholder: '入力してください',
                        inputType: 'choice',
                        message: {
                            maxlength: '#{label}は1つあたり#{maxlength}文字以内で#{inputType}してください。'
                        }
                    }
                }
            ]
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system   - APIから返却されたシステムエラー
         * @property {Object} question - 設問入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            question: reactive({})
        };

        /**
         * surveyData - クライアントアカウントのデータ
         * @type {Object}
         * @property {ref<Boolean>}     isEditable- 編集可能フラグ
         * @property {ref<Boolean>}     isLoading - 読み込みフラグ
         * @property {ref<Boolean>}     isError   - エラーフラグ
         * @property {reactive<Object>} data      - アカウントデータ
         */
        const surveyData = {
            isEditable: ref(true),
            isLoading: ref(false),
            isError: ref(false),
            data: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            question: new Validate(forms.question.map((obj) => ({...obj.term, ...obj.description})), models.question)
        };

        /**
         * preview - プレビュー時に必要なデータ
         * @type {Object}
         * @property {Object}      price           - 金額計算に必要なデータ
         * @property {String}      price.title     - 表示するタイトル
         * @property {ref<Number>} price.unitPrice - 表示するタイトル
         */
        const preview = {
            price: {
                title: '請求価格（税抜）',
                unitPrice: ref(0)
            }
        };

        /**
         * bindModelValue - 動的入力欄のためのバインドする属性・イベントの算出
         * @type {Object}
         */
        const bindModelValue = computed(() => {
            const {surveys} = models.question;
            const bindObject = {
                attrs: {},
                events: {}
            };

            // モデルの数だけ属性・イベントを生成
            surveys.forEach(({survey_type: type, question, choices}, index) => {
                // 属性の生成（v-model:[name]="modelValue" に相当）
                Object.assign(bindObject.attrs, {
                    [`survey_type-${index}`]: type,
                    [`question-${index}`]: question,
                    [`choices-${index}`]: choices
                });

                // イベントの生成（@update:[name]="modelValue = $event.target.value" に相当）
                Object.assign(bindObject.events, {
                    [`update:survey_type-${index}`]: (value) => {
                        models.question.surveys[index].survey_type = value;
                    },
                    [`update:question-${index}`]: (value) => {
                        models.question.surveys[index].question = value;
                    },
                    [`update:choices-${index}`]: (value) => {
                        models.question.surveys[index].choices = value;
                    }
                });
            });

            return bindObject;
        });

        /**
         * previewQuestionMarkup - 設問モデルの正規化とマークアップの生成
         * @type {Object<{ markup: String, model: Array<String> }>}
         */
        const previewQuestionMarkup = computed(() => {
            const surveys = [].concat(models.question.surveys);
            const formatted = [];
            let markup = '';

            surveys.forEach(({survey_type: type, question, choices}, index) => {
                // 記述式の場合は空の配列、それ以外は先頭または末尾の空白文字を取り除き、かつ空白文字ではない選択肢の配列
                const filterdChoices = type !== '2' ? choices.map((choice) => choice.replace(/^\s{0,}|\s{0,}$/g, '')).filter((choice) => choice !== '') : [];

                // 設問文が入力されていない または 記述式でないのに有効な選択肢がない場合は追加しない
                if (!question.replace(/^\s{0,}|\s{0,}$/g, '') || (type !== '2' && !filterdChoices.length)) {
                    return;
                }

                // 整形後のモデルを追加
                formatted.push({
                    survey_type: type,
                    question,
                    choices: filterdChoices
                });

                // 質問文のマークアップを追加
                markup += `<div class="question">${question}</div>`;

                // 記述式の回答タイプの場合
                if (type === '2') {
                    markup += `<textarea name="survey-dummy-${index}" placeholder="入力必須です"></textarea>`;

                    return;
                }

                // 単一・複数選択の場合
                markup += filterdChoices.map((choice) => `<label><input type="${type === '0' ? 'radio' : 'checkbox'}" name="survey-dummy-${index}" value="${choice}"><span>${choice}</span></label>`).join('');
            });

            return {markup, model: formatted};
        });

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * addQuestion - 設問の追加
             * @param {HTMLElement} form    - フォーム要素
             * @param {Number}      [index] - 設問追加位置（省略可・デフォルト 末尾に追加）
             * @returns {Void}
             */
            addQuestion(form, index) {
                const idx = index !== undefined ? index + 1 : models.question.surveys.length;

                // モデルを挿入（設問の追加）
                models.question.surveys.splice(idx, 0, {...models.survey});

                // DOM更新後、追加した設問へフォーカス移動
                nextTick(() => {
                    const {elements} = form;

                    elements[`question-${idx}`].focus();
                    elements[`question-${idx}`].scrollIntoView({behavior: 'smooth', block: 'center'});
                });
            },
            /**
             * shiftQuestion - 設問の移動
             * @param {HTMLElement} form - フォーム要素
             * @param {Number}      from - 移動元の位置
             * @param {Number}      to   - 移動先の位置
             * @returns {Void}
             */
            shiftQuestion(form, from, to) {
                const errors = {...formErrors.question};
                const model = {...models.question.surveys};

                models.question.surveys = models.question.surveys.reduce((array, survey, index) => {
                    // fromの設問をtoに入れ替える
                    if (index === from) {
                        array.push(model[to]);

                    // toの設問をfromに入れ替える
                    } else if (index === to) {
                        array.push(model[from]);

                    // 移動の必要がないものはそのままにする
                    } else {
                        array.push(survey);
                    }

                    return array;
                }, []);

                // エラーメッセージの位置変更
                Object.entries(formErrors.question).forEach(([key, value]) => {
                    const index = parseInt(key.replace(/(survey_type|question|choices)-/, ''), 10);

                    if (index === from || index === to) {
                        errors[key.replace(index, index === from ? to : from)] = value;
                        errors[key] = '';
                    }
                });

                // エラーオブジェクトの更新
                methods.updateErrors(errors);

                // DOM更新後、移動先の設問へフォーカス移動
                nextTick(() => {
                    const {elements} = form;

                    elements[`question-${to}`].focus();
                    elements[`question-${to}`].scrollIntoView({behavior: 'smooth', block: 'center'});
                });
            },
            /**
             * duplicateQuestion - 設問の複製
             * @param {HTMLElement} form  - フォーム要素
             * @param {Number}      index - 複製元の設問の位置
             * @returns {Void}
             */
            duplicateQuestion(form, index) {
                const errors = {...formErrors.question};

                // モデルを複製挿入（設問の追加）
                models.question.surveys.splice(index + 1, 0, {...models.question.surveys[index]});

                // エラーメッセージの位置変更
                Object.entries(formErrors.question).forEach(([key, value]) => {
                    const idx = parseInt(key.replace(/(survey_type|question|choices)-/, ''), 10);

                    if (idx === index) {
                        errors[key.replace(idx, idx + 1)] = value;
                    } else if (idx > index) {
                        errors[key.replace(idx, idx + 1)] = value;
                        errors[key] = '';
                    }
                });

                // エラーオブジェクトの更新
                methods.updateErrors(errors);

                // DOM更新後、複製した設問へフォーカス移動
                nextTick(() => {
                    const {elements} = form;

                    elements[`question-${index + 1}`].focus();
                    elements[`question-${index + 1}`].scrollIntoView({behavior: 'smooth', block: 'center'});
                });
            },
            /**
             * deleteQuestion - 設問の削除
             * @param {Number} index - 削除する設問の位置
             * @returns {Void}
             */
            deleteQuestion(index) {
                const errors = {...formErrors.question};
                const names = ['survey_type', 'question', 'choices'].map((name) => `${name}-${index}`);

                // 設問を削除
                models.question.surveys = models.question.surveys.filter((__, idx) => index !== idx);

                // 関連するエラーメッセージを削除
                Object.entries(errors).forEach(([key]) => {
                    if (!names.includes(key)) {
                        return;
                    }

                    delete formErrors.question[key];
                });
            },
            /**
             * updateErrors - エラーオブジェクトの更新
             * @param {Object} errors - 更新するエラーオブジェクト
             * @returns {Void}
             */
            updateErrors(errors) {
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.question, key)) {
                            delete formErrors.question[key];
                        }

                        return;
                    }

                    formErrors.question[key] = value;
                });
            },
            /**
             * updateValidatements - バリデーション情報更新処理
             * @description 動的に入力欄の増減が発生するため、バリデーション実施前にバリデーション項目を更新する必要があります
             * @param {Object} model - バリデーション対象のモデルオブジェクト
             * @returns {Void}
             */
            updateValidatements(model) {
                const validate = validates.question;
                const {surveys} = model;
                const validateSettings = forms.question.map((obj) => ({...obj.term, ...obj.description}));
                const validateForm = [];
                const validateModel = {};

                // バリデーション項目の作成
                surveys.forEach(({survey_type: type, question, choices}, index) => {
                    // 入力欄の個数分、設定を生成
                    validateForm.push(...validateSettings.map((setting) => {
                        const copy = {...setting};

                        // nameに連番を振る
                        copy.name += `-${index}`;

                        // 選択肢入力欄は、「記述式」の場合入力は必須でない
                        if (copy.name.startsWith('choices')) {
                            copy.isRequire = type !== '2';
                        }

                        return copy;
                    }));

                    // バリデーションクラスに渡すモデルのキー名を連番付きのものに変更
                    validateModel[`survey_type-${index}`] = type;
                    validateModel[`question-${index}`] = question;
                    validateModel[`choices-${index}`] = choices;
                });

                // バリデーションクラスの変数を更新、初期化処理を実施
                validate.form = validateForm;
                validate.model = validateModel;
                validate.init();
            },
            /**
             * validateQuestion - 入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             */
            validateQuestion(event, model) {
                const {currentTarget, submitter} = event;
                const {draft} = submitter.dataset;

                methods.updateValidatements(model);

                const {isError, errors} = validates.question.validate();

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                // エラーオブジェクトの更新
                methods.updateErrors(errors);

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    const errorTarget = currentTarget.elements[Object.keys(formErrors.question)[0]];

                    // 選択肢入力欄の場合は先頭にフォーカスする
                    if (errorTarget[Symbol.toStringTag] === 'RadioNodeList') {
                        errorTarget[0].focus();
                    } else {
                        errorTarget.focus();
                    }

                    // 読み込みフラグを更新（読み込み中）
                    forms.isLoading.value = false;

                    return;
                }

                // エラーがなければ作成処理を実行
                methods.updateQuestion(model, draft === 'true');
            },
            /**
             * updateQuestion - クライアント作成処理
             * @param model   {Object}  - 送信するモデル
             * @param isDraft {Boolean} - 下書き保存か否か
             */
            updateQuestion(model, isDraft) {
                const formData = {...model};

                forms.isLoading.value = true;
                forms.isDraft.value = isDraft;

                // 正規化されたモデルを追加
                formData.surveys = previewQuestionMarkup.value.model;

                // キャッシュバックポイントを計算 (単価 * 0.2) * 設問数
                formData.cb_amount = Math.ceil((preview.price.unitPrice.value * 0.2) * formData.surveys.length);

                axios.patch(
                    `/api/v2/client_account/bonuses_surveys/${surveyId}`,
                    {...formData, is_draft: isDraft},
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: isDraft ? '設問を下書き保存しました' : '設問を保存し、配信申請をしました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 一覧ページへ遷移
                    $router.push({name: isDraft ? 'ClientSurveysDraft' : 'ClientSurveysPending'});
                }).catch((error) => {
                    // エラーメッセージを更新
                    formErrors.system.value = error;
                }).finally(() => {
                    forms.isLoading.value = false;
                });
            },
            /**
             * getSurvey - APIからクライアントアカウント一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ accounts; Array, meta: Object }>}
             */
            async getSurvey() {
                const result = {};

                await axios.get(`/api/v2/client_account/bonuses/${surveyId}`, {
                    headers: store.state.auth,
                    params: {
                        'includes[][bonuses_surveys]': 'bonuses_survey_choices',
                        with_price: 'survey'
                    }
                }).then((response) => {
                    const {bonus, meta} = response.data;

                    result.isEditable = bonus.status === '下書き';
                    result.price = meta.service_price || 0;
                    result.survey = {
                        bonus_name: bonus.bonus_name,
                        description: bonus.description || '',
                        start_date: new Date(bonus.start_date),
                        end_date: new Date(bonus.end_date),
                        cap: bonus.cap.toString(),
                        surveys: bonus.bonuses_surveys.map(({survey_type: type, question, bonuses_survey_choices: choices}) => ({
                            survey_type: type.toString(),
                            question,
                            choices: choices.map((choice) => choice.choice)
                        }))
                    };
                });

                return result;
            },
            /**
             * getSurveyData - アンケート詳細を取得・データ更新
             * @param {Object} targetData - 更新するデータ
             * @returns {Void}
             */
            getSurveyData(targetData) {
                // すでに読み込み中の場合は取得しない
                if (targetData.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetData.isLoading.value = true;

                methods.getSurvey().then((response) => {
                    const {survey, price, isEditable} = response;

                    // データを更新
                    Object.entries(survey).forEach(([key, value]) => {
                        if (key !== 'description') {
                            targetData.data[key] = value;

                            return;
                        }

                        // markdownをHTMLにパース
                        targetData.data[key] = markdownit.render(value);
                    });
                    targetData.isEditable.value = isEditable;

                    // モデルを更新
                    if (survey.surveys.length) {
                        models.question.surveys = survey.surveys;
                    }

                    // 単価を更新
                    preview.price.unitPrice.value = price;
                }).catch(() => {
                    // エラーフラグを更新
                    targetData.isError.value = true;
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetData.isLoading.value = false;
                });
            }
        };

        onMounted(() => {
            methods.getSurveyData(surveyData);
        });

        return {
            breadcrumbs, models, forms, formErrors, validates, surveyData, methods, bindModelValue, preview, previewQuestionMarkup
        };
    }
});
</script>
