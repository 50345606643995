<template>
    <label
        class="a-service-checkbox"
        :class="{
            'is-error': isError,
            'is-readonly': $attrs.readonly,
            'is-disabled': $attrs.disabled,
            'is-checked': modelValue.includes($attrs.value)
        }"
        :for="$attrs.id"
    >
        <input
            :id="$attrs.id"
            type="checkbox"
            :name="$attrs.name"
            class="a-service-checkbox__input"
            :disabled="$attrs.disabled || $attrs.readonly"
            :value="$attrs.value"
            v-model="model"
            :indeterminate="$attrs.indeterminate"
        >
        <span v-if="$slots.image" class="a-service-checkbox__image"><slot name="image" /></span>
        <span class="a-service-checkbox__text"><slot /></span>
    </label>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isError: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Array,
            default: () => []
        }
    },
    setup(props, $) {
        const model = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                $.emit('update:model-value', value);
            }
        });

        return {model};
    }
});
</script>

<style lang="scss" scoped>
.a-service-checkbox {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 16px 24px;
    background: var.$color-utils-background;
    border: solid 1px var.$color-gray-10;
    border-radius: 4px;
    max-width: 182px;
    transition: border-color .3s ease 0s;

    &.is-error {
        border-color: var.$color-danger-50;

        .a-service-checkbox__input + .a-service-checkbox__text::before {
            color: var.$color-danger-50;
        }
    }

    &.is-readonly {
        opacity: .6;

        .a-service-checkbox__input ~ .a-service-checkbox__text {
            &::before,
            &::after {
                content: none;
            }
        }
    }

    &.is-disabled {
        opacity: .8;
    }

    &.is-checked {
        opacity: 1;
        border-color: var.$color-primary-50;

        .a-service-checkbox__text {
            color: var.$color-primary-50 !important;
        }
    }

    @at-root {
        .a-service-checkbox__input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;

            &[disabled] {
                ~ .a-service-checkbox__text {
                    color: var.$color-text-disabled;
                }

                &:checked {
                    ~ .a-service-checkbox__text {
                        color: var.$color-text-disabled;

                        &::before {
                            background: var.$color-text-disabled;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:indeterminate {
                    ~ .a-service-checkbox__text {

                        &::before {
                            background: var.$color-text-disabled;
                            border-color: var.$color-text-disabled;
                        }
                    }
                }
            }

            &:checked {
                ~ .a-service-checkbox__text {
                    color: var.$color-primary-50;

                    &::before {
                        background: var.$color-primary-50;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }

            &:indeterminate {
                ~ .a-service-checkbox__text {

                    &::before {
                        background: var.$color-primary-50;
                        border-color: var.$color-primary-50;
                    }

                    &::after {
                        top: 12px;
                        width: 10px;
                        height: 0;
                        border-width: 2px;
                        transform: none;
                        opacity: 1;
                    }
                }
            }
        }

        .a-service-checkbox__text {
            text-align: center;
            font-size: 1.4rem;
            line-height: (22 / 14);
            color: var.$color-text-medium;
            transition: color .3s ease 0s;
            margin: auto;

            &:not(:first-child) {
                padding-top: 24px;
            }

            &::before {
                position: absolute;
                top: 8px;
                right: 8px;
                display: block;
                content: "";
                width: 20px;
                height: 20px;
                background: var.$color-utils-background;
                border-radius: 2px;
                border: solid 2px currentColor;
                transition: background-color .3s ease 0s;
            }

            &::after {
                position: absolute;
                top: 13px;
                right: 12.5px;
                display: block;
                content: "";
                width: 11px;
                height: 7px;
                border-radius: 2px;
                border-left: solid 2px var.$color-text-white;
                border-bottom: solid 2px var.$color-text-white;
                transform: rotate(-45deg);
                opacity: 0;
            }
        }

        .a-service-checkbox__image {
            width: 64px;
            height: 64px;

            ::v-deep(img) {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
    }
}
</style>
