<template>
    <div class="a-select-option">
        <slot name="icon" />
        <span class="a-select-option__text">{{label}}</span>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            required: true
        }
    }
});
</script>

<style lang="scss" scoped>
.a-select-option {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: (22 / 14);
    min-height: 48px;
    padding: 12px 16px;
    color: currentColor;

    @at-root {
        .a-select-option__text {

            &:not(:first-child) {
                margin-left: 8px;
            }
        }
    }
}
</style>
