<template>
    <div class="a-text-field">
        <span v-if="$slots.icon" class="a-text-field__icon"><slot name="icon" /></span>
        <input
            ref="input"
            v-bind="$attrs"
            v-model="model"
            :type="type"
            class="a-text-field__input"
            :class="{'is-error': isError}"
            :aria-invalid="isError ? 'true' : undefined"
        />
        <span v-if="$slots.action && !$attrs.disabled" class="a-text-field__action"><slot name="action" :action="{element: input, ...$attrs}" /></span>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed, ref} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'text',
            varidator: (value) => ['email', 'password', 'search', 'tel', 'text', 'url'].includes(value)
        },
        modelValue: {
            type: String,
            default: ''
        },
        isError: {
            type: Boolean,
            default: false
        }
    },
    setup(props, $) {
        const input = ref(null);
        const model = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                $.emit('update:model-value', value);
            }
        });

        return {model, input};
    }
});
</script>

<style lang="scss" scoped>
.a-text-field {
    position: relative;
    width: 100%;

    @at-root {
        .a-text-field__input {
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-medium;
            background: var.$color-utils-background;
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            transition: background-color .3s ease 0s, border-color .3s ease 0s;

            &:not(:first-child) {
                padding-left: 48px;
            }

            &:not(:last-child) {
                padding-right: 48px;
            }

            &:hover {
                background: #F2F6F9;
            }

            &:focus {
                border-color: var.$color-primary-50;
                background: var.$color-utils-background;
                outline: none;
            }

            &.is-error {
                border-color: var.$color-danger-50;
            }

            &[readonly] {
                background: var.$color-gray-10;
            }

            &[disabled] {
                color: var.$color-text-disabled;
                border-color: var.$color-gray-10;
                background: var.$color-gray-10;
                opacity: .6;
            }
        }

        .a-text-field__icon,
        .a-text-field__action {
            display: inline-flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        .a-text-field__icon {
            left: 16px;
        }

        .a-text-field__action {
            right: 0;

            > :not(:root) {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 48px;
                height: 100%;
            }

            &::v-deep([disabled]) {
                opacity: .6;
            }
        }
    }
}
</style>
