<template>
    <main class="t-main">
        <div class="t-main__form">
            <forms @submit.prevent="$emit('reset-password', $event)">
                <template v-slot:header>
                    <form-header v-bind="forms.header" />

                    <banner v-if="formErrors.system.value.length" appearance="danger">
                        <template v-slot:icon><icon name="Error" /></template>
                        <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
                    </banner>
                </template>

                <field v-bind="forms.password">
                    <template v-slot:field="fieldProps">
                        <text-field
                            v-bind="fieldProps.field"
                            :is-error="formErrors.resetPassword.password !== undefined"
                            :aria-errormessage="`${fieldProps.field.id}-invalid`"
                            :aria-required="fieldProps.field.isRequire"
                            :model-value="models.resetPassword.password"
                            @update:model-value="$emit('update:password', $event)"
                        >
                            <template v-slot:action="fieldActionProps">
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    @click="
                                        (fieldActionProps.action.element.type = fieldActionProps.action.element.type === 'text' ? 'password' : 'text') &
                                        ($event.currentTarget.setAttribute('aria-pressed', fieldActionProps.action.element.type === 'text')) &
                                        ($refs.icon1.$.props.name = fieldActionProps.action.element.type === 'text' ? 'Visibility' : 'Visibility Off')
                                    "
                                ><icon ref="icon1" name="Visibility Off" /></button>
                            </template>
                        </text-field>
                        <form-message v-if="formErrors.resetPassword.password" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.resetPassword.password"><template v-slot:icon><icon name="Error" /></template></form-message>
                    </template>
                </field>

                <field v-bind="forms.password_confirmation">
                    <template v-slot:field="fieldProps">
                        <text-field
                            v-bind="fieldProps.field"
                            :is-error="formErrors.resetPassword.password_confirmation !== undefined"
                            :aria-errormessage="`${fieldProps.field.id}-invalid`"
                            :aria-required="fieldProps.field.isRequire"
                            :model-value="models.resetPassword.password_confirmation"
                            @update:model-value="$emit('update:password_confirmation', $event)"
                        >
                            <template v-slot:action="fieldActionProps">
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    @click="
                                        (fieldActionProps.action.element.type = fieldActionProps.action.element.type === 'text' ? 'password' : 'text') &
                                        ($event.currentTarget.setAttribute('aria-pressed', fieldActionProps.action.element.type === 'text')) &
                                        ($refs.icon2.$.props.name = fieldActionProps.action.element.type === 'text' ? 'Visibility' : 'Visibility Off')
                                    "
                                ><icon ref="icon2" name="Visibility Off" /></button>
                            </template>
                        </text-field>
                        <form-message v-if="formErrors.resetPassword.password_confirmation" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.resetPassword.password_confirmation"><template v-slot:icon><icon name="Error" /></template></form-message>
                    </template>
                </field>

                <template v-slot:footer>
                    <form-footer>
                        <button-group>
                            <standard-button appearance="primary" type="submit" :is-loading="forms.isLoading.value">{{$route.query.changed_password === 'true' ? '変更する' : '設定する'}}</standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>

            <modal v-bind="completedModal" :is-expanded="completedModal.isExpanded.value">
                <template v-slot:header="modalHeaderProps">
                    <modal-header title="パスワード変更完了" v-bind="modalHeaderProps.header" />
                </template>

                <p>{{completedModal.message.value}}</p>
                <p>新しいパスワードで再度ログインをしてください。</p>

                <template v-slot:footer>
                    <modal-footer>
                        <button-group layout="end">
                            <standard-button appearance="primary" type="submit" @click="$router.push({name: 'ClientLogin'})">OK</standard-button>
                        </button-group>
                    </modal-footer>
                </template>
            </modal>
        </div>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Forms, {
    FormHeader, FormFooter, Field, FormMessage
} from '@/components/01_Atoms/Forms';
import {TextField} from '@/components/01_Atoms/TextFields';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';

export default defineComponent({
    inheritAttrs: false,
    emits: ['reset-password', 'update:password', 'update:password_confirmation'],
    components: {
        Banner, ButtonGroup, StandardButton, Icon, Forms, FormHeader, FormFooter, Field, FormMessage, TextField, Modal, ModalHeader, ModalFooter
    },
    props: {
        models: {
            type: Object,
            default: () => {}
        },
        forms: {
            type: Object,
            default: () => {}
        },
        formErrors: {
            type: Object,
            default: () => {}
        },
        completedModal: {
            type: Object,
            default: () => {}
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    min-height: calc(100vh - 72px);

    @at-root {
        .t-main__form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 64px;
            width: 980px;
            margin: auto;
            background: var.$color-utils-background;
            border-radius: 6px;

            > ::v-deep(.a-form) {
                width: 480px;
            }

            ::v-deep(.a-form-header) {
                text-align: center;
            }
        }
    }
}
</style>
