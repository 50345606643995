<template>
    <button
        type="button"
        class="a-pagination-item"
        :disabled="$attrs.disabled"
        :aria-current="isCurrent ? 'true' : undefined"
        @click="$emit('clicked', $event)"
    >
        <slot name="icon" />
        <span
            class="a-pagination-item__text"
            :class="{'u-altText': $slots.icon}"
        >
            <slot />
        </span>
    </button>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isCurrent: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<style lang="scss" scoped>
.a-pagination-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    min-height: 36px;
    padding: 2px;
    font-size: 1.6rem;
    line-height: 1;
    color: var.$color-text-disabled;
    border-radius: 2px;
    transition: background-color .3s ease 0s;

    @include mixin.hover {
        background: #EDEEF0;
    }

    &[aria-current] {
        cursor: default;
        color: var.$color-text-white;
        background: var.$color-primary-70;

        @include mixin.hover {
            background: var.$color-primary-70;
        }
    }

    &[disabled] {
        cursor: default;
        color: var.$color-text-disabled;
        background: transparent;

        @include mixin.hover {
            color: var.$color-text-disabled;
            background: transparent;
        }
    }
}
</style>
