<template>
    <div class="a-message-empty">
        <div class="a-message-empty__content">
            <p class="a-message-empty__message"><b>{{message}}</b></p>
            <p v-if="repletion !== ''" class="a-message-empty__repletion">{{repletion}}</p>
        </div>

        <div v-if="$slots.actions" class="a-message-empty__actions"><slot name="actions" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        message: {
            type: String,
            default: 'メッセージテキスト'
        },
        repletion: {
            type: String,
            default: '補足テキスト'
        }
    }
});
</script>

<style lang="scss" scoped>
.a-message-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @at-root {
        .a-message-empty__content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .a-message-empty__message {
            font-size: 2rem;
            font-weight: bold;
            line-height: (28 / 20);
            color: var.$color-text-high;
        }

        .a-message-empty__repletion {
            font-size: 1.4rem;
            line-height: (22 / 14);
            color: var.$color-text-medium;
            margin-top: 16px;
        }

        .a-message-empty__actions {
            margin-top: 24px;
        }
    }
}
</style>
