<template>
    <div class="a-tabs">
        <slot name="tab-list" :tabs="{tabs, currentIndex}" />

        <div class="a-tabs__content">
            <slot
                v-for="(tab, index) in tabs"
                :key="tab"
                :name="`tab-panel-${index}`"
                :panel="{
                    id: tab.id,
                    'aria-labelledby': `${tab.id}-control`,
                    'aria-setsize': tabs.length,
                    hidden: currentIndex !== index
                }"
            />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        currentIndex: {
            type: Number,
            default: 0,
            required: true
        },
        tabs: {
            type: Array,
            default: () => ([
                {id: 'tab-1', label: 'Tab 1', badge: 0},
                {id: 'tab-2', label: 'Tab 2'},
                {id: 'tab-3', label: 'Tab 3'}
            ])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-tabs {
    &:not(:first-child) {
        margin-top: 32px;
    }

    @at-root {
        .a-tabs__content {
            padding: 32px 24px;
        }
    }
}
</style>
