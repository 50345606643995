<template>
    <account-setting
        :is-menu-expanded="isMenuExpanded"
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        :client="clientData"
        v-model:name="models.client.name"
        v-model:department="models.client.department"
        v-model:position="models.client.position"
        v-model:contact_name="models.client.contact_name"
        v-model:furigana="models.client.furigana"
        v-model:address="models.client.address"
        v-model:tel="models.client.tel"
        v-model:email="models.client.email"
        @update-client="methods.validateClient"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, watch
} from 'vue';
import axios from 'axios';
import store from '@/store';
import Validate from '@/validates';
import {AccountSetting} from '@/components/04_Templates/Client';

export default defineComponent({
    inheritAttrs: false,
    components: {AccountSetting},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        currentUserData: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, $) {
        /**
         * models - フォームのモデル定義
         * @type Object
         * @property {reactive<Object>} client     - クライアントのモデル
         * @property {String} client.name          - 会社名/個人
         * @property {String} client.department    - 部署名
         * @property {String} client.position      - 役職名
         * @property {String} client.contact_name  - お名前
         * @property {String} client.furigana      - フリガナ
         * @property {String} client.address       - 住所
         * @property {String} client.tel           - 電話番号
         * @property {String} client.email         - メールアドレス
         */
        const models = {
            client: reactive({
                name: '',
                department: '',
                position: '',
                contact_name: '',
                furigana: '',
                address: '',
                tel: '',
                email: ''
            })
        };

        /**
         * forms - フォームの定義
         * @type Object
         * @property {ref<Boolean>}                           isLoading - 読み込みフラグ
         * @property {Array<{ term: Any, description: Any }>} client    - クライアントのフォーム定義（定義リストの型で定義）
         */
        const forms = {
            isLoading: ref(false),
            client: [
                {
                    term: {
                        for: 'name',
                        text: '会社名/個人',
                        isRequire: true
                    },
                    description: {
                        id: 'name',
                        name: 'name',
                        placeholder: '例：キャッシュビーデータ株式会社'
                    }
                }, {
                    term: {
                        for: 'department',
                        text: '部署名',
                        isRequire: true
                    },
                    description: {
                        id: 'department',
                        name: 'department',
                        placeholder: '例：営業部'
                    }
                }, {
                    term: {
                        for: 'position',
                        text: '役職名'
                    },
                    description: {
                        id: 'position',
                        name: 'position',
                        placeholder: '例：部長'
                    }
                }, {
                    term: {
                        for: 'contact_name',
                        text: 'お名前',
                        isRequire: true
                    },
                    description: {
                        id: 'contact_name',
                        name: 'contact_name',
                        placeholder: '例：鈴木　一郎'
                    }
                }, {
                    term: {
                        for: 'furigana',
                        text: 'フリガナ',
                        isRequire: true,
                        inputMode: 'byteFullKana'
                    },
                    description: {
                        id: 'furigana',
                        name: 'furigana',
                        placeholder: '例：スズキ　イチロウ'
                    }
                }, {
                    term: {
                        for: 'address',
                        text: '住所',
                        isRequire: true
                    },
                    description: {
                        id: 'address',
                        name: 'address',
                        placeholder: '例：東京都港区赤坂一丁目2番7号'
                    }
                }, {
                    term: {
                        for: 'tel',
                        text: '電話番号',
                        isRequire: true,
                        maxlength: 15,
                        remove: {
                            label: 'ハイフン',
                            item: '-',
                            maxLength: 12
                        }
                    },
                    description: {
                        id: 'tel',
                        name: 'tel',
                        type: 'tel',
                        placeholder: '例：03-1234-5678'
                    }
                }, {
                    term: {
                        for: 'email',
                        text: 'メールアドレス',
                        inputType: 'email',
                        isRequire: true
                    },
                    description: {
                        id: 'email',
                        name: 'email',
                        placeholder: '例：xxxx@xxx.co.jp'
                    }
                }
            ]
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system - APIから返却されたシステムエラー
         * @property {Object} login  - ログイン情報入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            client: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            client: new Validate(forms.client.map((obj) => ({...obj.term, ...obj.description})), models.client)
        };

        /**
         * clientData - クライアントアカウントのデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込みフラグ
         * @property {ref<Boolean>} isError   - エラーフラグ
         * @property {Object}       data      - アカウントデータ
         */
        const clientData = {
            isLoading: ref(false),
            data: {}
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * emailUniqueCheck - メールアドレス重複チェック
             * @param {String} email - チェック対象のメールアドレス
             * @returns {Promise}
             */
            emailUniqueCheck(email) {
                // メールアドレスが未入力の場合
                if (email === '') {
                    return Promise.resolve({isError: false, message: {}});
                }

                // APIからバリデーション結果を取得
                return new Promise((resolve) => {
                    const clientId = clientData.data.client_accounts ? clientData.data.id : 0;

                    axios.get('/api/v2/client_account/clients/email_unique_check', {params: {email, id: clientId}}).then((response) => {
                        // 使用可能なメールアドレスの場合
                        resolve({isError: false, message: {email: response.data.message}});
                    }).catch((error) => {
                        // 使用不可なメールアドレスの場合
                        resolve({isError: true, message: {email: error[0]}});
                    });
                });
            },
            /**
             * validateClient - 入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             */
            async validateClient(event, model) {
                const {currentTarget} = event;
                const {isError, errors} = validates.client.validate();

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                const uniqueCheck = await methods.emailUniqueCheck(model.email);

                // メールアドレスが利用不可の場合、エラーメッセージを追加
                if (uniqueCheck.isError) {
                    Object.assign(errors, uniqueCheck.message);
                }

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.client, key)) {
                            delete formErrors.client[key];
                        }

                        return;
                    }

                    formErrors.client[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError || uniqueCheck.isError) {
                    currentTarget.elements[Object.keys(formErrors.client)[0]].focus();

                    // 読み込みフラグを更新（読み込み中）
                    forms.isLoading.value = false;

                    return;
                }

                // エラーがなければ更新処理を実行
                methods.updateClient(model);
            },
            /**
             * updateClient - クライアント更新処理
             * @param model {Object} - 送信するモデル
             * @returns {Void}
             */
            updateClient(model) {
                const clientId = clientData.data.client_accounts ? clientData.data.id : 0;

                // すでに読み込み中の場合は取得しない
                if (clientData.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                clientData.isLoading.value = true;

                axios.patch(
                    `/api/v2/client_account/clients/${clientId}`,
                    {...model},
                    {headers: store.state.auth}
                ).then((response) => {
                    const {'access-token': accessToken, client, uid} = response.headers;

                    // アクセス情報を更新（メールアドレス変更の場合ログイン不可となるため）
                    store.dispatch('updateStatus', {
                        'access-token': accessToken, client, uid, ban_status: false
                    });

                    // 再読み込みを実行
                    $.emit('getCurrentUser');
                    $.emit('displayToast', {
                        message: '基本情報の更新に成功しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // エラーをリセット
                    formErrors.client = reactive({});
                    formErrors.system.value.length = 0;
                }).catch((error) => {
                    // エラーメッセージを更新
                    formErrors.system.value = error[0] ? error : ['基本情報の更新に失敗しました。時間を置いて再度お試しください。'];
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    clientData.isLoading.value = false;
                    forms.isLoading.value = false;
                });
            }
        };

        watch(() => props.currentUserData, (client) => {
            // データが未取得の場合は何もしない
            if (!Object.keys(client).length) {
                return;
            }

            // ログインユーザ情報とモデルを更新
            clientData.data = client;
            Object.assign(models.client, {
                name: client.name,
                department: client.department,
                position: client.position,
                contact_name: client.contact_name,
                furigana: client.furigana,
                address: client.address,
                tel: client.tel,
                email: client.client_accounts[0].email
            });
        }, {deep: true, immediate: true});

        return {
            models, forms, formErrors, clientData, methods
        };
    }
});
</script>

<style lang="scss" scoped></style>
