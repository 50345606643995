<template>
    <client-header
        :is-login="true"
        :user-menu="userMenu"
        :is-expanded="sideMenu.isExpanded.value"
        :server-error-modal="serverErrorModal"
        :toasts="toasts"
        @close-modal="$emit('close-error-modal', $event)"
        @open-user-menu="userMenu.isExpanded.value = true"
        @close-user-menu="userMenu.isExpanded.value = false"
        @toggle-menu="sideMenu.isExpanded.value = $event"
        @logout="methods.logout"
    />
    <client-side-menu
        :navigations="sideMenu.navigations.value"
        :is-expanded="sideMenu.isExpanded.value"
    />
    <local-side-menu
        :navigations="localMenu"
        :is-menu-expanded="sideMenu.isExpanded.value"
    />
    <router-view
        :current-user-data="currentUserData"
        :is-menu-expanded="sideMenu.isExpanded.value"
        @getCurrentUser="methods.validateToken"
        @displayToast="methods.addToast"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref, reactive} from 'vue';
import store from '@/store';
import {useRoute, useRouter} from 'vue-router';
import {ClientHeader} from '@/components/04_Templates/_shared/Header';
import {ClientSideMenu, LocalSideMenu} from '@/components/04_Templates/_shared/SideMenu';

export default defineComponent({
    inheritAttrs: false,
    components: {
        ClientHeader, ClientSideMenu, LocalSideMenu
    },
    props: {
        serverErrorModal: {
            type: Object,
            default: () => ({})
        }
    },
    setup() {
        const $router = useRouter();
        const $route = useRoute();

        /**
         * currentUserData - ログインユーザデータ
         * @type {Object}
         */
        const currentUserData = reactive({...$route.meta.currentUser});
        const toasts = ref([]);

        /**
         * userMenu - ユーザメニューデータ
         * @type {Object}
         * @property {String}           id         - ユーザメニューのID名
         * @property {reactive<Object>} userName   - ユーザ名
         * @property {ref<Boolean>}     isExpanded - ユーザメニュー開閉フラグ
         * @property {Array<Object>}    menus      - メニュー内容
         * @property {String}           placement  - メニュー表示位置設定
         */
        const userMenu = {
            id: 'user-menu',
            userName: reactive({
                primary: $route.meta.currentUser ? $route.meta.currentUser.contact_name : '',
                secondary: $route.meta.currentUser ? $route.meta.currentUser.name : ''
            }),
            isExpanded: ref(false),
            menus: [
                {label: 'アカウント設定', appearance: 'link', to: {name: 'ClientAccountSetting'}},
                {isSeparator: true},
                {label: 'ログアウト', beforeIcon: 'Logout', emitEvent: 'logout'}
            ],
            placement: 'bottom-right'
        };

        /**
         * sideMenu - サイドメニューデータ
         * @type {Object}
         * @property {ref<Boolean>}                                       isExpanded  - サイドメニュー開閉フラグ
         * @property {Array<{ label: String, icon: String, to: Object }>} navigations - サイドメニュー内容
         */
        const sideMenu = {
            isExpanded: ref(false),
            navigations: ref([
                {
                    label: 'アンケート', icon: 'Questionary', code: 'survey', to: {name: 'ClientSurveys'}
                }, {
                    label: 'データ利用', icon: 'DataBox', code: 'data_box', to: {name: 'ClientDataBoxes'}
                }
            ])
        };

        /**
         * localMenu - ローカルナビゲーションメニューデータ
         * @type {Array<{ label: String, to: Object }>}
         */
        const localMenu = [
            {label: '基本情報変更', to: {name: 'ClientAccountSetting'}},
            {label: 'パスワード変更', to: {name: 'ClientChangePassword'}}
        ];

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * logout - ログアウト処理
             * @returns {Void}
             */
            logout() {
                store.dispatch('logout').then(() => {
                    $router.push({name: 'ClientLogin'});
                });
            },
            /**
             * validateToken - アクセストークン バリデーション
             * @returns {Void}
             */
            validateToken() {
                store.dispatch('validate').then((response) => {
                    userMenu.userName.primary = response.contact_name;
                    userMenu.userName.secondary = response.name;
                    Object.entries(response).forEach(([key, value]) => {
                        currentUserData[key] = value;
                    });
                });
            },
            addToast(toast) {
                toasts.value.push(toast);
            }
        };

        if ($route.meta.currentUser) {
            const {data_services: services} = $route.meta.currentUser;

            sideMenu.navigations.value = sideMenu.navigations.value.map((navigation) => {
                const navigate = {...navigation};
                const service = services.find((item) => item.service_code === navigate.code);
                const isInclude = service !== undefined;

                navigate.disabled = !isInclude;

                if (isInclude) {
                    navigate.initial_url = service.initial_url;
                }

                return navigate;
            });
        }

        return {
            currentUserData, toasts, userMenu, sideMenu, localMenu, methods
        };
    },
    // 入場前に実行する処理
    beforeRouteEnter: (to, _, next) => {
        // アクセストークン バリデーション
        store.dispatch('validate').then((response) => {
            to.meta.currentUser = response;
            next();
        }).catch(() => {
            // ログインしているが、情報が不正な場合は強制ログアウト
            store.dispatch('forceLogout');
            next({name: 'ClientLogin'});
        });
    }
});
</script>
