import {createApp} from 'vue';
import axios from 'axios';
import VuePapaParse from 'vue-papa-parse';
import App from './App.vue';
import router from './router';
import store from './store';

// add submitter polyfill
import 'event-submitter-polyfill';

const app = createApp(App);
app.use(store).use(router).use(VuePapaParse).mount('#app');

// 共通CSSの読み込み
require('@/styles/common.scss');
require('@/styles/utility.scss');

// axiosの共通設定
axios.defaults.timeout = 100000; // タイムアウト100秒
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; // APIベースURLの設定

// ストアデータの初期読み込み
store.dispatch('load');

app.provide('$papa', app.config.globalProperties.$papa);

// axios共通処理設定
axios.interceptors.response.use(
    (response) => response,
    /**
     * エラー共通処理
     * @param {ErrorObject} err - エラーレスポンスデータ
     * @returns {Promise}
     */
    (err) => {
        const {message} = err;

        // ネットワーク・タイムアウトエラー
        if (message && !err.response) {
            app.$.setupState.serverErrorModal.isExpanded.value = true;

            return Promise.reject([].concat([message]));
        }

        const {status, config} = err.response;

        // 401（認証）エラーの場合
        if (status === 401) {
            const {ban_status: banStatus} = err.response.data;

            // アカウントが停止されていたらstoreを更新
            if (typeof banStatus === 'boolean' && banStatus) {
                store.dispatch('updateStatus', {
                    'access-token': '',
                    client: '',
                    uid: '',
                    ban_status: true
                });
                router.push({path: '/403-fobidden'});

                return Promise.reject();
            }

            // リクエストURLからパラメータを削除
            const url = config.url.replace(/\?.+/, '');

            // ログインページではない かつ ログインバリデーションが実行された場合は、ログインページへリダイレクト
            if (url.endsWith('/validate_token') || url.endsWith('/clients/show_current')) {
                const {path} = router.currentRoute.value;
                const redirect = path.startsWith('/client') ? 'ClientLogin' : 'AdminLogin';

                // リダイレクトクエリ付きでログインページへリダイレクト
                router.push({name: redirect, query: {redirect_url: path}});

                return Promise.reject();
            }
        }

        // 操作中にアカウントが凍結された場合
        if (status === 403) {
            store.dispatch('updateStatus', {
                'access-token': '',
                client: '',
                uid: '',
                ban_status: true
            });
        }

        const {errors, error} = err.response.data;

        // 文字列でエラーが返ってきた場合は配列化して返却
        if (!errors) {
            return Promise.reject([].concat([error]));
        }

        // エラー文字列の配列を返却
        return Promise.reject(errors);
    }
);
