<template>
    <ul class="a-tab-list" :role="isNavigation ? undefined : 'tablist'" :aria-activedescendant="isNavigation ? undefined : tabs[currentIndex].id">
        <li
            v-for="(tab, index) in tabs"
            :key="tab"
            :role="isNavigation ? undefined : 'presentation'"
            class="a-tab-list__item"
        >
            <slot name="tab" :index="index" :item="Object.assign({...tab}, {
                id: `${tab.id}-control`,
                role: isNavigation ? null : 'tab',
                to: tab.to || {
                    path: tab.id
                },
                href: `#${tab.id}`,
                'aria-controls': tab.id,
                'aria-selected': currentIndex === index,
                tabindex: isNavigation || currentIndex === index ? 0 : -1,
                isExternalLink: isNavigation,
                isCurrent: currentIndex === index,
                label: tab.label,
                'data-badge-number': tab.badge
            })" />
        </li>
    </ul>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        isNavigation: {
            type: Boolean,
            default: false
        },
        currentIndex: {
            type: Number,
            default: 0,
            required: true
        },
        tabs: {
            type: Array,
            default: () => ([
                {id: 'tab-1', label: 'Tab 1', badge: 0},
                {id: 'tab-2', label: 'Tab 2'},
                {id: 'tab-3', label: 'Tab 3'}
            ])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-tab-list {
    display: flex;
    padding: 0 24px;
    border-bottom: solid 2px #E6E7E6;

    @at-root {
    }
}
</style>
