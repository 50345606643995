import Validator from './utils/validator';

export default class Validate {
    /**
     * フォームバリデーション
     *
     * @constructor
     * @param {Object} form  - フォーム要素のデータ（ラベルやバリデーションデータ）のオブジェクト
     * @param {Object} model - フォームモデル（入力値データ）のオブジェクト
     */
    constructor(form, model) {
        /**
         * メンバ変数
         * @type {Object}        this.form          - フォーム要素のデータ（ラベルやバリデーションデータ）のオブジェクト
         * @type {Object}        this.model         - フォームモデル（入力値データ）のオブジェクト
         * @type {Arrat<String>} this.names         - name属性値の配列
         * @type {Object}        this.validatements - Validatorクラスを継承するインスタンスの配列
         * @type {Object}        this.errors        - エラーメッセージのオブジェクト
         * @type {Boolean}       this.isError       - フォームにエラーがある（true）かない（false）か
         */
        this.form = form;
        this.model = model;
        this.names = [];
        this.validatements = {};
        this.errors = {};
        this.isError = false;

        // 初期化を実行
        this.init();
    }

    /**
     * init - 初期化
     * @returns {Void}
     */
    init() {
        /**
         * messageTemplate - エラーメッセージテンプレート
         * @type {Object}
         * @description #{}ワードはフォームに沿った内容で上書きされます
         */
        const messageTemplate = {
            required: '#{label}を#{inputType}してください', // 必須入力エラー
            numeric: '#{label}は半角数字のみで入力してください。', // 数値入力エラー
            byteFullKana: '#{label}は全角カタカナで入力してください', // 全角カタカナ入力エラー
            pattern: '#{label}の入力形式が正しくありません。', // 入力形式エラー
            password: '#{label}は半角英数字混在8文字以上で入力してください。', // パスワード入力形式エラー
            email: '#{label}を正しく#{inputType}してください', // メール入力形式エラー
            tel: '#{label}を正しく#{inputType}してください', // 電話番号入力形式エラー
            unmatch: '入力内容が一致していません。', // 不一致エラー
            accept: 'アップロードファイルが不正です。ファイルの拡張子は#{extension}にしてください。', // 拡張子エラー
            removingMaxlength: '#{label}は#{removeLabel}を除き、#{removeMaxLength}文字以下で入力してください。', // 文字長（最大）エラー
            maxlength: '#{label}は#{maxlength}文字以内で入力してください。', // 文字長（最大）エラー
            minlength: '#{label}は#{minlength}文字以上で入力してください。', // 文字長（最小）エラー
            fixLength: '#{label}は#{fixLength}文字で入力してください。', // 文字長（一致）エラー
            range: '#{label}は#{min}以上、#{max}以内の数値を#{inputType}してください。', // 数値範囲エラー
            maxsize: 'アップロードファイルは#{maxsize}以下にしてください。', // 容量超過エラー
            maxplaytime: 'アップロードファイルは#{maxplaytime}以内にしてください。', // 再生時間超過エラー
            invalidDate: '日付が無効です。YYYY/MM/DD形式で入力してください。', // 日付入力不正エラー
            invalidPeriod: '#{label}には#{label}以降の#{type}を指定してください。', // 期間入力不正エラー
            olderDate: '#{label}には#{after}以降の#{type}を指定してください。', // 日付指定範囲エラー（過去）
            futureDate: '#{label}には#{before}以前の#{type}を指定してください。', // 日付指定範囲エラー（未来）
            invalidRange: '最大#{label}には最小#{label}よりも大きいまたは同じ数値を入力してください。' // 数値範囲エラー
        };

        // オブジェクトからneme属性の配列を作成
        this.names = this.form.map((forms) => forms.name);

        // Validatorインスタンスを生成 { [name]: Validator }
        this.validatements = this.names.reduce((object, name, index) => {
            const relatedValue = this.model[this.form[index].related];
            const templates = {...messageTemplate};

            object[name] = new Validator(Object.assign(templates, this.form[index].message || {}), this.form[index], this.model[name], relatedValue);

            return object;
        }, {});

        // エラー情報を空にする（エラーメッセージなし、エラーフラグfalse）
        this.errors = {};
        this.isError = false;
    }

    /**
     * validate - 入力バリデーションの実行
     * @returns {Object<{ isError: Boolean, errors: Object }>}
     */
    validate() {
        const validatements = Object.values(this.validatements);
        let isError = false;

        validatements.forEach((validate, index) => {
            const name = this.names[index];

            validate.value = this.model[name];
            validate.relatedValue = this.model[this.form[index].related];

            if (validate.run()) {
                isError = true;
                this.errors[name] = validate.message;
            } else {
                this.errors[name] = '';
            }
        });

        this.isError = isError;

        return {isError, errors: this.errors};
    }
}
