<template>
    <databox-index
        :is-menu-expanded="isMenuExpanded"
        :navigation="navigation"
    />
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {DataboxIndex} from '@/components/04_Templates/Client';

export default defineComponent({
    inheritAttrs: false,
    components: {DataboxIndex},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: Object,
            default: () => {}
        }
    }
});
</script>

<style lang="scss" scoped></style>
