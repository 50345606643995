<template>
    <admin-header
        :is-login="true"
        :user-menu="userMenu"
        :is-expanded="sideMenu.isExpanded.value"
        :server-error-modal="serverErrorModal"
        :toasts="toasts"
        @close-modal="$emit('close-error-modal', $event)"
        @open-user-menu="userMenu.isExpanded.value = true"
        @close-user-menu="userMenu.isExpanded.value = false"
        @toggle-menu="sideMenu.isExpanded.value = $event"
        @logout="methods.logout"
    />
    <admin-side-menu
        :navigations="sideMenu.navigations"
        :is-expanded="sideMenu.isExpanded.value"
    />
    <router-view
        :is-menu-expanded="sideMenu.isExpanded.value"
        @displayToast="methods.addToast"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref} from 'vue';
import store from '@/store';
import {useRoute, useRouter} from 'vue-router';
import {AdminHeader} from '@/components/04_Templates/_shared/Header';
import {AdminSideMenu} from '@/components/04_Templates/_shared/SideMenu';

export default defineComponent({
    inheritAttrs: false,
    components: {
        AdminHeader, AdminSideMenu
    },
    props: {
        serverErrorModal: {
            type: Object,
            default: () => ({})
        }
    },
    setup() {
        const $router = useRouter();
        const $route = useRoute();
        const toasts = ref([]);
        const {currentUser} = $route.meta;

        /**
         * userMenu - ユーザメニューデータ
         * @type {Object}
         * @property {String}        id         - ユーザメニューのID名
         * @property {ref<String>}   userName   - ユーザ名
         * @property {ref<Boolean>}  isExpanded - ユーザメニュー開閉フラグ
         * @property {Array<Object>} menus      - メニュー内容
         * @property {String}        placement  - メニュー表示位置設定
         */
        const userMenu = {
            id: 'user-menu',
            userName: ref(currentUser ? currentUser.name : ''),
            isExpanded: ref(false),
            menus: [
                {label: 'ログアウト', beforeIcon: 'Logout', emitEvent: 'logout'}
            ],
            placement: 'bottom-right'
        };

        /**
         * sideMenu - サイドメニューデータ
         * @type {Object}
         * @property {ref<Boolean>}                                       isExpanded  - サイドメニュー開閉フラグ
         * @property {Array<{ label: String, icon: String, to: Object }>} navigations - サイドメニュー内容
         */
        const sideMenu = {
            isExpanded: ref(false),
            navigations: [
                {label: 'クライアント管理', icon: 'Company', to: {name: 'AdminClientAccount'}},
                {label: '申請管理', icon: 'Request', to: {name: 'AdminRequest'}}
            ]
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * logout - ログアウト処理
             * @returns {Void}
             */
            logout() {
                store.dispatch('logout', true).then(() => {
                    $router.push({name: 'AdminLogin'});
                });
            },
            addToast(toast) {
                toasts.value.push(toast);
            }
        };

        return {
            toasts, userMenu, sideMenu, methods
        };
    },
    // 入場前に実行する処理
    beforeRouteEnter: (to, _, next) => {
        // アクセストークン バリデーション
        store.dispatch('validate', true).then((response) => {
            to.meta.currentUser = response;
            next();
        }).catch(() => {
            // ログインしているが、情報が不正な場合は強制ログアウト
            store.dispatch('forceLogout');
            next({name: 'AdminLogin'});
        });
    }
});
</script>
