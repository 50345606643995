<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__utils">
            <breadcrumbs :breadcrumbs="breadcrumbs">
                <template v-slot:item="breadcrumbsItemProps">
                    <breadcrumbs-item v-bind="breadcrumbsItemProps.breadcrumbs">
                        <template v-if="breadcrumbsItemProps.breadcrumbs.beforeIcon" v-slot:before><icon :name="breadcrumbsItemProps.breadcrumbs.beforeIcon" /></template>
                        {{breadcrumbsItemProps.breadcrumbs.label}}
                        <template v-if="breadcrumbsItemProps.breadcrumbs.afterIcon" v-slot:after><icon :name="breadcrumbsItemProps.breadcrumbs.afterIcon" /></template>
                    </breadcrumbs-item>
                </template>
            </breadcrumbs>
        </div>

        <cards>
            <template v-slot:header>
                <form-header title="クライアント情報" />
            </template>

            <banner v-if="client.isBan.value" appearance="information">
                <template v-slot:icon><icon name="Information" /></template>
                <p>このアカウントは停止されています。</p>
            </banner>

            <banner v-if="client.isError.value" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p>ユーザのデータが存在しません。</p>
            </banner>

            <banner v-if="formErrors.system.value.length" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
            </banner>

            <forms @submit.prevent="$emit('update-client', $event, models.client)">
                <definition-list :items="[forms.client[0]]">
                    <template v-slot:item-0="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <checkbox-group direction="row" :items="itemProps.description.items.value">
                                <template v-slot:checkbox="slotProps">
                                    <service-checkbox
                                        v-bind="slotProps.item"
                                        :name="itemProps.description.name"
                                        :indeterminate="slotProps.indeterminate"
                                        :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                        :aria-errormessage="`${itemProps.description.id}-invalid`"
                                        :aria-required="itemProps.term.isRequire"
                                        :model-value="models.client[itemProps.description.name]"
                                        @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                    >
                                        {{slotProps.item.label}}
                                        <template v-slot:image><img :src="slotProps.item.image" alt=""></template>
                                    </service-checkbox>
                                </template>
                            </checkbox-group>
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                </definition-list>

                <definition-list :items="forms.client.filter((_, i) => i !== 0)">
                    <template v-slot:item-0="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-1="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-2="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-3="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-4="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-5="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-6="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-7="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :readonly="client.isError.value || client.isBan.value"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                </definition-list>

                <template v-if="!client.isError.value && !client.isBan.value" v-slot:footer>
                    <form-footer>
                        <button-group layout="end">
                            <standard-button :disabled="forms.isLoading.value" @click="$router.options.history.state.back === null ? $router.push({name: 'AdminClientAccount'}) : $router.back()">キャンセル</standard-button>
                            <standard-button type="submit" :is-loading="forms.isLoading.value" appearance="primary">変更を保存する</standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>
        </cards>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import Breadcrumbs, {BreadcrumbsItem} from '@/components/01_Atoms/Breadcrumbs';
import Cards from '@/components/01_Atoms/Cards';
import Icon from '@/components/01_Atoms/Icons';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Forms, {
    FormHeader, FormFooter, FormLabel, FormMessage
} from '@/components/01_Atoms/Forms';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import {TextField} from '@/components/01_Atoms/TextFields';
import CheckboxGroup, {ServiceCheckbox} from '@/components/01_Atoms/Checkboxes';

// コンポーネントのインポートから始める

export default defineComponent({
    inheritAttrs: false,
    emits: [
        'update-client',
        'update:name',
        'update:department',
        'update:position',
        'update:contact_name',
        'update:furigana',
        'update:address',
        'update:tel',
        'update:email',
        'update:data_services'
    ],
    components: {
        Banner, Breadcrumbs, BreadcrumbsItem, Cards, Icon, ButtonGroup, StandardButton, Forms, FormHeader, FormFooter, FormLabel, FormMessage, DefinitionList, DefinitionListTerm, DefinitionListDescription, TextField, CheckboxGroup, ServiceCheckbox
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        breadcrumbs: {
            type: Array,
            default: () => []
        },
        forms: {
            type: Object,
            default: () => ({})
        },
        formErrors: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        },
        client: {
            type: Object,
            default: () => ({data: [], isLoading: false})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__utils {
            padding: 24px 0;
        }
    }
}

.a-definition-list {

    & + & {
        margin-top: 32px;
        border-top: solid 1px var.$color-gray-10;
    }
}
</style>
