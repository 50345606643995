<template>
    <div class="a-card-header">
        <h1 v-if="level === 1" class="a-card-header__title">{{title}}</h1>
        <h2 v-else-if="level === 2" class="a-card-header__title">{{title}}</h2>
        <h3 v-else class="a-card-header__title">{{title}}</h3>

        <div v-if="$slots.actions" class="a-card-header__actions"><slot name="actions" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        level: {
            type: Number,
            default: 1,
            validator: (value) => value >= 1 && 3 >= value
        },
        title: {
            type: String,
            default: 'Form Title',
            required: true
        }
    }
});
</script>

<style lang="scss">
.a-card-header {
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: solid 1px #E6E7E6;

    @at-root {
        .a-card-header__title {
            flex-basis: 100%;
            color: var.$color-text-high;

            @at-root {
                h1#{&} {
                    font-size: 3.6rem;
                    line-height: (44 / 36);
                }

                h2#{&} {
                    font-size: 3.2rem;
                    line-height: (40 / 32);
                }

                h3#{&} {
                    font-size: 2.4rem;
                    line-height: (32 / 24);
                }
            }
        }

        .a-card-header__actions {
            flex: 0 0 auto;
        }
    }
}
</style>
