<template>
    <survey-duplicate
        :is-menu-expanded="isMenuExpanded"
        :breadcrumbs="breadcrumbs"
        :forms="forms"
        :form-errors="formErrors"
        :models="models"
        :calendar-range="calendarRange"
        :range-status-message="rangeStatusMessage"
        :address-status-message="addressStatusMessage"
        :confirm-modal="confirmModal"
        :survey-data="surveyData"
        :preview-markdown="previewMarkdown"
        v-model:file="models.client_resources.file"
        v-model:title="models.client_resources.title"
        v-model:thumbnail="models.thumbnail.thumbnail"
        v-model:custom_thumbnail="models.thumbnail.custom_thumbnail"
        v-model:bonus_name="models.survey.bonus_name"
        v-model:description="models.survey.description"
        v-model:start_date="models.survey.start_date"
        v-model:end_date="models.survey.end_date"
        v-model:cap="models.survey.cap"
        v-model:gender_rule="models.survey.gender_rule"
        v-model:gender_rule_root="models.rules.gender_rule_root"
        v-model:min_age="models.survey.age_rule.min_age"
        v-model:max_age="models.survey.age_rule.max_age"
        v-model:zip_rule_prefecture="models.survey.zip_rule_prefecture"
        v-model:zip_rule_prefecture_root="models.rules.zip_rule_prefecture_root"
        v-model:zip_rule_code="models.survey.zip_rule_code"
        v-model:sense_rules="models.survey.sense_rules"
        v-model:sense_rule_root="models.rules.sense_rule_root"
        v-model:use_id_filter="models.survey.use_id_filter"
        v-model:consumer_id_rules="models.survey.consumer_id_rules"
        v-model:use_id_filter_file="models.id_file.use_id_filter_file"
        v-model:use_id_filter_count="models.id_file.use_id_filter_count"
        v-model:file_loading="models.id_file.use_id_filter_file_loading"
        @openPicker="$event.isExpanded.value = true"
        @closePicker="$event.isExpanded.value = false"
        @duplicateSurvey="methods.validates"
        @updateSurveyState="methods.updateSurveyState"
        @setModelValue="(model, name, value) => model[name] = value"
        @resetSurveyDataResourceId="surveyData.data.resource_id = null"
        @updateLocation="$router.push({name: 'ClientSurveysQuestion', params: {surveyId}})"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, computed, watch, onMounted, nextTick, inject
} from 'vue';
import axios from 'axios';
import store from '@/store';
import Validate from '@/validates';
import {useRoute, useRouter} from 'vue-router';
import {SurveyDuplicate} from '@/components/04_Templates/Client';
import MarkdownIt from 'markdown-it';

export default defineComponent({
    inheritAttrs: false,
    components: {SurveyDuplicate},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const isSafari = window.navigator.userAgent.toLowerCase().includes('safari');
        const $route = useRoute();
        const $router = useRouter();
        const surveyId = ref($route.params.surveyId);
        const video = document.createElement('video');
        const today = new Date();
        const previewMarkdown = ref('');
        const markdownit = MarkdownIt({
            typographer: true
        });

        video.crossOrigin = '';

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: '賢い買物スキルの生活者アンケート',
                to: {name: 'ClientSurveys'},
                beforeIcon: 'Questionary'
            }, {
                label: '基本情報設定',
                to: {name: 'ClientSurveysEdit'}
            }
        ];

        /**
         * models - フォームのモデル定義
         * @type Object
         * @property {reactive<Object>} survey           - アンケートのモデル
         * @property {reactive<Object>} rules            - 「全て」選択モデル
         * @property {reactive<Object>} id_file          - IDフィルターのモデル
         * @property {String} survey.bonus_name          - タイトル
         * @property {String} survey.description         - 依頼文
         * @property {String} survey.start_date          - 開始希望日
         * @property {String} survey.end_date            - 終了希望日
         * @property {String} survey.cap                 - 最大サンプル数
         * @property {String} survey.gender_rule         - 対象性別
         * @property {String} survey.age_rule            - 年齢
         * @property {String} survey.zip_rule_prefecture - 地域（都道府県）
         * @property {String} survey.zip_rule_code       - 郵便番号
         * @property {Boolean} survey.use_id_filter      - IDフィルターのONOFF
         * @property {String} survey.consumer_id_rules   - IDフィルターに使用するConsumerID
         */
        const models = {
            rules: reactive({
                gender_rule_root: [],
                zip_rule_prefecture_root: [],
                sense_rule_root: []
            }),
            client_resources: reactive({
                file: [],
                title: '',
                thumbnail: []
            }),
            thumbnail: reactive({
                custom_thumbnail: [],
                auto_generated: [],
                thumbnail: '0'
            }),
            survey: reactive({
                bonus_name: '',
                description: '',
                start_date: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                end_date: null,
                cap: '',
                gender_rule: [],
                age_rule: {
                    min_age: '',
                    max_age: ''
                },
                zip_rule_prefecture: [],
                zip_rule_code: [],
                sense_rules: [],
                use_id_filter: false,
                consumer_id_rules: [],
                file_name: ''
            }),
            id_file: reactive({
                use_id_filter_file: [],
                use_id_filter_count: '',
                use_id_filter_file_loading: false
            })
        };

        /**
         * forms - フォームの定義
         * @type Object
         * @property {ref<Boolean>}                           isLoading - 読み込み中フラグ
         * @property {Array<{ term: Any, description: Any }>} survey    - アンケート基本情報のフォーム定義（定義リストの型で定義）
         */
        const forms = {
            isLoading: ref(false),
            survey: [
                {
                    term: {
                        for: 'file',
                        text: '動画・画像の設定',
                        description: '動画もしくは画像を１つまで添付することができます'
                    },
                    description: {
                        id: 'file',
                        name: 'file',
                        type: 'file',
                        accept: '.jpg,.jpeg,.gif,.png,.mp4',
                        maxsize: 10485760, // 10MB
                        maxplaytime: 30,
                        ariaDescribedby: 'file-description',
                        description: [
                            '動画の場合：mp4形式、画面サイズ 1280×720、10Mバイト以下、再生時間は30秒以内',
                            '画像の場合：jpgを推奨（jpg, gif, png）、10Mバイト以下'
                        ]
                    }
                }, {
                    term: {
                        for: 'bonus-name',
                        text: 'タイトル',
                        description: '推奨25文字、40文字以内',
                        isRequire: true,
                        maxlength: 40
                    },
                    description: {
                        id: 'bonus-name',
                        name: 'bonus_name',
                        placeholder: '入力してください'
                    }
                }, {
                    term: {
                        for: 'description',
                        text: '依頼文',
                        description: '1000文字以内',
                        maxlength: 1000
                    },
                    description: {
                        id: 'description',
                        name: 'description',
                        placeholder: '入力してください'
                    }
                }, {
                    term: {
                        for: 'start-date',
                        text: '開始希望日',
                        description: '本日以降の日を設定してください',
                        isRequire: true
                    },
                    description: {
                        isExpanded: ref(false),
                        id: 'start-date',
                        name: 'start_date',
                        placeholder: '選択してください',
                        inputType: 'date',
                        related: 'end_date',
                        range: [
                            new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                            new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())
                        ],
                        limit: {
                            after: new Date(today.getFullYear(), today.getMonth(), today.getDate())
                        },
                        message: {
                            invalidPeriod: '開始希望日には終了希望日以前の日付を指定してください。'
                        }
                    }
                }, {
                    term: {
                        for: 'end-date',
                        text: '終了希望日',
                        description: '開始日以降、3ヶ月以内',
                        isRequire: true
                    },
                    description: {
                        isExpanded: ref(false),
                        id: 'end-date',
                        name: 'end_date',
                        placeholder: '選択してください',
                        inputType: 'date',
                        related: 'start_date',
                        limit: {
                            after: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                            before: new Date(today.getFullYear(), today.getMonth() + 3, today.getDate())
                        },
                        message: {
                            invalidPeriod: '終了希望日には開始希望日以降の日付を指定してください。'
                        }
                    }
                }, {
                    term: {
                        for: 'cap',
                        text: '最大サンプル数',
                        description: '最大1万までの数値を入力してください',
                        isRequire: true,
                        pattern: '^[^0]|^0$',
                        range: [1, 10000],
                        message: {
                            pattern: '数値の先頭には0を入力しないでください'
                        }
                    },
                    description: {
                        id: 'cap',
                        name: 'cap',
                        inputMode: 'number',
                        placeholder: ''
                    }
                }, {
                    term: {
                        id: 'gender-rule',
                        text: '対象性別',
                        isRequire: true
                    },
                    description: {
                        inputType: 'checkbox',
                        name: 'gender_rule',
                        root: 'gender_rule_root',
                        children: 'gender_rule',
                        ariaDescribedby: 'gender-rule',
                        description: '男女共に一定数のサンプルが必要な場合は、男性・女性それぞれで同様のアンケートを作成することをお勧めいたします。',
                        items: [{
                            id: 'gender-rule-all',
                            label: 'すべて',
                            value: 'all',
                            children: [{
                                id: 'gender-rule-male',
                                label: '男性',
                                value: 'male'
                            },
                            {
                                id: 'gender-rule-female',
                                label: '女性',
                                value: 'female'
                            },
                            {
                                id: 'gender-rule-other',
                                label: 'どちらでもない',
                                value: 'other'
                            }]
                        }]
                    }
                }, {
                    term: {
                        for: 'age-rule',
                        text: '年齢',
                        description: '数値を入力してください'
                    },
                    description: {
                        id: 'age-rule',
                        name: 'age_rule',
                        placeholder: '',
                        inputType: 'range',
                        range: [15, 100],
                        modelNames: {
                            min: 'min_age',
                            max: 'max_age'
                        }
                    }
                }, {
                    term: {
                        for: 'zip-rule-prefecture',
                        text: '地域（都道府県）'
                    },
                    description: {
                        isExpanded: ref(false),
                        id: 'zip-rule-prefecture',
                        name: 'zip_rule_prefecture',
                        root: 'zip_rule_prefecture_root',
                        children: 'zip_rule_prefecture',
                        placeholder: '選択してください',
                        selectAll: '全ての都道府県',
                        placement: 'auto',
                        options: [
                            {
                                label: '北海道・東北地方',
                                value: '北海道・東北地方',
                                children: [
                                    {label: '北海道', value: '北海道'},
                                    {label: '青森県', value: '青森県'},
                                    {label: '岩手県', value: '岩手県'},
                                    {label: '秋田県', value: '秋田県'},
                                    {label: '宮城県', value: '宮城県'},
                                    {label: '山形県', value: '山形県'},
                                    {label: '福島県', value: '福島県'}
                                ]
                            }, {
                                label: '関東地方',
                                value: '関東地方',
                                children: [
                                    {label: '茨城県', value: '茨城県'},
                                    {label: '栃木県', value: '栃木県'},
                                    {label: '群馬県', value: '群馬県'},
                                    {label: '埼玉県', value: '埼玉県'},
                                    {label: '千葉県', value: '千葉県'},
                                    {label: '東京都', value: '東京都'},
                                    {label: '神奈川県', value: '神奈川県'}
                                ]
                            }, {
                                label: '中部地方',
                                value: '中部地方',
                                children: [
                                    {label: '新潟県', value: '新潟県'},
                                    {label: '富山県', value: '富山県'},
                                    {label: '石川県', value: '石川県'},
                                    {label: '福井県', value: '福井県'},
                                    {label: '山梨県', value: '山梨県'},
                                    {label: '長野県', value: '長野県'},
                                    {label: '岐阜県', value: '岐阜県'},
                                    {label: '静岡県', value: '静岡県'},
                                    {label: '愛知県', value: '愛知県'},
                                    {label: '三重県', value: '三重県'}
                                ]
                            }, {
                                label: '関西地方',
                                value: '関西地方',
                                children: [
                                    {label: '滋賀県', value: '滋賀県'},
                                    {label: '京都府', value: '京都府'},
                                    {label: '大阪府', value: '大阪府'},
                                    {label: '兵庫県', value: '兵庫県'},
                                    {label: '奈良県', value: '奈良県'},
                                    {label: '和歌山県', value: '和歌山県'}
                                ]
                            }, {
                                label: '中国地方',
                                value: '中国地方',
                                children: [
                                    {label: '鳥取県', value: '鳥取県'},
                                    {label: '島根県', value: '島根県'},
                                    {label: '岡山県', value: '岡山県'},
                                    {label: '広島県', value: '広島県'},
                                    {label: '山口県', value: '山口県'}
                                ]
                            }, {
                                label: '四国地方',
                                value: '四国地方',
                                children: [
                                    {label: '徳島県', value: '徳島県'},
                                    {label: '香川県', value: '香川県'},
                                    {label: '愛媛県', value: '愛媛県'},
                                    {label: '高知県', value: '高知県'}
                                ]
                            }, {
                                label: '九州・沖縄地方',
                                value: '九州・沖縄地方',
                                children: [
                                    {label: '福岡県', value: '福岡県'},
                                    {label: '佐賀県', value: '佐賀県'},
                                    {label: '長崎県', value: '長崎県'},
                                    {label: '熊本県', value: '熊本県'},
                                    {label: '大分県', value: '大分県'},
                                    {label: '宮崎県', value: '宮崎県'},
                                    {label: '鹿児島県', value: '鹿児島県'},
                                    {label: '沖縄県', value: '沖縄県'}
                                ]
                            }
                        ]
                    }
                }, {
                    term: {
                        for: 'zip-rule-code',
                        text: '郵便番号'
                    },
                    description: {
                        id: 'zip-rule-code',
                        name: 'zip_rule_code',
                        inputType: 'tag',
                        placeholder: '',
                        pattern: '(^\\d{0,3}$)|(^\\d{3}-\\d{0,4})$'
                    }
                }, {
                    term: {
                        id: 'sense-rule',
                        text: '価値観'
                    },
                    description: {
                        inputType: 'checkbox',
                        name: 'sense_rules',
                        root: 'sense_rule_root',
                        children: 'sense_rules',
                        ariaDescribedby: 'sense-role sense-role-description',
                        information: '価値観アンケートに回答した生活者が対象になります。価値観を選択しない場合、アンケート結果では、価値観アンケートの未回答者は未回答者と表示されます。',
                        description: [
                            '価値観セグメンテーションモデル「Societas®︎」（<a href="/pdf/societas分類一覧.pdf" target="_blank">societas分類一覧.pdf</a>）',
                            '簡単な設問に回答するだけで、独自に分類した12タイプの価値観類型に紐づけることが可能なセグメンテーションモデルです。',
                            '各タイプは価値観や性格、嗜好性などが網羅されています。',
                            'この価値観モデルを定量調査に活用することで、より詳細な顧客理解が可能になります。'
                        ],
                        items: [{
                            id: 'sense-role-all',
                            label: 'すべて選択する',
                            value: 'all',
                            children: [
                                {
                                    id: 'sense-role-1',
                                    label: '1-1 全般的にあまり消費に主体性やこだわりがない傾向',
                                    value: '1'
                                }, {
                                    id: 'sense-role-2',
                                    label: '1-2 基本的にこだわるところは少ない傾向だが、一点豪華主義で極端な消費を行うこともある',
                                    value: '2'
                                }, {
                                    id: 'sense-role-3',
                                    label: '2-1 仕事の道具は相棒であり特にこだわる傾向',
                                    value: '3'
                                }, {
                                    id: 'sense-role-4',
                                    label: '2-2 中長期で投資に見合うことを重視し消費する傾向',
                                    value: '4'
                                }, {
                                    id: 'sense-role-5',
                                    label: '3-1 少しギャンブルとの親和性がある傾向',
                                    value: '5'
                                }, {
                                    id: 'sense-role-6',
                                    label: '3-2 趣味にお金をかけるが、それ以外は抑え気味な傾向',
                                    value: '6'
                                }, {
                                    id: 'sense-role-7',
                                    label: '4-1 自己啓発、成長への投資観点で消費する傾向',
                                    value: '7'
                                }, {
                                    id: 'sense-role-8',
                                    label: '4-2 自己成長に加え、周りの人との交流にもお金を使う傾向',
                                    value: '8'
                                }, {
                                    id: 'sense-role-9',
                                    label: '5-1 食事、家事など家庭での消費が中心的な傾向',
                                    value: '9'
                                }, {
                                    id: 'sense-role-10',
                                    label: '5-2 ブランド品や高額な商品は良いものと魅力を感じる傾向',
                                    value: '10'
                                }, {
                                    id: 'sense-role-11',
                                    label: '6-1 消費意欲は強くなく、必要なものだけを消費する傾向',
                                    value: '11'
                                }, {
                                    id: 'sense-role-12',
                                    label: '6-2 趣味や交際費など、交流に関する消費が多い傾向',
                                    value: '12'
                                }
                            ]
                        }]
                    }
                }, {
                    term: {
                        for: 'use-id-filter',
                        text: '対象生活者'
                    },
                    description: {
                        id: 'use-id-filter',
                        name: 'use_id_filter',
                        accept: '.csv',
                        information: 'スイッチオンにより登録したcsvファイルに記載された生活者IDにアンケートが配信されます。',
                        description: [
                            '対象生活者IDは10万件まで指定できます。',
                            '対象としたい生活者のIDを縦一列に記述したCSVファイルをアップロードしてください。'
                        ]
                    }
                }
            ],
            client_resources: {
                description: {
                    name: 'file',
                    type: 'file',
                    accept: '.jpg,.jpeg,.gif,.png,.mp4',
                    maxsize: 10485760, // 10MB
                    maxplaytime: 30,
                    message: {
                        accept: 'アップロードファイルが不正です。ファイルの拡張子はJPG、GIF、PNG、またはMP4にしてください。'
                    }
                },
                title: {
                    id: 'title',
                    name: 'title',
                    label: '動画・画像のタイトル',
                    placeholder: '入力してください',
                    isRequire: true
                },
                thumbnail: {
                    inputType: 'file',
                    accept: '.jpg,.jpeg,.gif,.png',
                    maxsize: 10485760, // 10MB
                    id: 'thumbnail',
                    name: 'thumbnail',
                    label: 'サムネイル',
                    description: '画面サイズ 1280×720、jpgを推奨（jpg, gif, png）、10Mバイト以下',
                    items: computed(() => {
                        const {auto_generated: auto, custom_thumbnail: custom} = models.thumbnail;
                        const items = [];

                        if (auto.length) {
                            items.push({
                                id: 'thumbnail-1', name: 'thumbnail', url: auto[0].url, value: '0'
                            });

                            if (custom.length) {
                                items.push({
                                    id: 'thumbnail-2', name: 'thumbnail', url: custom[0].url, value: '1'
                                });
                            }
                        }

                        return items;
                    }),
                    message: {
                        accept: 'アップロードファイルが不正です。ファイルの拡張子はJPG、GIF、またはPNGにしてください。'
                    }
                },
                custom_thumbnail: {
                    id: 'custom-thumbnail',
                    name: 'custom_thumbnail',
                    type: 'file',
                    accept: '.jpg,.jpeg,.gif,.png',
                    message: {
                        accept: 'アップロードファイルが不正です。ファイルの拡張子はJPG、GIF、またはPNGにしてください。'
                    }
                }
            },
            id_file: {
                use_id_filter_file: {
                    inputType: 'file',
                    accept: '.csv',
                    id: 'use-id-filter-file',
                    name: 'use_id_filter_file',
                    message: {
                        accept: 'アップロードファイルが不正です。ファイルの拡張子はCSVにしてください。'
                    }
                }
            }
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system           - APIから返却されたシステムエラー
         * @property {Object} survey           - ログイン情報入力フォームのエラー
         * @property {Object} client_resources - 動画・画像入力欄のフォームエラー
         */
        const formErrors = {
            system: ref([]),
            survey: reactive({}),
            client_resources: reactive({}),
            id_file: reactive({})
        };

        /**
         * confirmModal - 確認モーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const confirmModal = {
            id: 'confirm-modal',
            isLoading: ref(false),
            isExpanded: ref(false),
            appearance: 'confirm',
            size: 'large',
            closable: false,
            title: 'アンケート基本情報を複製して作成しました',
            description: [
                '引き続き設問の編集を行う場合は、「設問設定へ進む」をクリックしてください。',
                '設問を編集せず配信申請を行う場合は「配信申請をする」クリックしてください。',
                '設問が設定されていないアンケートは申請いただけません。'
            ],
            ariaDescribedby: 'confirm-modal-description'
        };

        /**
         * surveyData - クライアントアカウントのデータ
         * @type {Object}
         * @property {ref<Boolean>}     isLoading - 読み込みフラグ
         * @property {ref<Boolean>}     isError   - エラーフラグ
         * @property {reactive<Object>} data      - アカウントデータ
         */
        const surveyData = {
            isLoading: ref(false),
            isError: ref(false),
            data: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            survey: new Validate(forms.survey.map((obj) => ({...obj.term, ...obj.description})), models.survey),
            client_resources: new Validate(Object.values(forms.client_resources), models.client_resources),
            id_file: new Validate(Object.values(forms.id_file), models.id_file)
        };

        /**
         * calendarRange - カレンダー表示制限の算出
         * @type {Object}
         */
        const calendarRange = computed(() => {
            const {start_date: start} = models.survey;

            if (start === null) {
                return [];
            }

            return [start, new Date(start.getFullYear(), start.getMonth() + 3, start.getDate())];
        });

        /**
         * rangeStatusMessage - 年齢入力欄のメッセージ算出
         * @type {Object}
         */
        const rangeStatusMessage = computed(() => {
            const min = models.survey.age_rule.min_age || 15;
            const max = models.survey.age_rule.max_age || 100;

            return `${min}歳以上〜${max}歳以下の生活者が対象になります`;
        });

        /**
         * addressStatusMessage - 都道府県・郵便番号入力欄のメッセージ算出
         * @type {Object}
         */
        const addressStatusMessage = computed(() => {
            const {zip_rule_prefecture: prefecture, zip_rule_code: code} = models.survey;
            const prefectureLength = prefecture.length;
            const codeLength = code.length;
            let message = '未設定の場合には、全ての地域の生活者が対象になります。';

            // 47都道府県が全て選択されている場合
            if (prefectureLength === 47) {
                message = codeLength ? '全ての地域の生活者が対象になります。入力された郵便番号は登録されません。' : '全ての地域の生活者が対象になります。';

            // いくつか都道府県が全て選択されている場合
            } else if (prefectureLength) {
                message = codeLength ? `選択した${prefectureLength}地域及び入力した郵便番号の居住区に住む生活者が対象になります。` : `選択した${prefectureLength}地域の生活者が対象になります。`;

            // 郵便番号のみが入力されている場合
            } else if (codeLength) {
                message = '入力した郵便番号の居住区に住む生活者が対象になります。';
            }

            return message;
        });

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * validates - 入力バリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             */
            validates(event, model) {
                // バリデーション項目を更新
                methods.updateValidatements(model.client_resources);

                const surveyValidateResult = methods.validateSurvey(event);
                const clientResourceValidateResult = methods.validateClientResource(event);
                const idFileValidateResult = methods.validateIdFilterFile(event);


                // いずれかがtrue（バリデーションエラーが存在する）の場合は何もしない
                if ([surveyValidateResult, clientResourceValidateResult, idFileValidateResult].some((result) => result)) {
                    return;
                }

                // 画像のアップロードを実行
                methods.createClientResource(model.client_resources).then((response) => {
                    const {
                        id, file_url: url, thumbnail_url: thumbnail, title
                    } = response;

                    // アップロード成功後、モデルにアップロード画像のIDを乗せて登録を実行
                    methods.duplicateSurvey({...model.survey, ...{client_resource_id: id}}, url, title, thumbnail);
                });
            },
            /**
             * updateValidatements - バリデーション情報更新処理
             * @description 動画・画像の添付の有無で、タイトルの入力必須が変化するため更新が必要です
             * @param {Object} model - バリデーション対象のモデルオブジェクト
             * @returns {Void}
             */
            updateValidatements(model) {
                const {survey, client_resources: resource} = validates;
                const {auto_generated: auto, custom_thumbnail: custom} = models.thumbnail;
                const {start_date: start} = models.survey;
                const currentThumbnail = [auto, custom][models.thumbnail.thumbnail] || [];
                const endDateIndex = survey.form.findIndex((form) => form.id === 'end-date');

                // 終了希望日のバリデーション項目を更新
                survey.form[endDateIndex].limit.before = start instanceof Date ? new Date(start.getFullYear(), start.getMonth() + 3, start.getDate()) : null;
                survey.init();

                // 選択されているサムネイル画像をモデルにセット
                models.client_resources.thumbnail = currentThumbnail;

                // ファイルが入力（選択）されている場合は、必須入力にし、初期化処理を実施
                resource.form[resource.form.findIndex((form) => form.id === 'title')].isRequire = model.file.length > 0;
                resource.init();
            },
            /**
             * validateSurvey - 入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @returns {Boolean}
             */
            validateSurvey(event) {
                const {currentTarget} = event;
                const {isError, errors} = validates.survey.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.survey, key)) {
                            delete formErrors.survey[key];
                        }

                        return;
                    }

                    formErrors.survey[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    const errorTarget = currentTarget.elements[Object.keys(formErrors.survey)[0]];

                    // エラーターゲットが複数存在する場合、先頭にフォーカス
                    if (!['HTMLInputElement', 'HTMLTextAreaElement', 'HTMLButtonElement'].includes(errorTarget[Symbol.toStringTag])) {
                        errorTarget[0].focus();
                    } else {
                        errorTarget.focus();
                    }
                }

                // エラーの有無を返却
                return isError;
            },
            /**
             * validateClientResource - 動画・画像入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @returns {Boolean}
             */
            validateClientResource(event) {
                const {currentTarget} = event;
                const {isError, errors} = validates.client_resources.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.client_resources, key)) {
                            delete formErrors.client_resources[key];
                        }

                        return;
                    }

                    formErrors.client_resources[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    const errorTarget = currentTarget.elements[Object.keys(formErrors.client_resources)[0]];

                    // エラーターゲットが複数存在する場合、先頭にフォーカス
                    if (!['HTMLInputElement', 'HTMLTextAreaElement', 'HTMLButtonElement'].includes(errorTarget[Symbol.toStringTag])) {
                        errorTarget[0].focus();
                    } else {
                        errorTarget.focus();
                    }
                }

                // エラーの有無を返却
                return isError;
            },
            /**
             * validateIdFilterFile - 入力内容のバリデーション処理
             * @returns {Boolean}
             */
            validateIdFilterFile() {
                const {isError, errors} = validates.id_file.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.id_file, key)) {
                            delete formErrors.id_file[key];
                        }
                        return;
                    }
                    formErrors.id_file[key] = value;
                });

                // エラーの有無を返却
                return isError;
            },

            /**
             * duplicateSurvey - クライアント複製・作成処理
             * @param model {Object}       - 送信するモデル
             * @param {String} [url]       - 添付画像・動画のURL
             * @param {String} [title]     - 添付画像・動画のタイトル
             * @param {String} [thumbnail] - 動画のサムネイルURL
             */
            duplicateSurvey(model, url, title, thumbnail) {
                const formData = {...model, cb_amount: surveyData.data.cb_amount, surveys: surveyData.data.surveys};

                // すでに処理中の場合は何もしない
                if (forms.isLoading.value) {
                    return;
                }

                // 読み込み中フラグを更新（読み込み中）
                forms.isLoading.value = true;

                // 性別モデルデータをArrayからHash型に整形
                // [String] => { [String]: Boolean }
                formData.gender_rule = ['male', 'female', 'other'].reduce((object, value) => {
                    object[value] = model.gender_rule.includes(value);

                    return object;
                }, {});

                // 47都道府県全てが選択された場合、パラメータを送信しない
                if (formData.zip_rule_prefecture.length === 47) {
                    formData.zip_rule_prefecture.length = 0;
                    formData.zip_rule_code.length = 0;
                }

                // CSVデータを一次配列へ変換、UseIDFilterがfalseの場合はConsumerIDRulesを空にする。
                if (formData.use_id_filter && formData.consumer_id_rules !== null) {
                    formData.consumer_id_rules = formData.consumer_id_rules.reduce((pre, current) => { pre.push(...current); return pre; }, []);
                } else if (!formData.use_id_filter && formData.consumer_id_rules !== null) {
                    formData.consumer_id_rules = [];
                }

                // URLが渡された場合、説明文の先頭行にURLを追記
                if (url) {
                    formData.description = thumbnail ? `[![${title}](${thumbnail})](${url})\n\n${title}\n\n${formData.description}` : `![${title}](${url})\n\n${title}\n\n${formData.description}`;
                }

                axios.post(
                    '/api/v2/client_account/bonuses',
                    {...formData},
                    {headers: store.state.auth}
                ).then((response) => {
                    // アンケートIDを更新
                    surveyId.value = response.data.bonus.id;

                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'アンケートを複製して作成しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // モーダルを表示
                    confirmModal.isExpanded.value = true;
                }).catch((error) => {
                    // エラーメッセージを更新
                    formErrors.system.value = error;
                    window.scrollTo(0, 0);
                }).finally(() => {
                    // 読み込み中フラグを更新（読み込み完了）
                    forms.isLoading.value = false;
                });
            },
            /**
             * createSurvey - クライアント作成処理
             * @param model {Object} - 送信するモデル
             * @returns {Promise}
             */
            createClientResource(model) {
                const formData = new FormData();
                const [file] = model.file;
                const [thumbnail] = model.thumbnail;

                // すでに処理中の場合は何もしない
                if (forms.isLoading.value) {
                    return Promise.reject();
                }

                // バイナリ送信用のモデル（フォームデータ）を作成
                formData.append('title', model.title);

                // 動画・画像が存在し、ファイルインスタンスを引き継いでいる場合は追加
                if (file && file instanceof File) {
                    formData.append('file', file);
                }

                // サムネイルが存在し、ファイルインスタンスを引き継いでいる場合は追加
                if (thumbnail && thumbnail instanceof File) {
                    formData.append('thumbnail', thumbnail);
                }

                // フォームデータのキー名のみ出力
                const keys = [...formData.entries()].map(([key]) => key);

                return new Promise((resolve, reject) => {
                    const hasNewResource = keys.includes('file');

                    // 新規アップロードファイルが存在しない かつ 複製元にリソースが紐づけられていない場合
                    if (!hasNewResource && !surveyData.data.resource_id) {
                        resolve({});

                        return;
                    }

                    // 読み込み中フラグを更新（読み込み中）
                    forms.isLoading.value = true;

                    axios.post(
                        hasNewResource ? '/api/v2/client_account/client_resources' : `/api/v2/client_account/client_resources/${surveyData.data.resource_id}/duplicate`,
                        formData,
                        {
                            headers: store.state.auth,
                            'content-type': 'multipart/form-data'
                        }
                    ).then((response) => {
                        // 読み込み中フラグを更新（読み込み完了）
                        forms.isLoading.value = false;

                        // 動画・画像のID・URLを返却
                        resolve(response.data.client_resource);
                    }).catch((error) => {
                        // 読み込み中フラグを更新（読み込み完了）
                        forms.isLoading.value = false;

                        // エラーメッセージを更新
                        formErrors.system.value = error;
                        reject();
                    });
                });
            },
            /**
             * updateSurveyState - アンケートステータス更新処理
             * @returns {Void}
             */
            updateSurveyState() {
                // 読み込みフラグを更新（読み込み中）
                confirmModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/client_account/bonuses/${surveyId.value}/set_status`,
                    {status: 'is_pending'},
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: '作成したアンケートの配信申請を行いました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 一覧（申請中）へ遷移
                    $router.push({name: 'ClientSurveysPending'});
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    confirmModal.isLoading.value = false;
                    // モーダルを閉じる
                    confirmModal.isExpanded.value = false;
                });
            },
            /**
             * getSurvey - APIからクライアントアカウント一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ accounts; Array, meta: Object }>}
             */
            async getSurvey() {
                const result = {};

                await axios.get(`/api/v2/client_account/bonuses/${surveyId.value}`, {
                    headers: store.state.auth,
                    params: {
                        'includes[][bonuses_surveys]': 'bonuses_survey_choices',
                        includes: ['client_resource', 'bonuses_gender_rules', 'bonuses_age_rules', 'bonuses_zip_rules', 'bonuses_sense_rules']
                    }
                }).then((response) => {
                    const {bonus} = response.data;
                    const {bonuses_age_rules: ageRule, client_resource: resource} = bonus;
                    const {min_age: min, max_age: max} = ageRule[0] || {min_age: null, max_age: null};
                    let isFilterNextLine = false;

                    result.resource_id = !resource ? 0 : resource.id;
                    result.surveys = bonus.bonuses_surveys.map(({survey_type: type, question, bonuses_survey_choices: choices}) => ({
                        survey_type: type.toString(),
                        question,
                        choices: choices.map((choice) => choice.choice)
                    }));
                    result.bonus = {
                        bonus_name: bonus.bonus_name,
                        description: !bonus.description ? '' : bonus.description.split('\n').filter((text) => {
                            const isResource = /\[!|!\[/.test(text);

                            if (isFilterNextLine) {
                                isFilterNextLine = text === '';
                                return false;
                            }

                            if (isResource) {
                                isFilterNextLine = true;
                            }

                            return !isResource;
                        }).join('\n'),
                        start_date: new Date(bonus.start_date),
                        end_date: new Date(bonus.end_date),
                        cap: bonus.cap.toString(),
                        cb_amount: bonus.cb_amount,
                        gender_rule: Object.entries(bonus.bonuses_gender_rules[0] || {}).reduce((array, [key, value]) => {
                            if (value === true) {
                                array.push(key);
                            }

                            return array;
                        }, []),
                        age_rule: {
                            min_age: min === null ? '' : min.toString(),
                            max_age: max === null ? '' : max.toString()
                        },
                        zip_rule_prefecture: bonus.bonuses_zip_rule.prefectures,
                        zip_rule_code: bonus.bonuses_zip_rule.zipcodes,
                        sense_rules: bonus.bonuses_sense_rules.map((rule) => rule.sense_id.toString())
                    };
                    result.resource = !resource ? {} : {
                        file: [{
                            name: resource.filename,
                            size: resource.size,
                            type: resource.type,
                            url: resource.file_url
                        }],
                        title: resource.title,
                        thumbnail: [{url: resource.thumbnail_url}]
                    };
                    result.thumbnail = !resource ? {} : {
                        custom_thumbnail: [{url: resource.thumbnail_url}],
                        thumbnail: '1'
                    };
                });

                return result;
            },
            /**
             * getSurveyData - アンケート詳細を取得・データ更新
             * @param {Object} targetData - 更新するデータ
             * @returns {Void}
             */
            getSurveyData(targetData) {
                // すでに読み込み中の場合は取得しない
                if (targetData.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetData.isLoading.value = true;

                methods.getSurvey().then((response) => {
                    const {
                        bonus, surveys, resource_id: id, resource, thumbnail
                    } = response;
                    let timer = 0;

                    // データを更新
                    Object.assign(targetData.data, {...bonus, ...{surveys}, ...{resource_id: id}});

                    // モデルを更新
                    Object.assign(models.survey, bonus);
                    Object.assign(models.client_resources, resource);

                    // エディターのモデルを更新（markdownをHTMLに変換）
                    timer = window.setInterval(() => {
                        // 初期化されていなかったら何もしない（初期化を待つ）
                        if (!window.tinymce || !window.tinymce.activeEditor.initialized) {
                            return;
                        }

                        // マークダウンをパース
                        window.tinymce.activeEditor.setContent(markdownit.render(bonus.description));
                        window.clearInterval(timer);
                    }, 10);

                    nextTick(() => {
                        models.client_resources.title = resource.title || '';
                        Object.assign(models.thumbnail, thumbnail);
                    });
                }).catch(() => {
                    // エラーフラグを更新
                    targetData.isError.value = true;
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetData.isLoading.value = false;
                });
            },
            /**
             * createVideoThumbnail - 動画サムネイル生成処理
             * @param {HTMLVideoElement} videoElement - 対象の動画が表示されているvideo要素
             * @param {String}           filename     - 生成するサムネイルのファイル名のプレフィックス
             * @returns {Array<File>}
             */
            createVideoThumbnail(videoElement, filename) {
                const input = document.createElement('input');
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                const {videoWidth, videoHeight} = videoElement;

                // canvas要素をセットアップ
                document.body.appendChild(canvas);
                input.type = 'file';
                canvas.width = videoWidth;
                canvas.height = videoHeight;
                context.drawImage(videoElement, 0, 0, videoWidth, videoHeight);

                // canvas要素に描画されている内容をバイナリ変換
                const binary = window.atob(canvas.toDataURL('image/jpeg').replace(/^.*,/, ''));
                const buffer = new Uint8Array(binary.length);
                const files = Object.create(input.files);

                for (let i = 0; i < binary.length; i += 1) {
                    buffer[i] = binary.charCodeAt(i);
                }

                // Blobに変換し、Fileオブジェクトとして追加
                files[0] = new File([new Blob([buffer.buffer], {type: 'image/jpeg'})], `${filename}_thumbnail.jpg`, {type: 'image/jpeg'});
                document.body.removeChild(canvas);

                return Object.values(files).map((file) => {
                    file.url = window.URL.createObjectURL(file);

                    return file;
                });
            },
            /**
             * getBinaryVideo - 動画データをバイナリ形式で取得
             * @param {String} fileurl - 動画ファイルのURL（APIが発行するActiveStorageへのリダイレクトリンク）
             * @returns {Promise}
             */
            async getBinaryVideo(fileurl) {
                let url = '';

                await axios.get(fileurl, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'video/mp4'
                    }
                }).then((response) => {
                    const uintArray = new Uint8Array(response.data);

                    url = window.URL.createObjectURL(new Blob([uintArray.buffer], {type: 'video/mp4'}));
                });

                return url;
            }
        };

        // 動画・画像の設定を監視
        watch(() => models.client_resources.file, (files) => {
            const [file] = files;
            const type = file ? file.type : '';

            // 動画・画像の差し替えがあった場合入力内容をリセット
            models.thumbnail.thumbnail = '0';
            models.thumbnail.auto_generated = [];
            models.thumbnail.custom_thumbnail = [];
            models.client_resources.title = '';

            // エラー内容もリセット
            formErrors.client_resources = reactive({});

            // 添付ファイルが空の場合
            if (!file || !type.startsWith('video')) {
                return;
            }

            // サムネイル自動生成イベントを実装
            video.onseeked = () => {
                models.thumbnail.auto_generated = methods.createVideoThumbnail(video, file.name);
                video.onseeked = null;
                video.currentTime = 1;
            };

            // currentTimeをセットすることでseekedEventを発火
            video.onloadeddata = () => {
                video.currentTime = 1;
            };

            // safariブラウザ かつ 外部リンク（APIが発行するActiveStorageへのリダイレクトリンク）の動画を読む必要がある場合
            if (isSafari && file.url.startsWith(process.env.VUE_APP_BASE_API)) {
                // 動画をバイナリで取得・Blob URLを発行し、読み込む
                methods.getBinaryVideo(file.url).then((url) => {
                    video.src = url;
                    video.load();
                });

                return;
            }

            // 動画セットし、強制ロード
            video.src = file.url;
            video.load();
        });
        // CSVファイルを監視
        const $papa = inject('$papa');
        watch(() => models.id_file.use_id_filter_file, (files) => {
            models.id_file.use_id_filter_file_loading = true;
            models.survey.file_name = files[0].name;
            const [file] = files;
            $papa.parse(file, {
                skipEmptyLines: true,
                complete(results) {
                    models.id_file.use_id_filter_file_loading = false;
                    $.emit('displayToast', {
                        message: 'ファイル読込完了',
                        isError: false,
                        icon: 'Success'
                    });
                    this.csvData = results.data;
                    models.id_file.use_id_filter_count = this.csvData.length;
                    models.survey.consumer_id_rules = this.csvData;
                }
            });
        });

        // マークダウンのパース
        watch(() => [models.survey.description, models.client_resources.file, models.client_resources.title, models.thumbnail.thumbnail, models.thumbnail.auto_generated], ([description, files, title, thumbIndex, auto]) => {
            const [file] = files;
            const type = file ? file.type : '';
            const {custom_thumbnail: thumbnail} = models.thumbnail;
            let resource = '';

            // 動画が添付されている場合
            if (type.startsWith('video')) {
                const currentThumbnail = [auto, thumbnail][thumbIndex][0];

                if (currentThumbnail) {
                    resource = `[![${title}](${currentThumbnail.url})](${file.url})\n\n${title}`;
                }

            // 画像が添付されている場合
            } else if (type.startsWith('image')) {
                resource = `![${title}](${file.url})\n\n${title}`;
            }

            // マークダウンをパースする
            const blobUrls = [...resource.matchAll(/\((blob:http.*?)\)/g)];
            let html = markdownit.render(`${resource.replaceAll('blob:http', 'http')}\n\n${description}`);

            blobUrls.map((url) => url[1]).forEach((blobUrl) => {
                html = html.replace(blobUrl.replace('blob:', ''), blobUrl);
            });

            html = html.replace(/href="(.*?)"/g, 'href="$1" target="_blank"');
            previewMarkdown.value = html;
        });

        onMounted(() => {
            methods.getSurveyData(surveyData);
        });

        return {
            surveyId, breadcrumbs, models, forms, formErrors, validates, calendarRange, rangeStatusMessage, addressStatusMessage, confirmModal, surveyData, previewMarkdown, methods
        };
    }
});
</script>
