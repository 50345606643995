<template>
    <forbidden-403 v-if="isBan && !['/client/login', '/admin/login', '/client/terms'].includes($route.path)" />
    <router-view
        v-else
        :server-error-modal="serverErrorModal"
        @close-error-modal="serverErrorModal.isExpanded.value = false"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref} from 'vue';
import {Forbidden403} from '@/components/05_Pages/NotFound';
import store from '@/store';

export default defineComponent({
    inheritAttrs: false,
    components: {Forbidden403},
    setup() {
        const isBan = ref(store.state.auth.ban_status || false);

        /**
         * serverErrorModal - サーバーエラーモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const serverErrorModal = {
            id: 'alert-modal',
            isExpanded: ref(false),
            appearance: 'alert',
            title: 'サーバーに接続できませんでした',
            description: ['ネットワークが混み合っているか、サーバーに問題が発生しています。', '時間を置いて再度お試しください。'],
            ariaDescribedby: 'alert-modal-description'
        };

        store.subscribeAction({
            after: (_, state) => {
                isBan.value = state.auth.ban_status;
            }
        });

        return {isBan, serverErrorModal};
    }
});
</script>

<style lang="scss"></style>
