<template>
    <fieldset class="a-fieldset" :class="{[`a-fieldset--level${level}`]: level !== 1}">
        <legend class="a-fieldset__legend">{{legend}}</legend>
        <p v-if="description !== ''" :id="`${$attrs.id}-description`" class="a-fieldset__description">{{description}}</p>

        <div class="a-fieldset__input">
            <slot />
        </div>
    </fieldset>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isRequire: {
            type: Boolean,
            default: false
        },
        legend: {
            type: String,
            required: true
        },
        level: {
            type: Number,
            default: 1,
            validator: (value) => value >= 1 && 3 >= value
        },
        description: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss" scoped>
.a-fieldset {
    position: relative;

    + .a-fieldset {
        margin-top: 48px;

        &::before {
            position: absolute;
            top: -46px;
            left: 0;
            right: 0;
            display: block;
            content: "";
            width: 100%;
            height: 1px;
            background: #E6E7E6;
        }
    }

    &--level2 {
        > .a-fieldset__legend {
            font-weight: bold;
            font-size: 2rem;
            line-height: (28 / 20);
        }

        > .a-fieldset__input {
            margin-top: 24px;
        }
    }

    &--level3 {
        > .a-fieldset__legend {
            font-weight: bold;
            font-size: 3.2rem;
            line-height: (40 / 32);
        }

        > .a-fieldset__input {
            margin-top: 32px;
        }
    }

    @at-root {
        .a-fieldset__legend {
            font-size: 1.6rem;
            line-height: (22 / 16);
            color: var.$color-text-medium;
            padding: 0;
        }

        .a-fieldset__description {
            font-size: 1rem;
            line-height: (18 / 10);
            color: var.$color-text-disabled;

            &:not(:first-child) {
                margin-top: 4px;
            }
        }

        .a-fieldset__input {
            margin-top: 8px;
        }
    }
}
</style>
