<template>
    <nav class="a-pagination" aria-label="ページネーション">
        <ul class="a-pagination__list">
            <li
                v-for="item in paginations"
                :key="item"
                class="a-pagination__item"
            >
                <slot
                    name="item"
                    :item="!isNaN(item) ? {
                        isCurrent: item === currentPage,
                        label: item,
                        page: item
                    } : {...item}"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    props: {
        currentPage: {
            type: Number,
            default: 1
        },
        previous: {
            type: Object,
            default: () => ({
                label: '前のページに移動',
                icon: 'Chevron Arrow Left Small'
            })
        },
        next: {
            type: Object,
            default: () => ({
                label: '次のページに移動',
                icon: 'Chevron Arrow Right Small'
            })
        },
        omit: {
            type: Object,
            default: () => ({
                label: '...',
                icon: 'More',
                disabled: true
            })
        },
        pages: {
            type: Array,
            default: () => ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
        }
    },
    setup(props) {
        const paginations = computed(() => {
            const {
                previous, next, omit, pages, currentPage
            } = props;
            const [first, last] = [pages[0], pages.slice(-1)[0]];
            let pagination = [];

            // 表示ページ数が7ページ以下の場合は全ページを表示
            if (pages.length <= 7) {
                pagination = [...pages];

            // 1〜4がカレントの場合
            } else if (1 <= currentPage && currentPage <= 4) {
                pagination = [...pages.slice(0, 5), omit, last];

            // (last - 3)〜lastがカレントの場合
            } else if ((last - 3) <= currentPage && currentPage <= last) {
                pagination = [first, omit, ...pages.slice(-5)];

            // 当てはまらない場合
            } else {
                pagination = [first, omit, ...pages.slice((currentPage - 3), (currentPage + 2)), omit, last];
            }

            // カレントが初めのページの場合は非活性にする
            previous.disabled = currentPage === first;
            previous.page = currentPage - 1;

            // カレントが最後のページの場合は非活性にする
            next.disabled = currentPage === last;
            next.page = currentPage + 1;

            return [previous, ...pagination, next];
        });

        return {paginations};
    }
});
</script>

<style lang="scss" scoped>
.a-pagination {
    width: fit-content;

    @at-root {
        .a-pagination__list {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .a-pagination__item {

            &:not(:first-child) {
                margin-left: 4px;
            }
        }
    }
}
</style>
