<template>
    <client-terms />
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {ClientTerms} from '@/components/04_Templates/Client';

export default defineComponent({
    inheritAttrs: false,
    components: {ClientTerms}
});
</script>

<style lang="scss" scoped></style>
