<template>
    <div class="a-section-card">
        <div class="a-section-card__header">
            <h1 v-if="level === 1" class="a-section-card__title">{{title}}</h1>
            <h2 v-else-if="level === 2" class="a-section-card__title">{{title}}</h2>
            <h3 v-else class="a-section-card__title">{{title}}</h3>
        </div>

        <div v-if="$slots.utils" class="a-section-card__utils"><slot name="utils" /></div>
        <div class="a-section-card__content"><slot /></div>

        <div v-if="$slots.footer" class="a-section-card__footer"><slot name="footer" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        level: {
            type: Number,
            default: 1,
            validator: (value) => value >= 1 && 3 >= value
        },
        title: {
            type: String,
            default: 'Section Title',
            required: true
        }
    }
});
</script>

<style lang="scss" scoped>
.a-section-card {
    background: var.$color-utils-background;
    border-radius: 6px;
    width: 1260px;
    margin: auto;

    @at-root {
        .a-section-card__header {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 64px;
        }

        .a-section-card__title {
            font-size: 3.6rem;
            font-weight: bold;
            line-height: (44 / 36);
        }

        .a-section-card__utils {
            padding: 0 64px 64px;
        }

        .a-section-card__content {
            padding: 0 64px;

            &:first-child {
                padding-top: 64px;
            }

            &:last-child {
                padding-bottom: 64px;
            }
        }

        .a-section-card__footer {
            display: flex;
            justify-content: flex-end;
            padding: 64px;
        }
    }
}
</style>
