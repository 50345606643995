<template>
    <dt class="a-definition-list-term"><span class="a-definition-list-term__inner"><slot /></span></dt>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.a-definition-list-term {
    min-width: 210px;
    flex: 0 1 100%;
    max-width: 300px;

    @at-root {
        .a-definition-list-term__inner {
            width: 100%;
            display: inline-flex;
            align-items: center;
            min-height: 48px;
        }
    }
}
</style>
