<template>
    <div class="a-definition-list">
        <dl class="a-definition-list__list">
            <div
                v-for="(item, index) in items"
                :key="item"
                class="a-definition-list__item"
            >
                <slot :name="`item-${index}`" :term="item.term" :description="item.description" />
            </div>
        </dl>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        items: {
            type: Array,
            default: () => ([
                {
                    term: 'タイトル1',
                    description: '説明文1'
                }, {
                    term: 'タイトル2',
                    description: '説明文2'
                }, {
                    term: 'タイトル3',
                    description: '説明文3'
                }
            ])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-definition-list {

    @at-root {
        .a-definition-list__item {
            display: flex;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }
}
</style>
