<template>
    <main class="t-main">
        <h1 class="t-forbidden-title">
            <span class="t-forbidden-title__icon">
                <svg width="156" height="156" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-v-4fae5ba8=""><path fill-rule="evenodd" clip-rule="evenodd" d="M10.92 3.52a1.009 1.009 0 0 0-.893-.52c-.38 0-.726.2-.896.517L2.106 16.642a.887.887 0 0 0 .043.913c.182.276.504.445.851.445h14c.346 0 .668-.168.85-.444.183-.276.2-.62.045-.912L10.92 3.52ZM11 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-8a1 1 0 1 0-2 0v5a1 1 0 1 0 2 0V7Z"></path></svg>
            </span>
            アカウントが停止されています
        </h1>

        <div class="t-text">
            <p>現在ログインいただいているアカウントについて、当サービスの<router-link :to="{name: 'ClientTerms'}">利用規約</router-link>の違反が確認されたため、アカウントを停止いたしました。</p>
            <p>今回の対応についてお問合せの場合は、以下のメールアドレスにご連絡をお願いします。</p>
        </div>

        <button-grounp layout="center">
            <standard-button appearance="link" :to="{name: 'ClientLogin'}">ログインページへ</standard-button>
            <standard-button appearance="primary" @click="$emit('copy-content', 'support@cashb-data.co.jp')">
                <template v-slot:before><icon name="Mail" /></template>
                support@cashb-data.co.jp
            </standard-button>
        </button-grounp>
    </main>

    <toasts :toasts="toasts" :timeout="1500">
        <template v-slot:toast="toastProps">
            <toast-item v-bind="toastProps.toast">
                <template v-if="toastProps.toast.icon" v-slot:icon><icon :name="toastProps.toast.icon" /></template>
            </toast-item>
        </template>
    </toasts>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGrounp, {StandardButton} from '@/components/01_Atoms/Buttons';
import Toasts, {ToastItem} from '@/components/01_Atoms/Toasts';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    emits: ['copy-content'],
    components: {
        ButtonGrounp, StandardButton, Icon, Toasts, ToastItem
    },
    props: {
        toasts: {
            type: Array,
            default: () => []
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background: #F2F6F9;
}

.t-forbidden-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 3.6rem;
    line-height: 1;
    color: var.$color-text-high;
    letter-spacing: .025em;
    margin-top: 48px;

    @at-root {
        .t-forbidden-title__icon {
            color: var.$color-gray-30;
            margin-bottom: 56px;
        }
    }
}

.t-text {
    font-size: 1.6rem;
    text-align: center;
    color: var.$color-text-medium;
    margin-top: 64px;
    line-height: 1;

    > :not(:first-child) {
        margin-top: 16px;
    }

    + :not(:first-child) {
        margin-top: 64px;
    }
}
</style>
