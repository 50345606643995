export default class Validator {
    /**
     * フォームバリデーター
     * @constructor
     * @param {Object} message        - エラーメッセージテンプレートのオブジェクト
     * @param {Object} form           - フォーム要素のデータ（ラベルやバリデーションデータ）のオブジェクト
     * @param {Any}    modelValue     - 入力値データ（文字列または文字列配列を想定）
     * @param {Any}    [relatedValue] - 検証に関連する対象の入力値データ（type="period"の場合は開始日または終了日、type="video|file"の場合は、表示用URLなど）
     */
    constructor(message, form, modelValue, relatedValue) {
        /**
         * メンバ変数
         * @type {Any}     this.value           - 入力値データ
         * @type {Any}     this.relatedValue    - 検証に関連する対象の入力値データ
         * @type {String}  this.label           - 表示ラベルテキスト
         * @type {String}  this.title           - 入力要素のタイトル
         * @type {String}  this.type            - 入力タイプ（text, password, checkbox, radio, select, date, datetime, file, video）
         * @type {String}  this.inputMode       - 入力モード指定（doubleByteKana, numeric）
         * @type {String}  this.name            - 入力欄のname属性値
         * @type {Boolean} this.require         - 入力必須チェックをするか否か
         * @type {String}  this.pattern         - 検証パターン（正規表現）文字列
         * @type {String}  this.accept          - 検証ファイル拡張子パターン文字列
         * @type {Number}  this.maxLength       - 最大入力文字数
         * @type {Number}  this.minLength       - 最小入力文字数
         * @type {Number}  this.fixLength       - 固定入力文字数
         * @type {Number}  this.maxsize         - 最大サイズ（type="video|file"の場合に指定可能）
         * @type {Number}  this.maxplaytime     - 最大再生時間（type="video"の場合に指定可能）
         * @type {String}  this.message         - 表示するエラーメッセージ
         * @type {Object}  this.messageTemplate - エラーメッセージテンプレート
         */
        this.value = modelValue;
        this.relatedValue = relatedValue;
        this.label = form.text || form.label;
        this.title = form.title;
        this.type = form.type || form.inputType || 'text';
        this.inputMode = form.inputMode;
        this.range = form.range || [];
        this.name = form.name;
        this.require = typeof form.isRequire === 'boolean' ? form.isRequire : false;
        this.pattern = form.pattern;
        this.accept = form.accept;
        this.limit = form.limit;
        this.remove = form.remove || {};
        this.maxLength = parseInt(form.maxlength, 10);
        this.minLength = parseInt(form.minlength, 10);
        this.fixLength = parseInt(form.fixLength, 10);
        this.maxsize = parseInt(form.maxsize, 10);
        this.maxplaytime = parseInt(form.maxplaytime, 10);
        this.message = '';
        this.messageTemplate = message;
    }

    /**
     * formatBytes - ファイル容量のフォーマット
     * @param {Number} bytes - ファイル容量の数値
     * @returns {String}
     */
    static formatBytes(bytes) {
        // 容量が0だった場合
        if (bytes === 0) {
            return '0 Bytes';
        }

        const kilo = 1024; // 何 byteを 1kとするか
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const sizeIndex = Math.floor(Math.log(bytes) / Math.log(kilo));

        return `${parseFloat((bytes / (kilo ** sizeIndex)).toFixed(0))}${sizes[sizeIndex]}`;
    }

    /**
     * formatTime - 再生時間のフォーマット
     * @param {Number} time - 秒数
     * @returns {String}    - n時間n分
     */
    static formatTime(time) {
        const units = {
            hour: '時間',
            minute: '分',
            second: '秒'
        };
        let formatted = '';

        // 不正な数値の場合は処理しない
        if (Number.isNaN(time)) {
            return '0秒';
        }

        const computedDate = {
            hour: Math.floor((time % (24 * 60 * 60)) / (60 * 60)),
            minute: Math.floor(((time % (24 * 60 * 60)) % (60 * 60)) / 60),
            second: time % (24 * 60 * 60) % (60 * 60) % 60
        };

        Object.entries(units).forEach(([key, value]) => {
            const date = computedDate[key];

            // 不正な数値の場合、スキップ
            if (Number.isNaN(date) || date <= 0) {
                return;
            }

            formatted += `${date}${value}`;
        });

        return formatted;
    }

    /**
     * createErrorMessage - エラーメッセージ生成
     *
     * @param {String} errorType - エラーの種別
     * @returns {String}
     */
    createErrorMessage(errorType) {
        const extensions = this.accept ? this.accept.replaceAll('.', '').split(',') : [];
        const replacements = {
            type: {
                text: 'テキスト',
                tag: 'タグ',
                password: 'パスワード',
                checkbox: 'チェックボックス',
                radio: 'ラジオボタン',
                select: 'セレクトボックス',
                date: '日付',
                file: 'ファイル',
                video: '動画ファイル'
            }[this.type],
            label: this.label,
            title: this.title,
            inputType: ['email', 'password', 'search', 'tel', 'text', 'url', 'choice'].includes(this.type) ? '入力' : '選択',
            extension: extensions.reduce((string, ext, index) => {
                let message = string + (extensions.length !== 1 && index === (extensions.length - 1) ? `または${ext.toUpperCase().replace(/.*?\//, '')}` : `${ext.toUpperCase().replace(/.*?\//, '')}、`);

                if (extensions.length === 1) {
                    message = message.slice(0, -1);
                }

                return message;
            }, ''),
            maxlength: this.maxLength,
            minlength: this.minLength,
            fixLength: this.fixLength,
            removeLabel: this.remove.label,
            removeMaxLength: this.remove.maxLength,
            before: this.limit && this.limit.before ? this.limit.before.toLocaleDateString() : '',
            after: this.limit && this.limit.after ? this.limit.after.toLocaleDateString() : '',
            min: Number.isNaN(Number(this.range[0])) ? '' : this.range[0],
            max: Number.isNaN(Number(this.range[1])) ? '' : this.range[1],
            maxsize: Validator.formatBytes(this.maxsize),
            maxplaytime: Validator.formatTime(this.maxplaytime)
        };
        let message = this.messageTemplate[errorType] || '';

        // #{}ワードを文字列置換
        Object.entries(replacements).forEach(([key, value]) => {
            message = message.replaceAll(`#{${key}}`, value !== undefined ? value : '');
        });

        return message;
    }

    /**
     * run - バリデーションの実行
     * @returns {Boolean}
     */
    run() {
        const result = this.verify();

        // エラーステートを更新
        this.isError = result !== '';

        // エラーメッセージを生成、エラーでない場合は空文字を代入
        this.message = this.isError ? this.createErrorMessage(result) : '';

        return this.isError;
    }

    /**
     * verify - 検証の実行
     * @description エラーが存在する場合、エラーの種類を返却します。エラーでない場合は空文字を返却します。
     * @returns {String}
     */
    verify() {
        let isEmptyValue = Array.isArray(this.value) ? this.value.length === 0 : !this.value;
        isEmptyValue = isEmptyValue || /^\s+$/.test(this.value);

        // ファイル・動画の入力だった場合
        if (this.type === 'file' || this.type === 'video') {
            isEmptyValue = typeof this.value !== 'string' && this.relatedValue === '';
        }

        // 必須入力チェック
        if (this.require && isEmptyValue) {
            return 'required';
        }

        // 形式チェック
        if (!isEmptyValue) {
            // 入力タイプ別チェック
            if (['tag', 'password', 'email', 'tel', 'date', 'file', 'video', 'range', 'choice'].includes(this.type)) {
                const result = this.validator(this.type)();

                // エラーがあった場合、エラータイプを返却
                if (result.isError) {
                    return result.errorType;
                }

                // 文字列でない値の場合は以降のバリデーションを実行しない
                if (typeof this.value !== 'string') {
                    return '';
                }
            }

            // 入力モードチェック
            if (this.inputMode) {
                const result = this.validator(this.inputMode)();

                // エラーがあった場合、エラータイプを返却
                if (result.isError) {
                    return result.errorType;
                }
            }

            // パターン検証
            if (this.pattern && !(new RegExp(this.pattern).test(this.value))) {
                return 'pattern';
            }

            // 文字長（最大）検証
            if (!Number.isNaN(this.maxLength) && Array.from(this.value.replace(/\r?\n/g, '')).length > this.maxLength) {
                return 'maxlength';
            }

            // 文字長（最小）検証
            if (!Number.isNaN(this.minLength) && Array.from(this.value.replace(/\r?\n/g, '')).length < this.minLength) {
                return 'minlength';
            }

            // 文字長（固定長）検証
            if (!Number.isNaN(this.fixLength) && Array.from(this.value.replace(/\r?\n/g, '')).length !== this.fixLength) {
                return 'fixLength';
            }

            // 数値範囲検証
            if (this.range.length === 2 && (this.value < this.range[0] || this.value > this.range[1])) {
                return 'range';
            }
        }

        return '';
    }

    /**
     * validator - 検証の実行
     * @param {String} type - バリデーションの種類
     * @returns {Function}
     */
    validator(type) {
        const self = this;

        return {
            /**
             * validator#numeric - 数値検証
             *
             * @description 入力された値が数値でない場合、エラーを返却します
             * @returns {Object}
             */
            number(value) {
                const val = value || self.value;

                return {isError: !/^\d+$/.test(val) || Number.isNaN(parseInt(val, 10)) || Number.isNaN(Number(val)), errorType: 'numeric'};
            },
            /**
             * validator#byteFullKana - 全角カタカナ検証
             *
             * @description 入力された値が全角カタカナでない場合、エラーを返却します
             * @returns {Object}
             */
            byteFullKana() {
                return {isError: !/^[\u30A0-\u30FF\s]+$/.test(self.value), errorType: 'byteFullKana'};
            },
            /**
             * validator#tag - タグ検証
             *
             * @description 指定文字数以上のタグが存在する場合 または タグの個数が指定の数よりも多い場合、エラーを返却します
             * @returns {Object}
             */
            tag() {
                // パターン検証
                if (self.pattern && self.value.some((value) => !(new RegExp(self.pattern).test(value)))) {
                    return {isError: true, errorType: 'pattern'};
                }

                // 全てのタグが文字数超過していないかの検証
                if (!Number.isNaN(self.maxLength) && self.value.some((value) => Array.from(value).length > self.maxLength)) {
                    return {isError: true, errorType: 'maxlength'};
                }

                // タグの個数超過チェック
                if (!Number.isNaN(self.maxsize) && self.value.length > self.maxsize) {
                    return {isError: true, errorType: 'maxsize'};
                }

                return {isError: false, errorType: ''};
            },
            /**
             * validator#password - パスワード検証
             *
             * @description 英数字混合 かつ 8文字以上でない場合、エラーを返却します
             * @returns {Object}
             */
            password() {
                // 未入力の場合は、エラーなしと判定（別で入力必須チェックが行われるため）
                if (!self.value) {
                    return {isError: false, errorType: ''};
                }

                // 関連値がある場合、一致しているか検証
                if (self.relatedValue && self.value !== self.relatedValue) {
                    return {isError: true, errorType: 'unmatch'};
                }

                // 英数字混在8文字以上であるかどうか
                if (!/^(?=.*?[a-zA-Z])(?=.*?[\d])[a-zA-Z\d!-/:-@¥[-`{-~]{8,}$/.test(self.value)) {
                    return {isError: true, errorType: 'password'};
                }

                return {isError: false, errorType: ''};
            },
            /**
             * validator#email - パスワード検証
             *
             * @description メールの形式に不正がある場合、エラーを返却します
             * @returns {Object}
             */
            email() {
                // 未入力の場合は、エラーなしと判定（別で入力必須チェックが行われるため）
                if (!self.value) {
                    return {isError: false, errorType: ''};
                }

                // 関連値がある場合、一致しているか検証
                if (self.relatedValue && self.value !== self.relatedValue) {
                    return {isError: true, errorType: 'unmatch'};
                }

                /**
                 * メール形式チェック
                 * @see {@link https://qiita.com/ikamirin/items/88c369fb5ed269cb126d}
                 */
                if (!/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/.test(self.value)) {
                    return {isError: true, errorType: 'email'};
                }

                return {isError: false, errorType: ''};
            },
            /**
             * validator#tel - パスワード検証
             *
             * @description 電話番号形式に不正がある場合、エラーを返却します
             * @returns {Object}
             */
            tel() {
                // 未入力の場合は、エラーなしと判定（別で入力必須チェックが行われるため）
                if (!self.value) {
                    return {isError: false, errorType: ''};
                }

                /**
                 * 電話番号形式チェック
                 * - 文字列の先頭は数字以外認めない
                 * - 文字列の末尾は数字以外認めない
                 * - 文字列中で「-」が2つ以上連続するのは禁止
                 * - 文字列は「-」または「数字」だけで構成される
                 * - ハイフンは3つ以下で構成される
                 */
                if (!/^(?!^[^\d])(?!.*[^\d]$)(?!.*-{2,})(?!^(.*-){4,})[-\d]*$/.test(self.value)) {
                    return {isError: true, errorType: 'tel'};
                }

                // 最大桁数チェック
                if (Array.from(self.value.replaceAll(self.remove.item, '')).length > self.remove.maxLength) {
                    return {isError: true, errorType: 'removingMaxlength'};
                }
                if (Array.from(self.value).length > self.maxLength) {
                    return {isError: true, errorType: 'maxlength'};
                }

                return {isError: false, errorType: ''};
            },
            /**
             * validator#range - 数値の範囲検証
             *
             * @description 入力された数値が範囲外であった場合、エラーを返却します
             * @returns {Object}
             */
            range() {
                const values = Object.values(self.value);
                const [from, to] = self.range;
                const [min, max] = values.map((value) => Number(value || undefined));

                // いずれかが空文字である場合
                if (values.some((value) => !value)) {
                    // 数値の範囲検証
                    if (
                        (!Number.isNaN(min) && min > to)
                        || (!Number.isNaN(max) && max < from)
                    ) {
                        return {isError: true, errorType: 'range'};
                    }

                    // 必須入力設定の場合はエラーを返却する
                    return self.require ? {isError: true, errorType: 'required'} : {isError: false, errorType: ''};
                }

                // いずれかが数値ではない場合
                if (values.some((value) => self.validator('number')(value).isError)) {
                    return {isError: true, errorType: 'numeric'};
                }

                // rengeの設定が正しくない（数値で指定されていない）場合は範囲検証しない
                if (Number.isNaN(from) || Number.isNaN(to)) {
                    return {isError: min > max, errorType: 'invalidRange'};
                }

                // 数値範囲検証
                if (values.some((value) => value < from || value > to)) {
                    return {isError: true, errorType: 'range'};
                }

                // 最小 > 最大の関係になっていた場合、エラー
                return {isError: min > max, errorType: 'invalidRange'};
            },
            /**
             * validator#range - 数値の範囲検証
             *
             * @description 入力された数値が範囲外であった場合、エラーを返却します
             * @returns {Object}
             */
            choice() {
                // 必須入力チェック
                if (self.value.join('') === '' || /^\s+$/.test(self.value.join(''))) {
                    return {isError: self.require, errorType: 'required'};
                }

                // 全てのタグが文字数超過していないかの検証
                if (!Number.isNaN(self.maxLength) && self.value.some((val) => Array.from(val).length > self.maxLength)) {
                    return {isError: true, errorType: 'maxlength'};
                }

                // 選択肢の個数超過チェック
                if (!Number.isNaN(self.maxsize) && self.value.length > self.maxsize) {
                    return {isError: true, errorType: 'maxsize'};
                }

                return {isError: false, errorType: ''};
            },
            /**
             * validator#date - 入力された日付の検証
             *
             * @description 日付の形式（yyyy-mm-dd）でない場合、または不正な期間（過去にさかのぼっている）場合、エラーを返却します
             * @returns {Object}
             */
            date() {
                const {limit} = self;
                const {before, after} = limit || {};

                // 未入力の場合は、エラーなしと判定（別で入力必須チェックが行われるため）
                if (self.value === null) {
                    return {isError: false, errorType: ''};
                }

                // Date型オブジェクトであるかどうかチェック
                if (!(self.value instanceof Date) || self.value.toString() === 'Invalid Date') {
                    return {isError: true, errorType: 'invalidDate'};
                }

                // 範囲検証（beforeに指定された日付よりも過去であるか）
                if (before instanceof Date && before.getTime() < self.value.getTime()) {
                    return {isError: true, errorType: 'futureDate'};
                }

                // 範囲検証（afterに指定された日付よりも未来であるか）
                if (after instanceof Date && after.getTime() > self.value.getTime()) {
                    return {isError: true, errorType: 'olderDate'};
                }

                // 関連値が存在する場合、期間チェックを実行
                if (self.relatedValue) {
                    const result = self.validator('period')();

                    // 期間チェックがエラーである場合、検証結果を返却
                    if (result.isError) {
                        return result;
                    }
                }

                return {isError: false, errorType: ''};
            },
            /**
             * validator#period - 期間の検証
             *
             * @description 「開始時刻 >= 終了時刻」関係（過去にさかのぼっている）になっていた場合、エラーを返却します
             * @returns {Object}
             */
            period() {
                const isStart = self.name.startsWith('start') || self.name.startsWith('open');
                const startDate = isStart ? self.value : self.relatedValue;
                const endDate = isStart ? self.relatedValue : self.value;

                // 日付をさかのぼっていた場合、エラー
                return {isError: startDate.getTime() > endDate.getTime(), errorType: 'invalidPeriod'};
            },
            /**
             * validator#file - 入力されたファイルの検証
             *
             * @description 指定されている拡張子以外のファイルの場合 または 指定されているサイズ（容量）以上のファイルの場合エラーを返却します
             * @returns {Object}
             */
            file() {
                const result = {isError: false, errorType: ''};

                // ファイルが空の場合はエラーなしと判定
                if (!self.value || !self.value.length) {
                    return result;
                }

                for (let i = 0; i < self.value.length; i += 1) {
                    const value = self.value[i];

                    // Fileインスタンスを継承している場合はバリデーションを実行
                    if (value instanceof File) {
                        // 拡張子のパターンが存在する場合
                        if (self.accept) {
                            const validMimeType = new RegExp(self.accept.replaceAll(',', '|').replaceAll('*', '.*'));

                            if (!validMimeType.test(value.type)) {
                                result.isError = true;
                                result.errorType = 'accept';

                                break;
                            }
                        }

                        // サイズの制限がある場合
                        if (!Number.isNaN(self.maxsize) && value.size > self.maxsize) {
                            result.isError = true;
                            result.errorType = 'maxsize';

                            break;
                        }

                        // 再生時間の制限がある場合
                        if (!Number.isNaN(self.maxplaytime) && value.duration) {
                            const playtime = parseInt(value.duration, 10);

                            // 再生時間が数正しい数値 かつ 制限を超えていたらエラー
                            if (!Number.isNaN(playtime) && playtime > self.maxplaytime) {
                                result.isError = true;
                                result.errorType = 'maxplaytime';

                                break;
                            }
                        }
                    }
                }

                return result;
            }
        }[type];
    }
}
