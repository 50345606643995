<template>
    <label class="a-checkbox" :for="$attrs.id">
        <input
            :id="$attrs.id"
            type="checkbox"
            :name="$attrs.name"
            class="a-checkbox__input"
            :class="{'is-error': isError}"
            :disabled="$attrs.disabled || $attrs.readonly"
            :value="$attrs.value"
            v-model="model"
            :indeterminate="$attrs.indeterminate"
        >
        <span class="a-checkbox__text"><slot /></span>
    </label>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isError: {
            type: Boolean,
            default: false
        },
        children: {
            type: Array,
            default: () => []
        },
        childValue: {
            type: Array,
            default: () => []
        },
        modelValue: {
            type: Array,
            default: () => []
        }
    },
    setup(props, $) {
        const model = computed({
            get() {
                const {children, modelValue, childValue} = props;

                // 子を持たない場合は自身のvalueを返す
                if (!children.length) {
                    return modelValue;
                }

                const values = children.map((child) => childValue.includes(child.value));
                const elementValue = $.attrs.value;
                const isChecked = modelValue.includes(elementValue);

                // 全ての子が選択されている場合 かつ 自身の値がモデルに含まれていない場合 モデルを更新
                if (values.every((value) => value) && !isChecked) {
                    $.emit('update:model-value', [...modelValue, elementValue]);

                // １つでも子が選択されていない場合 かつ 自身の値がモデルに含まれている場合 モデルを更新
                } else if (values.some((value) => !value) && isChecked) {
                    $.emit('update:model-value', modelValue.filter((value) => value !== elementValue));
                }

                return modelValue;
            },
            set(value) {
                const {children, childValue} = props;

                // 子を持たない場合は自身のvalueをセットする
                if (!children.length) {
                    $.emit('update:model-value', value);

                    return;
                }

                const childrenValues = children.map((child) => child.value);
                const elementValue = $.attrs.value;
                let update = childValue.filter((val) => !childrenValues.includes(val));

                // チェックされた場合
                if (value.includes(elementValue)) {
                    update = [].concat(update, childrenValues);
                }

                // 子を持っているかつ、チェックされた場合はchildrenのvalueをセット
                $.emit('update:model-value', value);
                $.emit('update:child-value', update);
            }
        });

        return {model};
    }
});
</script>

<style lang="scss" scoped>
.a-checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 20px;

    @at-root {
        .a-checkbox__input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;

            &.is-error {

                +.a-checkbox__text::before {
                    color: var.$color-danger-50;
                }
            }

            &[disabled] {
                +.a-checkbox__text {
                    color: var.$color-text-disabled;
                }

                &:checked {
                    +.a-checkbox__text {
                        color: var.$color-text-disabled;

                        &::before {
                            background: var.$color-text-disabled;
                        }

                        &::after {
                            opacity: 1;
                        }
                    }
                }

                &:indeterminate {
                    +.a-checkbox__text {

                        &::before {
                            background: var.$color-text-disabled;
                            border-color: var.$color-text-disabled;
                        }
                    }
                }
            }

            &:checked {
                +.a-checkbox__text {
                    color: var.$color-primary-50;

                    &::before {
                        background: var.$color-primary-50;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }

            &:indeterminate {
                +.a-checkbox__text {

                    &::before {
                        background: var.$color-primary-50;
                        border-color: var.$color-primary-50;
                    }

                    &::after {
                        top: 12px;
                        width: 10px;
                        height: 0;
                        border-width: 2px;
                        transform: none;
                        opacity: 1;
                    }
                }
            }
        }

        .a-checkbox__text {
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-medium;
            margin-left: 8px;
            transition: color .3s ease 0s;

            &::before {
                position: absolute;
                top: 3px;
                left: 0;
                display: block;
                content: "";
                width: 20px;
                height: 20px;
                background: var.$color-utils-background;
                border-radius: 2px;
                border: solid 2px currentColor;
                transition: background-color .3s ease 0s;
            }

            &::after {
                position: absolute;
                top: 8px;
                left: 4.5px;
                display: block;
                content: "";
                width: 11px;
                height: 7px;
                border-radius: 2px;
                border-left: solid 2px var.$color-text-white;
                border-bottom: solid 2px var.$color-text-white;
                transform: rotate(-45deg);
                opacity: 0;
            }
        }
    }
}
</style>
