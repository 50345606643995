<template>
    <div class="a-form-label">
        <label v-if="$attrs.for" class="a-form-label__label" :for="$attrs.for">{{text}}<span v-if="isRequire" class="a-form-label__badge">必須</span></label>
        <p v-else :id="$attrs.id" class="a-form-label__label">{{text}}<span v-if="isRequire" class="a-form-label__badge">必須</span></p>

        <p v-if="description !== ''" :id="`${$attrs.for || $attrs.id}-description`" class="a-form-label__description">{{description}}</p>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isRequire: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss" scoped>
.a-form-label {

    .a-form-label__label {
        font-size: 1.6rem;
        line-height: (22 / 16);
        color: var.$color-text-medium;
    }

    .a-form-label__badge {
        display: inline-flex;
        margin-left: 8px;
        padding: 2px 8px;
        font-size: 1.2rem;
        line-height: 1;
        color: var.$color-text-white;
        background: var.$color-danger-40;
        border-radius: 2px;
    }

    .a-form-label__description {
        font-size: 1rem;
        line-height: (18 / 10);
        color: var.$color-text-disabled;
        white-space: pre-wrap;

        &:not(:first-child) {
            margin-top: 4px;
        }
    }
}
</style>
