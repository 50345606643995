<template>
    <button
        type="button"
        class="a-button-action"
        :class="{
            [`a-button-action--${appearance}`]: true,
            [`a-button-action--${spacing}`]: spacing !== 'default'
        }"
    >
        <span v-if="$slots.before" class="a-button-action__before"><slot name="before" /></span>
        <span class="a-button-action__text"><slot>Button</slot></span>
        <span v-if="$slots.after" class="a-button-action__after"><slot name="after" /></span>
    </button>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        appearance: {
            type: String,
            default: 'positive',
            validator: (value) => (['positive', 'destructive', 'warning', 'additional'].includes(value))
        },
        spacing: {
            type: String,
            default: 'default',
            validator: (value) => (['default', 'compact', 'fill'].includes(value))
        }
    }
});
</script>

<style lang="scss" scoped>
.a-button-action {
    display: inline-flex;
    align-items: center;
    padding: 10px 16px;
    font-size: 1.6rem;
    letter-spacing: 0.035em;
    background: var.$color-utils-background;
    border: solid 1px;
    border-radius: 2px;
    transition: background .3s ease 0s;

    &--positive {
        color: var.$color-success-50;

        @include mixin.hover {
            background: var.$color-success-10;
        }
    }

    &--destructive {
        color: var.$color-danger-50;

        @include mixin.hover {
            background: var.$color-danger-10;
        }
    }

    &--warning {
        color: var.$color-warning-50;

        @include mixin.hover {
            background: var.$color-warning-10;
        }
    }

    &--additional {
        color: var.$color-information-50;

        @include mixin.hover {
            background: var.$color-information-10;
        }
    }

    &--compact {
        padding: 6px 16px;
    }

    &--fill {
        width: 100%;
        justify-content: center;
    }

    &[disabled] {
        cursor: default;
        background: var.$color-gray-20;
        opacity: .6;
        border-color: transparent;
        color: var.$color-text-white;

        @include mixin.hover {
            background: var.$color-gray-20;
        }
    }

    @at-root {
        .a-button-action__text {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 64px;
            min-height: 24px;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        .a-button-action__before,
        .a-button-action__after {
            display: flex;
        }

        .a-button-action__after {
            margin-left: 8px;
        }
    }
}
</style>
