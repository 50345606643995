<template>
    <div :id="$attrs.id" class="a-form-description"><slot /></div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss">
.a-form-description {
    width: 100%;
    font-size: 1.2rem;
    line-height: (18 / 12);
    color: var.$color-text-disabled;

    &:not(:first-child) {
        margin-top: 8px;
    }

    p:not([class]) + p:not([class]) {
        margin-top: 4px;
    }

    .a-checkbox-group + & {
        margin-top: 24px;
    }
}
</style>
