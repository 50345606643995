<template>
    <button
        v-if="appearance !== 'link'"
        v-bind="$attrs"
        :to="null"
        :label="null"
        class="a-menu-item"
        :class="{
            [`a-menu-item--${appearance}`]: appearance !== 'default'
        }"
        type="button"
        role="menuitem"
    >
        <span v-if="$slots.before" class="a-menu-item__before"><slot name="before" /></span>
        <span class="a-menu-item__text"><slot /></span>
        <span v-if="$slots.after" class="a-menu-item__after"><slot name="after" /></span>
    </button>
    <router-link
        v-else
        :to="$attrs.to"
        class="a-menu-item a-menu-item--link"
        role="menuitem"
        :tabindex="$attrs.tabindex"
        :aria-disabled="$attrs.disabled || $attrs['aria-disabled'] === 'true' ? 'true' : undefined"
    >
        <span v-if="$slots.before" class="a-menu-item__before"><slot name="before" /></span>
        <span class="a-menu-item__text"><slot /></span>
        <span v-if="$slots.after" class="a-menu-item__after"><slot name="after" /></span>
    </router-link>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        appearance: {
            type: String,
            default: 'default',
            validator: (value) => (['default', 'link', 'danger', 'warning'].includes(value))
        }
    }
});
</script>

<style lang="scss" scoped>
.a-menu-item {
    display: inline-flex;
    align-items: center;
    min-width: 180px;
    font-size: 1.4rem;
    line-height: (22 / 14);
    color: var.$color-text-medium;
    background: var.$color-utils-background;
    padding: 12px 16px;
    transition: background-color .3s ease 0s;

    @include mixin.hover {
        background: #F2F6F9;
    }

    &--link {
        text-decoration: none;
    }

    &--danger {
        color: var.$color-danger-50;
    }

    &--warning {
        color: var.$color-warning-50;
    }

    &[disabled],
    &[aria-disabled="true"] {
        cursor: default;
        color: var.$color-text-disabled;
        opacity: .6;

        @include mixin.hover {
            background: var.$color-utils-background;
        }
    }

    @at-root {
        .a-menu-item__text {

            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        .a-menu-item__before,
        .a-menu-item__after {
            display: flex;
        }

        .a-menu-item__after {
            margin-left: auto;
            padding-left: 8px;
        }
    }
}
</style>
