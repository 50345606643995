<template>
    <div class="a-text-field-group" :class="{'a-text-field-group--row': direction === 'row'}">
        <div
            v-for="field in fields"
            :key="field"
            class="a-text-field-group__item"
        >
            <slot name="field" :field="{...field}" />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        direction: {
            type: String,
            default: 'column',
            validator: (value) => ['row', 'column'].includes(value)
        },
        fields: {
            type: Array,
            default: () => [
                {
                    id: 'field-1',
                    name: 'field_group_root',
                    placeholder: '入力欄 1'
                }, {
                    id: 'field-2',
                    name: 'field_group_root',
                    placeholder: '入力欄 2'
                }, {
                    id: 'field-3',
                    name: 'field_group_root',
                    placeholder: '入力欄 3'
                }
            ]
        }
    }
});
</script>

<style lang="scss" scoped>
.a-text-field-group {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
        margin-top: 16px;
    }

    &--row {
        flex-direction: row;

        > :not(:root) {
            flex-basis: 100%;

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 8px;
            }
        }
    }

    @at-root {
        .a-text-field-group__item {

            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
}
</style>
