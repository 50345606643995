<template>
    <div class="a-file-input" :class="{'a-file-input--image': appearance === 'image'}">
        <div
            class="a-file-input__drop"
            :class="{
                'is-drag': isDrag,
                'is-readonly': $attrs.readonly,
                'is-disabled': $attrs.disabled,
                'is-error': isError
            }"
            @dragover.prevent="isDrag = $attrs.disabled || $attrs.readonly ? false : true"
            @dragleave.prevent="isDrag = false"
            @drop.prevent="methods.dropFile"
        >
            <label v-if="!isLoading" class="a-file-input__label" :for="$attrs.id">
                <input
                    ref="input"
                    :id="$attrs.id"
                    type="file"
                    class="a-file-input__input"
                    :name="$attrs.name"
                    :accept="$attrs.accept"
                    :multiple="multiple"
                    :readonly="$attrs.readonly"
                    :disabled="$attrs.disabled || $attrs.readonly"
                    @change="methods.updateModel"
                >
                <span class="a-file-input__text">
                    <span v-if="$slots.icon" class="a-file-input__icon"><slot name="icon" /></span>
                    {{label}}<span>または<a :href="`#${$attrs.id}`" role="button" @click.prevent="$refs.input.click()">ファイルを選択</a></span>
                </span>
            </label>
            <div v-else class="a-file-input__label">
                <div class="a-file-input__spinner"><icon name="Spinner" /></div>
            </div>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, ref, watch} from 'vue';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    components: {Icon},
    props: {
        appearance: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'image'].includes(value)
        },
        multiple: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'ファイルをドラッグアンドドロップ'
        },
        modelValue: {
            type: Array,
            default: () => []
        },
        isError: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, $) {
        const input = ref(null);
        const isDrag = ref(false);
        const methods = {
            dropFile(event) {
                const {files} = event.dataTransfer;
                const target = input.value;

                target.files = files;
                isDrag.value = false;
                methods.updateModel();
            },
            updateModel() {
                const target = input.value;
                const {multiple} = props;
                const model = (multiple ? [...target.files] : [target.files[0]]).map((file) => {
                    const {type} = file;

                    file.url = window.URL.createObjectURL(file);

                    if (type.startsWith('video')) {
                        const video = document.createElement('video');

                        video.src = file.url;

                        video.onloadedmetadata = () => {
                            file.duration = Math.floor(video.duration);
                            video.remove();
                        };

                        video.load();
                    }

                    return file;
                });

                $.emit('update:model-value', model);
            }
        };

        watch(() => props.modelValue, (value) => {
            const {files} = input.value;

            // モデルとinput要素の内容が一致している場合は何もしない
            if (value.length === files.length) {
                return;
            }

            input.value.value = '';

            // モデルが空の場合はここで終了
            if (!value.length) {
                return;
            }

            // ファイルオブジェクトを継承している物のみをvalueに追加
            [...files].forEach((file, index) => {
                if (!(file instanceof File)) {
                    delete files[index];

                    return;
                }

                files[index] = file;
            });

            // モデルとinput要素の内容を同期
            input.value.files = files;
        });

        return {input, isDrag, methods};
    }
});
</script>

<style lang="scss" scoped>
.a-file-input {
    position: relative;
    width: 100%;

    &--image {
        width: 210px;

        .a-file-input__label {
            height: 118px;
            font-size: 1.2rem;
        }

        .a-file-input__text {
            text-align: center;
            flex-direction: column;
        }

        .a-file-input__icon {
            margin: 0 0 24px 0;
        }
    }

    @at-root {
        .a-file-input__drop {
            background: var.$color-utils-background;
            border-radius: 2px;
            border: dashed 1px #B3B8B6;
            transition: background .3s ease 0s;

            &.is-drag,
            &:hover {
                background: #F2F6F9;
            }

            &:focus-within {
                box-shadow: 0px 0px 2px rgba(156, 203, 243, 1);
            }

            &.is-readonly {
                background: var.$color-gray-10;
            }

            &.is-disabled {
                background: var.$color-gray-10;
                opacity: .6;
                color: var.$color-text-disabled;
            }

            &.is-error {
                border: solid 1px var.$color-danger-50;
            }
        }

        .a-file-input__label {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 50px;
            font-size: 1.4rem;
            color: var.$color-text-medium;
            line-height: (22 / 14);
            position: relative;
        }

        .a-file-input__input {
            position: absolute;
            opacity: 0;
        }

        .a-file-input__text {
            display: inline-flex;
            align-items: center;
        }

        .a-file-input__icon {
            display: flex;
            margin-right: 8px;
        }
        .a-file-input__spinner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            > svg {
                animation: .86s linear 0s infinite normal none running animation-loading-spinner;
            }
        }
    }
}
</style>
