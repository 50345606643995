<template>
    <component
        :is="component"
        :step-list="stepList"
        :models="models"
        :forms="forms"
        :services="dataServices"
        :form-errors="formErrors"
        v-model:name="models.client.name"
        v-model:department="models.client.department"
        v-model:position="models.client.position"
        v-model:contact_name="models.client.contact_name"
        v-model:furigana="models.client.furigana"
        v-model:address="models.client.address"
        v-model:tel="models.client.tel"
        v-model:email="models.client.email"
        v-model:email_confirm="models.client.email_confirm"
        v-model:data_services="models.client.data_services"
        @create-client="methods.createClient"
        @validate-client="methods.validateClient"
    ></component>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, computed, onMounted
} from 'vue';
import {
    Agreement, Entry, Confirm, Completed
} from '@/components/04_Templates/Client';
import axios from 'axios';
import Validate from '@/validates';
import {useRouter, onBeforeRouteLeave, onBeforeRouteUpdate} from 'vue-router';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Agreement, Entry, Confirm, Completed
    },
    setup() {
        const $router = useRouter();

        /**
         * alias - テンプレートコンポーネントのエイリアス名
         * @type {Array<String>}
         */
        const alias = ['Agreement', 'Entry', 'Confirm', 'Completed'];

        /**
         * stepList - ステップリストの管理データ
         * @type {{currentStep: ref<number>, steps: Array<String>}}
         */
        const stepList = {
            currentStep: ref(0),
            steps: ['利用規約の同意', '基本情報の入力', '入力の確認', '完了']
        };

        /**
         * dataServices - 選択可能サービス一覧
         * @type {ref<Array<Object>>}
         */
        const dataServices = ref([]);

        /**
         * component - コンポーネント名算出処理
         * @return String - ステップに応じたコンポーネント名
         */
        const component = computed(() => alias[stepList.currentStep.value]);

        /**
         * models - フォームのモデル定義
         * @type Object
         * @property {reactive<Object>} client     - クライアントのモデル
         * @property {String} client.name          - 会社名/個人
         * @property {String} client.department    - 部署名
         * @property {String} client.position      - 役職名
         * @property {String} client.contact_name  - お名前
         * @property {String} client.furigana      - フリガナ
         * @property {String} client.address       - 住所
         * @property {String} client.tel           - 電話番号
         * @property {String} client.email         - メールアドレス
         * @property {String} client.email_confirm - メールアドレス（確認用）
         * @property {String} client.data_services - 利用サービス
         */
        const models = {
            client: reactive({
                name: '',
                department: '',
                position: '',
                contact_name: '',
                furigana: '',
                address: '',
                tel: '',
                email: '',
                email_confirm: '',
                data_services: []
            })
        };

        /**
         * forms - フォームの定義
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込み中フラグ
         * @property {Array<{ term: Any, description: Any }>} client - クライアントのフォーム定義（定義リストの型で定義）
         */
        const forms = {
            isLoading: ref(false),
            client: [
                {
                    term: {
                        id: 'data-service-rule',
                        text: '利用サービス',
                        isRequire: true
                    },
                    description: {
                        inputType: 'checkbox',
                        name: 'data_services',
                        ariaLabelledby: 'data-service-rule',
                        items: dataServices
                    }
                },
                {
                    term: {
                        for: 'name',
                        text: '会社名/個人',
                        description: '個人の方は個人と入力してください',
                        isRequire: true
                    },
                    description: {
                        id: 'name',
                        name: 'name',
                        placeholder: '例：キャッシュビーデータ株式会社',
                        ariaDescribedby: 'name-description'
                    }
                }, {
                    term: {
                        for: 'department',
                        text: '部署名',
                        description: '個人の方は個人と入力してください\n部署名のない方はなしと入力してください',
                        isRequire: true
                    },
                    description: {
                        id: 'department',
                        name: 'department',
                        placeholder: '例：営業部',
                        ariaDescribedby: 'department-description'
                    }
                }, {
                    term: {
                        for: 'position',
                        text: '役職名'
                    },
                    description: {
                        id: 'position',
                        name: 'position',
                        placeholder: '例：部長'
                    }
                }, {
                    term: {
                        for: 'contact_name',
                        text: 'お名前',
                        isRequire: true
                    },
                    description: {
                        id: 'contact_name',
                        name: 'contact_name',
                        placeholder: '例：鈴木　一郎'
                    }
                }, {
                    term: {
                        for: 'furigana',
                        text: 'フリガナ',
                        description: '全角カタカナで入力してください',
                        isRequire: true,
                        inputMode: 'byteFullKana'
                    },
                    description: {
                        id: 'furigana',
                        name: 'furigana',
                        placeholder: '例：スズキ　イチロウ',
                        ariaDescribedby: 'furigana-description'
                    }
                }, {
                    term: {
                        for: 'address',
                        text: '住所',
                        isRequire: true
                    },
                    description: {
                        id: 'address',
                        name: 'address',
                        placeholder: '例：東京都港区赤坂一丁目2番7号'
                    }
                }, {
                    term: {
                        for: 'tel',
                        text: '電話番号',
                        description: '半角数字ハイフン有で入力してください',
                        isRequire: true,
                        maxlength: 15,
                        remove: {
                            label: 'ハイフン',
                            item: '-',
                            maxLength: 12
                        }
                    },
                    description: {
                        id: 'tel',
                        name: 'tel',
                        type: 'tel',
                        placeholder: '例：03-1234-5678',
                        ariaDescribedby: 'tel-description'
                    }
                }, {
                    term: {
                        for: 'email',
                        type: 'email',
                        text: 'メールアドレス',
                        isRequire: true
                    },
                    description: {
                        id: 'email',
                        name: 'email',
                        placeholder: '例：xxxx@xxx.co.jp'
                    }
                }, {
                    term: {
                        for: 'email_confirm',
                        type: 'email',
                        text: 'メールアドレス',
                        description: '確認用',
                        isRequire: true,
                        related: 'email'
                    },
                    description: {
                        id: 'email_confirm',
                        name: 'email_confirm',
                        ariaDescribedby: 'email_confirm-description'
                    }
                }
            ]
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {ref<Array>} system - APIから返却されたシステムエラー
         * @property {Object}     client - クライアント入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            client: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            client: new Validate(forms.client.map((obj) => ({...obj.term, ...obj.description})), models.client)
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * emailUniqueCheck - メールアドレス重複チェック
             * @param {String} email - チェック対象のメールアドレス
             * @returns {Promise}
             */
            emailUniqueCheck(email) {
                // メールアドレスが未入力の場合
                if (email === '') {
                    return Promise.resolve({isError: false, message: {}});
                }

                // APIからバリデーション結果を取得
                return new Promise((resolve) => {
                    axios.get('/api/v2/client_account/clients/email_unique_check', {params: {email}}).then((response) => {
                        // 使用可能なメールアドレスの場合
                        resolve({isError: false, message: {email: response.data.message}});
                    }).catch((error) => {
                        // 使用不可なメールアドレスの場合
                        resolve({isError: true, message: {email: error[0]}});
                    });
                });
            },
            /**
             * validateClient - 入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             */
            async validateClient(event) {
                const {currentTarget} = event;
                const {isError, errors} = validates.client.validate();

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                const uniqueCheck = await methods.emailUniqueCheck(models.client.email);

                // メールアドレスが利用不可の場合、エラーメッセージを追加
                if (uniqueCheck.isError) {
                    Object.assign(errors, uniqueCheck.message);
                }

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.client, key)) {
                            delete formErrors.client[key];
                        }

                        return;
                    }

                    formErrors.client[key] = value;
                });

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = false;

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError || uniqueCheck.isError) {
                    const errorTarget = currentTarget.elements[Object.keys(formErrors.client)[0]];

                    // 選択肢入力欄の場合は先頭にフォーカスする
                    if (errorTarget[Symbol.toStringTag] === 'RadioNodeList') {
                        errorTarget[0].focus();
                    } else {
                        errorTarget.focus();
                    }

                    return;
                }

                // エラーがなかった場合、次のページへ移動
                $router.replace({path: '/client/request/confirm'});
            },
            /**
             * createClient - クライアント作成処理
             * @param model {Object} - 送信するモデル
             */
            createClient(model) {
                // すでに処理中の場合は何もしない
                if (forms.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                // API通信
                axios.post('/api/v2/client_account/clients', model).then(() => {
                    // 通信に成功した場合は完了ページへ遷移
                    $router.replace({path: '/client/request/completed'});
                }).catch((error) => {
                    // 入力に誤りがある場合は前のページ（入力ページ）に戻り、エラーを表示
                    formErrors.system.value = error;
                    $router.go(-1);

                    window.setTimeout(() => {
                        window.scrollTo(0, 500);
                    }, 50);
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    forms.isLoading.value = false;
                });
            },
            /**
             * getServices - サービス一覧取得
             * @return {Promise}
             */
            async getServices() {
                const result = [];

                await axios.get('/api/v2/common/data_services').then((response) => {
                    const {data_services: services} = response.data;

                    result.push(...services.map((service) => ({
                        id: `data-service-${service.id}`,
                        label: service.service_name,
                        value: service.service_code,
                        image: require(`@/assets/images/img-service-${service.service_code.replaceAll('_', '')}.png`) // eslint-disable-line
                    })));
                });

                return result;
            }
        };

        onMounted(() => {
            // サービス一覧を取得
            methods.getServices().then((response) => {
                dataServices.value = response;
            });
        });

        // 次へ進む時の処理
        onBeforeRouteLeave((to, from, next) => {
            const {path} = to;

            // 同階層配下へのページ遷移の場合、制御をかける
            if (path.startsWith('/client/request')) {
                const lastSegment = path.substring(path.lastIndexOf('/') + 1);
                const index = alias.map((alia) => alia.toLowerCase()).indexOf(lastSegment);

                // ブラウザの進む・戻るボタン対策
                if (window.location.pathname !== path) {
                    window.history.pushState('', '', to.path);
                }

                // ステップを更新
                stepList.currentStep.value = index || 0;
                window.scrollTo(0, 0);

                return;
            }

            next();
        });

        // 戻る時の処理
        onBeforeRouteUpdate((to, _, next) => {
            // 同階層配下へのページ遷移の場合、制御をかける
            if (to.path.startsWith('/client/request')) {
                // ステップが0ではない場合、-1する
                if (stepList.currentStep.value !== 0) {
                    stepList.currentStep.value -= 1;
                }

                window.scrollTo(0, 0);

                return;
            }

            next();
        });

        return {
            stepList, component, dataServices, models, forms, formErrors, methods
        };
    }
});
</script>

<style lang="scss" scoped></style>
