import axios from 'axios';

export default {
    namespaced: false,
    state: {
        'access-token': '',
        client: '',
        uid: '',
        ban_status: false
    },
    mutations: {
        /**
         * load - ストアデータ初期読み込み
         * @param {Object} state - モジュールのステートオブジェクト
         * @description ローカルストレージ内に存在するログイン情報を読み込みます
         */
        load(state) {
            Object.assign(state, JSON.parse(window.localStorage.getItem('auth') || '{}'));
        },
        /**
         * create - ストアデータの作成・更新
         * @param {Object} state - モジュールのステートオブジェクト
         * @param {Object} data  - 更新するデータ
         */
        create(state, data) {
            Object.assign(state, data);
            window.localStorage.setItem('auth', JSON.stringify(state));
        },
        /**
         * delete - ストアデータの削除（初期化）
         * @param {Object} state - モジュールのステートオブジェクト
         */
        delete(state) {
            Object.entries(state).forEach(([key, value]) => {
                if (typeof value === 'boolean') {
                    state[key] = false;

                    return;
                }

                state[key] = '';
            });

            window.localStorage.setItem('auth', JSON.stringify(state));
        }
    },
    actions: {
        /**
         * load - ストレージ内容の読み込み
         * @param {Object} $ - ストアオブジェクト
         * @returns {Void}
         */
        load($) {
            $.commit('load');
        },
        /**
         * updateStatus - ストアデータの更新
         * @param {Object} $     - ストアオブジェクト
         * @param {Object} state - 更新内容
         * @returns {Void}
         */
        updateStatus($, state) {
            $.commit('create', state);
        },
        /**
         * login - ログイン処理
         * @param {Object}  $               - ストアオブジェクト
         * @param {Object}  model           - 送信するモデル
         * @param {Boolean} [isAdministrator] - 管理者ログインか否か
         * @returns {Promise}
         */
        login($, {model, isAdministrator = false}) {
            const requestUrl = isAdministrator ? '/api/v2/admin_auth/sign_in' : '/api/v2/client_auth/sign_in';

            return new Promise((resolve, reject) => {
                axios.post(requestUrl, model).then((response) => {
                    const {data, headers} = response;
                    const {'access-token': accessToken, client, uid} = headers;

                    resolve(data.data);
                    $.commit('create', {
                        'access-token': accessToken, client, uid, ban_status: false
                    });
                }).catch((error) => (reject(error)));
            });
        },
        /**
         * logout - ログイン処理
         * @param {Object}  $               - ストアオブジェクト
         * @param {Boolean} [isAdministrator] - 管理者ログインか否か
         * @returns {Promise}
         */
        logout($, isAdministrator = false) {
            const requestUrl = isAdministrator ? '/api/v2/admin_auth/sign_out' : '/api/v2/client_auth/sign_out';
            const headers = $.state;

            return new Promise((resolve, reject) => {
                axios.delete(requestUrl, {
                    headers
                }).then(() => {
                    $.commit('delete');
                    resolve();
                }).catch((error) => reject(error));
            });
        },
        /**
         * forceLogout - 強制ログアウト処理
         * @description ログアウトAPIを呼び出さず、ログアウト処理が必要な場合に限り、実行します
         * @param {Object} $ - ストアオブジェクト
         * @returns {Promise}
         */
        forceLogout($) {
            $.commit('delete');
        },
        /**
         * validate - アクセストークンバリデーション処理
         * @param {Object}  $               - ストアオブジェクト
         * @param {Boolean} [isAdministrator] - 管理者ログインか否か
         * @returns {Promise}
         */
        validate($, isAdministrator = false) {
            const requestUrl = isAdministrator ? '/api/v2/admin_auth/validate_token' : '/api/v2/client_account/clients/show_current?includes[]=client_accounts&includes[]=data_services';

            return new Promise((resolve, reject) => {
                const headers = $.state;

                axios.get(requestUrl, {
                    headers
                }).then((response) => {
                    // 管理者ログインの場合ここで終了
                    if (isAdministrator) {
                        resolve(response.data.data);

                        return;
                    }

                    resolve(response.data.client);
                }).catch((error) => (reject(error)));
            });
        }
    }
};
