<template>
    <div v-bind="$attrs" class="a-tab-panel" role="tabpanel" tabindex="0">
        <slot />
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss" scoped>
.a-tab-panel {
    display: block;

    &[hidden] {
        display: none;
    }
}
</style>
