<template>
    <a
        v-if="isExternalLink"
        :href="$attrs.href || '#'"
        :to="null"
        class="a-tab"
        :class="{'is-current': isCurrent}"
        :role="$attrs.role"
        :tabindex="$attrs.tabindex"
        :aria-controls="$attrs['aria-controls']"
        :aria-selected="$attrs['aria-selected']"
        :aria-disabled="$attrs.disabled || $attrs['aria-disabled'] === 'true' ? 'true' : undefined"
    >
        <span class="a-tab__inner" :data-badge-number="$attrs['data-badge-number']"><slot /></span>
    </a>
    <router-link
        v-else
        :to="$attrs.to"
        class="a-tab"
        :class="{'is-current': isCurrent}"
        :aria-disabled="$attrs.disabled || $attrs['aria-disabled'] === 'true' ? 'true' : undefined"
    >
        <span class="a-tab__inner" :data-badge-number="$attrs['data-badge-number']"><slot /></span>
    </router-link>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isExternalLink: {
            type: Boolean,
            default: false
        },
        isCurrent: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<style lang="scss" scoped>
.a-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    color: var.$color-text-disabled;
    text-decoration: none;
    min-height: 50px;
    border-radius: 2px;
    transition: color .3s ease 0s;

    @include mixin.hover {
        color: var.$color-primary-50;
    }

    &.is-current {
        color: var.$color-primary-50;

        &::before {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
            height: 2px;
            background: var.$color-primary-50;
        }
    }

    &[aria-disabled="true"] {
        cursor: default;
        color: var.$color-text-disabled;
        opacity: .6;

        &::before {
            content: none;
        }

        @include mixin.hover {
            color: var.$color-text-disabled;
        }
    }

    @at-root {
        .a-tab__inner {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 48px;
            padding: 0 16px;

            &[data-badge-number]::after {
                position: relative;
                top: 1px;
                display: inline-flex;
                content: attr(data-badge-number);
                color: var.$color-text-medium;
                font-size: 1rem;
                line-height: 1;
                padding: 3px 6px;
                border-radius: 8px;
                background: var.$color-gray-10;
                margin-left: 8px;
            }
        }
    }
}
</style>
