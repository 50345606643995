<template>
    <div class="a-tag-input-field" :class="{'a-tag-input-field--with-action': $slots.action && !$attrs.disabled}">
        <div
            class="a-tag-input-field__filed"
            :class="{
                'is-error': isError,
                'is-readonly': $attrs.readonly,
                'is-disabled': $attrs.disabled
            }"
            @click="$refs.input.focus()"
            @keydown.enter="$refs.input.focus()"
        >
            <ul class="a-tag-input-field__list" aria-label="入力済のタグ" :hidden="!modelValue.length">
                <li
                    v-for="tag in modelValue"
                    :key="tag"
                    class="a-tag-input-field__item"
                    :class="{
                        'is-error': isError && ($attrs.pattern && !(new RegExp($attrs.pattern).test(tag)))
                    }"
                >
                    <span class="a-tag-input-field__tag">{{tag}}</span>
                    <button
                        class="a-tag-input-field__remove"
                        type="button"
                        :hidden="$attrs.disabled || $attrs.readonly"
                        :disabled="$attrs.disabled"
                        @click="(lastestAtion = ['remove', tag]) & $emit('update:model-value', modelValue.filter((value) => value !== tag))"
                        @keydown.enter="(lastestAtion = ['remove', tag]) & $emit('update:model-value', modelValue.filter((value) => value !== tag))"
                    >
                        <span class="u-altText">タグ「{{tag}}」を削除する</span>
                    </button>
                </li>
            </ul>

            <input
                ref="input"
                v-bind="$attrs"
                v-model="model"
                type="text"
                class="a-tag-input-field__input"
                @focusout="setValue(`${tagText},`)"
                @keydown.enter.prevent="setValue(`${tagText},`)"
                @keydown.delete="!modelValue.length || tagText !== '' ? undefined : (lastestAtion = ['remove', modelValue.slice(-1)[0]]) & $emit('update:model-value', modelValue.slice(0, modelValue.length - 1))"
            />

            <span v-if="$slots.action && !$attrs.disabled" class="a-tag-input-field__action"><slot name="action" /></span>
        </div>

        <div class="a-tag-input-field__region" aria-live="assertive" role="alert">
            <span class="u-altText" :hidden="regionText === ''">{{regionText}}</span>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, ref, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        isError: {
            type: Boolean,
            default: false
        }
    },
    setup(props, $) {
        const tagText = ref('');
        const lastestAtion = ref([]);
        const model = computed({
            get() {
                return tagText.value;
            },
            set(value) {
                const replacedValue = value.replace(/^,{1,}/g, '');
                let tags = [];

                if (replacedValue.includes(',')) {
                    // 先頭と末尾の連続するスペースを削除、かつ空文字ではないものを抽出
                    tags = replacedValue.split(',').map((tag) => tag.replace(/^\s{1,}|\s{1,}$/g, '')).filter((tag) => tag !== '');

                    // ついかするタグが存在する場合、入力文字を削除
                    if (tags.length) {
                        tagText.value = '';
                    }
                } else {
                    tagText.value = replacedValue;
                }

                if (tags.length) {
                    lastestAtion.value = ['update', tags.join('」、「')];
                }

                $.emit('update:model-value', [...new Set([...props.modelValue, ...tags])]);
            }
        });
        const {_setter: setValue} = model;
        const regionText = computed(() => {
            const [action, value] = lastestAtion.value;
            let region = '';

            if (action === 'remove') {
                region = `タグ「${value}」を削除しました`;
            } else if (action === 'update') {
                region = `タグ「${value}」を追加しました`;
            }

            return region;
        });

        return {
            model, tagText, regionText, lastestAtion, setValue
        };
    }
});
</script>

<style lang="scss" scoped>
.a-tag-input-field {
    position: relative;
    width: 100%;

    &--with-action {

        .a-tag-input-field__filed {
            padding-right: 48px;
        }
    }

    @at-root {
        .a-tag-input-field__list {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            padding-top: 11px;
            margin: -8px 0 0 -8px;
            padding-left: 16px;

            &[hidden] {
                display: none;
            }
        }

        .a-tag-input-field__item {
            position: relative;
            display: flex;
            align-items: center;
            background: #CFD0D3;
            font-size: 1.4rem;
            color: var.$color-text-medium;
            border-radius: 2px;
            margin: 8px 0 0 8px;
            padding: 2px 8px;
            overflow: hidden;

            &.is-error {
                background: var.$color-danger-20;
            }
        }

        .a-tag-input-field__remove {
            position: relative;
            width: 20px;
            height: 20px;
            margin-left: 8px;
            transition: background-color .3s ease 0s;

            @include mixin.hover {
                background: var.$color-danger-20;
            }

            &[hidden] {
                display: none;
            }

            &::before,
            &::after {
                position: absolute;
                display: block;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                content: "";
                margin: auto;
                width: 2px;
                height: 14px;
                border-radius: 1px;
                background: var.$color-text-medium;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        .a-tag-input-field__filed {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-medium;
            background: var.$color-utils-background;
            width: 100%;
            padding-right: 16px;
            min-height: 48px;
            transition: background-color .3s ease 0s, border-color .3s ease 0s;

            &:hover {
                background: #F2F6F9;
            }

            &:focus-within {
                border-color: var.$color-primary-50;
                background: var.$color-utils-background;
                outline: none;
            }

            &.is-error {
                border-color: var.$color-danger-50;
            }

            &.is-readonly {
                background: var.$color-gray-10;
            }

            &.is-disabled {
                color: var.$color-text-disabled;
                border-color: var.$color-gray-10;
                background: var.$color-gray-10;
                opacity: .6;
            }
        }

        .a-tag-input-field__input {
            position: relative;
            flex: 1 1 auto;
            font-size: 1.6rem;
            line-height: (24 /16);
            padding: 2px 4px;
            border: none;
            outline: none;
            background: transparent;
            padding: 11px 16px;
            flex-grow: 1;

            &:not(:last-child) {
                padding-right: 48px;
            }
        }

        .a-tag-input-field__action {
            display: inline-flex;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        .a-tag-input-field__action {
            right: 0;

            ::v-deep(button) {
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 48px;
                color: var.$color-text-medium;
                overflow: hidden;

                &[disabled] {
                    color: var.$color-text-disabled;
                    opacity: .6;
                }
            }
        }

        .a-tag-input-field__region {
            position: relative;
            overflow: hidden;
        }
    }
}
</style>
