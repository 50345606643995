<template>
    <request-show
        :is-menu-expanded="isMenuExpanded"
        :request="requestData"
        :breadcrumbs="breadcrumbs"
        :confirm-modal="confirmModal"
        :alert-modal="rejectAlertModal"
        @open-modal="$event.isExpanded.value = true"
        @close-modal="(state, element) => (state.isExpanded.value = false) & element.focus()"
        @approveRequest="methods.approveRequest"
        @rejectRequest="methods.rejectRequest"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, onMounted
} from 'vue';
import axios from 'axios';
import store from '@/store';
import {useRoute} from 'vue-router';
import {RequestShow} from '@/components/04_Templates/Admin';

export default defineComponent({
    inheritAttrs: false,
    components: {RequestShow},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: 'クライアント申請管理',
                to: {name: 'AdminRequest'},
                beforeIcon: 'Request'
            }, {
                label: 'クライアント承認',
                to: {name: 'AdminRequestShow'}
            }
        ];

        /**
         * dataServices - 選択可能サービス一覧
         * @type {reactive<Object>}
         * @property {Array<String>} services - 利用可能サービスコード
         * @property {Array<Object>} items    - サービス一覧情報
         */
        const dataServices = reactive({
            services: [],
            items: []
        });

        /**
         * requestData - サービス利用申請の情報
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込みフラグ
         * @property {ref<Boolean>} isError   - エラーフラグ
         * @property {ref<Boolean>} status    - アカウントのステータス
         * @property {ref<Array>}   data      - 利用申請情報
         */
        const requestData = {
            isLoading: ref(false),
            isError: ref(false),
            status: ref(''),
            data: ref([
                {
                    term: '会社名/個人',
                    description: ''
                }, {
                    term: '部署名',
                    description: ''
                }, {
                    term: '役職名',
                    description: ''
                }, {
                    term: 'お名前',
                    description: ''
                }, {
                    term: 'フリガナ',
                    description: ''
                }, {
                    term: '住所',
                    description: ''
                }, {
                    term: '電話番号',
                    description: ''
                }, {
                    term: 'メールアドレス',
                    description: ''
                }
            ]),
            options: ref([
                {
                    term: '利用サービス',
                    description: dataServices
                }
            ]),
            meta: reactive({
                request: '',
                approve: ''
            })
        };

        /**
         * confirmModal - 確認モーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - モーダル読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const confirmModal = {
            id: 'confirm-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'confirm',
            closable: false,
            title: '申請を承認しますか？',
            description: '承認されたユーザにはメールが送信されます。',
            ariaDescribedby: 'confirm-modal-description'
        };

        /**
         * rejectAlertModal - 申請却下アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - モーダル読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const rejectAlertModal = {
            id: 'alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: '申請を却下しますか？',
            description: '却下された申請は、元に戻すことができません。',
            ariaDescribedby: 'alert-modal-description'
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * approveRequest - 申請承認処理
             * @param {Object} targetTableData - 更新すすテーブルのデータ
             * @return {Void}
             */
            approveRequest() {
                const {requestId} = $route.params;

                // 更新するユーザのIDが不明 または すでに処理中の場合は何もしない
                if (!requestId || confirmModal.isLoading.value) {
                    return;
                }

                // モーダルの読み込みフラグを更新（処理中）
                confirmModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${requestId}/set_approval_status`,
                    {approval_status: 'is_approve'},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    confirmModal.isExpanded.value = false;

                    $.emit('displayToast', {
                        message: 'ユーザを承認しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 一覧のデータを再読み込み
                    methods.getRequestData(requestData);
                }).finally(() => {
                    // モーダルの読み込みフラグを更新（処理済み）
                    confirmModal.isLoading.value = false;
                });
            },
            /**
             * rejectRequest - 申請却下処理
             * @param {Object} targetTableData - 更新すすテーブルのデータ
             * @return {Void}
             */
            rejectRequest() {
                const {requestId} = $route.params;

                // 更新するユーザのIDが不明 または すでに処理中の場合は何もしない
                if (!requestId || rejectAlertModal.isLoading.value) {
                    return;
                }

                // モーダルの読み込みフラグを更新（処理中）
                rejectAlertModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${requestId}/set_approval_status`,
                    {approval_status: 'is_reject'},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    rejectAlertModal.isExpanded.value = false;

                    $.emit('displayToast', {
                        message: '申請を却下しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 一覧のデータを再読み込み
                    methods.getRequestData(requestData);
                }).finally(() => {
                    // モーダルの読み込みフラグを更新（処理済み）
                    rejectAlertModal.isLoading.value = false;
                });
            },
            /**
             * getClientAccounts - APIからクライアントアカウント一覧を取得
             * @returns {Object<{ account: Array, status: String, meta: Object }>}
             */
            async getClientAccounts() {
                const {requestId} = $route.params;
                const result = {};

                await axios.get(`/api/v2/admin_account/clients/${requestId}`, {
                    headers: store.state.auth,
                    params: {includes: ['client_accounts', 'data_services']}
                }).then((response) => {
                    const {client} = response.data;
                    const {data_services: services} = client;
                    const [account] = client.client_accounts;

                    result.status = account.approval_status;
                    result.meta = {
                        request: account.confirmation_sent_at ? new Date(account.confirmation_sent_at).toLocaleDateString() : '',
                        approve: account.confirmed_at ? new Date(account.confirmed_at).toLocaleDateString() : ''
                    };
                    result.account = [
                        {
                            term: '会社名/個人',
                            description: client.name
                        }, {
                            term: '部署名',
                            description: client.department
                        }, {
                            term: '役職名',
                            description: client.position
                        }, {
                            term: 'お名前',
                            description: client.contact_name
                        }, {
                            term: 'フリガナ',
                            description: client.furigana
                        }, {
                            term: '住所',
                            description: client.address
                        }, {
                            term: '電話番号',
                            description: client.tel
                        }, {
                            term: 'メールアドレス',
                            description: client.client_accounts[0].email
                        }
                    ];
                    result.services = services.map((service) => service.service_code);
                });

                return result;
            },
            /**
             * getRequestData - クライアントアカウント詳細を取得・データ更新
             * @param {Object} targetData - 更新するデータ
             * @returns {Void}
             */
            getRequestData(targetData) {
                // すでに読み込み中の場合は取得しない
                if (targetData.isLoading.value) {
                    return;
                }
                // 読み込みフラグを更新（読み込み中）
                targetData.isLoading.value = true;
                methods.getClientAccounts().then((response) => {
                    const {
                        account, status, meta, services
                    } = response;

                    // データを更新
                    targetData.data.value = account;
                    targetData.status.value = status;
                    dataServices.services = services;
                    Object.assign(targetData.meta, meta);
                }).catch(() => {
                    // エラーフラグを更新
                    targetData.isError.value = true;
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetData.isLoading.value = false;
                });
            },
            /**
             * getServices - サービス一覧取得
             * @return {Promise}
             */
            async getServices() {
                const result = [];

                await axios.get('/api/v2/common/data_services').then((response) => {
                    const {data_services: services} = response.data;

                    result.push(...services.map((service) => ({
                        id: `data-service-${service.id}`,
                        label: service.service_name,
                        value: service.service_code,
                        image: require(`@/assets/images/img-service-${service.service_code.replaceAll('_', '')}.png`) // eslint-disable-line
                    })));
                });

                return result;
            }
        };

        onMounted(() => {
            methods.getRequestData(requestData);

            // サービス一覧を取得
            methods.getServices().then((response) => {
                dataServices.items = response;
            });
        });

        return {
            requestData, breadcrumbs, confirmModal, rejectAlertModal, methods
        };
    }
});
</script>
