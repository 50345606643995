<template>
    <admin-login-header
        :server-error-modal="serverErrorModal"
        @close-modal="$emit('close-error-modal', $event)"
    />
    <router-view />
    <admin-login-footer />
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import store from '@/store';
import {AdminLoginHeader} from '@/components/04_Templates/_shared/Header';
import {AdminLoginFooter} from '@/components/04_Templates/_shared/Footer';

export default defineComponent({
    inheritAttrs: false,
    components: {AdminLoginHeader, AdminLoginFooter},
    props: {
        serverErrorModal: {
            type: Object,
            default: () => ({})
        }
    },
    // 入場前に実行する処理
    beforeRouteEnter: (_, __, next) => {
        const {auth} = store.state;

        // ログイン情報が1つでも空の場合は未ログインと判定
        if (Object.values(auth).some((item) => item === '')) {
            next();

            return;
        }

        // ログイン済みの場合はサービストップへ遷移
        next({name: 'AdminClientAccount'});
    }
});
</script>
