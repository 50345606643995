<template>
    <forbidden-403
        :toasts="toasts"
        @copy-content="methods.copy($event)"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref} from 'vue';
import {Forbidden403} from '@/components/04_Templates/NotFound';

export default defineComponent({
    inheritAttrs: false,
    components: {Forbidden403},
    setup() {
        const toasts = ref([]);

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            copy(content) {
                // クリップボードに追記
                navigator.clipboard.writeText(content).then(() => {
                    // コピーができたらトーストを表示
                    toasts.value.push({
                        message: 'メールアドレスをコピーしました',
                        isError: false,
                        icon: 'Success'
                    });
                });
            }
        };

        return {methods, toasts};
    }
});
</script>

<style lang="scss" scoped></style>
