<template>
    <div class="a-message-section">
        <div class="a-message-section__content">
            <div v-if="image !== ''" class="a-message-section__image"><img :src="image" alt="" /></div>
            <p class="a-message-section__message"><b>{{message}}</b></p>
            <p v-if="repletion !== ''" class="a-message-section__repletion">{{repletion}}</p>
        </div>

        <div v-if="$slots.actions" class="a-message-section__actions"><slot name="actions" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        image: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: 'メッセージテキスト'
        },
        repletion: {
            type: String,
            default: '補足テキスト'
        }
    }
});
</script>

<style lang="scss" scoped>
.a-message-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @at-root {
        .a-message-section__content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .a-message-section__message {
            font-size: 4.8rem;
            font-weight: bold;
            color: var.$color-primary-50;

            &:not(:first-child) {
                margin-top: 24px;
            }
        }

        .a-message-section__repletion {
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-disabled;
            margin-top: 24px;
        }

        .a-message-section__actions {
            margin-top: 60px;
        }
    }
}
</style>
