<template>
    <tbody class="a-table-body">
        <slot
            v-for="row in rows"
            :key="row"
            name="body-row"
            :cells="row"
        />
    </tbody>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        rows: {
            type: Array,
            default: () => {
                const row = [
                    {
                        key: 'body-1',
                        content: 'body 1'
                    }, {
                        key: 'body-2',
                        content: 'body 2'
                    }, {
                        key: 'body-3',
                        content: 'body 3'
                    }
                ];

                return new Array(10).fill(row);
            }
        }
    }
});
</script>

<style lang="scss" scoped>
.a-table-body {

    > :not(:root) {
        height: 64px;
    }
}
</style>
