<template>
    <footer id="footer" class="t-footer">
        <p class="t-footer__copy"><small>©2022 CASHb DATA, Inc.</small></p>
    </footer>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss" scoped>
.t-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 94px;
    padding: 40px;
    pointer-events: none;

    @at-root {
        .t-footer__copy {
            font-size: 1.4rem;
            line-height: 1;
            font-weight: bold;
            color: var.$color-text-high;
            pointer-events: auto;
        }
    }
}
</style>
