<template>
    <div class="a-modal-header">
        <div :id="$attrs.id" role="heading" class="a-modal-header__title" :aria-level="level">
            <span v-if="$slots.icon" class="a-modal-header__icon"><slot name="icon" /></span>
            {{title}}
        </div>
        <div v-if="$slots.actions" class="a-modal-header__actions"><slot name="actions" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        level: {
            type: Number,
            default: 3,
            validator: (value) => 0 < value || value < 7
        },
        title: {
            type: String,
            default: '',
            required: true
        }
    }
});
</script>

<style lang="scss" scoped>
.a-modal-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;

    @at-root {
        .a-modal-header__title {
            display: inline-flex;
            align-items: center;
            font-size: 2rem;
            font-weight: bold;
            line-height: (28 / 20);
            color: var.$color-text-high;
        }

        .a-modal-header__icon {
            display: flex;
            margin-right: 8px;

            .a-modal--alert & {
                color: var.$color-danger-50;
            }

            .a-modal--confirm & {
                color: var.$color-warning-50;
            }
        }

        .a-modal-header__actions {
            flex: 0 0 auto;
        }
    }
}
</style>
