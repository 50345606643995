<template>
    <button
        type="button"
        class="a-button-icon"
        :class="{
            [`a-button-icon--${placement}`]: placement !== 'bottom'
        }"
    >
        <slot />
    </button>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        placement: {
            type: String,
            default: 'bottom',
            validate: (value) => (['top', 'right', 'bottom', 'left'].includes(value))
        }
    }
});
</script>

<style lang="scss" scoped>
.a-button-icon {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 8px;
    font-size: 1rem;
    letter-spacing: .035em;
    background: #F8F8F9;
    border: solid 1px #F8F8F9;
    color: #808984;
    border-radius: 2px;
    transition: background .3s ease 0s,  border-color .3s ease 0s;

    &[title]::before {
        content: attr(title)
    }

    &[data-title]::before {
        content: attr(data-title)
    }

    &::before {
        pointer-events: none;
        display: inline-block;
        position: absolute;
        background: var.$color-gray-40;
        font-size: 1rem;
        color: var.$color-text-white;
        border-radius: 2px;
        bottom: -8px;
        padding: 2px 4px;
        white-space: nowrap;
        left: 50%;
        margin: auto;
        transform: translate(-50%, 100%);
        visibility: hidden;
        opacity: 0;
        transition: opacity .3s ease 0s, visibility .3s ease 0s;
    }

    @include mixin.hover {
        background: #EDEEF0;
        border-color: #CDCFD4;

        &::before {
            visibility: visible;
            opacity: 1;
        }
    }

    &--top::before {
        bottom: auto;
        top: -8px;
        transform: translate(-50%, -100%);
    }

    &--right::before {
        right: -8px;
        left: auto;
        bottom: 50%;
        transform: translate(100%, 50%);
    }

    &--left::before {
        bottom: 50%;
        left: -8px;
        transform: translate(-100%, 50%);
    }

    &[disabled] {
        cursor: default;
        background: #F8F8F9;
        border-color: #F8F8F9;
        opacity: .6;

        &::before {
            content: none;
        }
    }

    @at-root {
        .a-button-icon__text {
            position: absolute;
        }
    }
}
</style>
