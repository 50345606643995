<template>
    <main class="t-main">
        <div class="t-main__image"></div>
        <div class="t-main__form">
            <forms @submit.prevent="$emit('reissue-password', $event)">
                <template v-slot:header>
                    <form-header v-bind="forms.header" />
                    <banner v-if="formErrors.system.value.length" appearance="danger">
                        <template v-slot:icon><icon name="Error" /></template>
                        <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
                    </banner>
                </template>

                <field v-bind="forms.email">
                    <template v-slot:field="fieldProps">
                        <text-field
                            v-bind="fieldProps.field"
                            :is-error="formErrors.reissuePassword.email !== undefined"
                            :aria-errormessage="`${fieldProps.field.id}-invalid`"
                            :aria-required="fieldProps.field.isRequire"
                            :model-value="models.reissuePassword.email"
                            @update:model-value="$emit('update:email', $event)"
                        >
                            <template v-slot:action>
                                <button type="button" :disabled="models.reissuePassword.email === ''" @click="$emit('set-model-value', 'email', '')"><icon name="Close" /></button>
                            </template>
                        </text-field>
                        <form-message v-if="formErrors.reissuePassword.email" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.reissuePassword.email"><template v-slot:icon><icon name="Error" /></template></form-message>
                    </template>
                </field>

                <template v-slot:footer>
                    <form-footer>
                        <button-group>
                            <standard-button :is-loading="forms.isLoading.value" appearance="primary" type="submit">送信</standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>

            <router-link :to="{name: 'ClientLogin'}">ログイン画面へ戻る</router-link>
        </div>

        <modal v-bind="modal" :is-expanded="modal.isExpanded.value">
            <template v-slot:header="modalHeaderProps">
                <modal-header title="パスワード再発行を受け付けました" v-bind="modalHeaderProps.header" />
            </template>

            <p>{{modal.message.value}}</p>
            <p>メールに記載のURLよりパスワードの再設定を行なってください。</p>

            <template v-slot:footer>
                <modal-footer>
                    <button-group layout="end">
                        <standard-button appearance="primary" type="submit" @click="$router.push({name: 'ClientLogin'})">OK</standard-button>
                    </button-group>
                </modal-footer>
            </template>
        </modal>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Forms, {
    FormHeader, FormFooter, Field, FormMessage
} from '@/components/01_Atoms/Forms';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';
import {TextField} from '@/components/01_Atoms/TextFields';

export default defineComponent({
    inheritAttrs: false,
    emits: ['set-model-value', 'reissue-password', 'update:email'],
    components: {
        Banner, ButtonGroup, StandardButton, Icon, Forms, FormHeader, FormFooter, Field, FormMessage, Modal, ModalHeader, ModalFooter, TextField
    },
    props: {
        models: {
            type: Object,
            default: () => {}
        },
        forms: {
            type: Object,
            default: () => {}
        },
        formErrors: {
            type: Object,
            default: () => {}
        },
        modal: {
            type: Object,
            default: () => {}
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    min-height: 100vh;

    @at-root {
        .t-main__image {
            flex-basis: 54%;
            background-image: url(/img/bg-login-01.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: var.$color-gray-10;
        }

        .t-main__form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 46%;
            padding: 158px 24px 94px;
            max-width: 480px;
            margin: auto;

            a {
                margin-top: 32px;
            }
        }
    }
}
</style>
