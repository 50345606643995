<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__utils">
            <breadcrumbs :breadcrumbs="breadcrumbs">
                <template v-slot:item="breadcrumbsItemProps">
                    <breadcrumbs-item v-bind="breadcrumbsItemProps.breadcrumbs">
                        <template v-if="breadcrumbsItemProps.breadcrumbs.beforeIcon" v-slot:before><icon :name="breadcrumbsItemProps.breadcrumbs.beforeIcon" /></template>
                        {{breadcrumbsItemProps.breadcrumbs.label}}
                        <template v-if="breadcrumbsItemProps.breadcrumbs.afterIcon" v-slot:after><icon :name="breadcrumbsItemProps.breadcrumbs.afterIcon" /></template>
                    </breadcrumbs-item>
                </template>
            </breadcrumbs>
        </div>

        <cards>
            <template v-slot:header>
                <form-header title="クライアント承認">
                    <template v-slot:actions v-if="!request.isLoading.value && !request.isError.value">
                        <button-group layout="end" v-if="request.status.value === 'is_pending'">
                            <modal v-bind="confirmModal" :is-expanded="confirmModal.isExpanded.value" @close="$emit('closeModal', confirmModal, $refs.confirmModalTrigger.$el)">
                                <template v-slot:trigger="modalTriggerProps">
                                    <action-button ref="confirmModalTrigger" v-bind="modalTriggerProps.trigger"  @click="$emit('openModal', confirmModal)">
                                        <template v-slot:before><icon name="Success" /></template>
                                        承認する
                                    </action-button>
                                </template>

                                <template v-slot:header="modalHeaderProps">
                                    <modal-header :title="confirmModal.title" v-bind="modalHeaderProps.header">
                                        <template v-slot:icon><icon name="Warning" /></template>
                                    </modal-header>
                                </template>

                                <p :id="confirmModal.ariaDescribedby">{{confirmModal.description}}</p>

                                <template v-slot:footer>
                                    <modal-footer>
                                        <button-group layout="end">
                                            <standard-button :disabled="confirmModal.isLoading.value" @click="$emit('closeModal', confirmModal, $refs.confirmModalTrigger.$el)">キャンセル</standard-button>
                                            <standard-button appearance="primary" :is-loading="confirmModal.isLoading.value" @click="$emit('approveRequest', $event)">承認する</standard-button>
                                        </button-group>
                                    </modal-footer>
                                </template>
                            </modal>

                            <modal v-bind="alertModal" :is-expanded="alertModal.isExpanded.value" @close="$emit('closeModal', $refs.alertModalTrigger.$el)">
                                <template v-slot:trigger="modalTriggerProps">
                                    <action-button ref="alertModalTrigger" appearance="destructive" v-bind="modalTriggerProps.trigger" @click="$emit('openModal', alertModal)">
                                        <template v-slot:before><icon name="Failed" /></template>
                                        却下する
                                    </action-button>
                                </template>
                                <template v-slot:header="modalHeaderProps">
                                    <modal-header :title="alertModal.title" v-bind="modalHeaderProps.header">
                                        <template v-slot:icon><icon name="Error" /></template>
                                    </modal-header>
                                </template>

                                <p :id="alertModal.ariaDescribedby">{{alertModal.description}}</p>

                                <template v-slot:footer>
                                    <modal-footer>
                                        <button-group layout="end">
                                            <standard-button @click="$emit('closeModal', alertModal, $refs.alertModalTrigger.$el)">キャンセル</standard-button>
                                            <standard-button appearance="danger" @click="$emit('rejectRequest', $event)">却下する</standard-button>
                                        </button-group>
                                    </modal-footer>
                                </template>
                            </modal>
                        </button-group>
                        <banner v-else-if="request.status.value === 'is_approve'" appearance="success">
                            <template v-slot:icon><icon name="Success" /></template>
                            <p>承認済みユーザ</p>
                        </banner>
                        <banner v-else appearance="danger">
                            <template v-slot:icon><icon name="Failed" /></template>
                            <p>却下済みユーザ</p>
                        </banner>
                    </template>
                </form-header>
            </template>

            <banner v-if="request.isError.value" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p>申請データが存在しません。</p>
            </banner>

            <div class="t-date-text">
                <p v-if="request.meta.request" class="t-date-text__item"><span class="t-date-text__flag">申請日</span>{{request.meta.request}}</p>
                <p v-if="request.meta.approve" class="t-date-text__item"><span class="t-date-text__flag">承認日</span>{{request.meta.approve}}</p>
            </div>

            <definition-list :items="request.options.value">
                <template v-slot:item-0="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>
                        <checkbox-group direction="row" :items="itemProps.description.items">
                            <template v-slot:checkbox="slotProps">
                                <service-checkbox
                                    v-bind="slotProps.item"
                                    :readonly="true"
                                    :indeterminate="slotProps.indeterminate"
                                    :aria-errormessage="`${itemProps.description.id}-invalid`"
                                    :aria-required="itemProps.term.isRequire"
                                    :model-value="itemProps.description.services"
                                >
                                    {{slotProps.item.label}}
                                    <template v-slot:image><img :src="slotProps.item.image" alt=""></template>
                                </service-checkbox>
                            </template>
                        </checkbox-group>
                    </definition-list-description>
                </template>
            </definition-list>

            <definition-list :items="request.data.value">
                <template v-slot:item-0="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-1="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-2="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-3="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-4="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-5="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-6="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
                <template v-slot:item-7="itemProps">
                    <definition-list-term>{{itemProps.term}}</definition-list-term>
                    <definition-list-description>{{itemProps.description}}</definition-list-description>
                </template>
            </definition-list>
        </cards>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import Breadcrumbs, {BreadcrumbsItem} from '@/components/01_Atoms/Breadcrumbs';
import ButtonGroup, {StandardButton, ActionButton} from '@/components/01_Atoms/Buttons';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';
import Cards from '@/components/01_Atoms/Cards';
import {FormHeader} from '@/components/01_Atoms/Forms';
import Icon from '@/components/01_Atoms/Icons';
import CheckboxGroup, {ServiceCheckbox} from '@/components/01_Atoms/Checkboxes';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Banner, Breadcrumbs, BreadcrumbsItem, ButtonGroup, ActionButton, StandardButton, DefinitionList, DefinitionListTerm, DefinitionListDescription, Icon, Modal, ModalHeader, ModalFooter, Cards, FormHeader, CheckboxGroup, ServiceCheckbox
    },
    props: {
        breadcrumbs: {
            type: Array,
            default: () => []
        },
        request: {
            type: Object,
            default: () => ({data: [], isLoading: false})
        },
        alertModal: {
            type: Object,
            default: () => ({})
        },
        confirmModal: {
            type: Object,
            default: () => ({})
        },
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__utils {
            padding: 24px 0;
        }
    }
}

.t-date-text {
    display: flex;
    margin-bottom: 24px;

    @at-root {
        .t-date-text__item {
            color: var.$color-text-medium;
            font-size: 1.4rem;
            line-height: (22 / 14);

            &:not(:first-child) {
                margin-left: 24px;
            }
        }

        .t-date-text__flag {
            margin-right: 8px;
        }
    }
}

.a-definition-list {

    & + & {
        padding-top: 24px;
        margin-top: 24px;
        border-top: solid 1px var.$color-gray-10;
    }
}
</style>
