<template>
    <div
        class="a-checkbox-group"
        :class="{'a-checkbox-group--row': direction === 'row'}"
        role="group"
        :aria-labelledby="$attrs['aria-labelledby'] || null"
        :aria-describedby="$attrs['aria-describedby'] || null"
    >
        <div
            v-for="item in items"
            :key="item"
            class="a-checkbox-group__item"
        >
            <slot
                name="checkbox"
                :item="{...item}"
                :has-child="Object.hasOwnProperty.call(item, 'children')"
                :indeterminate="indeterminate(item).value"
            />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        direction: {
            type: String,
            default: 'column',
            validator: (value) => ['row', 'column'].includes(value)
        },
        childModel: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => [
                {
                    id: 'checkbox-1',
                    name: 'checkbox_group_root',
                    label: 'checkbox 1',
                    value: '1',
                    children: [
                        {
                            id: 'checkbox-child-1',
                            name: 'checkbox_group',
                            label: 'checkbox child 1',
                            value: '1'
                        }, {
                            id: 'checkbox-child-2',
                            name: 'checkbox_group',
                            label: 'checkbox child 2',
                            value: '2'
                        }, {
                            id: 'checkbox-child-3',
                            name: 'checkbox_group',
                            label: 'checkbox child 3',
                            value: '3'
                        }
                    ]
                }
            ]
        }
    },
    setup(props) {
        const indeterminate = (item) => computed(() => {
            const {childModel} = props;

            // 子を持っていない場合
            if (!Object.hasOwnProperty.call(item, 'children')) {
                return null;
            }

            const values = item.children.map((child) => childModel.includes(child.value));

            // 全てtrueでもなく、falseでもない場合、trueを返却する
            return !values.every((value) => value) && !values.every((value) => !value);
        });

        return {indeterminate};
    }
});
</script>

<style lang="scss" scoped>
.a-checkbox-group {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
        margin-top: 16px;
    }

    &--row {
        flex-direction: row;

        &:not(:first-child) {
            margin-top: 8px;
        }

        > :not(:root) {

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 8px;
            }
        }
    }

    @at-root {
        .a-checkbox-group__item {

            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }
}
</style>
