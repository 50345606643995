<template>
    <change-password
        :is-menu-expanded="isMenuExpanded"
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        v-model:current_password="models.client.current_password"
        v-model:password="models.client.password"
        v-model:password_confirmation="models.client.password_confirmation"
        @update-password="methods.validatePassword"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref, reactive} from 'vue';
import Validate from '@/validates';
import store from '@/store';
import axios from 'axios';
import {ChangePassword} from '@/components/04_Templates/Client';

export default defineComponent({
    inheritAttrs: false,
    components: {ChangePassword},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        /**
         * models - フォームのモデル定義
         * @type Object
         * @property {reactive<Object>} client             - クライアントのモデル
         * @property {String} client.current_password      - 現在のパスワード
         * @property {String} client.password              - 変更するパスワード
         * @property {String} client.password_confirmation - 変更するパスワード（再入力）
         */
        const models = {
            client: reactive({
                current_password: '',
                password: '',
                password_confirmation: ''
            })
        };

        /**
         * forms - フォームの定義
         * @type Object
         * @property {ref<Boolean>}                           isLoading - 読み込みフラグ
         * @property {Array<{ term: Any, description: Any }>} client    - クライアントのフォーム定義（定義リストの型で定義）
         */
        const forms = {
            isLoading: ref(false),
            client: [
                {
                    term: {
                        for: 'current-password',
                        text: '現在のパスワード'
                    },
                    description: {
                        id: 'current-password',
                        name: 'current_password',
                        type: 'password',
                        placeholder: '入力してください',
                        isRequire: true
                    }
                }, {
                    term: {
                        for: 'password',
                        text: '新しいパスワード'
                    },
                    description: {
                        id: 'password',
                        name: 'password',
                        type: 'password',
                        placeholder: '入力してください',
                        isRequire: true
                    }
                }, {
                    term: {
                        for: 'password-confirmation',
                        text: '新しいパスワード（再入力）'
                    },
                    description: {
                        id: 'password-confirmation',
                        name: 'password_confirmation',
                        type: 'password',
                        placeholder: '入力してください',
                        isRequire: true,
                        related: 'password',
                        message: {
                            required: 'パスワードを再入力してください'
                        }
                    }
                }
            ]
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system - APIから返却されたシステムエラー
         * @property {Object} login  - ログイン情報入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            client: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            client: new Validate(forms.client.map((obj) => ({...obj.term, ...obj.description})), models.client)
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * validatePassword - 入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             */
            validatePassword(event, model) {
                const {currentTarget} = event;
                const {isError, errors} = validates.client.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.client, key)) {
                            delete formErrors.client[key];
                        }

                        return;
                    }

                    formErrors.client[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    currentTarget.elements[Object.keys(formErrors.client)[0]].focus();

                    return;
                }

                // エラーがなければ更新処理を実行
                methods.updatePassword(model);
            },
            /**
             * updatePassword - パスワード更新処理
             * @param {Object} model - 送信するモデル
             */
            updatePassword(model) {
                if (forms.isLoading.value) {
                    return;
                }

                forms.isLoading.value = true;

                axios.patch(
                    '/api/v2/client_account/clients/change_password',
                    {...model},
                    {headers: store.state.auth}
                ).then(() => {
                    formErrors.system.value.length = 0;

                    $.emit('displayToast', {
                        message: 'パスワードの更新に成功しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // エラーをリセット
                    formErrors.client = reactive({});
                    formErrors.system.value.length = 0;
                }).catch((error) => {
                    formErrors.system.value = error;
                }).finally(() => {
                    forms.isLoading.value = false;
                });
            }
        };

        return {
            models, forms, formErrors, methods
        };
    }
});
</script>

<style lang="scss" scoped></style>
