<template>
    <component
        :is="['Pending', 'Approve'][tabNavigation.currentIndex.value]"
        :is-menu-expanded="isMenuExpanded"
        :tabs="tabNavigation"
        :request-table-data="currentTableData"
        :confirm-modal="confirmModal"
        :alert-modal="rejectAlertModal"
        @open-modal="(state, id) => (state.isExpanded.value = true) & (state.currentId.value = id)"
        @close-modal="(state, element) => (state.isExpanded.value = false) & element.focus()"
        @approveRequest="methods.approveRequest(currentTableData)"
        @rejectRequest="methods.rejectRequest(currentTableData)"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, computed, watch
} from 'vue';
import {RequestPending as Pending, RequestApproved as Approve} from '@/components/04_Templates/Admin';
import axios from 'axios';
import store from '@/store';
import {useRoute, useRouter} from 'vue-router';

export default defineComponent({
    inheritAttrs: false,
    components: {Pending, Approve},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();
        const $router = useRouter();

        /**
         * alias - テンプレートコンポーネントのエイリアス名
         * @type {Array<String>}
         */
        const alias = ['Pending', 'Approve'];

        /**
         * tabNavigation - タブ表示のデータ
         * @type {Object}
         * @property {red<Number>}   currentIndex - 現在のインデックス
         * @property {Boolean}       isNavigation - ナビゲーションモードを有効にする
         * @property {Array<Object>} tabs         - タブのデータ
         */
        const tabNavigation = {
            currentIndex: ref($route.path.substring($route.path.lastIndexOf('/') + 1) === 'pending' ? 0 : 1),
            isNavigation: true,
            tabs: [
                reactive({
                    id: 'pending',
                    label: '未承認',
                    badge: '-',
                    to: {name: 'AdminRequestPending'}
                }),
                reactive({
                    id: 'approved',
                    label: '承認済み',
                    to: {name: 'AdminRequestApproved'}
                })
            ]
        };

        /**
         * pendingRequestTableData - 未承認申請テーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const pendingRequestTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'contact_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'name',
                        content: '会社名',
                        isHead: true
                    }, {
                        key: 'department',
                        content: '部署名',
                        isHead: true
                    }, {
                        key: 'confirmation_sent_at',
                        content: '申請日',
                        isHead: true
                    }, {
                        key: 'confirmed_at',
                        content: '承認日',
                        isHead: true
                    }, {
                        key: 'action',
                        content: '操作',
                        resizing: 'hug',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '未承認のユーザが存在しません',
                repletion: '未承認のユーザがここに表示されます。'
            }
        };

        /**
         * approvedRequestTableData - 承認済み申請テーブルデータ
         * @type {Object}
         * @property {ref<Boolean>}                                   isLoading              - データ読み込み中ステータス
         * @property {Array<Array>}                                   headRows               - テーブルヘッダー情報
         * @property {Array<Array>}                                   bodyRows               - テーブルボディ情報
         * @property {Object}                                         pagination             - ページネーション情報
         * @property {ref<Number>}                                    pagination.currentPage - 現在のページ数
         * @property {Array<Nubmer>}                                  pagination.pages       - ページ数の一覧
         * @property {Object<{ message: String, repletion: String }>} emptyMessage           - データが空だった場合の表示メッセージ
         */
        const approvedRequestTableData = {
            isLoading: ref(false),
            headRows: [
                [
                    {
                        key: 'id',
                        content: 'クライアントID',
                        resizing: 'hug',
                        isHead: true
                    }, {
                        key: 'contact_name',
                        content: 'クライアント名',
                        isHead: true
                    }, {
                        key: 'name',
                        content: '会社名',
                        isHead: true
                    }, {
                        key: 'department',
                        content: '部署名',
                        isHead: true
                    }, {
                        key: 'confirmation_sent_at',
                        content: '申請日',
                        isHead: true
                    }, {
                        key: 'confirmed_at',
                        content: '承認日',
                        isHead: true
                    }
                ]
            ],
            bodyRows: ref([]),
            pagination: {
                currentPage: ref(1),
                pages: ref([1])
            },
            emptyMessage: {
                message: '承認済みのユーザが存在しません',
                repletion: '承認済みのユーザがここに表示されます。'
            }
        };

        /**
         * confirmModal - 確認モーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - モーダル読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const confirmModal = {
            id: 'confirm-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'confirm',
            closable: false,
            title: '申請を承認しますか？',
            description: '承認されたユーザにはメールが送信されます。',
            ariaDescribedby: 'confirm-modal-description'
        };

        /**
         * rejectAlertModal - 申請却下アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<String>}  currentId       - 現在開いているモーダルのID名
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {ref<Boolean>} isLoading       - モーダル読み込みフラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const rejectAlertModal = {
            id: 'alert-modal',
            currentId: ref(''),
            isExpanded: ref(false),
            isLoading: ref(false),
            appearance: 'alert',
            closable: false,
            title: '申請を却下しますか？',
            description: '却下された申請は、元に戻すことができません。',
            ariaDescribedby: 'alert-modal-description'
        };

        const methods = {
            /**
             * approveRequest - 申請承認処理
             * @param {Object} targetTableData - 更新すすテーブルのデータ
             * @return {Void}
             */
            approveRequest(targetTableData) {
                const currentRequestId = confirmModal.currentId.value;

                // 更新するユーザのIDが不明 または すでに処理中の場合は何もしない
                if (!currentRequestId || confirmModal.isLoading.value) {
                    return;
                }

                // モーダルの読み込みフラグを更新（処理中）
                confirmModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${currentRequestId}/set_approval_status`,
                    {approval_status: 'is_approve'},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    confirmModal.isExpanded.value = false;

                    $.emit('displayToast', {
                        message: 'ユーザを承認しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 一覧のデータを再読み込み
                    methods.getClientAccountTableData(targetTableData, {
                        approval_status: 'is_pending',
                        ban_status: false,
                        includes: ['client_accounts']
                    });
                }).finally(() => {
                    // モーダルの読み込みフラグを更新（処理済み）
                    confirmModal.isLoading.value = false;
                });
            },
            /**
             * rejectRequest - 申請却下処理
             * @param {Object} targetTableData - 更新すすテーブルのデータ
             * @return {Void}
             */
            rejectRequest(targetTableData) {
                const currentRequestId = rejectAlertModal.currentId.value;

                // 更新するユーザのIDが不明 または すでに処理中の場合は何もしない
                if (!currentRequestId || rejectAlertModal.isLoading.value) {
                    return;
                }

                // モーダルの読み込みフラグを更新（処理中）
                rejectAlertModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${currentRequestId}/set_approval_status`,
                    {approval_status: 'is_reject'},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    rejectAlertModal.isExpanded.value = false;

                    $.emit('displayToast', {
                        message: '申請を却下しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // 一覧のデータを再読み込み
                    methods.getClientAccountTableData(targetTableData, {
                        approval_status: 'is_pending',
                        ban_status: false,
                        includes: ['client_accounts']
                    });
                }).finally(() => {
                    // モーダルの読み込みフラグを更新（処理済み）
                    rejectAlertModal.isLoading.value = false;
                });
            },
            /**
             * getClientAccounts - APIからクライアントアカウント一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ accounts; Array, meta: Object }>}
             */
            async getClientAccounts(params) {
                const result = {};

                await axios.get('/api/v2/admin_account/clients', {
                    headers: store.state.auth,
                    params: Object.assign(params, $route.query)
                }).then((response) => {
                    const {clients, meta} = response.data;

                    result.accounts = clients.map((account) => ([
                        {key: 'id', content: account.id, resizing: 'hug'},
                        {key: 'contact_name', content: account.contact_name, clamp: 1},
                        {key: 'name', content: account.name},
                        {key: 'department', content: account.department},
                        {key: 'confirmation_sent_at', content: new Date(account.client_accounts[0].confirmation_sent_at).toLocaleDateString()},
                        {key: 'confirmed_at', content: account.client_accounts[0].confirmed_at ? new Date(account.client_accounts[0].confirmed_at).toLocaleDateString() : ''},
                        {key: 'action', content: account.id, resizing: 'hug'}
                    ]));

                    result.meta = meta;
                });

                return result;
            },
            /**
             * getClientAccountTableData - クライアントアカウント一覧を取得・データ更新
             * @param {Object} targetTable - 更新するテーブルデータ
             * @param {Object} params      - 送信するパラメータ
             * @returns {Void}
             */
            getClientAccountTableData(targetTable, params) {
                // すでに読み込み中の場合は取得しない
                if (targetTable.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetTable.isLoading.value = true;

                // データ取得
                methods.getClientAccounts(params).then((response) => {
                    const {accounts, meta} = response;

                    // テーブルデータを更新
                    targetTable.bodyRows.value = accounts;

                    // ページネーションを更新
                    targetTable.pagination.currentPage.value = meta.current_page;
                    targetTable.pagination.pages.value = Array.from(Array(meta.total_pages).keys(), (index) => index + 1);

                    // 「未承認」タブの場合は、バッジの数値を更新
                    if (tabNavigation.currentIndex.value === 0) {
                        tabNavigation.tabs[0].badge = meta.total_count;
                    }

                    // 現在のページが合計ページ数を逸脱している場合
                    if (meta.current_page > meta.total_pages) {
                        $router.replace({query: {...$route.query, ...{page: meta.total_pages}}});
                    }
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetTable.isLoading.value = false;
                });
            }
        };

        /**
         * currentTableData - ページに応じたテーブルデータの算出
         */
        const currentTableData = computed(() => [pendingRequestTableData, approvedRequestTableData][tabNavigation.currentIndex.value]);

        /**
         * URLパス変更監視処理
         * @type {Function}
         */
        watch(() => $route.path, (path) => {
            const lastSegment = path.substring(path.lastIndexOf('/') + 1);
            const index = alias.map((alia) => alia.toLowerCase()).indexOf(lastSegment);

            // /requests 以外のページヘ遷移する場合は何もしない
            if (index === -1) {
                return;
            }

            tabNavigation.currentIndex.value = index;
            methods.getClientAccountTableData(currentTableData.value, {
                approval_status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`,
                ban_status: false,
                includes: ['client_accounts']
            });
        }, {immediate: true});

        /**
         * URLクエリ変更監視処理
         * @type {Function}
         */
        watch(() => $route.query, (query) => {
            const currentQuery = tabNavigation.tabs[tabNavigation.currentIndex.value].to.query || {};

            tabNavigation.tabs[tabNavigation.currentIndex.value].to.query = query;

            // クエリに変更がない場合は読み込み処理を行わない
            if (JSON.stringify(Object.entries(currentQuery).sort()) === JSON.stringify(Object.entries(query).sort())) {
                return;
            }

            const currentTable = currentTableData.value;

            currentTable.pagination.currentPage.value = parseInt(query.page, 10);
            methods.getClientAccountTableData(currentTable, {
                approval_status: `is_${alias[tabNavigation.currentIndex.value].toLowerCase()}`,
                ban_status: false,
                includes: ['client_accounts']
            });
        }, {immediate: true});

        return {
            tabNavigation, pendingRequestTableData, approvedRequestTableData, rejectAlertModal, confirmModal, methods, currentTableData
        };
    }
});
</script>
