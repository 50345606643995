<template>
    <client-show
        :is-menu-expanded="isMenuExpanded"
        :client="clientData"
        :breadcrumbs="breadcrumbs"
        :alert-modal="alertModal"
        :confirm-modal="confirmModal"
        @open-modal="$event.isExpanded.value = true"
        @close-modal="(state, element) => (state.isExpanded.value = false) & element.focus()"
        @ban-client="methods.setBanStatus"
        @reactivate-client="methods.setApprovalStatus"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, onMounted, reactive, ref
} from 'vue';
import axios from 'axios';
import store from '@/store';
import {useRoute} from 'vue-router';
import {ClientShow} from '@/components/04_Templates/Admin';

export default defineComponent({
    inheritAttrs: false,
    components: {ClientShow},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $route = useRoute();

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: 'クライアント管理',
                to: {name: 'AdminClientAccount'},
                beforeIcon: 'Company'
            }, {
                label: 'クライアント情報',
                to: {name: 'AdminClientAccountShow'}
            }
        ];

        /**
         * dataServices - 選択可能サービス一覧
         * @type {reactive<Object>}
         * @property {Array<String>} services - 利用可能サービスコード
         * @property {Array<Object>} items    - サービス一覧情報
         */
        const dataServices = reactive({
            services: [],
            items: []
        });

        /**
         * clientData - クライアントの情報
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込みフラグ
         * @property {ref<Boolean>} isError   - エラーフラグ
         * @property {ref<Boolean>} isBan     - アカウント停止フラグ
         * @property {ref<Array>}   data      - クライアント情報
         */
        const clientData = {
            isLoading: ref(false),
            isError: ref(false),
            isBan: ref(false),
            data: ref([
                {
                    term: '会社名/個人',
                    description: ''
                }, {
                    term: '部署名',
                    description: ''
                }, {
                    term: '役職名',
                    description: ''
                }, {
                    term: 'お名前',
                    description: ''
                }, {
                    term: 'フリガナ',
                    description: ''
                }, {
                    term: '住所',
                    description: ''
                }, {
                    term: '電話番号',
                    description: ''
                }, {
                    term: 'メールアドレス',
                    description: ''
                }
            ]),
            options: ref([
                {
                    term: '利用サービス',
                    description: dataServices
                }
            ]),
            meta: reactive({
                request: '',
                approve: ''
            })
        };

        /**
         * alertModal - アラートモーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const alertModal = {
            id: 'alert-modal',
            isLoading: ref(false),
            isExpanded: ref(false),
            appearance: 'alert',
            closable: false,
            title: 'アカウントを停止しますか？',
            description: '停止したアカウントはサービス利用不可になります。',
            ariaDescribedby: 'alert-modal-description'
        };

        /**
         * confirmModal - 確認モーダル情報
         * @type {Object}
         * @property {String}       id              - モーダルのID名
         * @property {ref<Boolean>} isLoading       - 読み込みフラグ
         * @property {ref<Boolean>} isExpanded      - モーダル起動フラグ
         * @property {String}       appearance      - モーダルの表示形式
         * @property {Boolean}      closable        - 背景クリック・Escキー押下で閉じられるか
         * @property {String}       title           - 表示するタイトルの文字
         * @property {String}       description     - 表示する説明文
         * @property {String}       ariaDescribedby - aria-describedby属性値
         */
        const confirmModal = {
            id: 'alert-modal',
            isLoading: ref(false),
            isExpanded: ref(false),
            appearance: 'confirm',
            closable: false,
            title: 'アカウントの停止を解除しますか？',
            description: '停止を解除すると、アカウントは再びサービス利用可能になります。',
            ariaDescribedby: 'alert-modal-description'
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * getClientAccounts - APIからクライアントアカウント一覧を取得
             * @param {Object} params - 送信するパラメータ
             * @returns {Object<{ accounts: Array, meta: Object, isBan: Boolean }>}
             */
            async getClientAccounts() {
                const {clientId} = $route.params;
                const result = {};

                await axios.get(`/api/v2/admin_account/clients/${clientId}`, {
                    headers: store.state.auth,
                    params: {
                        includes: ['client_accounts', 'data_services']
                    }
                }).then((response) => {
                    const {client} = response.data;
                    const {data_services: services} = client;
                    const [account] = client.client_accounts;

                    result.isBan = account.ban_status;
                    result.meta = {
                        request: account.confirmation_sent_at ? new Date(account.confirmation_sent_at).toLocaleDateString() : '',
                        approve: account.confirmed_at ? new Date(account.confirmed_at).toLocaleDateString() : ''
                    };
                    result.account = [
                        {
                            term: '会社名/個人',
                            description: client.name
                        }, {
                            term: '部署名',
                            description: client.department
                        }, {
                            term: '役職名',
                            description: client.position
                        }, {
                            term: 'お名前',
                            description: client.contact_name
                        }, {
                            term: 'フリガナ',
                            description: client.furigana
                        }, {
                            term: '住所',
                            description: client.address
                        }, {
                            term: '電話番号',
                            description: client.tel
                        }, {
                            term: 'メールアドレス',
                            description: account.email
                        }
                    ];
                    result.services = services.map((service) => service.service_code);
                });

                return result;
            },
            /**
             * getClientAccountData - クライアントアカウント詳細を取得・データ更新
             * @param {Object} targetData - 更新するデータ
             * @returns {Void}
             */
            getClientAccountData(targetData) {
                // すでに読み込み中の場合は取得しない
                if (targetData.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                targetData.isLoading.value = true;

                methods.getClientAccounts().then((response) => {
                    const {
                        account, isBan, meta, services
                    } = response;

                    // データを更新
                    targetData.data.value = account;
                    targetData.isBan.value = isBan;
                    dataServices.services = services;
                    Object.assign(targetData.meta, meta);
                }).catch(() => {
                    // エラーフラグを更新
                    targetData.isError.value = true;
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    targetData.isLoading.value = false;
                });
            },
            /**
             * setBanStatus - アカウント停止処理
             * @returns {Void}
             */
            setBanStatus() {
                const {clientId} = $route.params;

                // 読み込みフラグを更新（読み込み中）
                alertModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${clientId}/set_ban_status`,
                    {ban_status: true},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    alertModal.isExpanded.value = false;

                    $.emit('displayToast', {
                        message: 'アカウントを停止しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // データを再読み込み
                    methods.getClientAccountData(clientData);
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    alertModal.isLoading.value = false;
                });
            },
            /**
             * setApprovalStatus - アカウント停止処理
             * @returns {Void}
             */
            setApprovalStatus() {
                const {clientId} = $route.params;

                // 読み込みフラグを更新（読み込み中）
                confirmModal.isLoading.value = true;

                axios.patch(
                    `/api/v2/admin_account/clients/${clientId}/set_ban_status`,
                    {ban_status: false},
                    {headers: store.state.auth}
                ).then(() => {
                    // モーダルを閉じる
                    confirmModal.isExpanded.value = false;

                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'アカウントの停止を解除しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // データを再読み込み
                    methods.getClientAccountData(clientData);
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    confirmModal.isLoading.value = false;
                });
            },
            /**
             * getServices - サービス一覧取得
             * @return {Promise}
             */
            async getServices() {
                const result = [];

                await axios.get('/api/v2/common/data_services').then((response) => {
                    const {data_services: services} = response.data;

                    result.push(...services.map((service) => ({
                        id: `data-service-${service.id}`,
                        label: service.service_name,
                        value: service.service_code,
                        image: require(`@/assets/images/img-service-${service.service_code.replaceAll('_', '')}.png`) // eslint-disable-line
                    })));
                });

                return result;
            }
        };

        // 初期読み込み時の処理（アカウントデータの取得）
        onMounted(() => {
            methods.getClientAccountData(clientData);

            // サービス一覧を取得
            methods.getServices().then((response) => {
                dataServices.items = response;
            });
        });

        return {
            clientData, breadcrumbs, alertModal, confirmModal, methods
        };
    }
});
</script>
