<template>
    <div
        class="a-toast-item"
        :class="{
            'a-toast-item--error': isError
        }"
        :aria-atomic="$attrs['aria-atomic'] ? 'true' : undefined"
        :hidden="$attrs.hidden"
    >
        <span v-if="$slots.icon" class="a-toast-item__icon"><slot name="icon" /></span>
        {{message}}
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isError: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        }
    },
    setup() {}
});
</script>

<style lang="scss" scoped>
.a-toast-item {
    display: inline-flex;
    align-items: center;
    min-height: 46px;
    width: 340px;
    border-radius: 2px;
    background: var.$color-success-50;
    font-size: 1.4rem;
    color: var.$color-text-white;
    padding: 8px 16px;
    visibility: visible;
    transition: transform .5s ease 0s, opacity .5s ease 0s, visibility .5s ease 0s;

    &--error {
        background: var.$color-danger-50;
    }

    &[hidden] {
        visibility: hidden;
        opacity: 0;
        transform: translateX(100%);
    }

    @at-root {
        .a-toast-item__icon {
            display: flex;
            padding-right: 16px;
        }
    }
}
</style>
