<template>
    <header id="header" class="t-header">
        <standard-button v-if="!$router.currentRoute.value.path.endsWith('/reset-password')" appearance="link" :to="{name: 'ClientAgreement'}">
            アカウントをお持ちでない場合
            <template v-slot:after><icon name="Rightwards" /></template>
        </standard-button>
    </header>

    <modal
        v-bind="serverErrorModal"
        :id="serverErrorModal.id"
        :is-expanded="serverErrorModal.isExpanded.value"
        @close="$emit('closeModal', $event)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="serverErrorModal.title" v-bind="modalHeaderProps.header" />
        </template>

        <div :id="serverErrorModal.ariaDescribedby">
            <p v-for="desc in serverErrorModal.description" :key="desc">{{desc}}</p>
        </div>

        <template v-slot:footer>
            <modal-footer>
                <button-group layout="end">
                    <standard-button appearance="primary" @click="$emit('closeModal', $event)">OK</standard-button>
                </button-group>
            </modal-footer>
        </template>
    </modal>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';

export default defineComponent({
    inheritAttrs: false,
    components: {
        ButtonGroup, StandardButton, Icon, Modal, ModalHeader, ModalFooter
    },
    props: {
        serverErrorModal: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-header {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100vw;
    min-width: 1280px;
    max-width: 1920px;
    height: 158px;
    padding: 40px 44px 40px 40px;
    justify-content: flex-end;
}
</style>
