<template>
    <div class="a-choice-input-field">
        <div
            v-for="index of fields"
            :key="index"
            class="a-choice-input-field__input"
        >
            <input
                :ref="`field-${index}`"
                v-bind="$attrs"
                type="text"
                class="a-choice-input-field__field"
                :class="{'is-error': isError}"
                :value="modelValue[index - 1]"
                @keydown.enter.prevent="methods.addField($event, $refs, index)"
                @keydown.delete="$event.target.value === '' ? methods.removeField($event, $refs, index - 1) : undefined"
                @input="methods.input($event.target.value, index - 1)"
            />
            <button type="button" class="a-choice-input-field__remove" :disabled="fields === 1 || $attrs.disabled || $attrs.readonly" @click="methods.removeField($event, $refs, index - 1)"><span class="u-altText">{{index}}番目の選択肢を削除</span></button>
        </div>
        <button v-if="!$attrs.disabled && !$attrs.readonly" type="button" class="a-choice-input-field__add" @click="methods.addField($event, $refs, fields)">選択肢を追加</button>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, watch, nextTick
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        limit: {
            type: Number,
            default: 20
        },
        modelValue: {
            type: Array,
            default: () => ['']
        },
        isError: {
            type: Boolean,
            default: false
        }
    },
    setup(props, $) {
        const field = ref(null);
        const fields = ref(props.modelValue.length || 1);
        const methods = {
            input(value, index) {
                const model = [].concat(props.modelValue);

                model[index] = value;

                $.emit('update:model-value', model);
            },
            addField(event, refs, index) {
                const model = [].concat(props.modelValue);

                if (model.length >= props.limit || event.isComposing) {
                    return;
                }

                model.splice(index, 0, '');

                $.emit('update:model-value', model);

                nextTick(() => {
                    Object.values(refs)[index][0].focus();
                });
            },
            removeField(event, refs, index) {
                const model = [].concat(props.modelValue);
                const {type} = event;

                // 入力欄が1個かそれ以下の場合は何もしない
                if (model.length <= 1) {
                    return;
                }

                $.emit('update:model-value', model.filter((_, idx) => idx !== index));

                if (type === 'keydown') {
                    event.preventDefault();

                    nextTick(() => {
                        Object.values(refs)[index === 0 ? 0 : index - 1][0].focus();
                    });
                }
            }
        };

        watch(() => props.modelValue, (value) => {
            fields.value = value.length;
        });

        return {fields, field, methods};
    }
});
</script>

<style lang="scss" scoped>
.a-choice-input-field {
    width: 100%;

    @at-root {
        .a-choice-input-field__input {
            display: flex;
            align-items: center;

            &:not(:first-child) {
                margin-top: 8px;
            }
        }

        .a-choice-input-field__remove {
            position: relative;
            padding: 0;
            width: 48px;
            height: 48px;
            overflow: hidden;
            margin-left: 8px;

            &::before,
            &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: block;
                content: "";
                width: 19px;
                height: 2px;
                margin: auto;
                border-radius: 2px;
                background: var.$color-text-medium;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }

            &[disabled] {
                opacity: .6;

                &::before,
                &::after {
                    background: var.$color-text-disabled;
                }
            }
        }

        .a-choice-input-field__field {
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-medium;
            background: var.$color-utils-background;
            width: 100%;
            height: 48px;
            padding: 12px 16px;
            transition: background-color .3s ease 0s, border-color .3s ease 0s;

            &:hover {
                background: #F2F6F9;
            }

            &:focus {
                border-color: var.$color-primary-50;
                background: var.$color-utils-background;
                outline: none;
            }

            &.is-error {
                border-color: var.$color-danger-50;
            }

            &[readonly] {
                background: var.$color-gray-10;
            }

            &[disabled] {
                color: var.$color-text-disabled;
                border-color: var.$color-gray-10;
                background: var.$color-gray-10;
                opacity: .6;
            }
        }

        .a-choice-input-field__add {
            position: relative;
            color: var.$color-primary-50;
            font-size: 1.6rem;
            padding: 2px;
            padding: 0 0 0 28px;
            margin-top: 8px;
            transition: color .3s ease 0s;

            @include mixin.hover {
                color: var.$color-primary-30;
            }

            &::before,
            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2px;
                display: block;
                content: "";
                width: 16px;
                height: 2px;
                margin: auto;
                border-radius: 2px;
                background: currentColor;
            }

            &::after {
                transform: rotate(90deg);
            }
        }
    }
}
</style>
