<template>
    <div
        class="a-banner"
        :class="{
            [`a-banner--${appearance}`]: true,
            'a-banner--with-icon': $slots.icon
        }"
    >
        <span v-if="$slots.icon" class="a-banner__icon"><slot name="icon" /></span>

        <div class="a-banner__content">
            <p v-if="title !==''" class="a-banner__title"><em>{{title}}</em></p>
            <slot />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        appearance: {
            type: String,
            required: true,
            aridator: (value) => ['information', 'danger', 'warning', 'success'].includes(value)
        },
        title: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss">
.a-banner {
    position: relative;
    padding: 16px;
    border-radius: 2px;

    + :not(:root) {
        margin-top: 24px;
    }

    &--information {
        background: var.$color-information-10;
        color: var.$color-information-50;
    }

    &--danger {
        background: var.$color-danger-10;
        color: var.$color-danger-50;
    }

    &--warning {
        background: var.$color-warning-10;
        color: var.$color-warning-50;
    }

    &--success {
        background: var.$color-success-10;
        color: var.$color-success-50;
    }

    &--with-icon {
        padding-left: 56px;
    }

    @at-root {
        .a-banner__icon {
            position: absolute;
            top: 18px;
            left: 18px;
            display: flex;
        }

        .a-banner__content {
            font-size: 1.4rem;
            line-height: (22 / 14);
            color: var.$color-text-medium;

            > :not(:first-child) {
                margin-top: 8px;
            }

            ul > li {
                position: relative;
                padding-left: 16px;

                &::before {
                    display: block;
                    position: absolute;
                    top: 9px;
                    left: 6px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    content: "";
                    background: currentColor;
                }
            }
        }

        .a-banner__title {
            font-size: 1.6rem;
            font-weight: bold;
            font-style: normal;
            line-height: (24 / 16);
            color: var.$color-text-high;
        }
    }
}
</style>
