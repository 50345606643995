<template>
    <main class="t-main">
        <section-card title="サービス利用申請">
            <template v-slot:utils>
                <step-list v-bind="stepList" :current-step="stepList.currentStep.value" />
            </template>

            <definition-list :items="forms.client">
                <template v-slot:item-0="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name].map((value) => services.find((service)=> service.value === value).label).join('、')}}</definition-list-description>
                </template>
                <template v-slot:item-1="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-2="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-3="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-4="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-5="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-6="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-7="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
                <template v-slot:item-8="itemProps">
                    <definition-list-term>{{itemProps.term.text}}</definition-list-term>
                    <definition-list-description>{{models.client[itemProps.description.name]}}</definition-list-description>
                </template>
            </definition-list>

            <template v-slot:footer>
                <button-group layout="end">
                    <standard-button :disabled="forms.isLoading.value" @click="$router.back()">前へ戻る</standard-button>
                    <standard-button :is-loading="forms.isLoading.value" appearance="primary" @click="$emit('create-client', models.client)">申請する</standard-button>
                </button-group>
            </template>
        </section-card>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {SectionCard} from '@/components/01_Atoms/Cards';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import StepList from '@/components/01_Atoms/StepLists';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';

export default defineComponent({
    inheritAttrs: false,
    emits: ['create-client'],
    components: {
        SectionCard, DefinitionList, DefinitionListTerm, DefinitionListDescription, StepList, ButtonGroup, StandardButton
    },
    props: {
        stepList: {
            type: Object,
            default: () => {}
        },
        forms: {
            type: Object,
            default: () => {}
        },
        models: {
            type: Object,
            default: () => {}
        },
        services: {
            type: Array,
            default: () => []
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 64px 24px;
}
</style>
