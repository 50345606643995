<script>
// import composition-api.
import {defineComponent, h} from 'vue';
import {RouterLink} from 'vue-router';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    components: {Icon},
    props: {
        appearance: {
            type: String,
            default: 'default',
            validator: (value) => (['default', 'primary', 'secondary', 'link', 'danger', 'warning'].includes(value))
        },
        spacing: {
            type: String,
            default: 'default',
            validator: (value) => (['default', 'compact', 'fill'].includes(value))
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    setup(props, $) {
        const isLink = props.appearance === 'link';

        return () => h(
            isLink ? RouterLink : 'button',
            {
                ...$.attrs,
                type: isLink ? null : $.attrs.type || 'button',
                to: isLink ? $.attrs.to : undefined,
                'aria-disabled': isLink && ($.attrs.disabled === '' || $.attrs.disabled === true) ? 'true' : undefined,
                disabled: !isLink ? $.attrs.disabled : undefined,
                event: isLink && $.attrs.disabled !== undefined ? '' : 'click',
                class: {
                    'a-button': true,
                    [`a-button--${props.appearance}`]: props.appearance !== 'default',
                    [`a-button--${props.spacing}`]: props.spacing !== 'default',
                    'is-loading': props.isLoading
                }
            },
            (() => {
                const slots = [
                    h('span', {class: 'a-button__inner'}, [
                        typeof $.slots.before === 'function' ? h('span', {class: 'a-button__before'}, $.slots.before()) : null,
                        h('span', {class: 'a-button__text'}, [$.slots.default()]),
                        typeof $.slots.after === 'function' ? h('span', {class: 'a-button__after'}, $.slots.after()) : null
                    ])
                ];

                if (props.isLoading) {
                    slots.push(
                        h('span', {class: 'a-button__loader'}, [
                            h('span', {class: 'a-button__spinner'}, [h(Icon, {name: 'Spinner'})]),
                            h('span', {class: 'u-altText'}, '読み込み中')
                        ])
                    );
                }

                return isLink ? () => slots : slots;
            })()
        );
    }
});
</script>

<style lang="scss" scoped>
.a-button {
    position: relative;
    padding: 10px 16px;
    font-size: 1.6rem;
    letter-spacing: .035em;
    background: rgba(#F2F6F9, .6);
    color: #505768;
    border-radius: 2px;
    transition: background .3s ease 0s;

    &--primary {
        color: #fff;
        background: #6490AE;

        .client-login & {
            color: var.$color-text-medium;
            background: var.$color-primary-20;

            &:not(.is-loading) {
                @include mixin.hover {
                    background: var.$color-primary-10;
                }
            }
        }

        &:not(.is-loading) {
            @include mixin.hover {
                background: #ABD5E6;
            }
        }
    }

    &--secondary {
        color: #6490AE;
        background: #fff;

        &:not(.is-loading) {
            @include mixin.hover {
                background: rgba(#E5F7FA, .5);
            }
        }
    }

    &--link {
        display: inline-flex;
        color: #6490AE;
        background: transparent;
        text-decoration: none;

        @include mixin.hover {
            background: transparent;

             .a-button__text {
                text-decoration: underline;
             }
        }
    }

    &--danger {
        color: #fff;
        background: #E14350;

        &:not(.is-loading) {
            @include mixin.hover {
                background: #F6988E;
            }
        }
    }

    &--warning {
        color: #fff;
        background: #FCBF16;

        &:not(.is-loading) {
            @include mixin.hover {
                background: #FEE072;
            }
        }
    }

    &--compact {
        padding: 6px 16px;
    }

    &--fill {
        width: 100%;
        justify-content: center;
    }

    &.is-loading {
        cursor: default;

        .a-button__inner {
            visibility: hidden;
        }
    }

    &[disabled] {
        cursor: default;
    }

    &[disabled],
    &--primary[disabled],
    &--danger[disabled],
    &--warning[disabled] {
        background: var.$color-gray-20;
        opacity: .6;

        @include mixin.hover {
            background: var.$color-gray-20;
        }
    }

    &--secondary[disabled],
    &--link[aria-disabled="true"] {
        opacity: .6;
        color: var.$color-text-disabled;
        background: #fff;

        @include mixin.hover {
            background: #fff;

            .a-button__text {
                text-decoration: none;
             }
        }
    }

    @at-root {
        .a-button__inner {
            display: inline-flex;
            align-items: center;
        }

        .a-button__loader {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
        }

        .a-button__spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            animation: .86s linear 0s infinite normal none running animation-loading-spinner;
        }

        .a-button__text {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 64px;
            min-height: 24px;

            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        .a-button__after {
            margin-left: 8px;
        }

        .a-button__before,
        .a-button__after {
            display: flex;
        }
    }
}
</style>
