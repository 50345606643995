<template>
    <client-header
        :is-login="false"
        :server-error-modal="serverErrorModal"
        @close-modal="$emit('close-error-modal', $event)"
    />
    <router-view />
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {ClientHeader} from '@/components/04_Templates/_shared/Header';

export default defineComponent({
    inheritAttrs: false,
    components: {
        ClientHeader
    },
    props: {
        serverErrorModal: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>
