<template>
    <form class="a-form">
        <slot name="header" />
        <div class="a-form__section"><slot /></div>
        <slot name="footer" />
    </form>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({});
</script>

<style lang="scss">
.a-form {

    @at-root {
        .a-form__section {
            padding: 24px 0;

            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
}
</style>
