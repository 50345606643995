<template>
    <main class="t-main">
        <div class="t-main__image"></div>
        <div class="t-main__form">
            <forms @submit.prevent="$emit('login', $event)">
                <template v-slot:header>
                    <form-header v-bind="forms.header" />

                    <banner v-if="formErrors.system.value.length" appearance="danger">
                        <template v-slot:icon><icon name="Error" /></template>
                        <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
                    </banner>
                </template>

                <field v-bind="forms.email">
                    <template v-slot:field="fieldProps">
                        <text-field
                            v-bind="fieldProps.field"
                            :is-error="formErrors.login.email !== undefined"
                            :aria-errormessage="`${fieldProps.field.id}-invalid`"
                            :aria-required="fieldProps.field.isRequire"
                            :model-value="models.login.email"
                            @update:model-value="$emit('update:email', $event)"
                        >
                            <template v-slot:action>
                                <button type="button" :disabled="models.login.email === ''" @click="$emit('set-model-value', 'email', '')"><icon name="Close" /></button>
                            </template>
                        </text-field>
                        <form-message v-if="formErrors.login.email" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.login.email"><template v-slot:icon><icon name="Error" /></template></form-message>
                    </template>
                </field>

                <field v-bind="forms.password">
                    <template v-slot:field="fieldProps">
                        <text-field
                            v-bind="fieldProps.field"
                            :is-error="formErrors.login.password !== undefined"
                            :aria-errormessage="`${fieldProps.field.id}-invalid`"
                            :aria-required="fieldProps.field.isRequire"
                            :model-value="models.login.password"
                            @update:model-value="$emit('update:password', $event)"
                        >
                            <template v-slot:action="fieldActionProps">
                                <button
                                    type="button"
                                    aria-pressed="false"
                                    @click="
                                        (fieldActionProps.action.element.type = fieldActionProps.action.element.type === 'text' ? 'password' : 'text') &
                                        ($event.currentTarget.setAttribute('aria-pressed', fieldActionProps.action.element.type === 'text')) &
                                        ($refs.icon.$.props.name = fieldActionProps.action.element.type === 'text' ? 'Visibility' : 'Visibility Off')
                                    "
                                ><icon ref="icon" name="Visibility Off" /></button>
                            </template>
                        </text-field>
                        <form-message v-if="formErrors.login.password" :id="`${fieldProps.field.id}-invalid`" appearance="danger" :message="formErrors.login.password"><template v-slot:icon><icon name="Error" /></template></form-message>
                    </template>
                </field>

                <template v-slot:footer>
                    <form-footer>
                        <button-group>
                            <standard-button appearance="primary" type="submit" spacing="fill" :is-loading="forms.isLoading.value"><b>LOGIN</b></standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>

            <router-link :to="{name: 'ClientReissuePassword'}">パスワードを忘れた場合</router-link>

            <ul class="t-links">
                <li><router-link :to="{name: 'ClientTerms'}">サービス利用規約</router-link></li>
            </ul>
        </div>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Forms, {
    FormHeader, FormFooter, Field, FormMessage
} from '@/components/01_Atoms/Forms';
import {TextField} from '@/components/01_Atoms/TextFields';

export default defineComponent({
    inheritAttrs: false,
    emits: ['set-model-value', 'login', 'update:email', 'update:password'],
    components: {
        Banner, ButtonGroup, StandardButton, Icon, Forms, FormHeader, FormFooter, Field, FormMessage, TextField
    },
    props: {
        models: {
            type: Object,
            default: () => ({})
        },
        forms: {
            type: Object,
            default: () => ({})
        },
        formErrors: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    min-height: 100vh;

    @at-root {
        .t-main__image {
            flex-basis: 54%;
            background-image: url(/img/bg-login-01.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: var.$color-gray-10;
        }

        .t-main__form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 46%;
            padding: 158px 24px 94px;
            max-width: 480px;
            margin: auto;

            a {
                margin-top: 32px;
            }
        }
    }
}

.t-links {
    margin-top: 32px;
    color: var.$color-text-disabled;
    font-size: 1.4rem;
    line-height: (20 / 14);

    ::v-deep(a) {
        color: inherit;
    }
}
</style>
