<template>
    <router-link
        :to="$attrs.to"
        class="a-breadcrumbs-item"
    >
        <span v-if="$slots.before" class="a-breadcrumbs-item__before"><slot name="before" /></span>
        <span class="a-breadcrumbs-item__text"><slot /></span>
        <span v-if="$slots.after" class="a-breadcrumbs-item__after"><slot name="after" /></span>
    </router-link>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss" scoped>
.a-breadcrumbs-item {
    display: inline-flex;
    align-items: center;
    padding: 2px 1px;
    font-size: 1.4rem;
    line-height: 1;
    color: var.$color-text-disabled;
    border-radius: 2px;
    text-decoration: none;

    &[aria-current] {
        color: var.$color-text-high;
    }

    @include mixin.hover {
        text-decoration: underline;
    }

    @at-root {
        .a-breadcrumbs-item__text {

            &:not(:first-child) {
                margin-left: 8px;
            }
        }

        .a-breadcrumbs-item__before,
        .a-breadcrumbs-item__after {
            display: flex;
        }

        .a-breadcrumbs-item__after {
            margin-left: 8px;
        }
    }
}
</style>
