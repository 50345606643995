<template>
    <nav class="a-breadcrumbs" aria-label="パンくずリスト">
        <ol class="a-breadcrumbs__list">
            <li
                v-for="item in breadcrumbs"
                :key="item"
                class="a-breadcrumbs__item"
            >
                <slot name="item" :breadcrumbs="{...item}" />
            </li>
        </ol>
    </nav>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        breadcrumbs: {
            type: Array,
            default: () => ([
                {label: 'Item 1', to: {path: '/'}, beforeIcon: 'Company'},
                {label: 'Item 2', to: {path: '/'}},
                {label: 'Item 3', to: {path: '/'}, afterIcon: 'Overview'}
            ])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-breadcrumbs {

    &:not(:first-child) {
        margin-top: 24px;
    }

    @at-root {
        .a-breadcrumbs__list {
            display: flex;
            flex-wrap: wrap;
        }

        .a-breadcrumbs__item {
            position: relative;
            display: flex;

            &:not(:first-child) {
                margin-left: 8px;
                padding-left: 14px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 7px;
                    left: 3px;
                    display: block;
                    width: 1px;
                    height: 12px;
                    background: var.$color-text-medium;
                    transform: rotate(24deg);
                }
            }
        }
    }
}
</style>
