<template>
    <main class="t-main">
        <section-card title="サービス利用申請">
            <template v-slot:utils>
                <step-list v-bind="stepList" :current-step="stepList.currentStep.value" />
            </template>

            <div class="t-main__content">
                <h2>サービス利用規約</h2>

                <p>本サービス規約(以下「本規約」といいます) は、キャッシュビーデータ株式会社（以下「当社」といいます）が提供する本書の第1条に記載する各サービス（以下「本サービス」といいます）のご利用に適用されますので、必ず本規約をご確認してください。</p>

                <h3>第1条 適用範囲</h3>
                <ol>
                    <li data-mark="1.">本規約は、次のサービスの利用に関して、お客様と当社との間の権利義務関係を定めて、お客様と当社の間の本サービスの利用に関わるすべての事項に適用されます。
                        <div>
                            <ol>
                                <li data-mark="(1)">賢い買物スキルの生活者アンケート</li>
                                <li data-mark="(2)">スキップされないターゲット広告</li>
                                <li data-mark="(3)">CASHb DATA BOX（但し、データ連携サービスは除く）</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="2.">第1条の1項の（1）賢い買物スキルの生活者アンケートおよび（2）スキップされないターゲット広告において、レシート撮影による購買確認付きのサービスや機能の追加を必要とするサービスをご利用する場合には、本規約の摘要対象外として別途契約を結ぶこととします。</li>
                    <li data-mark="3.">第1条の1項の（3）CASHb DATA BOXのサービスについては、当社と個別契約を締結することにより、当該サービス用のアカウント（以下「追加アカウント」といいます）の登録が可能となります。</li>
                    <li data-mark="4.">当社が当社ウェブサイト（当社運営のドメインhttps://www.cashb-data.co.jp/）上で掲載する本サービスに関するルール、諸規定等（本規約と矛盾抵触する内容を除く）は本規約の一部を構成するものとします。</li>
                    <li data-mark="5.">当社が当社ウェブサイトで提供する掲載記事、ダウンロード記事、ダウンロードしたデータについても、本規約が適用されるものとします。</li>
                </ol>

                <h3>第2条 本規約への同意</h3>
                <ol>
                    <li data-mark="1.">お客様は、本サービスのアカウント登録もしくは本サービスのご利用により、本規約および当社ウェブサイトで掲載する個人情報保護方針に同意されたものとします。</li>
                </ol>

                <h3>第3条 本サービスへの登録</h3>
                <ol>
                    <li data-mark="1.">本サービスを利用するお客様は、本サービスにアカウントを登録することが必要となります。</li>
                    <li data-mark="2.">本サービスを利用するお客様は、本規約を遵守することに同意し、かつ当社の定める登録情報を当社の定める方法で当社に提供することにより、本サービスの利用を申請することができます。</li>
                    <li data-mark="3.">第1条の1項の（3）CASHb DATA BOXのサービスについては、当社と個別契約を締結することにより、当該サービス用のアカウント（以下「追加アカウント」といいます）の登録が可能となります。</li>
                    <li data-mark="4.">当社は、第3条の1項に基づき登録を申請したお客様が、以下の各項目のいずれかの事由に該当する場合は、登録を拒否することができます。
                        <div>
                            <ol>
                                <li data-mark="(1)">当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</li>
                                <li data-mark="(2)">未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</li>
                                <li data-mark="(3)">反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていると当社が判断した場合</li>
                                <li data-mark="(4)">その他、当社が登録を適当でないと判断した場合</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="5.">当社は、前項その他当社の基準に従って、お客様の登録の可否を判断し、当社が登録を認める場合にはその旨をお客様に通知し、パスワードを発行します。</li>
                    <li data-mark="6.">お客様は、登録したID（以下「ユーザID」といいます）とパスワードを使用して、本サービスを利用することができます。（3）CASHb DATA BOXのサービスを利用するに当たっては、第3項に定める追加アカウントによるログインが必要となります。</li>
                    <li data-mark="7.">お客様は、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から依頼した資料を提出するものとします。</li>
                </ol>

                <h3>第4条 利用条件</h3>
                <ol>
                    <li data-mark="1.">お客様は、本サービス（第1条の1項）に登録したアカウントの有効期間中、本規約に従って、当社の定める方法に従って、本サービスを利用することができます。</li>
                    <li data-mark="2.">本サービスを利用できるお客様は、各サービスによって次に定めます。
                        <div>
                            <ol>
                                <li data-mark="(1)">「賢い買物スキルの生活者アンケート」は、登録されたお客様のみ</li>
                                <li data-mark="(2)">「スキップされないターゲット広告」は、登録されたお客様のみ</li>
                                <li data-mark="(3)">「CASHb DATA BOX」は、契約書に記載された人数</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="3.">「賢い買物スキルの生活者アンケート」及び「スキップされないターゲット広告」の仕様は、当社ウェブサイト等に別途定めるものとし、当社サービスの利用の際に表示されるものとします。なお、仕様の内容については、予告なく変更する場合があります。</li>
                    <li data-mark="4.">ユーザIDの共有、譲渡、及びその他の移転はできないものとします。</li>
                    <li data-mark="5.">前項にかかわらず、お客様は、本サービスの使用によって得られたレポートもしくはデータの一部もしくは全部を第三者に開示する場合、第20条に従い開示することができるものとします。ただし、次の各号に該当する場合は、あらかじめ当社の書面による承諾を得なければなりません。また、いかなる場合でも、第三者に開示する場合には著作権の表示をするものとします。
                        <div>
                            <ol>
                                <li data-mark="(1)">広告代理店、市場調査会社、コンサルティング会社等、当社と競合すると認められる第三者に対し開示する場合</li>
                                <li data-mark="(2)">第三者に対し、本サービスの使用によって得られたレポートもしくはデータの一部もしくは全部を定期的または継続的に開示する場合</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="6.">当社は、お客様が本条の規定に違反した場合、お客様の違反行為を差し止めることができ、本サービスの提供を停止します。また、お客様が、自己の違反行為により当社または第三者に対し損害を与えた場合は、自己の責任と費用をもって解決するものとします。</li>
                    <li data-mark="7.">お客様は、本サービスの使用によって得られたレポートもしくはデータを自己の責任において利用するものとします。なお、当社は、お客様が第三者へ開示する本サービスの使用によって得られたレポートもしくはデータの説明等について、一切の責任を負いません。</li>
                    <li data-mark="8.">当社は、当社の裁量により、本サービスに新しいサービスを追加、変更、終了させることがあり、お客様はあらかじめ承諾したうえで本サービスを利用します。</li>
                    <li data-mark="9.">お客様は、自己の責任と負担により適宜バックアップを行うものとし、当社はお客様に対しバックアップを行う義務を負わないものとします。</li>
                </ol>

                <h3>第5条 料金及び支払方法</h3>
                <ol>
                    <li data-mark="1.">お客様は、本サービス利用の対価として、当社が第1条の1項に定めるサービス毎に、当社ウェブサイト等で別途定める利用料金を負担するものとします。</li>
                    <li data-mark="2.">利用料金は、本サービスの利用の際に当社が計算し、お客様に通知します。お客様は、利用料金を当社が指定する期日までに、当社の指定する方法で支払うものとします。なお、振込手数料その他支払に必要な費用はお客様の負担とします。</li>
                    <li data-mark="3.">お客様が利用料金の支払を遅滞した場合、お客様は利用料金の20%を遅延損害金として当社に支払うものとします。</li>
                    <li data-mark="4.">利用料金は、お客様に事前に承認を得ることなく変更されることがあります。その際は当社所定の方法にて、事前に通知を行うものとします。お客様が本サービスの利用を継続した場合、お客様は利用料金の変更を承諾したものとみなします。</li>
                </ol>

                <h3>第6条 パスワード及びユーザIDの管理</h3>
                <ol>
                    <li data-mark="1.">お客様は、自己の責任において、パスワード及びユーザIDを管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
                    <li data-mark="2.">パスワード又はユーザIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザが負うものとし、当社は一切の責任を負いません。</li>
                    <li data-mark="3.">お客様は、パスワード又はユーザIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</li>
                </ol>

                <h3>第7条 禁止事項</h3>
                <ol>
                    <li data-mark="1.">お客様は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。
                        <div>
                            <ol>
                                <li data-mark="(1)">当社又は第三者の知的財産権、著作権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）</li>
                                <li data-mark="(2)">名誉毀損、誹謗中傷につながる表現、不適切な表現を投稿、アップロード又は拡散する行為</li>
                                <li data-mark="(3)">法令、公序良俗に反する表現、他人を中傷する表現、不適切な表現、露骨な性的表現、人種、社会的身分等による差別につながる表現、その他他人に不快感を与える表現を投稿、アップロード又は拡散する行為</li>
                                <li data-mark="(4)">他者になりすます、虚偽の提携を申告する、あるいは許可なく他者のアカウントにアクセスする、他者のデジタルコンテンツを真似る、不正確なURL、身分証明、あるいはサービスを経由した情報コンテンツを提示する、あるいは類似した詐欺行為</li>
                                <li data-mark="(5)">犯罪行為に関連する行為又は公序良俗に反する行為</li>
                                <li data-mark="(6)">本サービスのソースコード及びそれのあらゆる部分の逆行分析、逆コンパイル、分解、あるいは分析を試みる行為</li>
                                <li data-mark="(7)">当社の同意無しに本サービスを宣伝目的で利用する行為</li>
                                <li data-mark="(8)">当社による本サービスの運営を妨害するおそれのある行為</li>
                                <li data-mark="(9)">その他、当社が不適切と判断する行為</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="2.">当社は、お客様が第7条1項のいずれかに該当し、又は該当するおそれがあると判断した場合には、お客様に事前に通知することなく、利用登録の停止もしくは削除をすることができるものとします。当社は、本項に基づき当社が行った措置に基づきお客様に生じた損害について一切の責任を負いません。</li>
                </ol>

                <h3>第8条 本サービスの停止等</h3>
                <ol>
                    <li data-mark="1.">当社は、以下のいずれかに該当する場合には、登録ユーザに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
                        <div>
                            <ol>
                                <li data-mark="(1)">本サービスに係るシステム及び設備の点検又は保守作業を定期的又は緊急に行う場合</li>
                                <li data-mark="(2)">コンピュータ、通信回線等が事故により停止した場合</li>
                                <li data-mark="(3)">天災地変、戦争、テロ活動等の破壊活動その他の非常理由により本サービスの運営ができなくなった場合</li>
                                <li data-mark="(4)">災害、停電その他当社がコントロールできない事由、その他当社の事情によらない事由により本サービスの運営ができなくなった場合</li>
                                <li data-mark="(5)">当社が使用するコンピュータ、システムの使用ができなくなった場合</li>
                                <li data-mark="(6)">その他、当社が停止又は中断を必要と判断した場合</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="2.">当社は、お客様に事前に通知することなく、毎日データの更新のための処理を行います。また、緊急メンテナンスが突発的に発生する場合があります。それに伴い、データの不整合が発生したり、お客様がデータを見られない時間帯が発生します。</li>
                    <li data-mark="3.">当社は、当社の都合により本サービスの全部又は一部の提供を終了することができます。この場合、当社はお客様に事前に通知するものとし、第1条の1項の（1）賢い買物スキルの生活者アンケートおよび（2）スキップされないターゲット広告については、提供中のサービスにかかる費用を返金します。また、第1条の1項の（3）CASHb DATA BOXについては、契約にしたがって返金します。</li>
                    <li data-mark="4.">当社は、本条に基づき当社が行った措置に基づきお客様に生じた損害について一切の責任を負いません。</li>
                </ol>

                <h3>第9条 設備の負担等</h3>
                <ol>
                    <li data-mark="1.">本サービスの提供を受けるために必要な、コンピュータ、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、お客様の費用と責任において行うものとし、当社は、本サービスにおける動作保証及び使用機器への適合性について一切保証しません。</li>
                    <li data-mark="2.">お客様は自己の本サービスの利用環境に応じて、コンピュータ・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。</li>
                </ol>

                <h3>第10条 権利帰属</h3>
                <ol>
                    <li data-mark="1.">当社ウェブサイト及び本サービスに関するすべてのコンテンツ、文章、画像、データ、ソフトウェア、情報、その他の資料等の所有権及び知的財産権は当社又は当該権利を有する第三者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社の知的財産権の使用許諾を意味するものではありません。</li>
                    <li data-mark="2.">事前に当社又は当該権利を有する第三者が許諾している場合を除いて、本サービスの全ての素材に関して、その一部か全てかを問わず、所有権、著作権を含む一切の知的財産権、肖像権、パブリシティー権等、コンテンツ素材に関する権利を侵害する一切の行為（私的利用、法律で許可された範囲を超えた複製、変更、発表、送付、配布、移動、借用、使用、転用、再利用等）を禁止します。</li>
                </ol>

                <h3>第11条 登録の解約及び取消等</h3>
                <ol>
                    <li data-mark="1.">お客様は、本サービスの操作によってお客様のアカウント登録を削除することができます。但し、お客様が既に当社に支払った本サービスの利用料金は一切返還されません。</li>
                    <li data-mark="2.">前項にかかわらず、第1条の1項の（3）CASHb DATA BOXについては、個別契約の規定が適用されるものとします。</li>
                    <li data-mark="3.">当社は、お客様が、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該お客様について本サービスの利用を一時的に停止し、又はお客様の登録を取り消すことができます。
                        <div>
                            <ol>
                                <li data-mark="(1)">本規約のいずれかの条項に違反した場合</li>
                                <li data-mark="(2)">登録情報に虚偽の事実があることが判明した場合</li>
                                <li data-mark="(3)">支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</li>
                                <li data-mark="(4)">30日以上当社からの連絡に対して応答がない場合</li>
                                <li data-mark="(5)">第3条4項、第7条1項に該当する場合</li>
                                <li data-mark="(6)">その他、当社が登録ユーザとしての登録の継続を適当でないと判断した場合</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="4.">前各号のいずれかの事由に該当した場合、当社は、7日前までに当社所定の方法でお客様に通知することにより、お客様の登録を取り消すことができます。</li>
                    <li data-mark="5.">当社は、前項により本サービスの登録取り消しを受けたお客様に対し、お客様が既に当社に支払った本サービスの利用料金の一切を返還しないものとし、お客様は予めこれに承諾するものとします。</li>
                    <li data-mark="6.">当社は、本条に基づき当社が行った行為によりお客様に生じた損害について一切の責任を負いません。</li>
                </ol>

                <h3>第12条 保証の否認及び免責</h3>
                <ol>
                    <li data-mark="1.">当社は、本サービスにつき如何なる保証も行うものではありません。当社は本サービスについて、お客様の特定の目的への適合性、期待する機能・商業的有用性・正確性・完全性を有すること、継続的に利用できること、及び不具合が生じないこと等を含め、一切保証を致しません。</li>
                    <li data-mark="2.">お客様が当社から直接又は間接に、本サービス、当社ウェブサイト、その他の事項に関する何らかの情報を得た場合であっても、当社はお客様に対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。</li>
                    <li data-mark="3.">お客様は、本サービスを利用することが、お客様に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、お客様による本サービスの利用が、お客様に適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。</li>
                    <li data-mark="4.">本サービスに関連してお客様と第三者との間において生じた取引、連絡、紛争等については、お客様の責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。</li>
                    <li data-mark="5.">当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、お客様の登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連してお客様が被った損害につき、賠償する責任を一切負わないものとします。</li>
                    <li data-mark="6.">当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。</li>
                    <li data-mark="7.">本サービスの利用やアクセスにあたって生じる全てのコンピューターシステム、携帯デバイスへの障害、もしくはデータの欠損やその他の障害について、当社は一切責任を負いません。</li>
                    <li data-mark="8.">本サービスの利用にあたって、お客様がダウンロード、使用、取得するすべてのコンテンツ、資料、情報等は、お客様が自身の判断と責任により利用するものとし、当社は一切責任を負いません。</li>
                    <li data-mark="9.">当社は、当社に故意、重過失がある場合を除き、本サービスに関連してお客様が被った損害について、一切賠償の責任を負いません。法令の適用その他の理由により当社がお客様に対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って過去3か月間の期間にお客様から現実に受領した本サービスの利用料金の総額を上限とします。</li>
                </ol>

                <h3>第13条 秘密保持</h3>
                <ol>
                    <li data-mark="1.">本規約において「秘密情報」とは、利用契約又は本サービスに関連して、お客様が、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、以下の情報については、秘密情報から除外するものとします。
                        <div>
                            <ol>
                                <li data-mark="(1)">当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの</li>
                                <li data-mark="(2)">当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの</li>
                                <li data-mark="(3)">提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの</li>
                                <li data-mark="(4)">秘密情報によることなく単独で開発したもの</li>
                                <li data-mark="(5)">当社から秘密保持の必要なき旨書面で確認されたもの</li>
                            </ol>
                        </div>
                    </li>
                    <li data-mark="2.">お客様は、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。</li>
                    <li data-mark="3.">第12条の2項の定めに関わらず、お客様は、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。</li>
                    <li data-mark="4.">お客様は、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。</li>
                    <li data-mark="5.">当社は、本サービスを利用する際にお客様が用いたデータを、第三者に開示することはありません。但し、当社が本サービスの改善のために、一部を利用させていただく場合がございます。</li>
                </ol>

                <h3>第14条 有効期間</h3>
                <ol>
                    <li data-mark="1.">第1条の1項の（1）賢い買物スキルの生活者アンケートおよび（2）スキップされないターゲット広告は、お客様のアカウント登録が削除されるまで有効であり、該当サービスを利用することができます。</li>
                    <li data-mark="2.">第1条の1項の（3）CASHb DATA BOXは、個別契約に規定の有効期間に従います。</li>
                </ol>

                <h3>第15条 本規約の変更</h3>
                <ol>
                    <li data-mark="1.">当社は、本サービスの内容を自由に変更できるものとします。</li>
                    <li data-mark="2.">当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。</li>
                    <li data-mark="3.">当社は、本規約を変更する場合には、本サービスに登録されているお客様に当該変更内容および効力発生日を定め、当社が規定する方法で事前に通知するものとします。</li>
                </ol>

                <h3>第16条 連絡/通知</h3>
                <ol>
                    <li data-mark="1.">本サービスに関する問い合わせその他お客様から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社からお客様に対する連絡又は通知は、当社の定める方法で行うものとします。</li>
                </ol>

                <h3>第17条 本規約の譲渡</h3>
                <ol>
                    <li data-mark="1.">お客様は、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
                    <li data-mark="2.">当社は、本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにお客様の登録情報その他の情報を当該事業譲渡の譲受人に譲渡することができるものとし、お客様は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
                </ol>

                <h3>第18条 準拠法及び管轄裁判所</h3>
                <ol>
                    <li data-mark="1.">本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
                </ol>

                <h3>第19条 協議解決</h3>
                <ol>
                    <li data-mark="1.">当社及びお客様は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。</li>
                </ol>

                <h3>第20条 第三者開示・利用</h3>
                <ol>
                    <li data-mark="1.">利用目的の範囲を超えて、無断転載や流布などのリスクがある場合は、「Confidential」である旨を記載の上、「無断転載を禁ず」の旨を該当ページに明記してください。</li>
                    <li data-mark="2.">お客様が本サービスをして得られたデータに関する問い合わせは、お客様の責任においてご対応いただくものとします。</li>
                    <li data-mark="3.">第三者への開示・利用する際には、情報の根拠・証憑として当社出典の情報であることを明記してください。</li>
                    <li data-mark="4.">開示する情報の内容として、弊社の名称、お客様が利用した本サービスの名称およびデータもしくはレポートの収集日または期間を明記してください。</li>
                    <li data-mark="5.">本サービスを使用して得られたデータ、レポート等の開示利用により、消費者からクレームが発生した場合は、お客様の責任においてご対応いただくものとします。</li>
                    <li data-mark="6.">本サービスを使用して得られたデータ、レポート等の開示利用により他社からクレームが発生した場合には、当事者間で解決していただくものとします。</li>
                    <li data-mark="7.">本サービスを使用して得られたデータ、レポート等の開示利用により、当社または第三者に損害を与えた場合は、自己の責任と費用により解決していただくものとします。</li>
                </ol>

                <p>2022年7月1日 キャッシュビーデータ株式会社</p>
            </div>

            <template v-slot:footer>
                <standard-button appearance="primary" @click="$router.replace('/client/request/entry')">同意して次へ進む</standard-button>
            </template>
        </section-card>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {SectionCard} from '@/components/01_Atoms/Cards';
import StepList from '@/components/01_Atoms/StepLists';
import {StandardButton} from '@/components/01_Atoms/Buttons';

export default defineComponent({
    inheritAttrs: false,
    emits: [],
    components: {
        SectionCard, StepList, StandardButton
    },
    props: {
        stepList: {
            type: Object,
            default: () => {}
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 64px 24px;

    @at-root {
        .t-main__content {
            font-size: 1.6rem;
            line-height: 1.8;
            letter-spacing: 1px;

            ::v-deep(h2) {
                font-size: 2.4rem;
                text-align: center;
                position: relative;
                padding-bottom: 14px;

                &::after {
                    content: "";
                    width: 100px;
                    height: 2px;
                    position: absolute;
                    top: 34px;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    border-top: 2px solid var.$color-gray-20;
                }

                + :not([class]) {
                    margin-top: 50px;
                }
            }

            ::v-deep(h3) {
                font-size: 2rem;
            }

            ::v-deep(li) {
                position: relative;
                padding-left: 40px;

                &::before {
                    position: absolute;
                    left: 10px;
                    top: -1px;
                    content: attr(data-mark);
                }

                > div {
                    margin-top: 20px;

                    li:not(:first-child) {
                        margin-top: auto;
                    }
                }

                &:not(:first-child) {
                    margin-top: 20px;
                }
            }

            > :not([class]) {
                margin-top: 20px;
            }

            > :last-child {
                text-align: right;
            }
        }
    }
}
</style>
