<template>
    <div class="a-picker" :class="{'is-error': isError, [`a-picker--${place}`]: place !== 'bottom-left'}">
        <div class="a-picker__field">
            <span v-if="$slots.icon" class="a-picker__icon"><slot name="icon" /></span>

            <button
                ref="control"
                :id="id"
                :name="$attrs.name"
                type="button"
                class="a-picker__control"
                :class="{
                    'is-selected': value !== null,
                    'is-readonly': $attrs.readonly
                }"
                :aria-required="isRequired ? 'true' : undefined"
                :aria-controls="`${id}-picker`"
                :aria-expanded="isExpanded"
                aria-haspopup="true"
                :disabled="$attrs.disabled || $attrs.readonly"
                @click="$emit('open', $refs.picker)"
            >{{formattedValue}}</button>

            <span v-if="$slots.action && !$attrs.disabled" class="a-picker__action"><slot name="action" /></span>
        </div>

        <div
            ref="picker"
            :id="`${id}-picker`"
            class="a-picker__input"
            role="dialog"
            aria-modal="true"
            :aria-labelledby="`${id}-title`"
            tabindex="-1"
            :hidden="!isExpanded"
        ><slot name="picker" :picker="{id: `${id}-title`}" /></div>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, computed, watch, nextTick, onMounted
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: '選択してください'
        },
        value: {
            type: Object,
            default: null
        },
        formatType: {
            type: String,
            default: 'default'
        },
        placement: {
            type: String,
            default: 'bottom-left',
            validator: (value) => {
                const [vertical, horizontal] = value.split('-');

                return ['top', 'bottom', 'auto'].includes(vertical) && ['left', 'center', 'right'].includes(horizontal);
            }
        },
        isError: {
            type: Boolean,
            default: false
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const picker = ref(null);
        const control = ref(null);
        const place = ref(props.placement);
        const formattedValue = computed(() => {
            const {value, placeholder} = props;

            return value === null ? placeholder : `${value.getFullYear()}/${`0${value.getMonth() + 1}`.slice(-2)}/${`0${value.getDate()}`.slice(-2)}`;
        });

        onMounted(() => {
            const [vertical, horizontal] = props.placement.split('-');

            // 上下位置の自動調整が必要ではない場合は何もしない
            if (vertical !== 'auto') {
                return;
            }

            // メニューの上下位置監視
            const observer = new window.IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const {isIntersecting} = entry;

                    place.value = isIntersecting ? `bottom-${horizontal}` : `top-${horizontal}`;
                });
            }, {rootMargin: '0px 0px -360px 0px', threshold: 0});

            observer.observe(control.value);
        });

        watch(() => props.isExpanded, (isExpanded) => {
            if (!picker.value || !control.value) {
                return;
            }

            nextTick(() => {
                if (isExpanded) {
                    picker.value.querySelector('[tabindex="0"]').focus();
                } else {
                    control.value.focus();
                }
            });
        });

        return {
            picker, control, place, formattedValue
        };
    }
});
</script>

<style lang="scss" scoped>
.a-picker {
    display: flex;
    position: relative;

    &.is-error .a-picker__control {
        border: solid 1px var.$color-danger-50;
    }

    &--bottom-right {
        justify-content: flex-end;

        .a-picker__input {
            right: 0;
        }
    }

    &--bottom-center {
        justify-content: center;
    }

    &--top-right {
        justify-content: flex-end;

        .a-picker__input {
            top: -4px;
            right: 0;
            bottom: auto;
            transform: translateY(-100%);
        }
    }

    &--top-center {
        justify-content: center;

        .a-picker__input {
            top: -4px;
            bottom: auto;
            transform: translateY(-100%);
        }
    }

    &--top-left .a-picker__input {
        top: -4px;
        bottom: auto;
        transform: translateY(-100%);
    }

    @at-root {
        .a-picker__field {
            display: flex;
            align-items: center;
            position: relative;
            width: 220px;
            min-height: 48px;
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            background: var.$color-utils-background;

            &.is-disabled {
                background: var.$color-gray-10;
                opacity: .6;
            }
        }

        .a-picker__control {
            width: 100%;
            min-height: 46px;
            padding: 0 16px;
            font-size: 1.6rem;
            line-height: (24 / 16);
            text-align: left;
            color: var.$color-text-disabled;

            &.is-selected {
                color: var.$color-text-medium;
            }

            &:not(:first-child) {
                padding-left: 44px;
            }

            &:not(:last-child) {
                padding-right: 44px;
            }

            &[aria-expanded="true"] {
                pointer-events: none;
            }

            &[disabled] {
                cursor: default;
                border-color: var.$color-gray-10;
                background: var.$color-gray-10;

                &:not(.is-readonly) {
                    color: var.$color-text-disabled;
                    opacity: .6;
                }
            }
        }

        .a-picker__icon {
            display: flex;
            align-items: center;
            position: absolute;
            left: 16px;
            top: 0;
            bottom: 0;
            pointer-events: none;
        }

        .a-picker__action {
            display: flex;
            align-items: center;
            position: absolute;
            right: 16px;
            top: 0;
            bottom: 0;

            ::v-deep(button) {
                display: flex;
                position: relative;
                padding: 0;
                color: var.$color-text-medium;
                overflow: hidden;

                &[disabled] {
                    color: var.$color-text-disabled;
                    opacity: .6;
                }
            }
        }

        .a-picker__input {
            position: absolute;
            bottom: -4px;
            transform: translateY(100%);
            border: solid 1px #F2F6F9;
            border-radius: 2px;
            box-shadow: var.$effect-popover-shadow;
            z-index: 10;
        }
    }
}
</style>
