<template>
    <modal
        v-bind="serverErrorModal"
        :id="serverErrorModal.id"
        :is-expanded="serverErrorModal.isExpanded.value"
        @close="$emit('closeModal', $event)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="serverErrorModal.title" v-bind="modalHeaderProps.header" />
        </template>

        <div :id="serverErrorModal.ariaDescribedby">
            <p v-for="desc in serverErrorModal.description" :key="desc">{{desc}}</p>
        </div>

        <template v-slot:footer>
            <modal-footer>
                <button-group layout="end">
                    <standard-button appearance="primary" @click="$emit('closeModal', $event)">OK</standard-button>
                </button-group>
            </modal-footer>
        </template>
    </modal>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';

export default defineComponent({
    inheritAttrs: false,
    components: {
        ButtonGroup, StandardButton, Modal, ModalHeader, ModalFooter
    },
    props: {
        serverErrorModal: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped></style>
