<template>
    <not-found-404 />
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {NotFound404} from '@/components/04_Templates/NotFound';

export default defineComponent({
    inheritAttrs: false,
    components: {NotFound404}
});
</script>

<style lang="scss" scoped></style>
