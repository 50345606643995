<template>
    <div class="a-preview-image" :class="{'is-error': isError}">
        <div v-if="$slots.header" class="a-preview-image__header"><slot name="header" /></div>
        <div class="a-preview-image__body">
            <img v-if="source !== ''" class="a-preview-image__source" :src="source" alt="">
            <div class="a-preview-image__content">
                <dl class="a-preview-image__meta">
                    <div class="a-preview-image__item">
                        <dt>ファイル名</dt>
                        <dd>{{filename}}</dd>
                    </div>
                    <div class="a-preview-image__item">
                        <dt>ファイル容量</dt>
                        <dd>{{formatedSize}}</dd>
                    </div>
                </dl>

                <slot />
            </div>
        </div>
        <div v-if="$slots.footer" class="a-preview-image__footer"><slot name="footer" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        source: {
            type: String,
            default: ''
        },
        filename: {
            type: String,
            default: '不明'
        },
        size: {
            type: Number,
            default: 0
        },
        isError: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        /**
         * formatBytes - ファイル容量のフォーマット
         * @param {Number} bytes - ファイル容量の数値
         * @returns {String}
         */
        const formatBytes = (bytes) => {
            // 容量が0だった場合
            if (bytes === 0) {
                return '0 Bytes';
            }

            const kilo = 1024; // 何 byteを 1kとするか
            const sizes = ['Bytes', 'KB', 'MB', 'GB'];
            const sizeIndex = Math.floor(Math.log(bytes) / Math.log(kilo));

            return `${parseFloat((bytes / (kilo ** sizeIndex)).toFixed(1))}${sizes[sizeIndex]}`;
        };

        const formatedSize = computed(() => formatBytes(props.size));

        return {formatedSize};
    }
});
</script>

<style lang="scss" scoped>
.a-preview-image {
    width: 100%;
    border: solid 1px #B3B8B6;
    border-radius: 2px;
    background: var.$color-utils-background;
    padding: 24px;

    &:not(:first-child) {
        margin-top: 24px;
    }

    &.is-error {}

    @at-root {
        .a-preview-image__body {
            display: flex;

            @include mixin.bp(custom) {
                flex-direction: column;
            }
        }

        .a-preview-image__content {
            width: calc(100% - 314px);
            margin-left: 24px;

            @include mixin.bp(custom) {
                width: 100%;
                margin-left: 0;
                margin-top: 24px;
            }
        }

        .a-preview-image__source {
            background: rgba(var.$color-gray-10, .05);
            object-fit: scale-down;
            aspect-ratio: 16 / 9;
            max-width: 100%;
            max-height: 100%;
            width: 390px;
            align-self: flex-start;
        }

        .a-preview-image__meta {
            background: var.$color-gray-10;
            padding: 16px;
            margin: 0;
        }

        .a-preview-image__item {
            > dt {
                font-size: 1.2rem;
                line-height: (18 / 12);
                font-weight: bold;
            }

            > dd {
                font-size: 1.4rem;
                line-height: (18 / 14);
                margin: 4px 0 0;
            }

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        .a-preview-image__footer {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin-top: 24px;
        }
    }
}
</style>
