<template>
    <th v-if="isHead" class="a-table-cell" :class="{[`a-table-cell--${resizing}`]: resizing !== 'default'}">
        <div class="a-table-cell__ineer" :class="{[`u-clamp-l${clamp}`]: clamp !== 0}"><slot /></div>
    </th>
    <td v-else class="a-table-cell" :class="{[`a-table-cell--${resizing}`]: resizing !== 'default'}">
        <div class="a-table-cell__ineer" :class="{[`u-clamp-l${clamp}`]: clamp !== 0}"><slot /></div>
    </td>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isHead: {
            type: Boolean,
            default: false
        },
        clamp: {
            type: Number,
            default: 0
        },
        resizing: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'fill', 'hug'].includes(value)
        }
    }
});
</script>

<style lang="scss" scoped>
.a-table-cell{
    text-align: left;
    padding: 0 16px;
    border-bottom: solid 1px var.$color-gray-10;
    font-size: 1.6rem;
    color: var.$color-text-medium;
    min-width: 164px;

    &--fill {
        width: 100%;
    }

    &--hug {
        min-width: auto;
        white-space: nowrap;
    }

    @at-root {
        .a-table-cell__ineer {
            position: relative;
            max-width: 532px;
            z-index: 1;
        }
    }
}
</style>
