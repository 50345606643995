<template>
    <div class="a-step-list">
        <ul class="a-step-list__list">
            <li
                v-for="(step, index) in steps"
                :key="step"
                class="a-step-list__item"
                :class="{'is-done': index < currentStep}"
                :aria-current="index === currentStep ? 'true' : undefined"
            >
                <span class="a-step-list__text">{{step}}</span>
            </li>
        </ul>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        currentStep: {
            type: Number,
            default: 0
        },
        steps: {
            type: Array,
            default: () => (['利用規約の同意', '基本情報の入力', '入力の確認', '完了'])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-step-list {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;

    @at-root {
        .a-step-list__list {
            position: relative;
            display: inline-flex;
            justify-content: center;
            counter-reset: step 0;

            &::before {
                position: absolute;
                top: 19px;
                left: 0;
                right: 0;
                display: block;
                content: "";
                height: 3px;
                background: var.$color-utils-surface;
            }
        }

        .a-step-list__item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            font-size: 1.2rem;
            color: var.$color-utils-surface;
            background: var.$color-utils-background;
            border-radius: 50%;
            border: solid 3px var.$color-utils-surface;

            &::before {
                position: relative;
                top: -1px;
                content: counter(step);
                counter-increment: step 1;
                font-size: 1.6rem;
                line-height: 1;
                font-weight: bold;
                color: currentColor;
            }

            &:not(:first-child) {
                margin-left: 96px;

                &[aria-current="true"],
                &.is-done {
                    &::after {
                        position: absolute;
                        top: 16px;
                        left: -100px;
                        right: 36px;
                        display: block;
                        content: "";
                        height: 3px;
                        background: var.$color-primary-50;
                    }
                }
            }

            &.is-done .a-step-list__text,
            &[aria-current="true"] .a-step-list__text {
                color: var.$color-primary-50;
            }

            &.is-done {
                background: var.$color-primary-50;
                border-color: var.$color-primary-50;
                color: var.$color-text-white;
            }

            &[aria-current="true"] {
                background: var.$color-utils-background;
                border-color: var.$color-primary-50;
                color: var.$color-primary-50;
            }
        }

        .a-step-list__text {
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translate(-50%, 100%);
            white-space: nowrap;
            color: var.$color-text-disabled;
        }
    }
}
</style>
