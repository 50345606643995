<template>
    <main class="t-main">
        <section-card title="サービス利用申請">
            <template v-slot:utils>
                <step-list v-bind="stepList" :current-step="stepList.currentStep.value" />
            </template>

            <section-message
                :image="require('@/assets/images/img-message-01.png')"
                message="申請ありがとうございます"
                repletion="登録完了後は完了通知がメールで送られます"
            />

            <template v-slot:footer>
                <button-group layout="center">
                    <standard-button appearance="primary" @click="$router.push({name: 'ClientLogin'})">ログインページへ</standard-button>
                </button-group>
            </template>
        </section-card>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {SectionCard} from '@/components/01_Atoms/Cards';
import {SectionMessage} from '@/components/01_Atoms/Messages';
import StepList from '@/components/01_Atoms/StepLists';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';

export default defineComponent({
    inheritAttrs: false,
    emits: [],
    components: {
        SectionCard, SectionMessage, StepList, ButtonGroup, StandardButton
    },
    props: {
        stepList: {
            type: Object,
            default: () => {}
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 64px 24px;
}
</style>
