<template>
    <login-form
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        v-model:email="models.login.email"
        v-model:password="models.login.password"
        @set-model-value="(name, value) => models.login[name] = value"
        @login="methods.validateLogin($event, models.login)"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref, reactive} from 'vue';
import store from '@/store';
import Validate from '@/validates';
import {useRouter, useRoute} from 'vue-router';
import {LoginForm} from '@/components/04_Templates/Admin';

export default defineComponent({
    inheritAttrs: false,
    components: {LoginForm},
    setup() {
        const $route = useRoute();
        const $router = useRouter();

        /**
         * models - フォームモデル
         * @type {Object}
         * @property {reactive<Object>} login - ログインのモデル
         * @property {String} login.email     - 登録メールアドレス
         * @property {String} login.password  - パスワード
         */
        const models = {
            login: reactive({
                email: '',
                password: ''
            })
        };

        /**
         * forms - フォームのデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込み中フラグ
         * @property {Object}       header    - フォームヘッダー部分のデータ
         * @property {Object}       email     - 登録メールアドレス入力欄のデータ
         * @property {Object}       password  - パスワード入力欄のデータ
         */
        const forms = {
            isLoading: ref(false),
            header: {
                title: 'Admin Login',
                titleAlign: 'center'
            },
            email: {
                id: 'email',
                inputType: 'email',
                name: 'email',
                label: 'メールアドレス',
                placeholder: 'Email',
                isRequire: true
            },
            password: {
                id: 'password',
                type: 'password',
                name: 'password',
                label: 'パスワード',
                placeholder: 'Password',
                isRequire: true
            }
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system - APIから返却されたシステムエラー
         * @property {Object} login  - ログイン情報入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            login: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            login: new Validate([forms.email, forms.password], models.login)
        };

        /**
         * methods - メソッド（処理）
         * @type {Object<Fucntion>}
         */
        const methods = {
            /**
             * validateLogin - ログインフォームのバリデーション
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             * @returns {Void}
             */
            validateLogin(event, model) {
                const {currentTarget} = event;
                const {isError, errors} = validates.login.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.login, key)) {
                            delete formErrors.login[key];
                        }

                        return;
                    }

                    formErrors.login[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    currentTarget.elements[Object.keys(formErrors.login)[0]].focus();

                    return;
                }

                // エラーがなかった場合、ログイン処理を実行
                methods.login(model);
            },
            /**
             * login - ログイン処理
             * @param {Object} model - 送信するモデル
             */
            login(model) {
                // すでに処理中の場合は何もしない
                if (forms.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                store.dispatch('login', {model, isAdministrator: true}).then(() => {
                    const {query} = $route;

                    // redirect_urlクエリが設定されている場合、クエリのパスへ遷移する
                    if (query.redirect_url) {
                        $router.push({path: query.redirect_url});

                        return;
                    }

                    // アンケート一覧ページへ遷移
                    $router.push({name: 'AdminClientAccount'});
                }).catch((error) => {
                    // エラーメッセージを表示
                    formErrors.system.value = error;
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    forms.isLoading.value = false;
                });
            }
        };

        return {
            models, forms, formErrors, methods
        };
    }
});
</script>
