<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__heading">
            <h1 class="t-main__title">データ利用サービス</h1>
        </div>

        <div class="t-main__content">
            <p>データ利用サービスには外部サービスのTableau（タブロー）を使用します。</p>
            <p>以下のリンクよりサービスにログインしてください。</p>
        </div>

        <standard-button appearance="primary" @click="location(navigation.value.find((nav) => nav.code === 'data_box').initial_url)">
            Tableau（タブロー）へ移動
            <template v-slot:after><icon name="Rightwards" /></template>
        </standard-button>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';

export default defineComponent({
    inheritAttrs: false,
    components: {StandardButton, Icon},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: Object,
            default: () => {}
        }
    },
    setup() {
        const location = (url) => {
            window.open(url, '_blank');
        };

        return {location};
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px 0 64px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__content {
            margin-bottom: 32px;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }
    }
}
</style>
