<template>
    <div class="a-image-radio-button">
        <label class="a-image-radio-button__label" :for="$attrs.id">
            <input
                :id="$attrs.id"
                type="radio"
                :name="$attrs.name"
                class="a-image-radio-button__input"
                :disabled="$attrs.disabled"
                :value="$attrs.value"
                v-model="model"
            >
            <span class="a-image-radio-button__text"><span class="u-altText">{{text}}</span></span>
            <img class="a-image-radio-button__image" :src="url" alt="">
        </label>
        <button v-if="$slots.action" type="button" class="a-image-radio-button__action" @click="$emit('action', $event)"><slot name="action" /></button>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        url: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    setup(props, $) {
        const model = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                $.emit('update:model-value', value);
            }
        });

        return {model};
    }
});
</script>

<style lang="scss" scoped>
.a-image-radio-button {
    position: relative;

    @at-root {
        .a-image-radio-button__label {
            min-width: 210px;
            display: inline-flex;
            align-items: center;
            position: relative;
            z-index: 0;
        }

        .a-image-radio-button__input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;

            &[disabled] {
                +.a-image-radio-button__text {
                    color: var.$color-text-disabled;
                }

                &:checked {
                    +.a-image-radio-button__text {
                        color: var.$color-text-disabled;

                        &::before {
                            border-color: var.$color-text-disabled;
                        }

                        &::after {
                            opacity: 1;
                            background: var.$color-text-disabled;
                        }
                    }
                }
            }

            &:checked {
                +.a-image-radio-button__text {
                    color: var.$color-primary-50;

                    &::before,
                    &::after {
                        opacity: 1;
                    }

                    + .a-image-radio-button__image {
                        opacity: 1;
                    }
                }
            }
        }

        .a-image-radio-button__image {
            position: relative;
            max-width: 210px;
            opacity: .4;
            border-radius: 2px;
            object-fit: scale-down;
            aspect-ratio: 16/9;
            background: var.$color-gray-10;
            box-shadow: var.$effect-popover-shadow;
            transition: opacity .3s ease 0s;

            &:hover {
                opacity: .6;
            }
        }

        .a-image-radio-button__text {

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                content: "";
                width: 0;
                height: 0;
                opacity: 0;
                border-style: solid;
                border-width: 50px 50px 0 0;
                border-color: transparent;
                border-top-color: var.$color-primary-50;
                transition: opacity .3s ease 0s;
                z-index: 1;
            }

            &::after {
                position: absolute;
                top: 10px;
                left: 10px;
                display: block;
                content: "";
                width: 11px;
                height: 7px;
                opacity: 0;
                border-radius: 2px;
                border-left: solid 2px var.$color-text-white;
                border-bottom: solid 2px var.$color-text-white;
                transform: rotate(-45deg);
                transition: opacity .3s ease 0s;
                z-index: 1;
            }
        }

        .a-image-radio-button__action {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 8px;
            right: 8px;
            width: 24px;
            height: 24px;
            padding: 0;
            color: var.$color-danger-50;
            overflow: hidden;
            z-index: 0;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                width: 18px;
                height: 18px;
                margin: auto;
                border-radius: 50%;
                background: var.$color-utils-background;
                z-index: -1;
            }
        }
    }
}
</style>
