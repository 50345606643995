<template>
    <div class="a-card-footer"><slot /></div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss" scoped>
.a-card-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: solid 1px #E6E7E6;
}
</style>
