<template>
    <reset-password
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        :completed-modal="completedModal"
        v-model:password="models.resetPassword.password"
        v-model:password_confirmation="models.resetPassword.password_confirmation"
        @reset-password="methods.validatePassword($event, models.resetPassword)"
    />
</template>

<script>
// import composition-api.
import {defineComponent, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import axios from 'axios';
import Validate from '@/validates';
import {ResetPassword} from '@/components/04_Templates/Client';

export default defineComponent({
    inheritAttrs: false,
    components: {ResetPassword},
    setup() {
        const $route = useRoute();
        const $router = useRouter();
        const {reset_password_token: resetPasswordToken, changed_password: changedPassword} = $route.query;

        /**
         * models - フォームモデル
         * @type {Object}
         * @property {reactive<Object>} resetPassword             - パスワード再設定のモデル
         * @property {String} resetPassword.password              - 登録メールアドレス
         * @property {String} resetPassword.password_confirmation - パスワード
         */
        const models = {
            resetPassword: reactive({
                password: '',
                password_confirmation: ''
            })
        };

        /**
         * forms - フォームのデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading             - 読み込み中フラグ
         * @property {Object}       header                - フォームヘッダー部分のデータ
         * @property {Object}       password              - 新しいパスワード入力欄のデータ
         * @property {Object}       password_confirmation - 新しいパスワード（再入力）入力欄のデータ
         */
        const forms = {
            isLoading: ref(false),
            header: {
                title: changedPassword === 'true' ? 'パスワード再設定' : 'パスワード初期設定'
            },
            password: {
                id: 'password',
                type: 'password',
                name: 'password',
                label: '新しいパスワード',
                autocomplete: 'new-password',
                isRequire: true
            },
            password_confirmation: {
                id: 'confirm-password',
                type: 'password',
                name: 'password_confirmation',
                label: '新しいパスワードを再入力',
                title: '新しいパスワード',
                autocomplete: 'new-password',
                isRequire: true,
                related: 'password',
                message: {
                    required: 'パスワードを再入力してください',
                    password: '#{title}は半角英数字混在8文字以上で入力してください。'
                }
            }
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system - APIから返却されたシステムエラー
         * @property {Object} login  - ログイン情報入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            resetPassword: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            resetPassword: new Validate([forms.password, forms.password_confirmation], models.resetPassword)
        };

        /**
         * completedModal - 変更完了モーダルのデータ
         * @type {Object}
         * @property {String}       id         - モーダルのID名
         * @property {String}       appearance - モーダルの見た目
         * @property {Boolean}      closable   - モーダル範囲外及びEscキーで閉じる効果を有効にする
         * @property {ref<Boolean>} isExpanded - 開閉フラグ
         * @property {ref<String>}  message    - 表示するメッセージ
         */
        const completedModal = {
            id: 'completed',
            appearance: 'default',
            closable: false,
            isExpanded: ref(false),
            message: ref('')
        };

        /**
         * methods - メソッド（処理）
         * @type {Object<Function>}
         */
        const methods = {
            /**
             * validatePassword - パスワード再設定フォームのバリデーション
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             * @returns {Void}
             */
            validatePassword(event, model) {
                const {currentTarget} = event;
                const {isError, errors} = validates.resetPassword.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.resetPassword, key)) {
                            delete formErrors.resetPassword[key];
                        }

                        return;
                    }

                    formErrors.resetPassword[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    currentTarget.elements[Object.keys(formErrors.resetPassword)[0]].focus();

                    return;
                }

                // エラーがなかった場合、ログイン処理を実行
                methods.resetPassword(model);
            },
            /**
             * resetPassword - パスワード再設定処理
             * @param {Object} model - 送信するモデル
             */
            resetPassword(model) {
                // すでに処理中の場合は何もしない
                if (forms.isLoading.value) {
                    return;
                }

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                axios.patch('/api/v2/client_auth/password', {
                    reset_password_token: resetPasswordToken,
                    ...model
                }).then((response) => {
                    // モーダルを表示
                    completedModal.message.value = response.data.message;
                    completedModal.isExpanded.value = true;
                }).catch((error) => {
                    // エラーメッセージを表示
                    formErrors.system.value = error;
                }).finally(() => {
                    // 読み込みフラグを更新（読み込み完了）
                    forms.isLoading.value = false;
                });
            }
        };

        // パスワードトークンがない場合はログイン画面へリダイレクト
        if (!resetPasswordToken) {
            $router.replace({name: 'ClientLogin'});
        }

        return {
            models, forms, formErrors, completedModal, methods
        };
    }
});
</script>
