<template>
    <div class="a-preview-price">
        <p class="a-preview-price__title"><b>{{title}}</b></p>
        <output :id="$attrs.id" class="a-price-preview__output" :name="$attrs.name">{{isLoading ? '計算中…' : `${price}円`}}</output>
        <p class="a-preview-price__note"><small>{{notes}}</small></p>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: '金額'
        },
        notes: {
            type: String,
            default: 'お見積もり額を表示します。\n但し、別途契約をされているお客様は、契約内容に従います。'
        },
        unitPrice: {
            type: Number,
            default: 0
        },
        calc: {
            type: Function,
            default: (unit) => unit
        }
    },
    setup(props) {
        const price = computed(() => {
            const {calc, unitPrice} = props;

            return calc(unitPrice).toLocaleString();
        });

        return {price};
    }
});
</script>

<style lang="scss" scoped>
.a-preview-price {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px 8px;
    background: var.$color-primary-10;
    border-radius: 2px;

    @at-root {
        .a-preview-price__title {
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-high;

            > b {
                font-weight: normal;
            }
        }

        .a-price-preview__output {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
            margin-top: 4px;
        }

        .a-preview-price__note {
            white-space: pre-wrap;
            font-size: 1.2rem;
            color: var.$color-text-disabled;

            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
}
</style>
