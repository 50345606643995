<template>
    <div class="a-previews">
        <div v-if="$slots.header" class="a-previews__header"><slot name="header" /></div>
        <div class="a-previews__body"><slot name="body" /></div>
        <div v-if="$slots.footer" class="a-previews__footer"><slot name="footer" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {},
    setup() {}
});
</script>

<style lang="scss" scoped>
.a-previews {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    max-width: 378px;

    @at-root {
        .a-previews__header {
            padding: 24px 0;
        }

        .a-previews__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 590px;
            overflow: hidden;

            &:first-child {
                padding-top: 24px;
            }

            &:last-child {
                padding-bottom: 24px;
            }
        }

        .a-previews__footer {
            padding: 24px 0;
        }
    }
}
</style>
