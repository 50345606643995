<template>
    <tr
        class="a-table-row"
        :class="{'a-table-row--clickable': clickable}"
        :tabindex="clickable ? 0 : undefined"
    >
        <slot
            v-for="(cell, index) in cells"
            :key="cell"
            :name="`cell-${index}`"
            :cell="cell"
        />
    </tr>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        clickable: {
            type: Boolean,
            default: false
        },
        cells: {
            type: Array,
            default: () => ([
                {
                    key: 'cell-1',
                    content: 'cell 1'
                }, {
                    key: 'cell-2',
                    content: 'cell 2'
                }, {
                    key: 'cell-3',
                    content: 'cell 3'
                }
            ])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-table-row {
    position: relative;

    &:focus-within {

        ::v-deep(.a-table-cell) > :first-child {
            z-index: 2;
        }
    }

    &--clickable {

        > :first-child {

            &::after {
                display: block;
                position: absolute;
                top: -2px;
                bottom: 0;
                left: -18px;
                right: -18px;
                content: "";
                background: var.$color-primary-10;
                z-index: 0;
                opacity: 0;
                transition: opacity .3s ease 0s;
            }

            @include mixin.hover {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media, _:future, .a-table-row--clickable > :first-child::after {
        top: auto;
        bottom: auto;
        left: 0;
        right: auto;
        width: 100%;
        height: 65px;
        transform: translateY(-47px);
    }
}
</style>
