<template>
    <label class="a-radio-button" :for="$attrs.id">
        <input
            :id="$attrs.id"
            type="radio"
            :name="$attrs.name"
            class="a-radio-button__input"
            :disabled="$attrs.disabled"
            :value="$attrs.value"
            v-model="model"
        >
        <span class="a-radio-button__text"><slot /></span>
    </label>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    setup(props, $) {
        const model = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                $.emit('update:model-value', value);
            }
        });

        return {model};
    }
});
</script>

<style lang="scss" scoped>
.a-radio-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 20px;

    @at-root {
        .a-radio-button__input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0;

            &[disabled] {
                +.a-radio-button__text {
                    color: var.$color-text-disabled;
                }

                &:checked {
                    +.a-radio-button__text {
                        color: var.$color-text-disabled;

                        &::before {
                            border-color: var.$color-text-disabled;
                        }

                        &::after {
                            opacity: 1;
                            background: var.$color-text-disabled;
                        }
                    }
                }
            }

            &:checked {
                +.a-radio-button__text {
                    color: var.$color-primary-50;

                    &::before {
                        border-color: var.$color-primary-50;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        .a-radio-button__text {
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-medium;
            margin-left: 8px;
            transition: color .3s ease 0s;

            &::before {
                position: absolute;
                top: 3px;
                left: 0;
                display: block;
                content: "";
                width: 20px;
                height: 20px;
                background: var.$color-utils-background;
                border-radius: 50%;
                border: solid 2px currentColor;
                transition: background-color .3s ease 0s;
            }

            &::after {
                position: absolute;
                top: 8px;
                left: 5px;
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                background: var.$color-primary-50;
                border-radius: 50%;
                opacity: 0;
            }
        }
    }
}
</style>
