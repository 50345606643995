<template>
    <div class="a-select" :class="{[`a-select--${place}`]: place !== 'bottom-left'}">
        <button
            ref="control"
            :id="id"
            :name="$attrs.name"
            type="button"
            class="a-select__control"
            :class="{
                'is-selected': modelValue !== '',
                'is-readonly': $attrs.readonly
            }"
            :aria-required="isRequired ? 'true' : undefined"
            :aria-controls="`${id}-options`"
            :aria-expanded="isExpanded"
            aria-haspopup="listbox"
            :disabled="$attrs.disabled || $attrs.readonly"
            @keydown.enter="$emit('open', $event)"
            @click="$emit('open', $event)"
        >
            <slot name="option" :option="options[currentIndex]" />
        </button>

        <div
            ref="listbox"
            :id="`${id}-options`"
            class="a-select__options"
            role="listbox"
            :aria-activedescendant="`${id}-option-${currentIndex}`"
            :aria-labelledby="$attrs['aria-labelledby']"
            tabindex="-1"
            :hidden="!isExpanded"
            @keydown.up.prevent="$emit('update:model-value', options[arrowKeyControlSupport.previous].value)"
            @keydown.down.prevent="$emit('update:model-value', options[arrowKeyControlSupport.next].value)"
            @keydown.enter.prevent="$emit('close', $event)"
            @keydown.space="$emit('close', $event)"
            @keydown.esc="$emit('close', $event)"
            @focusout="methods.focusout"
        >
            <ul class="a-select__list" role="presentation">
                <li
                    v-for="(option, index) in options"
                    :key="option"
                    :id="`${id}-option-${index}`"
                    role="option"
                    :aria-selected="option.value === modelValue"
                    :aria-disabled="option.disabled ? 'true' : undefined"
                    class="a-select__item"
                    :data-value="option.value"
                    tabindex="-1"
                    @keydown.enter="option.disabled ? undefined : $emit('update:model-value', option.value) & $emit('close', $event)"
                    @click="option.disabled ? undefined : $emit('update:model-value', option.value) & $emit('close', $event)"
                >
                    <slot name="option" :option="option" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, computed, ref, watch, nextTick, onMounted
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            required: false
        },
        modelValue: {
            type: String,
            default: ''
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'bottom-left',
            validator: (value) => {
                const [vertical, horizontal] = value.split('-');

                return ['top', 'bottom', 'auto'].includes(vertical) && ['left', 'center', 'right'].includes(horizontal);
            }
        },
        options: {
            type: Array,
            required: true,
            default: () => [
                {
                    label: '選択してください',
                    value: ''
                }, {
                    label: '選択肢1',
                    value: '1'
                }, {
                    label: '選択肢2',
                    value: '2'
                }, {
                    label: '選択肢3',
                    value: '3'
                }
            ]
        }
    },
    setup(props, $) {
        const control = ref(null);
        const listbox = ref(null);
        const place = ref(props.placement);
        const currentIndex = computed(() => {
            const {options, modelValue} = props;
            const index = options.findIndex((option) => option.value === modelValue);

            return index === -1 ? 0 : index;
        });
        const arrowKeyControlSupport = computed(() => {
            const current = currentIndex.value;
            let validCurrentIndex = 0;

            const validOptionIndex = props.options.reduce((array, option, index) => {
                if (index === current || !option.disabled) {
                    array.push(index);

                    if (index === current) {
                        validCurrentIndex = array.length - 1;
                    }
                }

                return array;
            }, []);

            return {
                previous: validOptionIndex[validCurrentIndex - 1] || validOptionIndex[0],
                next: validOptionIndex[validCurrentIndex + 1] || validOptionIndex.slice(-1)[0]
            };
        });

        const methods = {
            focusout(event) {
                const {currentTarget, relatedTarget} = event;

                if (currentTarget.contains(relatedTarget)) {
                    return;
                }

                $.emit('close', event);
            }
        };

        watch(currentIndex, () => {
            nextTick(() => {
                const currentId = listbox.value.getAttribute('aria-activedescendant');
                const currentElement = document.getElementById(currentId);
                const targetOffsetTop = () => {
                    let scroll = 0;
                    const getOffsetTop = (element) => {
                        const {offsetTop, offsetParent} = element;

                        if (!offsetParent) {
                            return;
                        }

                        const role = offsetParent ? offsetParent.getAttribute('role') : '';

                        scroll += offsetTop;

                        if (role !== 'listbox') {
                            getOffsetTop(offsetParent);
                        }
                    };

                    getOffsetTop(currentElement);

                    return scroll;
                };

                if (!currentElement) {
                    return;
                }

                listbox.value.scrollTop = targetOffsetTop() - 8;
            });
        });

        onMounted(() => {
            const [vertical, horizontal] = props.placement.split('-');

            // 上下位置の自動調整が必要ではない場合は何もしない
            if (vertical !== 'auto') {
                return;
            }

            // メニューの上下位置監視
            const observer = new window.IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const {isIntersecting} = entry;

                    place.value = isIntersecting ? `bottom-${horizontal}` : `top-${horizontal}`;
                });
            }, {rootMargin: '0px 0px -220px 0px', threshold: 0});

            observer.observe(control.value);
        });

        watch(() => props.isExpanded, (isExpanded) => {
            nextTick(() => {
                if (isExpanded) {
                    listbox.value.focus();
                    listbox.value.scrollTop = 0;
                } else {
                    control.value.focus();
                }
            });
        });

        return {
            control, listbox, place, currentIndex, arrowKeyControlSupport, methods
        };
    }
});
</script>

<style lang="scss" scoped>
.a-select {
    display: flex;
    position: relative;

    &--bottom-right {
        justify-content: flex-end;

        .a-select__options {
            right: 0;
        }
    }

    &--bottom-center {
        justify-content: center;
    }

    &--top-right {
        justify-content: flex-end;

        .a-select__options {
            top: -4px;
            right: 0;
            bottom: auto;
            transform: translateY(-100%);
        }
    }

    &--top-center {
        justify-content: center;

        .a-select__options {
            top: -4px;
            bottom: auto;
            transform: translateY(-100%);
        }
    }

    &--top-left .a-select__options {
        top: -4px;
        bottom: auto;
        transform: translateY(-100%);
    }

    @at-root {
        .a-select__control {
            position: relative;
            min-width: 240px;
            min-height: 48px;
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            padding: 0 28px 0 0;
            color: var.$color-text-disabled;
            background: var.$color-utils-background;

            &.is-selected {
                color: var.$color-text-medium;
            }

            &[disabled] {
                cursor: default;
                background: var.$color-gray-10;

                &:not(.is-readonly) {
                    color: var.$color-text-disabled;
                    border-color: var.$color-gray-10;
                    opacity: .6;
                }
            }

            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 16px;
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                margin: auto;
                border-radius: 2px;
                border-right: solid 2px var.$color-text-medium;
                border-bottom: solid 2px var.$color-text-medium;
                transform: rotate(45deg);
                pointer-events: none;
            }
        }

        .a-select__options {
            position: absolute;
            bottom: -4px;
            transform: translateY(100%);
            border: solid 1px #F2F6F9;
            border-radius: 2px;
            min-width: 240px;
            max-height: 216px;
            overflow: auto;
            background: var.$color-utils-background;
            box-shadow: var.$effect-popover-shadow;
            z-index: 10;
        }

        .a-select__list {
            flex-direction: column;
            padding: 8px 0;
            display:flex;
        }

        .a-select__item {
            cursor: pointer;
            color: var.$color-text-medium;
            background: var.$color-utils-background;
            transition: background-color .3s ease 0s;

            @include mixin.hover {
                background: #F2F6F9;
            }

            &[aria-disabled="true"] {
                cursor: default;
                opacity: .6;
                background: var.$color-gray-10;
                color: var.$color-text-disabled;
            }

            &[aria-selected="true"] {
                background: #F2F6F9;
            }
        }
    }
}
</style>
