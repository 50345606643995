<template>
    <div class="a-select-checkbox" :class="{[`a-select-checkbox--${place}`]: place !== 'bottom'}">
        <div
            class="a-select-checkbox__filed"
            :class="{
                'has-action': $slots.action && !$attrs.disabled,
                'is-error': isError,
                'is-readonly': $attrs.readonly,
                'is-disabled': $attrs.disabled
            }"
        >
            <button
                ref="control"
                :id="id"
                :name="$attrs.name"
                type="button"
                class="a-select-checkbox__control"
                :class="{'is-selected': modelValue.length}"
                :aria-required="isRequired ? 'true' : undefined"
                :aria-controls="`${id}-options`"
                :aria-expanded="isExpanded"
                aria-haspopup="listbox"
                :disabled="$attrs.disabled || $attrs.readonly"
                @keydown.enter="$emit('open', $event)"
                @click="$emit('open', $event)"
            >
                <span class="a-select-checkbox__placeholder">{{modelValue.length ? `${modelValue.length}つの項目を選択` : placeholder}}</span>
            </button>

            <ul class="a-select-checkbox__list" aria-label="選択済" :hidden="!modelValue.length">
                <li
                    v-for="option in selectedOptions"
                    :key="option"
                    class="a-select-checkbox__item"
                >
                    <span class="a-select-checkbox__tag">{{option.label}}</span>
                    <button
                        class="a-select-checkbox__deselect"
                        type="button"
                        :hidden="$attrs.disabled || $attrs.readonly"
                        :disabled="$attrs.disabled"
                        @click="methods.updateChildModel(option.value, option.label, option.children, option.root)"
                        @keydown.enter="methods.updateChildModel(option.value, option.label, option.children, option.root)"
                    >
                        <span class="u-altText">「{{option.label}}」の選択を解除</span>
                    </button>
                </li>
            </ul>

            <div
                ref="optionList"
                :id="`${id}-options`"
                class="a-select-checkbox__options"
                role="listbox"
                aria-multiselectable="true"
                :aria-activedescendant="`${id}-option-${focusIndex}`"
                :aria-labelledby="$attrs['aria-labelledby']"
                tabindex="-1"
                :hidden="!isExpanded"
                @keydown.up.prevent="focusIndex = arrowKeyControlSupport.previous"
                @keydown.down.prevent="focusIndex = arrowKeyControlSupport.next"
                @keydown.enter.prevent="
                    typeof arrowKeyControlSupport.current.isChild === 'boolean' ?
                        methods[arrowKeyControlSupport.current.isChild ? 'updateChildModel' : 'updateModel'](arrowKeyControlSupport.current.value, arrowKeyControlSupport.current.label, arrowKeyControlSupport.current.children, arrowKeyControlSupport.current.root) :
                        methods.selectAll(optionValues.child.selected && optionValues.root.selected, optionValues.root.values, optionValues.child.values)
                    "
                @keydown.space="
                    typeof arrowKeyControlSupport.current.isChild === 'boolean' ?
                        methods[arrowKeyControlSupport.current.isChild ? 'updateChildModel' : 'updateModel'](arrowKeyControlSupport.current.value, arrowKeyControlSupport.current.label, arrowKeyControlSupport.current.children, arrowKeyControlSupport.current.root) :
                        methods.selectAll(optionValues.child.selected && optionValues.root.selected, optionValues.root.values, optionValues.child.values)
                    "
                @keydown.esc="$emit('close', $event) & (focusIndex = selectAll !== '' ? 'all' : '0')"
                @focusout="($event.currentTarget.contains($event.relatedTarget) ? $event.currentTarget.focus() : $emit('close', $event)) & (focusIndex = selectAll !== '' ? 'all' : '0')"
            >
                <ul class="a-select-checkbox__optionlist" role="group">
                    <li
                        v-if="selectAll !== ''"
                        :id="`${id}-option-all`"
                        role="option"
                        :aria-selected="focusIndex === 'all'"
                        :aria-checked="optionValues.child.selected && optionValues.root.selected ? 'true' : !modelValue.length && !rootValue.length ? 'false' : 'mixed'"
                        class="a-select-checkbox__option"
                        tabindex="-1"
                        @keydown.enter="methods.selectAll(optionValues.child.selected && optionValues.root.selected, optionValues.root.values, optionValues.child.values)"
                        @click="methods.selectAll(optionValues.child.selected && optionValues.root.selected, optionValues.root.values, optionValues.child.values)"
                    >
                        <slot name="option" :option="{
                            label: selectAll,
                            value: ''
                        }" />
                    </li>
                    <li
                        v-for="(option, index) in options"
                        :key="option"
                        :id="`${id}-option-${index}`"
                        role="option"
                        :aria-selected="focusIndex === `${index}`"
                        :aria-checked="rootValue.includes(option.value) ? 'true' : (option.children || []).some((child) => modelValue.includes(child.value)) ? 'mixed' : 'false'"
                        :aria-disabled="option.disabled ? 'true' : undefined"
                        class="a-select-checkbox__option"
                        :data-value="option.value"
                        tabindex="-1"
                        @keydown.enter="option.disabled ? undefined : methods.updateModel(option.value, option.label, option.children)"
                        @click="option.disabled ? undefined : methods.updateModel(option.value, option.label, option.children)"
                    >
                        <slot name="option" :option="option" />

                        <ul v-if="option.children" class="a-select-checkbox__optionlist" role="group">
                            <li
                                v-for="(child, idx) in option.children"
                                :key="child"
                                :id="`${id}-option-${index}-${idx}`"
                                role="option"
                                :aria-selected="focusIndex === `${index}-${idx}`"
                                :aria-checked="modelValue.includes(child.value)"
                                :aria-disabled="option.disabled || child.disabled ? 'true' : undefined"
                                class="a-select-checkbox__option"
                                :data-value="child.value"
                                tabindex="-1"
                                @keydown.enter.stop="option.disabled || child.disabled ? undefined : methods.updateChildModel(child.value, child.label, option.children, option.value)"
                                @click.stop="option.disabled || child.disabled ? undefined : methods.updateChildModel(child.value, child.label, option.children, option.value)"
                            >
                                <slot name="option" :option="child" />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <span v-if="$slots.action && !$attrs.disabled" class="a-select-checkbox__action"><slot name="action" /></span>
        </div>
        <div class="a-select-checkbox__region" aria-live="assertive" role="alert">
            <span class="u-altText" :hidden="regionText === ''">{{regionText}}</span>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, computed, ref, nextTick, watch, onMounted
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            required: false
        },
        placement: {
            type: String,
            default: 'bottom',
            validator: (value) => ['top', 'bottom', 'auto'].includes(value)
        },
        selectAll: {
            type: String,
            default: '全て選択'
        },
        placeholder: {
            type: String,
            default: '選択してください'
        },
        modelValue: {
            type: Array,
            default: () => []
        },
        rootValue: {
            type: Array,
            default: () => []
        },
        isError: {
            type: Boolean,
            default: false
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            required: true,
            default: () => [
                {
                    label: '選択肢1',
                    value: '1',
                    children: [{
                        label: '選択肢1-1',
                        value: '1-1'
                    }, {
                        label: '選択肢1-2',
                        value: '1-2'
                    }, {
                        label: '選択肢1-3',
                        value: '1-3'
                    }]
                }, {
                    label: '選択肢2',
                    value: '2'
                }, {
                    label: '選択肢3',
                    value: '3'
                }
            ]
        }
    },
    setup(props, $) {
        const optionList = ref(null);
        const control = ref(null);
        const place = ref(props.placement);
        const focusIndex = ref(props.selectAll === '' ? '0' : 'all');
        const selectedAll = ref(false);
        const lastestAtion = ref([]);
        const optionValues = computed(() => {
            const {options, modelValue, rootValue} = props;
            const childValues = [];
            const rootValues = options.reduce((array, option) => {
                if (!option.disabled) {
                    array.push(option.value);

                    if (Object.hasOwnProperty.call(option, 'children')) {
                        childValues.push(...option.children.filter((child) => !child.disabled).map((child) => child.value));
                    }
                }

                return array;
            }, []);

            return {
                child: {
                    selected: childValues.every((value) => modelValue.includes(value)),
                    values: childValues
                },
                root: {
                    selected: rootValues.every((value) => rootValue.includes(value)),
                    values: rootValues
                }
            };
        });
        const selectedOptions = computed(() => {
            const {modelValue, rootValue, options} = props;
            const unselectedRootValues = [];
            const childOptions = options.reduce((array, option) => {
                if (option.children) {
                    const children = [];
                    const checkedAllChildren = option.children.map((child) => child.value).every((value) => modelValue.includes(value));

                    option.children.forEach((child) => {
                        const opt = {...child};

                        children.push(Object.assign(opt, {children: option.children, root: option.value}));
                    });

                    if (checkedAllChildren && !rootValue.includes(option.value)) {
                        unselectedRootValues.push(option.value);
                    }

                    array.push(...children);
                }

                return array;
            }, []);

            if (unselectedRootValues.length) {
                $.emit('update:rootValue', [...new Set([...rootValue, ...unselectedRootValues])]);
            }

            return childOptions.filter((option) => modelValue.includes(option.value));
        });
        const arrowKeyControlSupport = computed(() => {
            const current = focusIndex.value;
            const currentSelectOption = {};
            let isInvallidCurrent = false;
            let validCurrentIndex = 0;
            const validOptionIndex = props.options.reduce((array, option, index) => {
                if (!option.disabled) {
                    array.push(`${index}`);

                    if (array.slice(-1)[0] === current) {
                        Object.assign(currentSelectOption, {
                            isChild: false,
                            label: option.label,
                            value: option.value,
                            children: option.children
                        });
                    }

                    if (Object.hasOwnProperty.call(option, 'children')) {
                        array.push(...option.children.reduce((ary, opt, idx) => {
                            if (!opt.disabled) {
                                ary.push(`${index}-${idx}`);
                            }

                            if (ary.slice(-1)[0] === current) {
                                Object.assign(currentSelectOption, {
                                    isChild: true,
                                    label: opt.label,
                                    value: opt.value,
                                    children: option.children,
                                    root: option.value
                                });
                            }

                            return ary;
                        }, []));
                    }

                    if (isInvallidCurrent) {
                        focusIndex.value = `${index}`;
                        validCurrentIndex = array.length;
                        isInvallidCurrent = false;

                        Object.assign(currentSelectOption, {
                            isChild: false,
                            label: option.label,
                            value: option.value,
                            children: option.children
                        });
                    }
                } else if (index.toString() === current) {
                    isInvallidCurrent = true;
                }

                return array;
            }, props.selectAll === '' ? [] : ['all']);

            if (isInvallidCurrent) {
                [focusIndex.value] = validOptionIndex.slice(-1);
            }

            if (validCurrentIndex === 0) {
                validCurrentIndex = validOptionIndex.indexOf(current);
            }

            return {
                current: currentSelectOption,
                previous: validOptionIndex[validCurrentIndex - 1] || validOptionIndex[0],
                next: validOptionIndex[validCurrentIndex + 1] || validOptionIndex.slice(-1)[0]
            };
        });
        const regionText = computed(() => {
            const [action, value] = lastestAtion.value;

            return action ? value : '';
        });
        const methods = {
            updateModel(value, label, children) {
                let action = props.rootValue.includes(value) ? 'deselect' : 'select';

                // 選択済の場合
                methods[action]('rootValue', [value]);

                // 子を持たない場合はここで終了
                if (!children) {
                    lastestAtion.value = [action, `${label}${action === 'select' ? 'を選択しました' : 'の選択を解除しました'}`];

                    return;
                }

                const values = children.filter((child) => !child.disabled).map((child) => child.value);

                action = values.every((val) => props.modelValue.includes(val)) ? 'deselect' : 'select';
                lastestAtion.value = [action, `${label}と関連する${children.length}項目${action === 'select' ? 'を選択しました' : 'の選択を解除しました'}`];

                methods[action]('modelValue', values);
            },
            updateChildModel(value, label, children, root) {
                let action = props.modelValue.includes(value) ? 'deselect' : 'select';

                // 選択済の場合
                methods[action]('modelValue', [value]);
                lastestAtion.value = [action, `${label}${action === 'select' ? 'を選択しました' : 'の選択を解除しました'}`];

                // 子を持たない場合はここで終了
                if (!children) {
                    return;
                }

                nextTick(() => {
                    const values = children.filter((child) => !child.disabled).map((child) => child.value);

                    action = values.every((val) => props.modelValue.includes(val)) ? 'select' : 'deselect';

                    methods[action]('rootValue', [root]);
                });
            },
            selectAll(isSelected, rootValues, childValues) {
                if (isSelected) {
                    $.emit('update:modelValue', []);
                    $.emit('update:rootValue', []);

                    lastestAtion.value = ['deselect', '全ての項目の選択を解除しました'];
                } else {
                    const {modelValue, rootValue} = props;

                    $.emit('update:modelValue', [...new Set([...modelValue, ...childValues])]);
                    $.emit('update:rootValue', [...new Set([...rootValue, ...rootValues])]);

                    lastestAtion.value = ['select', '全ての項目を選択しました'];
                }
            },
            select(modelName, values) {
                $.emit(`update:${modelName}`, [...new Set([...props[modelName], ...values])]);
            },
            deselect(modelName, values) {
                $.emit(`update:${modelName}`, props[modelName].filter((value) => !values.includes(value)));
            }
        };

        watch(focusIndex, () => {
            nextTick(() => {
                const currentId = optionList.value.getAttribute('aria-activedescendant');
                const currentElement = document.getElementById(currentId);
                const targetOffsetTop = () => {
                    let scroll = 0;
                    const getOffsetTop = (element) => {
                        const {offsetTop, offsetParent} = element;

                        if (!offsetParent) {
                            return;
                        }

                        const role = offsetParent ? offsetParent.getAttribute('role') : '';

                        scroll += offsetTop;

                        if (role !== 'listbox') {
                            getOffsetTop(offsetParent);
                        }
                    };

                    getOffsetTop(currentElement);

                    return scroll;
                };

                if (!currentElement) {
                    return;
                }

                optionList.value.scrollTop = targetOffsetTop() - 8;
            });
        });

        watch(() => props.isExpanded, (isExpanded) => {
            nextTick(() => {
                if (isExpanded) {
                    optionList.value.focus();
                    optionList.value.scrollTop = 0;
                } else {
                    control.value.focus();
                }
            });
        });

        onMounted(() => {
            const {placement} = props;

            // 上下位置の自動調整が必要ではない場合は何もしない
            if (placement !== 'auto') {
                return;
            }

            // メニューの上下位置監視
            const observer = new window.IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    const {isIntersecting} = entry;

                    place.value = isIntersecting ? 'bottom' : 'top';
                });
            }, {rootMargin: '0px 0px -448px 0px', threshold: 0});

            observer.observe(control.value);
        });

        return {
            optionList, control, place, selectedAll, focusIndex, optionValues, selectedOptions, arrowKeyControlSupport, regionText, methods
        };
    }
});
</script>

<style lang="scss" scoped>
.a-select-checkbox {
    position: relative;
    width: 100%;

    &--top {

        .a-select-checkbox__options {
            top: -4px;
            bottom: auto;
            transform: translateY(-100%);
        }
    }

    @at-root {
        .a-select-checkbox__list {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            margin: -8px 0 0 -8px;
            padding: 11px 0 10px 16px;

            &[hidden] {
                display: none;
            }
        }

        .a-select-checkbox__item {
            position: relative;
            display: flex;
            align-items: center;
            background: #CFD0D3;
            font-size: 1.4rem;
            color: var.$color-text-medium;
            border-radius: 2px;
            margin: 8px 0 0 8px;
            padding: 2px 8px;
            overflow: hidden;
            pointer-events: auto;
        }

        .a-select-checkbox__deselect {
            position: relative;
            width: 20px;
            height: 20px;
            margin-left: 8px;
            transition: background-color .3s ease 0s;

            @include mixin.hover {
                background: var.$color-danger-20;
            }

            &[hidden] {
                display: none;
            }

            &::before,
            &::after {
                position: absolute;
                display: block;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                content: "";
                margin: auto;
                width: 2px;
                height: 14px;
                border-radius: 1px;
                background: var.$color-text-medium;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }

        .a-select-checkbox__filed {
            position: relative;
            min-width: 240px;
            min-height: 48px;
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            padding: 0 28px 0 0;
            background: var.$color-utils-background;

            &.has-action {
                padding-right: 68px;
            }

            &.is-error {
                border-color: var.$color-danger-50;
            }

            &.is-disabled {
                background: var.$color-gray-10;
                opacity: .6;
            }

            &.is-readonly {
                background: var.$color-gray-10;
            }
        }

        .a-select-checkbox__control {
            text-align: left;
            width: 100%;
            min-height: 46px;
            height: 100%;
            font-size: 1.6rem;
            padding: 10px 16px;

            &[aria-expanded="true"] {
                pointer-events: none;
            }

            &.is-selected {
                position: absolute;
                color: var.$color-text-medium;
                padding: 0;

                .a-select-checkbox__placeholder {
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    top: -110%;
                    white-space: nowrap;
                    transform: translateY(-100%);
                    overflow: hidden;
                }
            }

            &:focus-visible {
                outline: none;

                &::before {
                    outline: 2px solid #9CCBF3;
                }
            }

            &[disabled] {
                cursor: default;
                color: var.$color-text-disabled;
                border-color: var.$color-gray-10;
            }

            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 16px;
                display: block;
                content: "";
                width: 10px;
                height: 10px;
                margin: auto;
                border-radius: 2px;
                border-right: solid 2px var.$color-text-medium;
                border-bottom: solid 2px var.$color-text-medium;
                transform: rotate(45deg);
                pointer-events: none;
            }

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                content: "";
            }
        }

        .a-select-checkbox__placeholder {
            color: var.$color-text-disabled;
        }

        .a-select-checkbox__options {
            position: absolute;
            bottom: -4px;
            transform: translateY(100%);
            border: solid 1px #F2F6F9;
            border-radius: 2px;
            width: 100%;
            max-height: 400px;
            overflow: auto;
            background: var.$color-utils-background;
            box-shadow: var.$effect-popover-shadow;
            z-index: 100;

            > .a-select-checkbox__optionlist > .a-select-checkbox__option {
                border-top: solid 1px var.$color-gray-10;
            }
        }

        .a-select-checkbox__optionlist {
            position: relative;
            flex-direction: column;
            padding: 8px 0;
            display:flex;

            .a-select-checkbox__optionlist {
                padding: 0;

                .a-select-checkbox__option {
                    &::before {
                        left: 44px;
                    }

                    &::after {
                        left: 48px;
                    }
                }

                ::v-deep(.a-select-option) {
                    padding-left: 72px;
                }
            }
        }

        .a-select-checkbox__option {
            position: relative;
            cursor: pointer;
            color: var.$color-text-medium;

            > ::v-deep(.a-select-option) {
                padding-left: 44px;
                transition: background-color .3s ease 0s;

                @include mixin.hover {
                    background: #F2F6F9;
                }
            }

            &::before,
            &::after {
                position: absolute;
                content: "";
                border-radius: 2px;
            }

            &::before {
                top: 14px;
                left: 16px;
                display: block;
                width: 20px;
                height: 20px;
                background: var.$color-utils-background;
                border: solid 2px currentColor;
                transition: background-color .3s ease 0s;
            }

            &::after {
                top: 19px;
                left: 20px;
                display: block;
                content: "";
                width: 11px;
                height: 7px;
                border-left: solid 2px var.$color-text-white;
                border-bottom: solid 2px var.$color-text-white;
                transform: rotate(-45deg);
                opacity: 0;
            }

            &[aria-disabled="true"] {
                cursor: default;
                opacity: .6;
                background: var.$color-gray-10;
                color: var.$color-text-disabled;
            }

            &[aria-selected="true"] > ::v-deep(.a-select-option) {
                background: #F2F6F9;
            }

            &[aria-checked="true"] {
                color: var.$color-primary-50;

                &::before {
                    background: var.$color-primary-50;
                }

                &::after {
                    opacity: 1;
                }
            }

            &[aria-checked="mixed"] {
                &::before {
                    background: var.$color-primary-50;
                    border-color: var.$color-primary-50;
                }

                &::after {
                    top: 23px;
                    left: 21px;
                    width: 10px;
                    height: 0;
                    border-width: 2px;
                    transform: none;
                    opacity: 1;
                }
            }
        }

        .a-select-checkbox__action {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            right: 44px;
            bottom: 0;

            ::v-deep(button) {
                display: flex;
                position: relative;
                color: var.$color-text-medium;
                overflow: hidden;

                &[disabled] {
                    color: var.$color-text-disabled;
                    opacity: .6;
                }
            }
        }

        .a-select-checkbox__region {
            position: relative;
            overflow: hidden;
        }
    }
}
</style>
