<template>
    <component :is="name"></component>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
// import icon components.
const files = require.context('./_resources', true, /\.vue$/);
const components = {};
const iconNames = [];

files.keys().forEach((key) => {
    const name = key.replace(/(\.\/|\.vue)/g, '');

    iconNames.push(name);
    components[name] = files(key).default;
});

export {iconNames};
export default defineComponent({
    components,
    props: {
        name: {
            type: String,
            required: true
        }
    }
});
</script>

<style lang="scss"></style>
