<template>
    <div class="a-preview-question">
        <div class="a-preview-question__inner">
            <div class="a-preview-question__header">
                <div class="a-preview-question__navigation">
                    <p class="a-preview-question__title">アンケート</p>
                </div>
            </div>

            <form class="a-preview-question__form" @submit.prevent v-html="$attrs.innerHTML"></form>

            <button class="a-preview-question__submit" @click.prevent disabled>回答を送信する</button>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false
});
</script>

<style lang="scss" scoped>
.a-preview-question {
    position: relative;
    width: 390px;
    aspect-ratio: 390 / 790;
    transform: scale((280 / 390));
    padding: 48px 20px 16px 20px;
    min-height: 790px;
    color: #000;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        content: "";
        background-image: url(/img/bg-iphone-mockup.png);
        background-size: cover;
        pointer-events: none;
        z-index: 2;
    }

    @at-root {
        .a-preview-question__inner {
            position: relative;
            background: var.$color-utils-background;
            overflow: hidden scroll;
            height: calc(100% - 50px);
            border-radius: 8px;
            margin-top: 50px;
        }

        .a-preview-question__header {
            position: fixed;
            top: 48px;
            left: 22px;
            right: 18px;
            z-index: 1;
        }

        .a-preview-question__navigation {
            display: flex;
            align-items: center;
            background: var.$color-utils-background;
            padding: 0 16px;
            height: 50px;
            box-shadow: 0 1px 4px rgba(#000, .1);
            z-index: 1;
        }

        .a-preview-question__title {
            position: relative;
            font-size: 1.6rem;
            line-height: 1;
            letter-spacing: 0;
            padding: 0 44px;
            width: 100%;
            text-align: center;
            font-weight: bold;

            &::before {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 12px;
                content: "";
                width: 12px;
                height: 12px;
                margin: auto;
                border-left: solid 1px #000;
                border-bottom: solid 1px #000;
                transform: rotate(45deg);
            }
        }

        .a-preview-question__submit {
            position: sticky;
            bottom: 2px;
            left: 0;
            right: 0;
            width: 100%;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #23AC38;
            color: var.$color-text-white;

            &[disabled] {
                cursor: default;
                background: #808080;
            }
        }

        .a-preview-question__form {

            ::v-deep(.question) {
                background: #EBF2AF;
                min-height: 54px;
                padding: 18px 7px;
                font-size: 1.3rem;
                line-height: (18 / 13);
                letter-spacing: -.03em;
            }

            ::v-deep(label) {
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                width: 100%;
                background: #fff;
                min-height: 36px;
                font-size: 1.3rem;
                line-height: (18 / 13);
                letter-spacing: -.03em;
                padding: 8px 40px 8px 20px;

                + label {
                    border-top: solid 1px rgba(#CACACA, .35);
                }
            }

            ::v-deep(input[type="radio"]) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0;

                &:checked + span::after {
                    content: "";
                }

                + span {
                    &::before,
                    &::after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        display: block;
                        margin: auto;
                        border-radius: 50%;
                    }

                    &::before {
                        content: "";
                        right: 20px;
                        width: 16px;
                        height: 16px;
                        border: solid 1px #23AC38;
                    }

                    &::after {
                        right: 24px;
                        width: 8px;
                        height: 8px;
                        background: #23AC38;
                    }
                }
            }

            ::v-deep(input[type="checkbox"]) {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                opacity: 0;

                &:checked + span::after {
                    content: "";
                }

                + span {
                    &::before,
                    &::after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        display: block;
                        margin: auto;
                    }

                    &::before {
                        content: "";
                        right: 20px;
                        width: 16px;
                        height: 16px;
                        border: solid 1px #9CB250;
                    }

                    &::after {
                        right: 25px;
                        width: 8.5px;
                        height: 10.25px;
                        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20width%3D%2210%22%20height%3D%2212%22%20viewBox%3D%220%200%2010%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.5%207C1.18841%207.64809%202.5%209.20363%202.5%2011C2.5%2012.7964%204.21739%204.54541%209%201%22%20stroke%3D%22%233E9D4C%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E');
                    }
                }
            }

            ::v-deep(textarea) {
                width: calc(100% - 14px);
                height: 72px;
                margin: 6px 8px;
                border: solid 1px #000;
                border-radius: 4px;
                padding: 4px;
                resize: none;
            }
        }
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media, _:future, .a-preview-question__inner {
        height: 673px;
    }

    _::-webkit-full-page-media, _:future, .a-preview-question__submit {
        bottom: -188px;
        transform: translateY(2px);
    }
}
</style>
