<template>
    <div
        class="a-radio-button-group"
        :class="{'a-radio-button-group--row': direction === 'row'}"
        role="radiogroup"
        :aria-labelledby="$attrs['aria-labelledby'] || null"
        :aria-describedby="$attrs['aria-describedby'] || null"
    >
        <div
            v-for="(item, index) in items"
            :key="item"
            class="a-radio-button-group__item"
        >
            <slot name="radio" :item="{...item}" :index="index" />
        </div>

        <div
            v-if="$slots['last-item']"
            class="a-radio-button-group__item"
        >
            <slot name="last-item" />
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        direction: {
            type: String,
            default: 'column',
            validator: (value) => ['row', 'column'].includes(value)
        },
        items: {
            type: Array,
            default: () => [
                {
                    id: 'radio-1',
                    name: 'radio_group_root',
                    label: 'radio 1',
                    value: '1'
                }, {
                    id: 'radio-2',
                    name: 'radio_group_root',
                    label: 'radio 2',
                    value: '2'
                }, {
                    id: 'radio-3',
                    name: 'radio_group_root',
                    label: 'radio 3',
                    value: '3'
                }
            ]
        }
    }
});
</script>

<style lang="scss" scoped>
.a-radio-button-group {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
        margin-top: 8px;
    }

    &--row {
        flex-direction: row;

        > :not(:root) {

            &:not(:first-child) {
                margin-top: 0;
                margin-left: 24px;
            }
        }
    }

    @at-root {
        .a-radio-button-group__item {

            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }
}
</style>
