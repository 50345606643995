<template>
    <div class="a-form-header" :class="{[`a-form-header--${titleAlign}`]: titleAlign !== 'left'}">
        <div class="a-form-header__header">
            <h1 v-if="level === 1" class="a-form-header__title">{{title}}</h1>
            <h2 v-else-if="level === 2" class="a-form-header__title">{{title}}</h2>
            <h3 v-else class="a-form-header__title">{{title}}</h3>
            <p v-if="description !== ''" class="a-form-header__description">{{description}}</p>
        </div>

        <div v-if="$slots.actions" class="a-form-header__actions"><slot name="actions" /></div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        level: {
            type: Number,
            default: 1,
            validator: (value) => value >= 1 && 3 >= value
        },
        title: {
            type: String,
            default: 'Form Title',
            required: true
        },
        titleAlign: {
            type: String,
            default: 'left',
            validator: (value) => ['left', 'center', 'right'].includes(value)
        },
        description: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss" scoped>
.a-form-header {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .a-card & {
        padding: 24px;
    }

    &--center {
        .a-form-header__header {
            text-align: center;
        }
    }

    &--right {
        .a-form-header__header {
            text-align: right;
        }
    }

    @at-root {
        .a-form-header__header {
            flex-basis: 100%;
        }

        .a-form-header__title {
            color: var.$color-text-high;

            @at-root {
                h1#{&} {
                    font-size: 3.6rem;
                    line-height: (44 / 36);
                }

                h2#{&} {
                    font-size: 3.2rem;
                    line-height: (40 / 32);
                }

                h3#{&} {
                    font-size: 2.4rem;
                    line-height: (32 / 24);
                }
            }
        }

        .a-form-header__description {
            font-size: 1.6rem;
            line-height: (22 / 16);
            margin-top: 16px;
            color: var.$color-text-medium;
        }

        .a-form-header__actions {
            flex: 0 0 auto;
        }
    }
}
</style>
