<template>
    <slot name="trigger" :trigger="appearance === 'default' ? {
        'aria-haspopup': true,
        'aria-expanded': isExpanded,
        'aria-controls': id
    } : {}" />

    <teleport to="body">
        <div
            class="a-modal"
            :class="{
                [`a-modal--${size}`]: size !== 'default',
                [`a-modal--${appearance}`]: appearance !== 'default'
            }"
            :hidden="!isExpanded"
            @keydown.esc="closable ? $emit('close', id) : undefined"
            @click.self="closable ? $emit('close', id) : undefined"
        >
            <div
                ref="modal"
                :id="id"
                class="a-modal__inner"
                :aria-labelledby="$slots.header ? `${id}-title` : undefined"
                :aria-describedby="$attrs['aria-describedby']"
                aria-modal="true"
                :role="appearance === 'default' ? 'dialog' : 'alertdialog'"
                tabindex="-1"
            >
                <slot name="header" :header="{id: `${id}-title`}" />

                <div class="a-modal__section"><slot /></div>

                <slot name="footer" />
            </div>
        </div>
    </teleport>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, watch, nextTick
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            required: true
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        appearance: {
            type: String,
            default: 'defalut',
            validator: (value) => ['default', 'alert', 'confirm'].includes(value)
        },
        closable: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: 'default',
            validator: (value) => ['small', 'default', 'large', 'x-large'].includes(value)
        }
    },
    setup(props) {
        const modal = ref(null);
        const methods = {
            /**
             * tabindexControl - tabindex属性の制御
             *
             * @param {HTMLElement} ignoreElementRoot - tabindexの制御から除外するルート要素
             * @param {Boolean} isSetIndex            - tabindex属性を付与する(true)か、外す(false)か
             */
            tabindexControl(ignoreElementRoot, isSetIndex) {
                if (!ignoreElementRoot) {
                    return;
                }

                const focusableElementSelector = 'a[href], area[href], [tabindex], button, input, select, textarea, iframe, object, audio, video, embed, summary';
                const focusableElements = [...document.querySelectorAll(focusableElementSelector)];
                const ignoreElements = [...ignoreElementRoot.querySelectorAll(focusableElementSelector)].concat(ignoreElementRoot);
                const targetElements = focusableElements.filter((elemnet) => !ignoreElements.includes(elemnet));

                targetElements.forEach((_, index) => {
                    const element = targetElements[index];

                    // 付与する場合
                    if (isSetIndex) {
                        // `element.tabIndex`だとフォーカス可能な要素から0が取得できてしまうので`getAttribute`を使う
                        if (element.getAttribute('tabindex') !== null) {
                            element.dataset.tabindex = element.tabIndex;
                        }

                        element.tabIndex = -1;

                    // 外す場合
                    } else {
                        // data-tabindex属性を持っている場合
                        if (Object.prototype.hasOwnProperty.call(element.dataset, 'tabindex')) {
                            element.tabIndex = element.dataset.tabindex;
                            element.removeAttribute('data-tabindex');

                            return;
                        }

                        element.removeAttribute('tabindex');
                    }
                });
            }
        };

        watch(() => props.isExpanded, (isExpanded) => {
            if (!isExpanded) {
                methods.tabindexControl(modal.value, false);

                return;
            }

            nextTick(() => {
                methods.tabindexControl(modal.value, true);
                modal.value.focus();
            });
        });

        return {modal};
    }
});
</script>

<style lang="scss" scoped>
.a-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 40px 24px;
    background: var.$color-utils-scrim;
    z-index: 99;
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease 0s;

    &--small {
        .a-modal__inner {
            width: 400px;
        }
    }

    &--large {
        .a-modal__inner {
            width: 800px;
        }
    }

    &--x-large {
        .a-modal__inner {
            width: 968px;
        }
    }

    &[hidden] {
        visibility: hidden;
        opacity: 0;

        .a-modal__inner {
            transform: scale(.5);
        }
    }

    @at-root {
        .a-modal__inner {
            display: flex;
            flex-direction: column;
            width: 600px;
            max-height: 100%;
            border-radius: 4px;
            background: var.$color-utils-background;
            transform: scale(1);
            transition: transform .3s ease 0s;
        }

        .a-modal__section {
            padding: 8px 24px;
            overflow: hidden auto;

            &:last-child {
                padding-bottom: 24px;
            }
        }
    }
}
</style>
