<template>
    <div class="a-toggle">
        <input :id="$attrs.id"
            type='checkbox'
            class="a-toggle__input"
            :value="$attrs.value"
            v-model="model"
            :name="$attrs.name"
        >
        <label :for="$attrs.id" class="a-toggle__label"/>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    props: {
        modelValue: {
            type: Boolean,
            defalt: false
        }
    },
    setup(props, $) {
        const model = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                $.emit('update:model-value', value);
            }
        });
        return {model};
    }
});
</script>

<style lang="scss" scoped>
.a-toggle__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  margin-right: 400px;
}

.a-toggle__label {
  width: 55px;
  height: 25px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 40px;
  transition: 0.4s;
  box-sizing: border-box;

}

.a-toggle__label:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
}

.a-toggle__input:checked + .a-toggle__label {
  background-color: var.$color-primary-50;
}

.a-toggle__input:checked + .a-toggle__label:after {
  left: 30px;
}

.a-toggle {
  position: relative;
  width: 55px;
  height: 25px;
  margin: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 0px;
}
</style>
