<template>
    <main class="t-main">
        <section-card title="サービス利用申請">
            <template v-slot:utils>
                <step-list v-bind="stepList" :current-step="stepList.currentStep.value" />
            </template>

            <banner appearance="information">
                <p>メールアドレスは間違えないように入力してください</p>

                <ol>
                    <li>基本情報を入力してください。</li>
                    <li>必要事項を入力後「次へ進む」ボタンをクリックしてください。</li>
                    <li>確認画面が表示されるので「申請する」ボタンをクリックしてください。</li>
                    <li>完了ページが表示されたら登録が完了します。登録完了後は完了通知がメールで送られます。</li>
                </ol>
            </banner>

            <forms id="client-form" @submit.prevent="$emit('validate-client', $event)">
                <banner v-if="formErrors.system.value.length" appearance="danger">
                    <template v-slot:icon><icon name="Error" /></template>
                    <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
                </banner>

                <definition-list :items="forms.client">
                    <template v-slot:item-0="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <checkbox-group direction="row" :items="itemProps.description.items.value">
                                <template v-slot:checkbox="slotProps">
                                    <service-checkbox
                                        v-bind="slotProps.item"
                                        :name="itemProps.description.name"
                                        :indeterminate="slotProps.indeterminate"
                                        :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                        :aria-errormessage="`${itemProps.description.id}-invalid`"
                                        :aria-required="itemProps.term.isRequire"
                                        :model-value="models.client[itemProps.description.name]"
                                        @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                                    >
                                        {{slotProps.item.label}}
                                        <template v-slot:image><img :src="slotProps.item.image" alt=""></template>
                                    </service-checkbox>
                                </template>
                            </checkbox-group>
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-1="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-2="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-3="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-4="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-5="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-6="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-7="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-8="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-9="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                </definition-list>

                <template v-slot:footer>
                    <form-footer>
                        <button-group layout="end">
                            <standard-button :disabled="forms.isLoading.value" @click="$router.back()">前へ戻る</standard-button>
                            <standard-button :is-loading="forms.isLoading.value" type="submit" appearance="primary">次へ進む</standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>
        </section-card>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Banner from '@/components/01_Atoms/Banners';
import Icon from '@/components/01_Atoms/Icons';
import {SectionCard} from '@/components/01_Atoms/Cards';
import Forms, {FormFooter, FormLabel, FormMessage} from '@/components/01_Atoms/Forms';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import StepList from '@/components/01_Atoms/StepLists';
import {TextField} from '@/components/01_Atoms/TextFields';
import CheckboxGroup, {ServiceCheckbox} from '@/components/01_Atoms/Checkboxes';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';

export default defineComponent({
    inheritAttrs: false,
    emits: [
        'validate-client',
        'create-client',
        'update:name',
        'update:department',
        'update:position',
        'update:contact_name',
        'update:furigana',
        'update:address',
        'update:tel',
        'update:email',
        'update:email_confirm',
        'update:data_services'
    ],
    components: {
        Banner, Icon, SectionCard, Forms, FormFooter, FormLabel, FormMessage, TextField, CheckboxGroup, ServiceCheckbox, DefinitionList, DefinitionListTerm, DefinitionListDescription, StepList, ButtonGroup, StandardButton
    },
    props: {
        stepList: {
            type: Object,
            default: () => {}
        },
        forms: {
            type: Object,
            default: () => {}
        },
        formErrors: {
            type: Object,
            default: () => {}
        },
        models: {
            type: Object,
            default: () => {}
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 64px 24px;
}
</style>
