<template>
    <thead class="a-table-head">
        <slot
            v-for="row in rows"
            :key="row"
            name="header-row"
            :cells="row.map((cell) => {
                cell.isHead = true;

                return cell;
            })"
        />
    </thead>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        rows: {
            type: Array,
            default: () => ([
                [
                    {
                        key: 'head-1',
                        content: 'head 1'
                    }, {
                        key: 'head-2',
                        content: 'head 2'
                    }, {
                        key: 'head-3',
                        content: 'head 3'
                    }
                ]
            ])
        }
    }
});
</script>

<style lang="scss" scoped>
.a-table-head {

    > :not(:root) {
        height: 50px;
    }

    ::v-deep(.a-table-cell) {
        font-size: 1rem;
        font-weight: normal;
        color: var.$color-text-high;
    }
}
</style>
