<template>
    <teleport to="body">
        <div class="a-toasts" role="status" aria-live="polite" @transitionend="methods.hundleTransitionend">
            <slot name="toast" :toast="{...stacks[0], ...{ariaAtomic: true, hidden: !isVisible}}" />
        </div>
    </teleport>
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, watch
} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        toasts: {
            type: Array,
            default: () => []
        },
        timeout: {
            type: Number,
            default: 5000
        }
    },
    setup(props) {
        const isVisible = ref(false);
        const stacks = ref([...props.toasts]);

        watch(() => props.toasts, (toasts) => {
            stacks.value.push(toasts.slice(-1)[0]);

            if (!isVisible.value) {
                isVisible.value = true;
            }
        }, {deep: true});

        watch(isVisible, (flag) => {
            if (!flag) {
                return;
            }

            window.setTimeout(() => {
                isVisible.value = false;
            }, props.timeout);
        });

        const methods = {
            hundleTransitionend(event) {
                const {propertyName} = event;

                if (isVisible.value || propertyName !== 'visibility') {
                    return;
                }

                stacks.value.shift();

                if (stacks.value.length) {
                    isVisible.value = true;
                }
            }
        };

        return {isVisible, methods, stacks};
    }
});
</script>

<style lang="scss" scoped>
.a-toasts {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 16px;
    bottom: 16px;
    left: 0;
    z-index: 200;
    pointer-events: none;
}
</style>
