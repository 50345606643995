<template>
    <div ref="picker" class="a-date-picker" @focusout="methods.focusout">
        <div class="a-date-picker__controls">
            <div :id="$attrs.id" class="a-date-picker__date" aria-live="polite">{{currentYear}}年 {{currentMonth}}月</div>
            <ul class="a-date-picker__buttons">
                <li class="a-date-picker__item">
                    <button class="a-date-picker__previous" type="button" tabindex="-1" :disabled="calendarControlSupport.previous" @click="methods.updateCalendar(-1)">
                        <span class="u-altText">前の月</span>
                    </button>
                </li>
                <li class="a-date-picker__item">
                    <button class="a-date-picker__next" type="button" tabindex="-1" :disabled="calendarControlSupport.next" @click="methods.updateCalendar(1)">
                        <span class="u-altText">次の月</span>
                    </button>
                </li>
            </ul>
        </div>

        <table
            class="a-date-picker__table"
            :aria-labelledby="$attrs.id"
            @keydown.up.prevent="methods.updateCurrentDate(arrowKeyControlSupport.up, true)"
            @keydown.right.prevent="methods.updateCurrentDate(arrowKeyControlSupport.right, true)"
            @keydown.down.prevent="methods.updateCurrentDate(arrowKeyControlSupport.down, true)"
            @keydown.left.prevent="methods.updateCurrentDate(arrowKeyControlSupport.left, true)"
            @keydown.esc="$emit('close', $event)"
        >
            <thead class="a-date-picker__header">
                <tr class="a-date-picker__row">
                    <th
                        v-for="week in dayOfWeeks"
                        :key="week"
                        :abbr="week.abbreviation"
                        class="a-date-picker__th"
                    >{{week.label}}</th>
                </tr>
            </thead>
            <tbody class="a-date-picker__body">
                <tr
                    v-for="weeks in calendar"
                    :key="weeks"
                    class="a-date-picker__row"
                >
                    <td
                        v-for="day in weeks"
                        :key="day"
                        class="a-date-picker__td"
                    >
                        <button
                            class="a-date-picker__button"
                            :class="{'is-disabled': visibleAroundDays && day.isAroundDay}"
                            :aria-label="day.alternative"
                            :aria-selected="modelValue !== null && modelValue.getTime() === day.date.getTime()"
                            :disabled="day.disabled"
                            :tabindex="currentDate.getTime() === day.date.getTime() ? 0 : -1"
                            type="button"
                            :hidden="!visibleAroundDays && day.isAroundDay"
                            @click="methods.select(day.date) & $emit('close', $event)"
                            @keydown.enter="methods.select(day.date) & $emit('close', $event)"
                        >{{day.label}}</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
// import composition-api.
import {
    defineComponent, computed, nextTick, watch, ref
} from 'vue';

const today = new Date();

export default defineComponent({
    inheritAttrs: false,
    props: {
        visibleAroundDays: {
            type: Boolean,
            default: true
        },
        year: {
            type: Number,
            default: today.getFullYear()
        },
        month: {
            type: Number,
            default: today.getMonth() + 1
        },
        firstDayOfWeek: {
            type: Number,
            default: 0,
            validator: (value) => 0 <= value || value <= 6
        },
        range: {
            type: Array,
            default: () => [],
            validator: (value) => {
                if (!value.length) {
                    return true;
                }

                return value.length === 2 && value.every((val) => val instanceof Date);
            }
        },
        modelValue: {
            type: Object,
            default: () => null,
            validator: (value) => value === null || value instanceof Date
        }
    },
    setup(props, $) {
        const currentYear = ref(props.modelValue === null ? props.year : props.modelValue.getFullYear());
        const currentMonth = ref(props.modelValue === null ? props.month : props.modelValue.getMonth() + 1);
        const currentDate = ref(props.modelValue || new Date(currentYear.value, currentMonth.value - 1, 1));
        const picker = ref(null);
        let isComputing = false;
        const dayOfWeeks = [
            {label: '日', abbreviation: '日曜日'},
            {label: '月', abbreviation: '月曜日'},
            {label: '火', abbreviation: '火曜日'},
            {label: '水', abbreviation: '水曜日'},
            {label: '木', abbreviation: '木曜日'},
            {label: '金', abbreviation: '金曜日'},
            {label: '土', abbreviation: '土曜日'}
        ].reduce((array, week, index) => {
            const data = {...week};

            data.sort = index >= props.firstDayOfWeek ? index - props.firstDayOfWeek : 7 - (props.firstDayOfWeek - index);
            array.push(data);

            return array;
        }, []).sort((a, b) => a.sort - b.sort);
        const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();
        const calendar = computed(() => {
            const {firstDayOfWeek, range} = props;
            const [from, to] = range;
            const year = currentYear.value;
            const month = currentMonth.value;
            const current = new Date(year, month - 1, 1);
            const daysInCurrentMonth = getDaysInMonth(year, month);
            const beforeBlanks = current.getDay() < firstDayOfWeek ? (current.getDay() - firstDayOfWeek) + 7 : current.getDay() - firstDayOfWeek;
            const weekInMonth = Math.ceil((daysInCurrentMonth + beforeBlanks) / 7);
            const calendarData = [];
            const setRange = range.length !== 0;

            isComputing = true;

            for (let index = 0; index < weekInMonth; index += 1) {
                const weeks = [];

                for (let idx = 0; idx < 7; idx += 1) {
                    const currentLoopCount = ((7 * index) + idx);
                    const day = (currentLoopCount + 1) - beforeBlanks;
                    const isOver = day > daysInCurrentMonth;
                    let targetMonth = month - 1;
                    let date = null;

                    if (isOver) {
                        targetMonth += 1;
                    }

                    date = new Date(year, targetMonth, isOver ? (day - daysInCurrentMonth) : day);
                    weeks.push({
                        isAroundDay: currentLoopCount < beforeBlanks || isOver,
                        label: date.getDate(),
                        alternative: `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`,
                        date,
                        disabled: setRange ? date.getTime() < from.getTime() || date.getTime() > to.getTime() : false
                    });
                }

                calendarData.push(weeks);
            }

            nextTick(() => {
                isComputing = false;
            });

            return calendarData;
        });
        const calendarControlSupport = computed(() => {
            const {range} = props;
            const [from, to] = range.map((date) => date.getTime());
            const current = currentDate.value;
            const result = {
                previous: false,
                next: false
            };

            if (!range.length) {
                return result;
            }

            const previous = new Date(current.getFullYear(), current.getMonth(), 0); // 前月の末日
            const next = new Date(current.getFullYear(), current.getMonth() + 1, 1); // 翌月の1日

            result.previous = previous.getTime() < from;
            result.next = next.getTime() > to;

            return result;
        });
        const arrowKeyControlSupport = computed(() => {
            const {range} = props;
            const [from, to] = range.map((date) => date.getTime());
            const current = currentDate.value;
            const date = {
                year: current.getFullYear(),
                month: current.getMonth(),
                day: current.getDate()
            };
            const result = {
                up: new Date(date.year, date.month, date.day - 7),
                down: new Date(date.year, date.month, date.day + 7),
                left: new Date(date.year, date.month, date.day - 1),
                right: new Date(date.year, date.month, date.day + 1)
            };

            if (range.length !== 0) {
                const keys = Object.keys(result);

                for (let index = 0; index < keys.length; index += 1) {
                    const targetTime = result[keys[index]].getTime();

                    if (targetTime < from || targetTime > to) {
                        result[keys[index]] = current;
                    }
                }
            }

            return result;
        });
        const methods = {
            select(date) {
                currentYear.value = date.getFullYear();
                currentMonth.value = date.getMonth() + 1;
                currentDate.value = date;

                $.emit('update:model-value', date);
            },
            updateCalendar(index) {
                const {modelValue, range} = props;
                let month = currentMonth.value + index;

                if (month < 1) {
                    month = 12;
                    currentYear.value -= 1;
                } else if (month > 12) {
                    month = 1;
                    currentYear.value += 1;
                }

                if (
                    modelValue !== null
                    && modelValue.getFullYear() === currentYear.value
                    && modelValue.getMonth() + 1 === month
                ) {
                    currentDate.value = modelValue;
                } else if (range.length) {
                    const [from, to] = range;
                    const currentTime = new Date(currentYear.value, month - 1, 1).getTime();

                    // フォーカスカレントが範囲外（過去）の場合
                    if (currentTime < from.getTime()) {
                        currentDate.value = from;

                    // フォーカスカレントが範囲外（未来）の場合
                    } else if (currentTime > to.getTime()) {
                        currentDate.value = to;
                    } else {
                        currentDate.value = new Date(currentYear.value, month - 1, 1);
                    }
                } else {
                    currentDate.value = new Date(currentYear.value, month - 1, 1);
                }

                currentMonth.value = month;

                nextTick(() => {
                    picker.value.querySelector('[tabindex="0"]').focus();
                });
            },
            updateCurrentDate(date, focus) {
                currentYear.value = date.getFullYear();
                currentMonth.value = date.getMonth() + 1;
                currentDate.value = date;

                if (focus) {
                    nextTick(() => {
                        picker.value.querySelector('[tabindex="0"]').focus();
                    });
                }
            },
            init() {
                const {range} = props;

                if (!range.length) {
                    return;
                }

                const [from, to] = range;
                const currentTime = currentDate.value.getTime();

                // フォーカスカレントが範囲外（過去）の場合
                if (currentTime < from.getTime()) {
                    methods.updateCurrentDate(from);

                // フォーカスカレントが範囲外（未来）の場合
                } else if (currentTime > to.getTime()) {
                    methods.updateCurrentDate(to);
                }
            },
            focusout(event) {
                const {relatedTarget, currentTarget} = event;

                if (currentTarget.hidden || isComputing) {
                    return;
                }

                if (!currentTarget.parentElement.contains(relatedTarget)) {
                    $.emit('close', event);
                }
            },
            focusControl() {
                if (!picker.value) {
                    return;
                }

                picker.value.querySelector('[tabindex="0"]').focus();
            }
        };

        watch(() => [props.modelValue, props.range], ([value]) => {
            if (value instanceof Date) {
                methods.updateCurrentDate(value);
            }

            methods.init();
        }, {immediate: true});

        return {
            dayOfWeeks, currentYear, currentMonth, currentDate, picker, calendar, arrowKeyControlSupport, calendarControlSupport, methods
        };
    }
});
</script>

<style lang="scss" scoped>
.a-date-picker {
    padding: 8px;
    width: 317px;
    background: var.$color-utils-background;

    @at-root {
        .a-date-picker__controls {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 12px;
        }

        .a-date-picker__date {
            color: var.$color-text-medium;
            font-size: 2rem;
            font-weight: bold;
            line-height: (28 / 20);
        }

        .a-date-picker__buttons {
            display: flex;
            align-items: center;
        }

        .a-date-picker__previous,
        .a-date-picker__next {
            position: relative;
            width: 40px;
            height: 40px;
            color: var.$color-text-medium;
            background: var.$color-utils-background;
            transition: background-color .3s ease 0s;
            overflow: hidden;

            @include mixin.hover {
                background: var.$color-primary-10;
            }

            &::before {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                content: "";
                width: 8px;
                height: 8px;
                margin: auto;
                border-top: solid 2px currentColor;
            }

            &[disabled] {
                cursor: default;
                color: var.$color-text-disabled;
                opacity: .6;

                @include mixin.hover {
                    background: var.$color-utils-background;
                }
            }
        }

        .a-date-picker__previous {
            border-radius: 2px 0 0 2px;

            &::before {
                right: 0;
                left: 0;
                border-left: solid 2px currentColor;
                transform: rotate(-45deg);
            }
        }

        .a-date-picker__next {
            border-radius: 0 2px 2px 0;

            &::before {
                right: 0;
                left: -2px;
                border-right: solid 2px currentColor;
                transform: rotate(45deg);
            }
        }

        .a-date-picker__table {
            width: 100%;
        }

        .a-date-picker__th,
        .a-date-picker__td {
            font-size: 1.4rem;
            line-height: 1;
            color: var.$color-text-medium;
            height: 40px;
        }

        .a-date-picker__button {
            position: relative;
            width: 100%;
            height: 100%;
            color: var.$color-text-medium;
            font-size: 1.4rem;
            line-height: 1;
            font-weight: bold;
            transition: color .3s ease 0s;
            z-index: 0;

            &:focus {
                color: var.$color-primary-50;

                &::before {
                    opacity: 1;
                    background: var.$color-primary-10;
                }
            }

            &:focus-visible {
                outline: none;
            }

            @include mixin.hover {
                color: var.$color-primary-50;

                &::before {
                    opacity: 1;
                    background: var.$color-primary-10;
                }
            }

            &.is-disabled,
            &[disabled] {
                cursor: default;
                color: var.$color-text-disabled;
                opacity: .6;

                @include mixin.hover {

                    &::before {
                        opacity: 0;
                    }
                }
            }

            &[aria-selected="true"] {
                color: var.$color-text-white;

                &::before {
                    opacity: 1;
                    background: var.$color-primary-50;
                }

                @include mixin.hover {
                    color: var.$color-text-white;

                    &::before {
                        background: var.$color-primary-50;
                    }
                }
            }

            &[hidden] {
                display: none;
            }

            &::before {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                content: "";
                width: 32px;
                height: 32px;
                border-radius: 50%;
                z-index: -1;
                opacity: 0;
                transition: opacity .3s ease 0s, background-color .3s ease 0s;
            }
        }
    }
}
</style>
