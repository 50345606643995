<template>
    <reissue-password
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        :modal="sentEmailModal"
        v-model:email="models.reissuePassword.email"
        @set-model-value="(name, value) => models.reissuePassword[name] = value"
        @reissue-password="methods.validateEmail($event, models.reissuePassword)"
    />
</template>

<script>
// import composition-api.
import {defineComponent, ref, reactive} from 'vue';
import axios from 'axios';
import Validate from '@/validates';
import {ReissuePassword} from '@/components/04_Templates/Client';

export default defineComponent({
    inheritAttrs: false,
    components: {ReissuePassword},
    setup() {
        /**
         * models - フォームモデル
         * @type {Object}
         * @property {reactive<Object>} reissuePassword - パスワード再発行のモデル
         * @property {String} reissuePassword.email     - 登録メールアドレス
         */
        const models = {
            reissuePassword: reactive({
                email: ''
            })
        };

        /**
         * forms - フォームのデータ
         * @type {Object}
         * @property {ref<Boolean>} isLoading - 読み込み中フラグ
         * @property {Object}       header    - フォームヘッダー部分のデータ
         * @property {Object}       email     - 登録メールアドレス入力欄のデータ
         */
        const forms = {
            isLoading: ref(false),
            header: {
                title: 'パスワード再発行',
                description: '登録時に使用したメールアドレスを入力してください。'
            },
            email: {
                id: 'email',
                inputType: 'email',
                name: 'email',
                label: 'メールアドレス',
                isRequire: true
            }
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {ref<Array>} system          - APIから返却されたシステムエラー
         * @property {Object}     reissuePassword - メールアドレス入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            reissuePassword: reactive({})
        };

        /**
        * validates - フォームバリデーションオブジェクト
        * @type {Object}
        */
        const validates = {
            reissuePassword: new Validate([forms.email], models.reissuePassword)
        };

        /**
         * sentEmailModal - メール送信完了モーダルのデータ
         * @type {Object}
         * @property {String}       id         - モーダルのID名
         * @property {String}       appearance - モーダルの見た目
         * @property {Boolean}      closable   - モーダル範囲外及びEscキーで閉じる効果を有効にする
         * @property {ref<Boolean>} isExpanded - 開閉フラグ
         * @property {ref<String>}  message    - 表示するメッセージ
         */
        const sentEmailModal = {
            id: 'completed',
            appearance: 'default',
            closable: false,
            isExpanded: ref(false),
            message: ref('')
        };

        /**
         * methods - メソッド（処理）
         * @type {Object<Fucntion>}
         */
        const methods = {
            /**
             * validateEmail - メールアドレスバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             * @returns {Void}
             */
            validateEmail(event, model) {
                const {currentTarget} = event;
                const {isError, errors} = validates.reissuePassword.validate();

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.reissuePassword, key)) {
                            delete formErrors.reissuePassword[key];
                        }

                        return;
                    }

                    formErrors.reissuePassword[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError) {
                    currentTarget.elements[Object.keys(formErrors.reissuePassword.email)[0]].focus();

                    return;
                }

                // エラーがなかった場合、パスワード再発行処理を実施
                methods.reissuePassword(model);
            },
            /**
             * reissuePassword - パスワード再発行処理
             * @param {Object} model - 送信するモデル
             */
            reissuePassword(model) {
                // すでに処理中の場合は何もしない
                if (forms.isLoading.value) {
                    return;
                }

                // 読み込みフラグの更新（読み込み中）
                forms.isLoading.value = true;

                // パスワード再発行処理を実施
                axios.post('/api/v2/client_auth/password', {...model}).then((response) => {
                    const {message} = response.data;

                    // モーダルのメッセージを更新し、表示する
                    sentEmailModal.message.value = message;
                    sentEmailModal.isExpanded.value = true;
                }).catch((error) => {
                    // エラーメッセージの表示
                    formErrors.system.value = error;
                }).finally(() => {
                    // 読み込みフラグの更新（読み込み完了）
                    forms.isLoading.value = false;
                });
            }
        };

        return {
            models, forms, formErrors, sentEmailModal, methods
        };
    }
});
</script>
