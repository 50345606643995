<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <div class="t-main__heading">
            <h1 class="t-main__title">クライアント管理</h1>
            <standard-button appearance="primary" @click="$router.push({name: 'AdminClientAccountNew'})">
                <template v-slot:before><icon name="Add" /></template>
                新規クライアント登録
            </standard-button>
        </div>

        <div class="t-main__utils">
            <tab-list v-bind="tabs" :current-index="tabs.currentIndex.value">
                <template v-slot:tab="slotProps">
                    <tab v-bind="slotProps.item" :is-external-link="false">{{slotProps.item.label}}</tab>
                </template>
            </tab-list>
        </div>

        <tables v-bind="clientTableData" :is-loading="clientTableData.isLoading.value" :body-rows="clientTableData.bodyRows.value">
            <template v-slot:table-header="tableHeaderProps">
                <table-head v-bind="tableHeaderProps.rows">
                    <template v-slot:header-row="tableRowProps">
                        <table-row v-bind="tableRowProps">
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-6="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-head>
            </template>

            <template v-slot:table-body="tableBodyProps">
                <table-body v-bind="tableBodyProps.rows">
                    <template v-slot:body-row="tableRowProps">
                        <table-row
                            v-bind="tableRowProps"
                            :clickable="true"
                            @click.stop="$router.push({name: 'AdminClientAccountShow', params:{clientId: tableRowProps.cells.slice(-1)[0].content}})"
                            @keydown.enter.stop="$router.push({name: 'AdminClientAccountShow', params:{clientId: tableRowProps.cells.slice(-1)[0].content}})"
                        >
                            <template v-slot:cell-0="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-1="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell"><avator :primary="tableCellProps.cell.content.contact_name" :secondary="tableCellProps.cell.content.company" /></table-cell>
                            </template>
                            <template v-slot:cell-2="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell" :clamp="1">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-3="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-4="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-5="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">{{tableCellProps.cell.content}}</table-cell>
                            </template>
                            <template v-slot:cell-6="tableCellProps">
                                <table-cell v-bind="tableCellProps.cell">
                                    <button-group>
                                        <icon-button
                                            :ref="`alertModalTrigger-${tableCellProps.cell.content}`"
                                            title="停止を解除"
                                            @click.stop="$emit('openModal', alertModal, tableCellProps.cell.content)"
                                            @keydown.enter.stop="$emit('openModal', alertModal, tableCellProps.cell.content)"
                                        >
                                            <icon name="User Active" />
                                        </icon-button>
                                    </button-group>
                                </table-cell>
                            </template>
                        </table-row>
                    </template>
                </table-body>
            </template>

            <template v-slot:empty>
                <empty-message v-bind="clientTableData.emptyMessage"/>
            </template>

            <template v-slot:footer v-if="clientTableData.bodyRows.value.length">
                <p v-if="clientTableData.pagination.pages.value.length">{{clientTableData.pagination.pages.value.slice(-1)[0]}}ページ中 {{clientTableData.pagination.currentPage.value}}ページ</p>
                <pagination :current-page="clientTableData.pagination.currentPage.value" :pages="clientTableData.pagination.pages.value">
                    <template v-slot:item="pagitaionItemProps">
                        <pagination-item
                            :is-current="pagitaionItemProps.item.isCurrent"
                            :disabled="pagitaionItemProps.item.disabled"
                            @clicked="$router.push({query: {...Object.assign({}, $route.query), ...{page: pagitaionItemProps.item.page}}})"
                        >
                            <template v-if="pagitaionItemProps.item.icon" v-slot:icon><icon :name="pagitaionItemProps.item.icon" /></template>
                            {{pagitaionItemProps.item.label}}
                        </pagination-item>
                    </template>
                </pagination>
            </template>
        </tables>
    </main>

    <modal
        v-bind="alertModal"
        :id="alertModal.id"
        :is-expanded="alertModal.isExpanded.value"
        @close="$emit('closeModal', alertModal, $refs[`alertModalTrigger-${alertModal.currentId.value}`].$el)"
    >
        <template v-slot:header="modalHeaderProps">
            <modal-header :title="alertModal.title" v-bind="modalHeaderProps.header">
                <template v-slot:icon><icon name="Error" /></template>
            </modal-header>
        </template>

        <p :id="alertModal.ariaDescribedby">{{alertModal.description}}</p>

        <template v-slot:footer>
            <modal-footer>
                <button-group layout="end">
                    <standard-button :disabled="alertModal.isLoading.value" @click="$emit('closeModal', alertModal, $refs[`alertModalTrigger-${alertModal.currentId.value}`].$el)">キャンセル</standard-button>
                    <standard-button appearance="primary" :is-loading="alertModal.isLoading.value" @click="$emit('reactivateClient', $event)">解除する</standard-button>
                </button-group>
            </modal-footer>
        </template>
    </modal>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import {Avator} from '@/components/01_Atoms/Avators';
import ButtonGroup, {StandardButton, IconButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Tables, {
    TableHead, TableBody, TableRow, TableCell
} from '@/components/01_Atoms/Tables';
import {Tab, TabList} from '@/components/01_Atoms/Tabs';
import Modal, {ModalHeader, ModalFooter} from '@/components/01_Atoms/ModalDialogs';
import Pagination, {PaginationItem} from '@/components/01_Atoms/Paginations';
import {EmptyMessage} from '@/components/01_Atoms/Messages';

export default defineComponent({
    inheritAttrs: false,
    components: {
        Avator, ButtonGroup, StandardButton, IconButton, Icon, Tables, TableHead, TableBody, TableRow, TableCell, Tab, TabList, Modal, ModalHeader, ModalFooter, Pagination, PaginationItem, EmptyMessage
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        tabs: {
            type: Object,
            default: () => ({})
        },
        clientTableData: {
            type: Object,
            default: () => ({})
        },
        alertModal: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 0  24px 24px 98px;
    background: #F2F6F9;
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 234px;
    }

    @at-root {
        .t-main__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 48px 0 64px 0;

            > :last-child {
                flex: 0 0 auto;
            }
        }

        .t-main__utils {
            padding-bottom: 48px;
            margin: auto -24px;
        }

        .t-main__title {
            font-size: 3.6rem;
            line-height: (44 / 36);
            font-weight: bold;
            color: var.$color-text-high;
        }
    }
}
</style>
