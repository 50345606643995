<template>
    <main class="t-main" :class="{'is-menu-expanded': isMenuExpanded}">
        <cards>
            <template v-slot:header>
                <form-header title="基本情報変更" />
            </template>

            <banner v-if="formErrors.system.value.length" appearance="danger">
                <template v-slot:icon><icon name="Error" /></template>
                <p v-for="error in formErrors.system.value" :key="error">{{error}}</p>
            </banner>

            <forms @submit.prevent="$emit('update-client', $event, models.client)">
                <definition-list :items="forms.client">
                    <template v-slot:item-0="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-1="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-2="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-3="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-4="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-5="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-6="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                    <template v-slot:item-7="itemProps">
                        <definition-list-term><form-label v-bind="itemProps.term" /></definition-list-term>
                        <definition-list-description>
                            <text-field
                                v-bind="itemProps.description"
                                :is-error="formErrors.client[itemProps.description.name] !== undefined"
                                :aria-errormessage="`${itemProps.description.id}-invalid`"
                                :aria-required="itemProps.term.isRequire"
                                :model-value="models.client[itemProps.description.name]"
                                @update:model-value="$emit(`update:${itemProps.description.name}`, $event)"
                            />
                            <form-message v-if="formErrors.client[itemProps.description.name]" :id="`${itemProps.description.id}-invalid`" appearance="danger" :message="formErrors.client[itemProps.description.name]"><template v-slot:icon><icon name="Error" /></template></form-message>
                        </definition-list-description>
                    </template>
                </definition-list>

                <template v-slot:footer>
                    <form-footer>
                        <button-group layout="end">
                            <standard-button type="submit" :is-loading="forms.isLoading.value" appearance="primary">変更を保存する</standard-button>
                        </button-group>
                    </form-footer>
                </template>
            </forms>
        </cards>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import Cards from '@/components/01_Atoms/Cards';
import Banner from '@/components/01_Atoms/Banners';
import ButtonGroup, {StandardButton} from '@/components/01_Atoms/Buttons';
import Icon from '@/components/01_Atoms/Icons';
import Forms, {
    FormHeader, FormFooter, FormLabel, FormMessage
} from '@/components/01_Atoms/Forms';
import DefinitionList, {DefinitionListTerm, DefinitionListDescription} from '@/components/01_Atoms/DefinitionLists';
import {TextField} from '@/components/01_Atoms/TextFields';

export default defineComponent({
    inheritAttrs: false,
    emits: [
        'update-client',
        'update:name',
        'update:department',
        'update:position',
        'update:contact_name',
        'update:furigana',
        'update:address',
        'update:tel',
        'update:email'
    ],
    components: {
        Cards, Banner, ButtonGroup, StandardButton, Icon, Forms, FormHeader, FormFooter, FormLabel, FormMessage, DefinitionList, DefinitionListTerm, DefinitionListDescription, TextField
    },
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        },
        forms: {
            type: Object,
            default: () => ({})
        },
        formErrors: {
            type: Object,
            default: () => ({})
        },
        client: {
            type: Object,
            default: () => ({})
        },
        models: {
            type: Object,
            default: () => ({})
        }
    }
});
</script>

<style lang="scss" scoped>
.t-main {
    padding: 24px 24px 24px 277px;
    background: #F2F6F9;
    min-height: calc(100vh - 72px);
    transition: padding-left .3s ease 0s;

    &.is-menu-expanded {
        padding-left: 415px;
    }
}
</style>
