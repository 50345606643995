<template>
    <div class="a-range-input" :class="{'is-error': isError}">
        <div class="a-range-input__label">
            <input v-bind="$attrs" :id="$attrs.id" type="text" class="a-range-input__input" v-model="minimum">
            <span class="a-range-input__text">以上</span>
        </div>
        <span class="a-range-input__separator">〜</span>
        <div class="a-range-input__label">
            <input v-bind="$attrs" :id="null" type="text" class="a-range-input__input" v-model="maximum">
            <span class="a-range-input__text">以下</span>
        </div>
    </div>
</template>

<script>
// import composition-api.
import {defineComponent, computed} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        minValue: {
            type: String,
            default: ''
        },
        maxValue: {
            type: String,
            default: ''
        },
        isError: {
            type: Boolean,
            default: false
        }
    },
    setup(props, $) {
        const model = (modelValue) => ({
            get() {
                return props[modelValue];
            },
            set(value) {
                $.emit(`update:${modelValue}`, value);
            }
        });
        const minimum = computed(model('minValue'));
        const maximum = computed(model('maxValue'));

        return {minimum, maximum};
    }
});
</script>

<style lang="scss" scoped>
.a-range-input {
    display: flex;
    align-items: center;
    width: 100%;

    &.is-error .a-range-input__input {
        border-color: var.$color-danger-50;
    }

    @at-root {
        .a-range-input__input {
            border: solid 1px #B3B8B6;
            border-radius: 2px;
            font-size: 1.6rem;
            line-height: (24 / 16);
            color: var.$color-text-medium;
            background: var.$color-utils-background;
            width: 60px;
            padding: 12px 14px;
            transition: background-color .3s ease 0s, border-color .3s ease 0s;

            &:hover {
                background: #F2F6F9;
            }

            &:focus {
                border-color: var.$color-primary-50;
                background: var.$color-utils-background;
                outline: none;
            }

            &[readonly] {
                background: var.$color-gray-10;
            }

            &[disabled] {
                color: var.$color-text-disabled;
                border-color: var.$color-gray-10;
                background: var.$color-gray-10;
                opacity: .6;
            }
        }

        .a-range-input__separator {
            margin: auto 8px;
            line-height: 1;
        }

        .a-range-input__text {
            margin-left: 8px;
        }
    }
}
</style>
