<template>
    <label class="a-preview-csv__item">
        <p>対象生活者数：
            <span v-if="count">{{ count }}</span>
            <span v-else>ー</span>
        </p>
    </label>
</template>


<script>
// import composition-api.
import {defineComponent} from 'vue';

export default defineComponent({
    inheritAttrs: false,
    props: {
        count: {
            type: null,
            default: '-'
        }
    }
});
</script>

<style lang="scss" scoped>
.a-preview-csv__item {
    width: 100%;
    background: var.$color-utils-background;
    left: 0px;
    top: 50px;
    padding-top: 30px;
}
</style>
