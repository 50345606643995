<template>
    <client-new
        :is-menu-expanded="isMenuExpanded"
        :breadcrumbs="breadcrumbs"
        :models="models"
        :forms="forms"
        :form-errors="formErrors"
        v-model:name="models.client.name"
        v-model:department="models.client.department"
        v-model:position="models.client.position"
        v-model:contact_name="models.client.contact_name"
        v-model:furigana="models.client.furigana"
        v-model:address="models.client.address"
        v-model:tel="models.client.tel"
        v-model:email="models.client.email"
        v-model:email_confirm="models.client.email_confirm"
        v-model:data_services="models.client.data_services"
        @create-client="methods.validateClient"
    />
</template>

<script>
// import composition-api.
import {
    defineComponent, ref, reactive, onMounted
} from 'vue';
import axios from 'axios';
import store from '@/store';
import Validate from '@/validates';
import {useRouter} from 'vue-router';
import {ClientNew} from '@/components/04_Templates/Admin';

export default defineComponent({
    inheritAttrs: false,
    components: {ClientNew},
    props: {
        isMenuExpanded: {
            type: Boolean,
            default: false
        }
    },
    setup(_, $) {
        const $router = useRouter();

        /**
         * breadcrumbs - パンくずリスト
         * @type Array<Object>
         */
        const breadcrumbs = [
            {
                label: 'クライアント管理',
                to: {name: 'AdminClientAccount'},
                beforeIcon: 'Company'
            }, {
                label: 'クライアント登録',
                to: {name: 'AdminClientAccountNew'}
            }
        ];

        /**
         * dataServices - 選択可能サービス一覧
         * @type {ref<Array<Object>>}
         */
        const dataServices = ref([]);

        /**
         * models - フォームのモデル定義
         * @type Object
         * @property {reactive<Object>} client     - クライアントのモデル
         * @property {String} client.name          - 会社名/個人
         * @property {String} client.department    - 部署名
         * @property {String} client.position      - 役職名
         * @property {String} client.contact_name  - お名前
         * @property {String} client.furigana      - フリガナ
         * @property {String} client.address       - 住所
         * @property {String} client.tel           - 電話番号
         * @property {String} client.email         - メールアドレス
         * @property {String} client.data_services - 利用サービス
         */
        const models = {
            client: reactive({
                name: '',
                department: '',
                position: '',
                contact_name: '',
                furigana: '',
                address: '',
                tel: '',
                email: '',
                email_confirm: '',
                data_services: []
            })
        };

        /**
         * forms - フォームの定義
         * @type Object
         * @property {ref<Boolean>}                           isLoading - クライアントのフォーム定義（定義リストの型で定義）
         * @property {Array<{ term: Any, description: Any }>} client    - クライアントのフォーム定義（定義リストの型で定義）
         */
        const forms = {
            isLoading: ref(false),
            client: [
                {
                    term: {
                        id: 'data-service-rule',
                        text: '利用サービス',
                        isRequire: true
                    },
                    description: {
                        inputType: 'checkbox',
                        name: 'data_services',
                        ariaLabelledby: 'data-service-rule',
                        items: dataServices
                    }
                }, {
                    term: {
                        for: 'name',
                        text: '会社名/個人',
                        isRequire: true
                    },
                    description: {
                        id: 'name',
                        name: 'name',
                        placeholder: '例：キャッシュビーデータ株式会社'
                    }
                }, {
                    term: {
                        for: 'department',
                        text: '部署名',
                        isRequire: true
                    },
                    description: {
                        id: 'department',
                        name: 'department',
                        placeholder: '例：営業部'
                    }
                }, {
                    term: {
                        for: 'position',
                        text: '役職名'
                    },
                    description: {
                        id: 'position',
                        name: 'position',
                        placeholder: '例：部長'
                    }
                }, {
                    term: {
                        for: 'contact_name',
                        text: 'お名前',
                        isRequire: true
                    },
                    description: {
                        id: 'contact_name',
                        name: 'contact_name',
                        placeholder: '例：鈴木　一郎'
                    }
                }, {
                    term: {
                        for: 'furigana',
                        text: 'フリガナ',
                        isRequire: true,
                        inputMode: 'byteFullKana'
                    },
                    description: {
                        id: 'furigana',
                        name: 'furigana',
                        placeholder: '例：スズキ　イチロウ'
                    }
                }, {
                    term: {
                        for: 'address',
                        text: '住所',
                        isRequire: true
                    },
                    description: {
                        id: 'address',
                        name: 'address',
                        placeholder: '例：東京都港区赤坂一丁目2番7号'
                    }
                }, {
                    term: {
                        for: 'tel',
                        text: '電話番号',
                        isRequire: true,
                        maxlength: 15,
                        remove: {
                            label: 'ハイフン',
                            item: '-',
                            maxLength: 12
                        }
                    },
                    description: {
                        id: 'tel',
                        name: 'tel',
                        type: 'tel',
                        placeholder: '例：03-1234-5678'
                    }
                }, {
                    term: {
                        for: 'email',
                        text: 'メールアドレス',
                        inputType: 'email',
                        isRequire: true
                    },
                    description: {
                        id: 'email',
                        name: 'email',
                        placeholder: '例：xxxx@xxx.co.jp'
                    }
                }, {
                    term: {
                        for: 'email-confirm',
                        text: 'メールアドレス（再入力）',
                        inputType: 'email',
                        isRequire: true,
                        related: 'email'
                    },
                    description: {
                        id: 'email-confirm',
                        name: 'email_confirm'
                    }
                }
            ]
        };

        /**
         * formErrors - フォームエラーの情報
         * @type {Object}
         * @property {Object} system - APIから返却されたシステムエラー
         * @property {Object} login  - ログイン情報入力フォームのエラー
         */
        const formErrors = {
            system: ref([]),
            client: reactive({})
        };

        /**
         * validates - フォームバリデーションオブジェクト
         * @type {Object}
         */
        const validates = {
            client: new Validate(forms.client.map((obj) => ({...obj.term, ...obj.description})), models.client)
        };

        /**
         * methods - メソッド（処理）定義
         * @type Object<Function>
         */
        const methods = {
            /**
             * emailUniqueCheck - メールアドレス重複チェック
             * @param {String} email - チェック対象のメールアドレス
             * @returns {Promise}
             */
            emailUniqueCheck(email) {
                // メールアドレスが未入力の場合
                if (email === '') {
                    return Promise.resolve({isError: false, message: {}});
                }

                // APIからバリデーション結果を取得
                return new Promise((resolve) => {
                    axios.get('/api/v2/client_account/clients/email_unique_check', {params: {email}}).then((response) => {
                        // 使用可能なメールアドレスの場合
                        resolve({isError: false, message: {email: response.data.message}});
                    }).catch((error) => {
                        // 使用不可なメールアドレスの場合
                        resolve({isError: true, message: {email: error[0]}});
                    });
                });
            },
            /**
             * validateClient - 入力内容のバリデーション処理
             * @param {SubmitEvent} event - 送信イベントオブジェクト
             * @param {Object}      model - 送信するモデル
             */
            async validateClient(event, model) {
                const {currentTarget} = event;
                const {isError, errors} = validates.client.validate();

                // 読み込みフラグを更新（読み込み中）
                forms.isLoading.value = true;

                const uniqueCheck = await methods.emailUniqueCheck(model.email);

                // メールアドレスが利用不可の場合、エラーメッセージを追加
                if (uniqueCheck.isError) {
                    Object.assign(errors, uniqueCheck.message);
                }

                // エラーオブジェクトの更新
                Object.entries(errors).forEach(([key, value]) => {
                    // valueが空文字のものを除いて更新
                    if (value === '') {
                        // エラーが解消された場合はプロパティごと削除
                        if (Object.prototype.hasOwnProperty.call(formErrors.client, key)) {
                            delete formErrors.client[key];
                        }

                        return;
                    }

                    formErrors.client[key] = value;
                });

                // エラーが発生した場合、入力欄にフォーカスを移動、処理を中止
                if (isError || uniqueCheck.isError) {
                    const errorTarget = currentTarget.elements[Object.keys(formErrors.client)[0]];

                    // 選択肢入力欄の場合は先頭にフォーカスする
                    if (errorTarget[Symbol.toStringTag] === 'RadioNodeList') {
                        errorTarget[0].focus();
                    } else {
                        errorTarget.focus();
                    }

                    // 読み込みフラグを更新（読み込み中）
                    forms.isLoading.value = false;

                    return;
                }

                // エラーがなければ作成処理を実行
                methods.createClient(model);
            },
            /**
             * createClient - クライアント作成処理
             * @param model {Object} - 送信するモデル
             */
            createClient(model) {
                forms.isLoading.value = true;

                axios.post(
                    '/api/v2/admin_account/clients',
                    {...model},
                    {headers: store.state.auth}
                ).then(() => {
                    // トーストを表示
                    $.emit('displayToast', {
                        message: 'アカウントを新規作成しました',
                        isError: false,
                        icon: 'Success'
                    });

                    // エラーを削除
                    formErrors.system.value.length = 0;

                    // 一覧ページへ遷移
                    $router.push({name: 'AdminClientAccount'});
                }).catch((error) => {
                    // エラーメッセージを更新
                    formErrors.system.value = error;
                }).finally(() => {
                    forms.isLoading.value = false;
                });
            },
            /**
             * getServices - サービス一覧取得
             * @return {Promise}
             */
            async getServices() {
                const result = [];

                await axios.get('/api/v2/common/data_services').then((response) => {
                    const {data_services: services} = response.data;

                    result.push(...services.map((service) => ({
                        id: `data-service-${service.id}`,
                        label: service.service_name,
                        value: service.service_code,
                        image: require(`@/assets/images/img-service-${service.service_code.replaceAll('_', '')}.png`) // eslint-disable-line
                    })));
                });

                return result;
            }
        };

        onMounted(() => {
            methods.getServices().then((response) => {
                dataServices.value = response;
            });
        });

        return {
            breadcrumbs, models, forms, formErrors, validates, methods
        };
    }
});
</script>
