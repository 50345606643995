<template>
    <main class="t-main">
        <div class="t-main__content">
            <h1 class="t-notfound-title">
                <span class="t-notfound-title__heading">404</span>
                <span class="t-notfound-title__sub">ページが見つかりません</span>
            </h1>

            <div class="t-text">
                <p>URLが正しくないか、ページが移動された可能性があります。</p>
                <p>このページが正しくない場合、システム管理者に連絡してください。</p>
            </div>

            <button-grounp layout="center">
                <standard-button appearance="link" :to="{name: 'ClientLogin'}" @click="$emit('logout', $event)">別のアカウントでログインする</standard-button>
            </button-grounp>
        </div>
        <div class="t-main__media"><img :src="require('@/assets/images/img-not-found.png')" alt=""></div>
    </main>
</template>

<script>
// import composition-api.
import {defineComponent} from 'vue';
import ButtonGrounp, {StandardButton} from '@/components/01_Atoms/Buttons';

export default defineComponent({
    inheritAttrs: false,
    components: {ButtonGrounp, StandardButton}
});
</script>

<style lang="scss" scoped>
.t-main {
    display: flex;
    align-items: center;
    height: 100vh;

    @at-root {
        .t-main__content,
        .t-main__media {
            flex: 0 0 50%;
        }
    }
}

.t-notfound-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1;

    @at-root {
        .t-notfound-title__heading {
            font-size: 25rem;
            color: var.$color-gray-30;
            letter-spacing: -.025em;
        }

        .t-notfound-title__sub {
            font-size: 3.6rem;
            color: var.$color-text-high;
            margin-top: 48px;
            letter-spacing: .025em;
        }
    }
}

.t-text {
    font-size: 1.6rem;
    text-align: center;
    color: var.$color-text-medium;
    margin-top: 32px;
    line-height: 1;

    > :not(:first-child) {
        margin-top: 16px;
    }

    + :not(:first-child) {
        margin-top: 48px;
    }
}

.t-main__media {
    display: flex;
    justify-content: center;
}
</style>
